import { http } from '../../../utils/http';
import { GET_ALL_CART_START, GET_ALL_CART_SUCCESS, GET_ALL_CART_ERROR,
    GET_CART_START, GET_CART_SUCCESS, GET_CART_ERROR
} from '../../constants';
import { setMessage } from '../MessageActions';

const getAllCartStart = () => {
    return { type: GET_ALL_CART_START };
};
const getAllCartSuccess = (payload) => {
    return { type: GET_ALL_CART_SUCCESS, payload };
};
const getAllCartError = (error) => {
    return { type: GET_ALL_CART_ERROR, error };
};

const getCartStart = () => {
    return { type: GET_CART_START };
};
const getCartSuccess = (payload) => {
    return { type: GET_CART_SUCCESS, payload };
};
const getCartError = (error) => {
    return { type: GET_CART_ERROR, error };
};

const addToCart = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', `orders-service/carts/profiles/${id}`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(getAllCartError(err));
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Error!'));
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};
const addToCartBySchoolAdmin = (payload, profileId, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', `orders-service/carts/profiles/${profileId}/books`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(getAllCartError(err));
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Error!'));
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const updateCart = (payload, id, successCB) => (dispatch, getState) => {
    try {
        const isIncrement = payload?.quantity >= 0;
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'PUT', `orders-service/carts/${id}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(`${isIncrement ? 'Incremented' : 'Decremented'} successfully`));
                    successCB && successCB(res);
                }
            })
            .catch((err) => {
                dispatch(setMessage(err?.data?.message));
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const getCart =
    (id, searchData = '', page = 1, rowsPerPage = 1000) =>
    (dispatch) => {
        dispatch(getCartStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_ORDER,
                'GET',
                `orders-service/carts/profiles/${id}?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getCartData: res
                    };
                    dispatch(getCartSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getCartError(err));
                    dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Error!'));
                });
        } catch (error) {
            dispatch(getCartError('Something went wrong'));
        }
    };

const deleteBookFromCart = (cartId, bookId, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'DELETE', `orders-service/carts/${cartId}/books/${bookId}`, true)
            .then((res) => {
                dispatch(setMessage("Deleted Successfully"));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const getAllCart =
    (searchData = '',role='', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllCartStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_ORDER,
                'GET',
                `orders-service/carts/profiles?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}&includeEmptyCarts=false&role=${role}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllCartData: res
                    };
                    dispatch(getAllCartSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllCartError(err));
                });
        } catch (error) {
            dispatch(getAllCartError('Something went wrong'));
        }
    };

export { addToCart,addToCartBySchoolAdmin, updateCart, getAllCart, getCart, deleteBookFromCart };
