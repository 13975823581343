import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Grid, Container, Card, Box, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@material-ui/styles';

import StudentWork from './StudentWork';
import work1 from '../../../../assets/images/homepageOne/work1.png';
import work2 from '../../../../assets/images/homepageOne/work2.png';
import work3 from '../../../../assets/images/homepageOne/student-work3.jpeg';
import work4 from '../../../../assets/images/homepageOne/student-work4.jpeg';
import work5 from '../../../../assets/images/homepageOne/student-work5.jpeg';
import work6 from '../../../../assets/images/homepageOne/student-work8.jpeg';
import work7 from '../../../../assets/images/homepageOne/student-work9.jpeg';
import work8 from '../../../../assets/images/homepageOne/student-work-11.jpeg';
import work9 from '../../../../assets/images/homepageOne/student-work-12.jpeg';
import { height } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
    carousel: {
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        paddingBottom: '64px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}));

export default function WorkDoneCarousel() {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <>
            {matchesLgUp && (
                <Container>
                    <div style={{ marginBottom: '40px' }}>
                        <Carousel
                            showThumbs={false}
                            autoPlay={true}
                            infiniteLoop
                            interval={4000}
                            showArrows={false}
                            showStatus={false}
                            width={'100%'}
                        >
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work1}
                                    header={'Character descriptions written by our learner'}
                                    subHeader={'Dia'}
                                ></StudentWork>
                                <br />
                                <StudentWork
                                    imgUrl={work2}
                                    header={`This story character is a result of our learner's imagination`}
                                    subHeader={'Anvita'}
                                ></StudentWork>
                                <br />
                                <StudentWork
                                    imgUrl={work3}
                                    header={"Online interactive classes to boost your child's vocabulary and grammar"}
                                    subHeader={'Mansi'}
                                ></StudentWork>
                                <br />
                            </Grid>
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work4}
                                    header={'The Muddlehead, as imagined by our learner'}
                                    subHeader={'Surina'}
                                ></StudentWork>
                                <br />
                                <StudentWork imgUrl={work5} header={`The ability to read is vital`} subHeader={'Farida'}></StudentWork>
                                <br />
                                <StudentWork
                                    imgUrl={work6}
                                    header={'It paves the way for success in school and later life'}
                                    subHeader={'Farida'}
                                ></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={1} className={classes.carousel}>
                                <StudentWork imgUrl={work7} header={'Learn about the world'} subHeader={'Avyaan'}></StudentWork>
                                <br />
                                <StudentWork
                                    imgUrl={work8}
                                    header={`Learner's obtain lots of information`}
                                    subHeader={'Neha'}
                                ></StudentWork>
                                <br />
                                <StudentWork imgUrl={work9} header={`Our child's creativity`} subHeader={'Smita'}></StudentWork>
                            </Grid>
                        </Carousel>
                    </div>
                </Container>
            )}

            {matchesLgDown && matchesSmUp && (
                <Container>
                    <div style={{ marginBottom: '40px' }}>
                        <Carousel
                            showThumbs={false}
                            autoPlay={true}
                            infiniteLoop
                            interval={4000}
                            showArrows={false}
                            showStatus={false}
                            width={'100%'}
                        >
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work1}
                                    header={'Character descriptions written by our learner'}
                                    subHeader={'Dia'}
                                ></StudentWork>
                                <br />
                                <StudentWork
                                    imgUrl={work2}
                                    header={`This story character is a result of our learner's imagination`}
                                    subHeader={'Anvita'}
                                ></StudentWork>
                                <br />
                            </Grid>
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work3}
                                    header={"Online interactive classes to boost your child's vocabulary and grammar"}
                                    subHeader={'Mansi'}
                                ></StudentWork>
                                <br />
                                <StudentWork
                                    imgUrl={work4}
                                    header={'The Muddlehead, as imagined by our learner'}
                                    subHeader={'Surina'}
                                ></StudentWork>
                                <br />
                            </Grid>
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork imgUrl={work5} header={`The ability to read is vital`} subHeader={'Farida'}></StudentWork>
                                <br />
                                <StudentWork
                                    imgUrl={work6}
                                    header={'It paves the way for success in school and later life'}
                                    subHeader={'Farida'}
                                ></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={1} className={classes.carousel}>
                                <StudentWork imgUrl={work7} header={'Learn about the world'} subHeader={'Avyaan'}></StudentWork>
                                <br />
                                <StudentWork
                                    imgUrl={work8}
                                    header={`Learner's obtain lots of information`}
                                    subHeader={'Neha'}
                                ></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={1} className={classes.carousel}>
                                <StudentWork imgUrl={work9} header={`Our child's creativity`} subHeader={'Smita'}></StudentWork>
                            </Grid>
                        </Carousel>
                    </div>
                </Container>
            )}

            {matchesSmDown && (
                <Container>
                    <div style={{ marginBottom: '40px' }}>
                        <Carousel
                            showThumbs={false}
                            autoPlay={true}
                            infiniteLoop
                            interval={4000}
                            showArrows={false}
                            showStatus={false}
                            width={'100%'}
                        >
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work1}
                                    header={'Character descriptions written by our learner'}
                                    subHeader={'Dia'}
                                ></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work2}
                                    header={`This story character is a result of our learner's imagination`}
                                    subHeader={'Anvita'}
                                ></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work3}
                                    header={"Online interactive classes to boost your child's vocabulary and grammar"}
                                    subHeader={'Mansi'}
                                ></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work4}
                                    header={'The Muddlehead, as imagined by our learner'}
                                    subHeader={'Surina'}
                                ></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork imgUrl={work5} header={`The ability to read is vital`} subHeader={'Farida'}></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work6}
                                    header={'It paves the way for success in school and later life'}
                                    subHeader={'Farida'}
                                ></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={1} className={classes.carousel}>
                                <StudentWork imgUrl={work7} header={'Learn about the world'} subHeader={'Avyaan'}></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={3} className={classes.carousel}>
                                <StudentWork
                                    imgUrl={work8}
                                    header={`Learner's obtain lots of information`}
                                    subHeader={'Neha'}
                                ></StudentWork>
                            </Grid>
                            <Grid container columnGap={1} rowGap={1} className={classes.carousel}>
                                <StudentWork imgUrl={work9} header={`Our child's creativity`} subHeader={'Smita'}></StudentWork>
                            </Grid>
                        </Carousel>
                    </div>
                </Container>
            )}
        </>
    );
}
