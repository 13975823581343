import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardMedia, Typography, Dialog, DialogContent, DialogTitle } from '@mui/material';

const StudentWork = ({ imgUrl, header, subHeader }) => {
  const [open, setOpen] = useState(false);

  const handleImageClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card onClick={handleImageClick} sx={{ width: 345, cursor: 'pointer' }}>
        <CardMedia component="img" height="140" image={imgUrl} alt="Student Work" />
        <CardContent sx={{ height: 120, marginTop:-5 }}>
          <CardHeader
            title={<Typography variant="h6" style={{ fontWeight: 'lighter' }}>{header}</Typography>}
            subheader={<Typography variant="subtitle1" style={{ fontWeight: 'lighter' }}>{subHeader}</Typography>}
          />
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{<Typography variant="h4" style={{ fontWeight: 'lighter' }}>{header}</Typography>}</DialogTitle>
        <DialogContent>
          <img src={imgUrl} alt="Expanded Student Work" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default StudentWork;
