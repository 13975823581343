import {
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REGISTER_USER_START,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    REGISTER_USER_OTP_START,
    REGISTER_USER_OTP_SUCCESS,
    REGISTER_USER_OTP_ERROR,
    CHANGE_PASSWORD_START,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    FORGET_PASSWORD_START,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    data: null,
    message: '',
    isMailSent: false,
    countryIds: [],
    isLogin: false
};

export default function authReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case LOGIN_START:
        case REGISTER_USER_START:
        case REGISTER_USER_OTP_START:
        case CHANGE_PASSWORD_START:
            return {
                ...state,
                loading: true,
                isMailSent: false,
                isLogin: false
            };
        case LOGIN_SUCCESS:
        case REGISTER_USER_SUCCESS:
        case REGISTER_USER_OTP_SUCCESS:
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false,
                isMailSent: false,
                isLogin: true
            };
        case LOGIN_ERROR:
        case REGISTER_USER_ERROR:
        case REGISTER_USER_OTP_ERROR:
        case CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message,
                isMailSent: false,
                isLogin: false
            };
        case FORGET_PASSWORD_START:
            return {
                ...state,
                loading: true,
                message: ''
            };
        case FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                message: ''
            };
        case FORGET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                message: action.payload
            };
        case RESET_PASSWORD_START:
            return {
                ...state,
                loading: true,
                message: ''
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                message: ''
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                message: action.payload
            };

        default:
            return state;
    }
}
