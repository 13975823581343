import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import { GET_ALL_REFERRAL_START, GET_ALL_REFERRAL_SUCCESS, GET_ALL_REFERRAL_ERROR } from '../../constants';

const getAllReferralStart = () => {
    return { type: GET_ALL_REFERRAL_START };
};
const getAllReferralSuccess = (payload) => {
    return { type: GET_ALL_REFERRAL_SUCCESS, payload };
};
const getAllReferralError = (error) => {
    return { type: GET_ALL_REFERRAL_ERROR, error };
};

const getAllReferral =
    (searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllReferralStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_ORDER,
                'GET',
                `orders-service/referrals?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allReferrals: res
                    };
                    dispatch(getAllReferralSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllReferralError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllReferralError('Something went wrong'));
        }
    };

export { getAllReferral };
