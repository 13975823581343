import React from 'react';
import SneakVideo from '../../../assets/videos/sneak peak 05.mp4';
import { Grid } from '@material-ui/core';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    background: {
        display: 'flex',
        backgroundColor: grey[100],
        padding: 21,
        justifyContent: 'center',
        alignItems: 'center'
    },
    textStyle: {
        fontWeight: 'lighter',
        marginRight: 5
    }
}));

export default function SneakPeak() {
    const classes = useStyles();
    return (
        <div>
            <Grid container className={classes.background}>
                <Grid item sm={6}>
                    <video
                        id="my-player"
                        className="video-js  vjs-default-skin vjs-big-play-button"
                        controls
                        preload="auto"
                        data-setup="{}"
                        style={{ width: '200px', height: '200px' }}
                    >
                        <source src={SneakVideo} type="video/mp4"></source>
                    </video>
                </Grid>
                <Grid item sm={6}>
                    <p className={classes.textStyle}>
                        <span style={{ color: 'orange', fontFamily: 'Josefin Sans', fontWeight:'lighter', fontSize: '21px' }}> Course Review </span>
                        <br />
                        <br />
                        <h6 style={{ textAlign: 'justify', fontSize: '18px', fontFamily: 'Josefin Sans', fontWeight:'lighter', letterSpacing: '1px' }}>
                            The comfort of taking up the BookEinstein online courses at one's own time, expediency and
                            place has encouraged him to take up six courses in year's time and be successful in passing
                            it with flying colours.
                        </h6>
                    </p>
                </Grid>
            </Grid>
        </div>
    );
}
