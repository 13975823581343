import { http } from '../utils/http';

export const contactUs = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/contact-us', payload, false)
            .then((data) => {
                resolve(data);
            })

            .catch((err) => {
                reject(err);
            });
    });
};
