import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography, Button, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getWishlist, deleteBookFromWishlist, moveBookToCart } from '../../../store/actions/WishlistActions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useTheme } from '@emotion/react';
import { setMessage } from '../../../store/actions/MessageActions';

const useStyles = makeStyles((theme) => ({
    productBox: {
        marginLeft: 20
    },
    itemContainer: {
        marginTop: 20,
        borderColor: theme.palette.text.secondary,
        padding: 10,
        borderRadius: 8
    },
    statusStyle: { color: '#008d42' }
}));

const profile_id = Cookies.get('profileId');

export default function Wishlist({ active }) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { getWishlistData } = useSelector((state) => state?.wishlist);
    const newData = getWishlistData?.content;

    const handleDelete = (wishListId, bookId) => {
        dispatch(
            deleteBookFromWishlist(wishListId, bookId, () => {
                setTimeout(() => {
                    window.location.reload();
                    dispatch(getWishlist(`${profile_id}`));
                }, 2000);
            })
        );
    };

    // const handleMoveToCart = async (bookId) => {
    //     await moveBookToCart(profile_id, bookId)
    //         .then((data) => { })
    //         .catch((error) => {
    //             console.log(error);
    //             dispatch(setMessage(error?.data?.message));
    //         });
    //         window.location.reload;        
    // };

    const handleMoveToCart = async (bookId) => {
        try {
            await moveBookToCart(profile_id, bookId);
            dispatch(setMessage("Book moved to cart successfully"));
            window.location.reload();
        } catch (error) {
            console.log(error);
            dispatch(setMessage(error?.data?.message));
        }
    };

    useEffect(() => {
        const p_id = Cookies.get('profileId');
        dispatch(getWishlist(`${p_id}`));
    }, []);

    return (
        <>
            {active === 'FifthCard' && (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="h3">
                                {!newData || newData.length === 0 ? (
                                    <Typography variant="h3" style={{ marginBottom: 80 }}>
                                        Your wishlist is empty
                                    </Typography>
                                ) : (
                                    <Typography variant="h3" style={{ marginBottom: 80 }}>
                                        No. of books in wishlist: <Typography style={{ fontSize: 16 }}>{newData?.length}</Typography>
                                    </Typography>
                                )}
                            </Typography>
                            <Divider style={{ marginTop: 10, marginBottom: 30 }} />
                            {!newData || newData.length === 0 ? (
                                ''
                            ) : (
                                <>
                                    {newData?.map((item) => (
                                        <Grid container xs={12} className={classes.itemContainer}>
                                            <Grid item xs={8}>
                                                <Box display="flex">
                                                    <img
                                                        src={item?.bookDetail?.imageUrls}
                                                        alt="product"
                                                        width="120px"
                                                        height="150px"
                                                        style={{ borderRadius: 8 }}
                                                    />
                                                    <Box className={classes.productBox}>
                                                        <Typography variant="subtitle1"> {item?.bookDetail?.title} </Typography>
                                                        <Typography variant="subtitle2"> {item?.bookDetail?.author} </Typography>
                                                        <Typography
                                                            variant="subtitle1"
                                                            className={classes.statusStyle}
                                                            style={{
                                                                color:
                                                                    (item?.bookDetail?.availableStatus === 'AVAILABLE' &&
                                                                        theme.palette.success.main) ||
                                                                    (item?.bookDetail?.availableStatus === 'OUT_OF_STOCK' &&
                                                                        theme.palette.error.dark)
                                                            }}
                                                        >
                                                            {item?.bookDetail?.availableStatus.replace(/_/g, ' ')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignContent: 'center',
                                                    flexWrap: 'wrap',
                                                    justifyContent: 'space-around'
                                                }}
                                            >
                                                {item?.bookDetail?.availableStatus !== 'OUT_OF_STOCK' ? (
                                                    <Button
                                                        style={{ marginTop: 20 }}
                                                        color="primary"
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() => {
                                                            handleMoveToCart(item?.bookDetail?.id);
                                                        }}
                                                    >
                                                        Move to cart
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                                <CloseIcon
                                                    color="error"
                                                    fontSize="small"
                                                    style={{ cursor: 'pointer', marginTop: 20 }}
                                                    onClick={() => handleDelete(item?.wishListId, item?.bookDetail?.id)}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
