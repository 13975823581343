import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { OutlinedInput, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
    name: PropTypes.string
};

export default function RHFTextField({ name, placeholder, defaultValue, inputProps, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    defaultValue={defaultValue}
                    variant="outlined"
                    placeholder={placeholder}
                    {...field}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                    inputProps={inputProps}
                    {...other}
                />
            )}
        />
    );
}
