import {
    GET_ALL_FAQ_START,
    GET_ALL_FAQ_SUCCESS,
    GET_ALL_FAQ_ERROR,
    GET_FAQ_START,
    GET_FAQ_SUCCESS,
    GET_FAQ_ERROR,
    GET_ALL_QA_START,
    GET_ALL_QA_SUCCESS,
    GET_ALL_QA_ERROR,
    GET_QA_START,
    GET_QA_SUCCESS,
    GET_QA_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    allFAQs: null,
    getFAQData: null,

    allQAs: null,
    getQAData: null,
    message: ''
};

export default function GetAllFAQReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_FAQ_START:
        case GET_FAQ_START:

        case GET_ALL_QA_START:
        case GET_QA_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_FAQ_SUCCESS:
        case GET_FAQ_SUCCESS:

        case GET_ALL_QA_SUCCESS:
        case GET_QA_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_FAQ_ERROR:
        case GET_FAQ_ERROR:

        case GET_ALL_QA_ERROR:
        case GET_QA_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
