import {
    GET_REPORT_START,
    GET_REPORT_SUCCESS,
    GET_REPORT_ERROR,
    GET_ALL_REPORT_START,
    GET_ALL_REPORT_SUCCESS,
    GET_ALL_REPORT_ERROR,
} from '../../constants';

const initialState = {
    loading: false,
    allReportData: null,
    reportData: null,
    message: ''
};

export default function ReportReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_REPORT_START:
        case GET_ALL_REPORT_START:
            return {
                ...state,
                loading: true
            };
        case GET_REPORT_SUCCESS:
        case GET_ALL_REPORT_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_REPORT_ERROR:
        case GET_ALL_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
