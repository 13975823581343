import { GET_PENDING_HOMEWORK_START, GET_PENDING_HOMEWORK_SUCCESS, GET_PENDING_HOMEWORK_ERROR } from '../../constants';

const initialState = {
    loading: false,
    allPendingHomework: null,
    message: ''
};

export default function GetAllPendingHomeworkReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_PENDING_HOMEWORK_START:
            return {
                ...state,
                loading: true
            };
        case GET_PENDING_HOMEWORK_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_PENDING_HOMEWORK_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
