import React, { useRef } from 'react';
import { Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import meetOurTeachersBg from '../../../../assets/images/homepageOne/meetOurTeachersBg.png';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '20%',
            alignItems: 'center',
            width: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '5%',
            alignItems: 'center',
            width: 'auto'
        }
    },
    video: {
        [theme.breakpoints.down('xl')]: {
            display: 'flex',
            justifyContent: 'center',
            marginRight: '-5%',
            marginBottom: '20px'
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
            marginRight: 0,
            marginBottom: '20px'
        }
    },
    descText: {
        [theme.breakpoints.between('sm', 'lg')]: { marginLeft: 'auto', marginRight: 'auto', width: 'auto' }
    }
}));

export default function TeacherTestimonial({ videoUrl, title, subTitle, description, onVideoPlay, onVideoPause }) {
    const classes = useStyles();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
    const videoRef = useRef(null);

    const handlePlay = () => {
        if (onVideoPlay) {
            onVideoPlay();
        }
    };

    const handlePause = () => {
        if (onVideoPause) {
            onVideoPause();
        }
    };

    return (
        <>
            <Grid container className={classes.content}>
                <Box className={classes.video} sx={{ width: '50%', flex: 1, zIndex: '100', borderRadius: '5px', paddingTop: 2 }}>
                    <video
                        ref={videoRef}
                        className="video-js vjs-default-skin vjs-big-play-button"
                        controls
                        preload="auto"
                        style={{ height: '260px', width: '275px', paddingTop: 15, border: '1px solid black' }}
                        onPlay={handlePlay}
                        onPause={handlePause}
                    >
                        <source src={videoUrl} type="video/mp4"></source>
                    </video>
                </Box>
                <Box
                    sx={
                        matchDownSM
                            ? {
                                  width: '100%',
                                  backgroundImage: `url(${meetOurTeachersBg})`,
                                  backgroundSize: 'cover',
                                  padding: '80px',
                                  position: 'relative',
                                  display: 'block',
                                  borderRadius: '5px',
                                  alignSelf: 'center'
                              }
                            : {
                                  width: '80%',
                                  backgroundImage: `url(${meetOurTeachersBg})`,
                                  backgroundSize: 'cover',
                                  padding: '80px',
                                  position: 'relative',
                                  borderRadius: '5px'
                              }
                    }
                    className={classes.descText}
                >
                    <Box
                        sx={{
                            color: '#FF8F44',
                            fontWeight: 'lighter',
                            marginLeft: 'auto',
                            display: 'block',
                            fontSize: '20px',
                            textAlign: 'start',
                            fontFamily: 'Josefin Sans'
                        }}
                    >
                        {' '}
                        {title}{' '}
                    </Box>
                    <Box
                        sx={{
                            color: '#000000',
                            marginBottom: '8px',
                            textAlign: 'start',
                            fontWeight: 'lighter',
                            fontFamily: 'Josefin Sans'
                        }}
                    >
                        {' '}
                        {subTitle}{' '}
                    </Box>
                    <Box sx={{ color: '#000000', textAlign: 'start', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                        <i>
                            {description}
                        </i>
                    </Box>
                </Box>
            </Grid>
        </>
    );
}
