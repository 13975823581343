import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/styles';
import MainCard from '../../../ui-component/cards/MainCard';
import { Grid, Button, Card, IconButton, InputAdornment } from '@material-ui/core';
import { AccountCircle, Edit, Person, PhoneAndroidOutlined, Email } from '@material-ui/icons';
import { getLoggedUserProfile, updateLoggedProfile } from '../../../store/actions/ProfileActions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, switchUserProfile } from '../../../store/actions/AddUserActions';
import BackDropLoader from '../../../ui-component/BackDropLoader';
import useUserRole from '../../../hooks/useUserRole';
import { profileUpload, profileDelete } from '../../../services/ProfileUpload';
import { setMessage } from '../../../store/actions/MessageActions';
import { getAllSchool } from '../../../store/actions/SchoolActions';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        background: theme.palette.primary.main,
        padding: 10,
        borderRadius: 8,
        marginBottom: 20
    },
    pageTitle: {
        fontSize: 16,
        color: theme.palette.background.paper,
        fontWeight: 'lighter'
    },
    viewIcon: {
        verticalAlign: 'sub',
        background: 'tranparent',
        color: '#fff'
    },
    label: {
        width: '200px'
    },
    textfield: {
        width: '500px'
    },
    select: {
        width: '500px'
    },
    btnProfile: {
        width: '90px',
        height: '30px'
    },
    edit: {
        backgroundColor: '#ff8c00',
        width: '20px',
        height: '20px',
        borderRadius: '25px',
        marginTop: '30px',
        marginRight: '100px',
        fontSize: '10px',
        position: 'absolute'
    },
    delete: {
        backgroundColor: '#ff8c00',
        width: '20px',
        height: '20px',
        borderRadius: '25px',
        marginTop: '40px',
        marginRight: '130px',
        fontSize: '10px'
    },
    span: {
        color: 'red'
    },
    error: {
        color: 'red'
    },
    profileDetails: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: { display: 'block' }
    },
    avtar: {
        width: '90px',
        height: '90px',
        [theme.breakpoints.down('md')]: {
            width: 60,
            height: 60
        }
    }
}));

const validationSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    mobile: yup
        .string()
        .matches(/^([0-9]{10})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$/, 'Enter Valid Mobile Number')
        .required('Mobile Number Is required')
});

// const config = {
//     bucketName: 'bookeinstein-s3-bucket',
//     dirName: 'profile-pics',
//     region: 'ap-south-1',
//     accessKeyId: 'AKIAZV3LGYH7PYO7F5R2',
//     secretAccessKey: 'rj1/Y6t+2JQLxb0XUiv4oLpjpLDpIYsNcof7khLC',
//     s3Url: 'https:/your-custom-s3-url.com/', /* optional */
// };

export default function AdminProfile({ active }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'SCHOOL_ADMIN', 'TEACHER', 'STUDENT','SUPER_ADMIN'] });
    const { loggedUserProfile, loading } = useSelector((state) => state?.userProfile);
    const { getUserProfileData } = useSelector((state) => state?.addUser);
    const localProfileId = Cookies.get('profileId');
    const [isEditable, setEditable] = useState(false);
    const [file, setFile] = useState(null);
    const [displayImage, setDisplayImage] = useState();
    const { getAllSchoolData } = useSelector((state) => state?.school);
    const schoolList = getAllSchoolData?.content;

    // console.log('school', schoolList);

    const handleChangePhoto = function loadFile(event) {
        // setDisplayImage(URL.createObjectURL(event.target.files[0]));
        // setFile(event.target.files[0]);
        const imageUrl = URL.createObjectURL(event.target.files[0]);
        setDisplayImage(imageUrl);
        setFile(event.target.files[0]);
        const imageUrlWithCacheBust = imageUrl + `?${new Date().getTime()}`;
        setDisplayImage(imageUrlWithCacheBust);
    };

    const handleUpload = (e) => {
        ProfileUpload();
    };

    const handleDelete = (e) => {
        ProfileDelete();
        setDisplayImage(false);
    };

    const initialValues = {
        firstName: loggedUserProfile?.firstName || '',
        lastName: loggedUserProfile?.lastName || '',
        mobile: loggedUserProfile?.mobile || '',
        email: loggedUserProfile?.email || '',
        profileId: localProfileId || '',
        classAndSection: loggedUserProfile?.classAndSection || '',
        schoolId: loggedUserProfile?.schoolId || ''
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues
    });

    const { reset, handleSubmit, setValue, watch } = methods;
    const switchProfile = watch('profileId');

    const onSubmit = (data) => {
        const payload = {
            firstName: data?.firstName,
            lastName: data?.lastName,
            mobile: data?.mobile,
            email: data?.email,
            classAndSection: data?.classAndSection,
            schoolId: data?.schoolId
        };

        dispatch(
            updateLoggedProfile(payload, () => {
                dispatch(getLoggedUserProfile());
            })
        );
        setEditable(false);
    };

    useEffect(() => {
        if (loggedUserProfile && Object.keys(loggedUserProfile)?.length) {
            setValue('firstName', loggedUserProfile?.firstName);
            setValue('lastName', loggedUserProfile?.lastName);
            setValue('mobile', loggedUserProfile?.mobile);
            setValue('email', loggedUserProfile?.email);
            setValue('classAndSection', loggedUserProfile?.classAndSection);
            setDisplayImage(loggedUserProfile?.profilePictureUrl);
        }
    }, [loggedUserProfile, setValue]);

    const token = Cookies.get('token');

    useEffect(() => {
        if (token) {
            dispatch(getLoggedUserProfile());
        }
    }, [token, dispatch]);

    useEffect(() => {
        if (switchProfile) {
            dispatch(
                switchUserProfile(switchProfile, () => {
                    dispatch(getLoggedUserProfile());
                    dispatch(getUserProfile());
                })
            );
        }
    }, [switchProfile]);

    const ProfileUpload = async () => {
        const formData = new FormData();
        formData.append('profilePicture', file);
        await profileUpload(formData)
            .then((res) => {
                dispatch(setMessage('Profile Picture Uploaded Successfully'));
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage('Something went wrong'));
            });
    };

    const ProfileDelete = async () => {
        const formData = new FormData();
        formData.append('profilePicture', file);
        await profileDelete(formData)
            .then((res) => {
                dispatch(setMessage('Profile Picture Removed Successfully'));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        dispatch(getAllSchool());
    }, []);

    return (
        <>
            {loading && <BackDropLoader isOpen={true} />}
            <Grid item container xs={12} sm={12} md={12} className={classes.card}>
                {active === 'ThirdCard' && (
                    <>
                        <Card className={classes.infoCard}>
                            <MainCard>
                                <div className={classes.titleContainer}>
                                    <Person className={classes.viewIcon} />
                                    <span className={classes.pageTitle}>&nbsp; Profile Details </span>
                                </div>
                                <MainCard>
                                    {/* -------------User Profile------------------ */}

                                    <Grid container paddingLeft="15px" rowSpacing={2}>
                                        <Grid item xs={12} sm={12}>
                                            <input
                                                name="upload"
                                                type="file"
                                                id="upload"
                                                // onClick={ProfileUpload}
                                                // accept="image/*"
                                                onChange={handleChangePhoto}
                                                // onBlur={formik.handleBlur}
                                                // value={formik.values.upload}
                                                style={{ display: 'none', alignSelf: 'left' }}
                                            />

                                            <label htmlFor="upload">
                                                <div className={classes.profileDetails}>
                                                    <div>
                                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                                            <Avatar
                                                                id="avatar"
                                                                src={displayImage}
                                                                //src={loggedUserProfile?.profilePictureUrl}
                                                                className={classes.avtar}
                                                            ></Avatar>
                                                        </IconButton>
                                                    </div>

                                                    <div>
                                                        <Grid item xs={12} alignSelf="center">
                                                            <h5 style={{ width: '150px', marginTop: '23px' }}>
                                                                {loggedUserProfile
                                                                    ? loggedUserProfile?.firstName + ' ' + loggedUserProfile?.lastName
                                                                    : ''}
                                                            </h5>
                                                            <p>{loggedUserProfile ? loggedUserProfile?.email : ''}</p>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </label>
                                        </Grid>
                                        <br />
                                        <Grid container justifyContent="space-between" alignItems="center">
                                            <div>
                                                <Button variant="contained" className="btnProfile" onClick={handleUpload}>
                                                    upload new
                                                </Button>
                                                &nbsp;
                                                <Button variant="outlined" className="btnProfile" onClick={handleDelete}>
                                                    Remove
                                                </Button>
                                            </div>
                                            <div>
                                                <Edit
                                                    color="primary"
                                                    fontSize="small"
                                                    onClick={() => setEditable(true)}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                        <Grid
                                            container
                                            xs={12}
                                            sm={12}
                                            spacing={2}
                                            //columnSpacing={5}
                                            style={{ paddingLeft: '15px', marginTop: 30 }}
                                        >
                                            <Grid item container xs={12} sm={6}>
                                                <label className={classes.label}>
                                                    First Name <span className={classes.span}> * </span>
                                                </label>
                                                <RHFTextField
                                                    name="firstName"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <AccountCircle />
                                                        </InputAdornment>
                                                    }
                                                    required
                                                    disabled={!isEditable}
                                                />
                                            </Grid>
                                            <Grid item container xs={12} sm={6}>
                                                <label className={classes.label}>
                                                    Last Name <span className={classes.span}> * </span>
                                                </label>
                                                <RHFTextField
                                                    name="lastName"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <AccountCircle />
                                                        </InputAdornment>
                                                    }
                                                    required
                                                    disabled={!isEditable}
                                                />
                                            </Grid>
                                            <Grid item container xs={12} sm={6}>
                                                <label className={classes.label}>
                                                    Mobile <span className={classes.span}> * </span>
                                                </label>
                                                <RHFTextField
                                                    name="mobile"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <PhoneAndroidOutlined />
                                                        </InputAdornment>
                                                    }
                                                    required
                                                    disabled={!isEditable}
                                                />
                                            </Grid>
                                            <Grid item container xs={12} sm={6}>
                                                <label className={classes.label}>
                                                    Email <span className={classes.span}> * </span>
                                                </label>
                                                <RHFTextField
                                                    name="email"
                                                    startAdornment={
                                                        <InputAdornment position="start">
                                                            <Email />
                                                        </InputAdornment>
                                                    }
                                                    required
                                                    disabled
                                                />
                                            </Grid>
                                            {loggedUserProfile?.role === 'SCHOOL_ADMIN' && (
                                                <Grid item container xs={12} sm={6}>
                                                    <label className={classes.label}> School </label>
                                                    <RHFTextField name="school" required disabled />
                                                </Grid>
                                            )}

                                            {/* {loggedUserProfile.role === 'STUDENT' && (
                                        <>
                                            <Grid item container xs={12} sm={6}>
                                                <label className={classes.label}> School </label>
                                                <RHFSelect name="schoolId" label="">
                                                    <option value="Select a School" selected>
                                                        Select a School
                                                    </option>
                                                    {schoolList?.map((item, index) => (
                                                        <option key={index} value={item?.id}>
                                                            {item?.name}
                                                        </option>
                                                    ))}
                                                </RHFSelect>
                                            </Grid>

                                            <Grid item container xs={12} sm={6}>
                                                <label className={classes.label}> Class/Section </label>
                                                <RHFTextField name="classAndSection" required disabled={!isEditable} />
                                            </Grid>
                                        </>
                                    )} */}

                                            {loggedUserProfile?.role === 'STUDENT' && (
                                                <>
                                                    <Grid item container xs={12} sm={6}>
                                                        <label className={classes.label}> School </label>
                                                        <RHFSelect name="schoolId" label="" disabled={!isEditable}>
                                                            {schoolList?.length}
                                                            {initialValues.schoolId ? (
                                                                <option
                                                                    value={schoolList?.filter((f) => f?.id === initialValues.schoolId)[0]?.id}
                                                                    selected
                                                                >
                                                                    {schoolList?.filter((f) => f?.id === initialValues.schoolId)[0]?.name}
                                                                </option>
                                                            ) : (
                                                                <option value="Select a School" selected>
                                                                    Select a School
                                                                </option>
                                                            )}
                                                            {schoolList?.map((item, index) => (
                                                                <option key={index} value={item?.id}>
                                                                    {item?.name}
                                                                </option>
                                                            ))}
                                                        </RHFSelect>
                                                    </Grid>

                                                    <Grid item container xs={12} sm={6}>
                                                        <label className={classes.label}> Class/Section </label>
                                                        <RHFTextField name="classAndSection" required disabled={!isEditable} />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            style={{ marginTop: 40, marginBottom: 40 }}
                                        >
                                            <Button variant="contained" type="submit" color="primary">
                                                Save Changes
                                            </Button>
                                        </Grid>

                                        {loggedUserProfile?.role === 'STUDENT' && (
                                            <>
                                                <div className={classes.titleContainer}>
                                                    <Person className={classes.viewIcon} />
                                                    <span className={classes.pageTitle}>&nbsp; Switch Profile </span>
                                                </div>
                                                <Grid item container xs={12} sm={6}>
                                                    <label className={classes.label}>Current Profile</label>
                                                    <RHFSelect name="profileId" label="">
                                                        {getUserProfileData?.userProfile?.map((item) => (
                                                            <option key={item?.id} value={item?.id}>
                                                                {item?.firstName} {item?.lastName}
                                                            </option>
                                                        ))}
                                                    </RHFSelect>
                                                </Grid>
                                            </>
                                        )}
                                        <br />
                                    </FormProvider>
                                </MainCard>
                            </MainCard>
                        </Card>
                    </>
                )}
            </Grid>
        </>
    );
}
