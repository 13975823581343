import React, { useState } from 'react';
import '../../../home/Style.css';
import { Card, Box, Grid } from '@mui/material';
import { useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    courseCard: {
        display: 'flex',
        flexDirection: 'row',
        width: '19em',
        height: '12em',
        padding: '0px 8px 0px 8px',

        [theme.breakpoints.down('md')]: {
            width: '14em',
            height: '9em'
        },
        [theme.breakpoints.down('sm')]: {
            width: '18em',
            height: '12em'
        }
    },
    cardImg: {
        width: '100%',
        height: '100%',
        display: 'block',
        borderRadius: 8
    }
}));

export default function BooksCategoriesCard({ title, imageUrl, id, url, bgColor, minAge, maxAge }) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
                <Card elevation={3} sx={{ backgroundColor: `${bgColor}` }} className={classes.courseCard}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            fontSize: '32px',
                            fontWeight: 'lighter',
                            flex: 1,
                            // alignItems: 'center',
                            justifyContent: 'top'
                            // padding: '8px'
                        }}
                    >
                        <Box style={{ marginBottom: '4px', padding: '12px', marginTop: '50px' }}>
                            {/* {title} */}
                            <Box
                                sx={{
                                    fontSize: '35px',
                                    fontWeight: 'lighter',
                                    color: 'white',
                                    lineHeight: '0.8',
                                    fontFamily: 'Josefin Sans'
                                }}
                            >
                                {title.split(' ')[0] ? title.split(' ')[0] : <></>}
                            </Box>
                            <Box sx={{ fontSize: '35px', fontWeight: 'lighter', color: 'white', lineHeight: '1.8', fontFamily: 'Josefin Sans' }}>
                                {title.split(' ')[1] ? title.split(' ')[1] : <></>}
                            </Box>
                        </Box>
                        {/* <Box style={{ padding: 16 }}>
                    {minAge}-{maxAge}
                </Box> */}
                    </Box>

                    {/* <Box
               
            </Box> */}
                    <Box height={130} width={150} style={{ marginTop: 30 }}>
                        <img src={imageUrl} className={classes.cardImg} />
                        &nbsp;
                    </Box>
                </Card>
            </Grid>
        </Grid>
        // </Link>
    );
}
