import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    dialog: {
        position: 'absolute',
        width: 550,
        [theme.breakpoints.down('xs')]: {
            width: '80vw'
        }
    },
    dialogContent: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginTop: 0
        }
    },
    dialogAction: {
        justifyContent: 'right',
        marginTop: 10,
        marginBottom: 10,
        marginRight: 15
    },
    closeIcon: {
        color: '#FFFFFF',
        marginBottom: '-5px',
        cursor: 'pointer'
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        marginBottom: 20
    },
    dialogTitleChild: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    titleIcon: {
        backgroundColor: theme.palette.error.main,
        color: '#FFFFFF',
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '2.5rem'
        }
    },
    dialogTitleDiv: {
        display: 'inline-block',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    container: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        }
    },
    deleteButton: {
        height: 40,
        width: 80,
        backgroundColor: theme.palette.error.main,
        color: '#FFFFFF',
        '&:hover': {
            background: theme.palette.error.dark
        }
    },
    noButton: {
        height: 40,
        width: 80
    }
}));
