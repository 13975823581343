import React from 'react';
import { Box, Button, Card } from '@mui/material';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter',
        display: 'flex',
        flexDirection: 'row',
        width: '300px',
        height: '180px',
        padding: '0px 8px 0px 8px',
        [theme.breakpoints.down('md')]: {
            width: '400px',
            height: '200px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '300px',
            marginLeft: 20,
            marginRight: 20
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            marginLeft: 0,
            marginRight: 0
        }
    }
}));

export default function CoursesCard({ imageUrl, text, buttonLink, bgColor }) {
    const classes = useStyles();
    return (
        <Card elevation={3} className={classes.container} sx={{ backgroundColor: bgColor, position: 'relative' }}>
            <Box>
                <Box style={{ marginBottom: '4px', padding: '16px' }}>
                    <Box sx={{ fontSize: '20px', fontWeight: 'lighter', color: '#272343', lineHeight: 1 }}>{text}</Box>
                </Box>
                <Box style={{ display: 'flex', position: 'absolute', bottom: '10px' }}>
                    <a href={buttonLink} style={{ textDecoration: 'none' }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#FF8F44',
                                fontFamily: 'Josefin Sans',
                                fontWeight: 'lighter',
                                width: '110px'
                            }}
                        >
                            Know More
                        </Button>
                    </a>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',

                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '60%'
                }}
            >
                <img src={imageUrl} style={{ width: '100%', height: '60%', resize: 'contain' }} alt="child having online class" />
            </Box>
        </Card>
    );
}
