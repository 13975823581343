import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Link, Divider } from '@material-ui/core';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

export default function ConfirmDialog(props) {
    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles();
    const pathname = window.location.pathname;

    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
                <div className={classes.dialogTitleChild}>
                    <Typography style={{ fontSize: 20 }}>{confirmDialog.title}</Typography>
                    <Link to="#" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>
                        <CloseIcon className={classes.closeIcon} />
                    </Link>
                </div>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                <Typography variant="subtitle1">{confirmDialog.subTitle}</Typography>
            </DialogContent>
            <Divider style={{ marginTop: 15 }} />
            <DialogActions className={classes.dialogAction}>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.noButton}
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                >
                    Cancel
                </Button>
                <Button variant="contained" className={classes.deleteButton} onClick={confirmDialog.onConfirm}>
                    {pathname == '/admin/security-deposits' ? 'Refund' : 'Delete'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
