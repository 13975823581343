import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import {
    GET_ALL_VOUCHER_START,
    GET_ALL_VOUCHER_SUCCESS,
    GET_ALL_VOUCHER_ERROR,
    GET_VOUCHER_START,
    GET_VOUCHER_SUCCESS,
    GET_VOUCHER_ERROR
} from '../../constants';

const getAllVoucherStart = () => {
    return { type: GET_ALL_VOUCHER_START };
};
const getAllVoucherSuccess = (payload) => {
    return { type: GET_ALL_VOUCHER_SUCCESS, payload };
};
const getAllVoucherError = (error) => {
    return { type: GET_ALL_VOUCHER_ERROR, error };
};

const getVoucherStart = () => {
    return { type: GET_VOUCHER_START };
};
const getVoucherSuccess = (payload) => {
    return { type: GET_VOUCHER_SUCCESS, payload };
};
const getVoucherError = (error) => {
    return { type: GET_VOUCHER_ERROR, error };
};

const addVoucher = (payload, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'gift-voucher', payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const getVoucher = (id) => (dispatch) => {
    dispatch(getVoucherStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `gift-voucher/${id}`, true)
            .then((res) => {
                const payload = {
                    getVoucherData: res
                };
                dispatch(getVoucherSuccess(payload));
            })
            .catch((err) => {
                dispatch(getVoucherError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const getAllVoucher =
    (searchData = '') =>
    (dispatch) => {
        dispatch(getAllVoucherStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/gift-voucher?searchKey=${searchData}&pageNumber=1&size=20`,
                true
            )
                .then((res) => {
                    const payload = {
                        allVouchers: res
                    };
                    dispatch(getAllVoucherSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllVoucherError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllVoucherError('Something went wrong'));
        }
    };

export { addVoucher, getAllVoucher, getVoucher };
