import { http } from '../utils/http';
import { setMessage } from '../store/actions/MessageActions';

export const addFreeTrail = (payload, dispatch) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/book-free-class', payload, false)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const addVoucher = (payload, dispatch) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/gift-voucher', payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
