import {
    GET_ALL_SCHOOL_START,
    GET_ALL_SCHOOL_SUCCESS,
    GET_ALL_SCHOOL_ERROR,
    GET_ALL_SCHOOL_ENQUIRY_START,
    GET_ALL_SCHOOL_ENQUIRY_SUCCESS,
    GET_ALL_SCHOOL_ENQUIRY_ERROR,
    GET_SCHOOL_START,
    GET_SCHOOL_SUCCESS,
    GET_SCHOOL_ERROR,
    GET_SCHOOL_LIST_START,
    GET_SCHOOL_LIST_SUCCESS,
    GET_SCHOOL_LIST_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    getAllSchoolData: null,
    getAllSchoolEnquiryData: null,
    getSchoolData: null,
    schoolList: null,
    message: ''
};

export default function GetAllSchoolReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_SCHOOL_START:
        case GET_ALL_SCHOOL_ENQUIRY_START:
        case GET_SCHOOL_START:
        case GET_SCHOOL_LIST_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_SCHOOL_SUCCESS:
        case GET_ALL_SCHOOL_ENQUIRY_SUCCESS:
        case GET_SCHOOL_LIST_SUCCESS:
        case GET_SCHOOL_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_SCHOOL_ERROR:
        case GET_ALL_SCHOOL_ENQUIRY_ERROR:
        case GET_SCHOOL_ERROR:
        case GET_SCHOOL_LIST_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
