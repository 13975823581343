import { http } from '../utils/http';
import axios from 'axios';

export const getSubscriptions = (page = 1, rowsPerPage = 10, searchData = '') => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/module-subscriptions?pageNumber=${page}&pageSize=${rowsPerPage}&userSearchKey=${searchData}&moduleSearchKey=${searchData}`
            )
            .then((data) => {
                console.log(data);
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getFreeLessons = (page = 1, rowsPerPage = 10, searchData = '') => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/book-free-class?pageNumber=${page}&pageSize=${rowsPerPage}&searchKey=${searchData}`
            )
            .then((data) => {
                console.log(data);
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getSubscriptionsListByProfileId = (id,page = 1, rowsPerPage = 10) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/module-subscriptions/profiles/${id}?pageNumber=${page}&pageSize=${rowsPerPage}`
            )
            .then((data) => {
                console.log(data);
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};