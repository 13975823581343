import { http } from '../utils/http';

export const getAllLanguages = () => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'GET', `books-service/languages`, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getAllSessions = (id) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/sessions/module/${id}/list`, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getAllActiveBatches = (id, studentAge) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'GET', `batches-service/batches/modules/${id}/active?age=${studentAge}`, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getModuleDetails = (id) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/modules/${id}`, {}, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
