import React from 'react';
import { Box, Card, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export default function PopularModuleCard({ header, img, listOfFeatures }) {
    return (
        <>
            <div style={{ position: 'relative' }}>
                <Card>
                    <Box style={{ display: 'flex', flexDirection: 'column', width: '350px', height: '500px' }}>
                        <Box
                            style={{
                                flex: 2,
                                backgroundImage: `url(${img})`,
                                backgroundSize: '100% 100%',
                                backgroundRepeat: 'no-repeat',
                                border: '1px solid',
                                borderColor: 'transparent'
                            }}
                        ></Box>
                        <Box style={{ flex: 3, padding: '6px' }}>
                            <Box style={{ display: 'flex', flexDirection: 'column', padding: '8px 16px', textAlign: 'start'
                        ,fontWeight: 'lighter' }}>
                                <h5 style={{color: 'black', fontWeight: 'lighter'}}>{header}</h5>
                            </Box>

                            {listOfFeatures.map((item, index) => {
                                return (
                                    <Box style={{ display: 'flex', color: '#FF8F44', fontSize: '20px' }} key={index}>
                                        <Box style={{ padding: '4px' }}>&#x2713;</Box>
                                        <Box
                                            style={{
                                                padding: '4px',
                                                display: 'flex',
                                                justifyContent: 'flex-start'
                                            }}
                                        >
                                            <h6 style={{ color: 'black', textAlign: 'start', fontWeight: 'lighter' }}>{item}</h6>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Card>
                <Box
                    style={{
                        position: 'absolute',
                        bottom: '-4%',
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <a style={{ textDecoration: 'none' }} href = '/login'>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: ' #ff8f44', width: '160px', paddingTop: '8px', paddingBottom: '8px', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                        >
                            Register Now
                        </Button>
                    </a>
                </Box>
            </div>
        </>
    );
}
