import {
    GET_SUBSCRIPTION_START,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR,
    GET_ALL_SUBSCRIPTION_START,
    GET_ALL_SUBSCRIPTION_SUCCESS,
    GET_ALL_SUBSCRIPTION_ERROR,
    GET_ALL_BOOKTRIALS_START,
    GET_ALL_BOOKTRIALS_SUCCESS,
    GET_ALL_BOOKTRIALS_ERROR,
    GET_ALL_SECURITY_DEPOSITS_SUCCESS,
    GET_ALL_SECURITY_DEPOSITS_ERROR,
    GET_ALL_SECURITY_DEPOSITS_START,
    GET_PLAN_START,
    GET_PLAN_SUCCESS,
    GET_PLAN_ERROR,
    GET_ALL_PLAN_START,
    GET_ALL_PLAN_SUCCESS,
    GET_ALL_PLAN_ERROR,
    GET_ALL_USER_PLAN_START,
    GET_ALL_USER_PLAN_SUCCESS,
    GET_ALL_USER_PLAN_ERROR,
    GET_PLAN_OPTION_START,
    GET_PLAN_OPTION_SUCCESS,
    GET_PLAN_OPTION_ERROR,
    GET_ALL_PLAN_OPTION_START,
    GET_ALL_PLAN_OPTION_SUCCESS,
    GET_ALL_PLAN_OPTION_ERROR,

} from '../../constants';

const initialState = {
    loading: false,
    allSubscription: null,
    allBookTrials: null,
    subscriptionData: null,
    getAllPlans: null,
    getUserPlan:null,
    getPlan: null,
    allPlanOptions: null,
    planOption: null,
    allSecurityDeposit: null,
    message: ''
};

export default function SubscriptionReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_SUBSCRIPTION_START:
        case GET_ALL_SUBSCRIPTION_START:
        case GET_ALL_BOOKTRIALS_START:
        case GET_PLAN_START:
        case GET_ALL_PLAN_START:
        case GET_ALL_USER_PLAN_START:
        case GET_PLAN_OPTION_START:
        case GET_ALL_PLAN_OPTION_START:
        case GET_ALL_SECURITY_DEPOSITS_START:
            return {
                ...state,
                loading: true
            };
        case GET_SUBSCRIPTION_SUCCESS:
        case GET_ALL_SUBSCRIPTION_SUCCESS:
        case GET_ALL_BOOKTRIALS_SUCCESS:
        case GET_PLAN_SUCCESS:
        case GET_ALL_PLAN_SUCCESS:
        case GET_ALL_USER_PLAN_SUCCESS:
        case GET_PLAN_OPTION_SUCCESS:
        case GET_ALL_PLAN_OPTION_SUCCESS:
        case GET_ALL_SECURITY_DEPOSITS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_SUBSCRIPTION_ERROR:
        case GET_ALL_SUBSCRIPTION_ERROR:
        case GET_ALL_BOOKTRIALS_ERROR:
        case GET_PLAN_ERROR:
        case GET_ALL_PLAN_ERROR:
        case GET_ALL_USER_PLAN_ERROR:
        case GET_PLAN_OPTION_ERROR:
        case GET_ALL_PLAN_OPTION_ERROR:
        case GET_ALL_SECURITY_DEPOSITS_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
