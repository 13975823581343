import React, { useState, useContext, createContext } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import { login } from '../store/actions/AuthActions/Login.actions';
import { useHistory } from 'react-router';
import { viewLoggedUSerProfileSuccess } from '../store/actions/ProfileActions';

const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const dispatch = useDispatch();
    const token = Cookies.get('token') ? 'user' : null;
    const [user, setUser] = useState(token);
    const history = useHistory();
    const signin = (payload, successCB, errorCB) => {
        dispatch(
            login(
                payload,
                (data) => {
                    setUser('user');
                },
                (err) => {
                    errorCB(err);
                }
            )
        );
    };
    const signout = (cb) => {
        Cookies.remove('token');
        Cookies.remove('profileId');
        Cookies.remove('userRole');
        setUser(null);
        dispatch(viewLoggedUSerProfileSuccess({ loggedUserProfile: null }));
        history.push('/home');
        cb();
    };

    return {
        signin,
        user,
        signout
    };
}
