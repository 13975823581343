import { GET_ALL_PLANS_WITH_OPTIONS_START, GET_ALL_PLANS_WITH_OPTIONS_SUCCESS, GET_ALL_PLANS_WITH_OPTIONS_ERROR } from '../../constants';

const initialState = {
    loading: false,
    getAllPlansWithOptionsData: null,
    message: ''
};

export default function GetAllPlansWithOptionsReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_PLANS_WITH_OPTIONS_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_PLANS_WITH_OPTIONS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_PLANS_WITH_OPTIONS_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
