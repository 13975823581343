import React from 'react';
import Cookies from 'js-cookie';
import { Grid } from '@mui/material';
import FooterBg from '../../../assets/images/footerBg.png';
import mainLogo from '../../../assets/images/mainLogo.png';
import Facebook from '../../../assets/images/facebook.svg';
import Instagram from '../../../assets/images/instagram.svg';
import WhatsApp from '../../../assets/images/whatsapp.svg';

import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    footer: {
        width: '100%',
        background: '#FFEFC8',
        paddingTop: '16px',
        color: 'white'
    },
    footerContainer: {
        backgroundImage: `url(${FooterBg})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
        padding: '4em',
        paddingBottom: '24px',
        [theme.breakpoints.down('sm')]: { justifyContent: 'center', padding: 1 }
    },
    emailInput: {
        marginLeft: -80,
        height: '40px',
        padding: '8px',
        borderRadius: '10px 0px 0px 10px',
        border: 'none',
        [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
    },
    subscribeButton: {
        marginTop: 8,
        height: '40px',
        padding: '8px',
        borderRadius: '0px 10px 10px 0px',
        border: 'none',
        backgroundColor: '#FF8F44',
        color: '#fff',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: { justifyContent: 'center', marginTop: 0 }
    },
    gridItem: {
        margin: '3em'
    },
    informationGrid: {
    },
    socialIcons: { display: 'flex', justifyContent: 'center' },

    allRightsReserved: { display: 'flex', justifyContent: 'center', [theme.breakpoints.down('sm')]: { textAlign: 'center' } },

    icons: { height: '3rem' },

    contactUs: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        }
    },
    footerLogoContainer: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },

    logo: {
        width: '70%',
        [theme.breakpoints.down('sm')]: { width: '60%', justifyContent: 'center', marginTop: 40 }
    }
}));

export default function FooterNew() {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <footer className={classes.footer}>
                <div className={classes.footerContainer}>
                    <Grid container direction="row">
                        <Grid item sm className={classes.gridItem} style={{ minWidth: '20em' }}>
                            <Grid container direction="column" className={classes.footerLogoContainer}>
                                <Grid item>
                                    <img src={mainLogo} alt="" className={classes.logo} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm className={classes.gridItem} style={matchesSM ? { marginLeft: '7.5em' } : undefined}>
                            <Grid
                                container
                                direction="column"
                                alignItems={matchesSM ? 'center' : undefined}
                                display={matchesSM ? 'contents' : undefined}
                            >
                                <Grid item xs={12}>
                                    <h5 style={{ color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}> Information </h5>
                                </Grid>
                                <Grid item>
                                    <Grid spacing={3} container>
                                        <Grid item sm className={classes.informationGrid}>
                                            <Grid container direction="column">
                                                <Grid item>
                                                    <a
                                                        href="/home"
                                                        style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                    >
                                                        Home
                                                    </a>
                                                </Grid>
                                                <Grid item>
                                                    <a
                                                        href="/onlineClasses"
                                                        style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                    >
                                                        Enrichment Classes
                                                    </a>
                                                </Grid>
                                                <Grid item>
                                                    <a
                                                        href="/school"
                                                        style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                    >
                                                        School Offering
                                                    </a>
                                                </Grid>
                                                <Grid item>
                                                    <a
                                                        href="/bookLibrary"
                                                        style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                    >
                                                        Book Library
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item sm className={classes.informationGrid}>
                                            <Grid container direction="column" >
                                                <Grid item>
                                                    {!Cookies.get('token') ? (
                                                        <Link
                                                            to="/login"
                                                            style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                        >
                                                            Refer a Friend
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to="/refer"
                                                            style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                        >
                                                            Refer a Friend
                                                        </Link>
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    <a
                                                        href="/pricing-plans"
                                                        style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                    >
                                                        Pricing Plans
                                                    </a>
                                                </Grid>
                                                <Grid item>
                                                    <Link to="/faq" style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}>
                                                        FAQs
                                                    </Link>
                                                </Grid>
                                                <Grid item>
                                                    <a
                                                        href="/termsConditions"
                                                        style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                    >
                                                        Terms & Conditions
                                                    </a>
                                                </Grid>
                                                <Grid item>
                                                    <a
                                                        href="/privacy-policy"
                                                        style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                    >
                                                        Privacy Policy
                                                    </a>
                                                </Grid>
                                                <Grid item>
                                                    <a
                                                        href="/about-us"
                                                        style={{ textDecoration: 'none', color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}
                                                    >
                                                        About Us
                                                    </a>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm className={classes.gridItem}>
                            <Grid container spacing={0.8} direction="column" className={classes.contactUs}>
                                <Grid item>
                                    <h5 style={{ color: '#fff', fontFamily: 'Josefin Sans', fontWeight:'lighter' }}> Contact Us </h5>
                                </Grid>
                                <Grid item style={{ fontFamily: 'Josefin Sans', fontWeight:'lighter' }}>
                                    213, V.K. Industrial Estate, Pais Street, Opp. Planet Godrej, Mahalaxmi East, Mumbai 400011
                                </Grid>
                                <Grid item style={{ fontFamily: 'Josefin Sans', fontWeight:'lighter' }}>
                                    (+91) 9967650304
                                </Grid>
                                <Grid item style={{ fontFamily: 'Josefin Sans', fontWeight:'lighter' }}>
                                    {' '}
                                    contact@bookeinstein.com
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} direction="column">
                        <Grid item>
                            <Grid container spacing={2} className={classes.socialIcons}>
                                <Grid item>
                                    <a href="https://www.facebook.com/BookEinstein/">
                                        <img src={Facebook} alt="fb link" className={classes.icons}></img>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://www.instagram.com/bookeinstein/">
                                        <img src={Instagram} alt="instagram link" className={classes.icons}></img>
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://api.whatsapp.com/send?phone=9967650304">
                                        <img src={WhatsApp} alt="whatsapp link" className={classes.icons} />
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.allRightsReserved}>
                            <p style={{ fontFamily: 'Josefin Sans', fontWeight:'lighter' }}> All rights reserved © 2023 Educurve India Pvt. Ltd. </p>
                        </Grid>
                    </Grid>
                </div>
            </footer>
        </>
    );
}
