import {
    GET_ALL_GRADE_START,
    GET_ALL_GRADE_SUCCESS,
    GET_ALL_GRADE_ERROR,
    GET_GRADE_START,
    GET_GRADE_SUCCESS,
    GET_GRADE_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    allGrades: null,
    getGradeData: null,
    message: ''
};

export default function GetAllGradeReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_GRADE_START:
        case GET_GRADE_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_GRADE_SUCCESS:
        case GET_GRADE_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_GRADE_ERROR:
        case GET_GRADE_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
