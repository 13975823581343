import React from 'react';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Typography, Button, Container } from '@mui/material';
import { useHistory } from 'react-router';

export default function PageNotFound() {
    const history = useHistory();
    return (
        <div style={{ backgroundColor: '#dfefe8', height: '100vh', textAlign: 'center', verticalAlign: 'center' }}>
            <Container style={{ maxWidth: 'lg' }}>
                <Typography fontSize="88px" style={{ fontWeight: 'lighter' }}>
                    404
                </Typography>
                <br />
                <Typography fontSize="48px">
                    <ReportProblemOutlinedIcon fontSize="24px" style={{ color: 'yellow' }} /> The page you were <br /> looking for is not
                    found!
                </Typography>
                <Typography fontSize="12px">
                    You may have mistyped the address or the page may have <br /> moved
                </Typography>
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    style={{ boxShadow: '0 0.5em 0.5em 0.1em #68c2d9' }}
                    onClick={() => history.push('/home')}
                >
                    Back to Home
                </Button>
            </Container>
        </div>
    );
}
