// import axios from 'axios';
// import Cookies from 'js-cookie';
// import { getDateDifference } from './getDateDifference';

// export const http = (
//     apiUrl = `${process.env.REACT_APP_ROOT_URL}`,
//     method = 'get',
//     request = '',
//     payload = {},
//     isToken = true,
//     headerOptions = {},
//     optionalOptions = {}
// ) => {
//     const url = `${apiUrl}/${request}`;
//     let token = Cookies.get('token');
//     token = token ? token.replace('JWT', '').trim() : null;
//     const authorization = isToken ? { Authorization: `Bearer ${token}` } : {};
//     const options = {
//         method,
//         url,
//         data: payload,
//         headers: {
//             ...authorization,
//             ...headerOptions
//         },
//         ...optionalOptions
//     };

//     return new Promise((resolve, reject) => {
//         axios(options)
//             .then((res) => {
//                 resolve(res.data);
//             })
//             .catch((error) => {
//                 if (error.response && error.response.data) {
//                     if (error?.response?.data?.message === 'Token is expired.' && error?.response?.data?.code === 401) {
//                         refresh();
//                         // window.location.reload();
//                     } else {
//                         error.response.data = {
//                             message: error.response.data.message
//                         };
//                     }
//                     reject(error.response);
//                 } else {
//                     reject('Network Error');
//                 }
//             });
//     });
// };

// export async function refresh() {
//     const refreshToken = Cookies.get('refreshToken');
//     const expireAt = Cookies.get('expiryAt');
//     const { diffMins } = getDateDifference(+expireAt);
//     if (diffMins <= 5 && refreshToken) {
//         try {
//             const response = await http(
//                 process.env.REACT_APP_ROOT_URL,
//                 'POST',
//                 `users-service/users/token/refresh?token=${refreshToken}`,
//                 {},
//                 false
//             );
//             Cookies.set('token', response.access_token);
//             Cookies.set('profileId', response.profile_id);
//             Cookies.set('refreshToken', response.refresh_token);
//             Cookies.set('expiryAt', response.expiry_at);
//         } catch (error) {}
//     }
// }

import axios from 'axios';
import Cookies from 'js-cookie';
import { NavLink, Navigate, Redirect } from 'react-router-dom';
import { getDateDifference } from './getDateDifference';

export const http = (
    apiUrl = `${process.env.REACT_APP_ROOT_URL}`,
    method = 'get',
    request = '',
    payload = {},
    isToken = true,
    headerOptions = {},
    optionalOptions = {}
) => {
    const url = `${apiUrl}/${request}`;
    let token = Cookies.get('token');
    token = token ? token.replace('JWT', '').trim() : null;
    const authorization = isToken ? { Authorization: `Bearer ${token}` } : {};
    const options = {
        method,
        url,
        data: payload,
        headers: {
            ...authorization,
            ...headerOptions
        },
        ...optionalOptions
    };

    return new Promise((resolve, reject) => {
        axios(options)
            .then((res) => {
                resolve(res?.data);
            })
            .catch((error) => {
                if (error?.response && error?.response?.data) {
                    if (error?.response?.data?.message === 'Token is expired.' && error?.response?.data?.code === 401) {
                        refresh();
                        Cookies.remove('expiryAt');
                        Cookies.remove('userRole');
                        Cookies.remove('refreshToken');
                        Cookies.remove('profileId');
                        Cookies.remove('userId');
                        Cookies.remove('token');
                        Cookies.remove('schoolId');
                        // window.location.href = '/login';
                        //<Redirect to="/login" />;
                        //refresh();

                        // window.location.reload();
                    }
                    //  else {
                    //     error.response.data = {
                    //         message: error?.response?.data?.message
                    //     };
                    // }
                    reject(error.response);
                } else {
                    reject('Network Error');
                }
            });
    });
};

export async function refresh() {
    const refreshToken = Cookies.get('refreshToken');
    const expireAt = Cookies.get('expiryAt');
    const { diffMins } = getDateDifference(+expireAt);
    if (diffMins <= 5 && refreshToken) {
        try {
            const response = await http(
                process.env.REACT_APP_ROOT_URL,
                'POST',
                `users-service/users/token/refresh?token=${refreshToken}`,
                {},
                false
            );
            Cookies.set('token', response.access_token);
            Cookies.set('profileId', response.profile_id);
            Cookies.set('refreshToken', response.refresh_token);
            Cookies.set('expiryAt', response.expiry_at);
            if (response.user_id) Cookies.set('userId', response?.user_id);
        } catch (error) {
            window.location.href = '/login';
        }
    }
}

//============================ new code ==========================

// const instance = axios.create({
//   baseURL: `${process.env.REACT_APP_ROOT_URL}`,

// });
// instance.interceptors.request.use(
//   (config) => {
//     const token = TokenService.getLocalAccessToken();
//     if (token) {
//       config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
//     //   config.headers["x-access-token"] = token; // for Node.js Express back-end
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// instance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;
//     if (originalConfig.url !== "/auth/signin" && err.response) {
//       // Access Token was expired
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         try {
//           const rs = await instance.post("/auth/refreshtoken", {
//             refreshToken: TokenService.getLocalRefreshToken(),
//           });
//           const { accessToken } = rs.data;
//           TokenService.updateLocalAccessToken(accessToken);
//           return instance(originalConfig);
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       }
//     }
//     return Promise.reject(err);
//   }
// );
// export default instance;
