import React, { useState, useEffect } from 'react';
import MainCard from '../../../../ui-component/cards/MainCard';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { withStyles, useTheme } from '@material-ui/styles';
import {
    Grid,
    IconButton,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    OutlinedInput,
    InputAdornment,
    Tooltip
} from '@material-ui/core';

import { IconSearch } from '@tabler/icons';
import AddIcon from '@material-ui/icons/Add';
import SubjectIcon from '@material-ui/icons/Subject';
import useStyles from '../../../books/styles';
import { getBooks } from '../../../../store/actions/BookActions';
import { addToCart } from '../../../../store/actions/CartActions';
//import AddEditStock from './AddEditStock';
import AddEditCollection from './add-edit-collection';
import useUserRole from '../../../../hooks/useUserRole';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

export default function BookCollection() {
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'SCHOOL_ADMIN','SUPER_ADMIN'] });
    const history = useHistory();
    const { id,role } = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [value, setValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isOpenModal, setOpenModal] = useState(false);
    const [isCartId, setCartId] = useState(null);
    const [isBooksId, setBooksId] = useState(null);

    const { allBooks } = useSelector((state) => state.books);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        //setPage(0);
    };
    const handleAddBook = (data) => {
        const payload = {
            bookId: data?.id
        };

        dispatch(
            addToCart(payload, id, () => {   
                if(role){
                    history.push(`/admin/cart/${id}${role ? `/${role}` : ''}`);
                }else{
                    history.push(`/admin/cart/${id}`);
                }     
            })
        );
        
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        dispatch(getBooks(value ? value : '', page + 1, rowsPerPage));
    }, [value, page, rowsPerPage]);

    useEffect(() => {
        if (id) {
            setCartId(id);
        }
    }, [id]);

    return (
        <>
            <MainCard>
                <div className={classes.titleContainer}>
                    <SubjectIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; Select Books For Cart </span>
                </div>
                <Paper elevation={5} className={classes.root}>
                    <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <OutlinedInput
                                className={classes.searchControl}
                                id="input-search-header"
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" className={classes.startAdornment} />
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </Grid>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.tableHeaderCell}>
                                <StyledTableRow>
                                    <TableCell> Cover</TableCell>
                                    <TableCell> Title </TableCell>
                                    <TableCell> Author </TableCell>
                                    <TableCell> Publisher </TableCell>
                                    <TableCell> Isbn No. </TableCell>
                                    <TableCell align="center"> Rack </TableCell>
                                    <TableCell align="center"> Status </TableCell>
                                    <TableCell align="right"> Action </TableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {allBooks?.content?.map((row) => (
                                    <StyledTableRow key={row?.id}>
                                        <TableCell>
                                            <img src={row?.imageUrls?.[0]} alt="book" width="60px" />
                                        </TableCell>
                                        <TableCell> {row?.title} </TableCell>
                                        <TableCell> {row?.author} </TableCell>
                                        <TableCell> {row?.publisher} </TableCell>
                                        <TableCell> {row?.isbn} </TableCell>
                                        <TableCell align="center"> {row?.shelfId} </TableCell>
                                        <TableCell align="center">
                                            <Typography
                                                className={classes.status}
                                                style={{
                                                    backgroundColor:
                                                        (row?.status === 'ACTIVE' && theme.palette.success.main) ||
                                                        (row?.status === 'PENDING' && 'blue') ||
                                                        (row?.status === 'INACTIVE' && theme.palette.warning.dark)
                                                }}
                                            >
                                                {row?.status}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Tooltip title="Add Book To Cart">
                                                <IconButton onClick={() => handleAddBook(row)}>
                                                    <AddIcon color="primary" style={{ cursor: 'pointer' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={allBooks?.totalElements || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-displayedRows': {
                                marginBottom: 0
                            },
                            '.MuiTablePagination-selectLabel': {
                                marginBottom: 0
                            }
                        }}
                    />
                </Paper>

                {isOpenModal && <AddEditCollection open={isOpenModal} close={handleCloseModal} isCartId={isCartId} isBooksId={isBooksId} />}
            </MainCard>
        </>
    );
}
