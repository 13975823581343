import { http } from '../utils/http';
import axios from 'axios';

export const getAllPlanDropdown = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/plans`)

            .then((data) => {
                resolve(data);
            })

            .catch((err) => {
                reject(err);
            });
    });
};

export const getAllPlanOptionsDropdown = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/plans/${id}/options`)

            .then((data) => {
                resolve(data);
            })

            .catch((err) => {
                reject(err);
            });
    });
};

export const getPlanDetails = (id) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/plans/${id}`, {}, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getPlanOptionDetails = (id) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/plans/options/${id}`, {}, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export const getAvaialableCoupons = () => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/discount`, {}, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export const checkOfferCouponValidity = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/discount/plan/validate`, payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const checkGiftVoucherValidity = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service​/gift-voucher​/plan​/validate`, payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const calculatePrice = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service​/plan-subscriptions​/calculate`, payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const payNow = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/plan-subscriptions`, payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export const upgradePayNow = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/plan-subscriptions/upgrade/plan`, payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const payNowCash = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/plan-subscriptions/cash`, payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const upgradeNowCash = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/plan-subscriptions/upgrade/cash`, payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updateSubscription = (id) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'PUT', `subscriptions-service/plan-subscriptions/subscriptionUpdate/${id}`,{}, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
