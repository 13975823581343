import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from './../../../config';
import Logo from '../../../assets/images/users/logoWithName.png';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath} style={{ textDecoration: 'none' }}>
            <img src={Logo} alt="Logo" style={{ width: 120 }} />
        </ButtonBase>
    );
};

export default LogoSection;
