import {
    GET_ALL_CONTACTUS_START,
    GET_ALL_CONTACTUS_SUCCESS,
    GET_ALL_CONTACTUS_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    allContactUs: null,
    message: ''
};

export default function GetAllContactUsReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_CONTACTUS_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_CONTACTUS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_CONTACTUS_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
