import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { Button, Link, Stack } from '@material-ui/core';

// project imports
import AnimateButton from './../../../../ui-component/extended/AnimateButton';

// style constant
const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.paper,
        backgroundColor: theme.palette.error.main,
        textTransform: 'capitalize',
        marginLeft: 10,
        marginBottom: 10,
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        }
    }
}));

//-----------------------|| PROFILE MENU - UPGRADE PLAN CARD ||-----------------------//

const UpgradePlanCard = () => {
    const classes = useStyles();

    return (
        <Stack direction="row">
            <AnimateButton>
                <Button component={Link} to="#" variant="contained" className={classes.button}>
                    Logout
                </Button>
            </AnimateButton>
        </Stack>
    );
};

export default UpgradePlanCard;
