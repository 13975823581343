import { dashboard, schoolAdminDashboard, superAdmindashboard } from './dashboard';
import { onlineClasses } from './onlineClasses';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard, onlineClasses, schoolAdminDashboard,superAdmindashboard]
};

export default menuItems;
