import { http } from '../../../utils/http';
import { useHistory } from 'react-router';
import {
    VIEW_PROFILE_START,
    VIEW_PROFILE_SUCCESS,
    VIEW_PROFILE_ERROR,
    LOGGED_USER_PROFILE_START,
    LOGGED_USER_PROFILE_SUCCESS,
    LOGGED_USER_PROFILE_ERROR
} from '../../constants';
import { setMessage } from '../MessageActions';

const viewProfileStart = () => {
    return { type: VIEW_PROFILE_START };
};
const viewProfileSuccess = (payload) => {
    return { type: VIEW_PROFILE_SUCCESS, payload };
};
const viewProfileError = (error) => {
    return { type: VIEW_PROFILE_ERROR, error };
};

const viewLoggedUSerProfileStart = () => {
    return { type: LOGGED_USER_PROFILE_START };
};
export const viewLoggedUSerProfileSuccess = (payload) => {
    return { type: LOGGED_USER_PROFILE_SUCCESS, payload };
};
const viewLoggedUSerProfileError = (error) => {
    return { type: LOGGED_USER_PROFILE_ERROR, error };
};

const viewProfileById = (id) => (dispatch, getState) => {
    dispatch(viewProfileStart());
    try {
        http(process.env.REACT_APP_ROOT_URL, 'GET', `users-service/users/${id}/profiles`, true)
            .then((res) => {
                const payload = {
                    viewProfileData: res
                };
                dispatch(viewProfileSuccess(payload));
            })
            .catch((err) => {
                dispatch(viewProfileError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(viewProfileError('Something went wrong'));
    }
};

const AddProfiles = (payload, id, successCB) => (dispatch, getState) => {
    try {
        http(process.env.REACT_APP_ROOT_URL, 'POST', `users-service/users/${id}/profiles`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(setMessage(error?.message));
    }
};

const getLoggedUserProfile = () => (dispatch, getState) => {
    //const history = useHistory();
    dispatch(viewLoggedUSerProfileStart());
    try {
        http(process.env.REACT_APP_ROOT_URL, 'GET', `users-service/users/profiles/logged`, true)
            .then((res) => {
                const payload = {
                    loggedUserProfile: res
                };
                dispatch(viewLoggedUSerProfileSuccess(payload));
            })
            .catch((err) => {
                dispatch(viewLoggedUSerProfileError(err));
                dispatch(setMessage(err?.message));
                //history.push('/login');
            });
    } catch (error) {
        dispatch(viewLoggedUSerProfileError('Something went wrong'));
    }
};

const updateLoggedProfile = (payload, successCB) => (dispatch, getState) => {
    try {
        http(process.env.REACT_APP_ROOT_URL, 'PUT', `users-service/users/profiles/logged`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Profile Updated Successfully'));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(setMessage(error?.message));
    }
};

export { viewProfileById, AddProfiles, getLoggedUserProfile, updateLoggedProfile };
