import React, { useState, useRef } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Grid, Container, Card, Box } from '@mui/material';

import TeacherTestimonial from './TeacherTestimonial';
import teacher1 from '../../../../assets/videos/homepageOne/Insiya_video.mp4';
import teacher2 from '../../../../assets/videos/homepageOne/Larissa_video.mp4';
import teacher3 from '../../../../assets/videos/homepageOne/Namrata_video.mp4';
import teacher4 from '../../../../assets/videos/homepageOne/Niyosha Teacher.mp4';
import teacher5 from '../../../../assets/videos/homepageOne/Shameera_video.mp4';
import teacher6 from '../../../../assets/videos/homepageOne/Shrinkhala_video.mp4';
import teacher7 from '../../../../assets/videos/homepageOne/Shrinkhala_video.mp4';
import teacher8 from '../../../../assets/videos/homepageOne/Shrinkhala_video.mp4';
import teacher9 from '../../../../assets/videos/homepageOne/Shrinkhala_video.mp4';
import teacher10 from '../../../../assets/videos/homepageOne/Shrinkhala_video.mp4';

export default function TeacherTestimonialCarousel() {
    const carouselRef = useRef(null);
    const videoRefs = [useRef(null), useRef(null)];

    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    const handleVideoPlay = () => {
        setIsVideoPlaying(true);
    };

    const handleVideoPause = () => {
        setIsVideoPlaying(false);
    };

    return (
        <>
            <Container>
                <div className="carousel-wrapper" style={{ marginBottom: '35px' }}>
                    <Carousel
                        ref={carouselRef}
                        showThumbs={false}
                        autoPlay={!isVideoPlaying}
                        infiniteLoop
                        interval={4000}
                        showArrows={false}
                        showStatus={false}
                        width={'95%'}
                    >
                        <div style={{ paddingBottom: '0px' }}>
                            <TeacherTestimonial
                                videoUrl={teacher1}
                                title="Insiya"
                                subTitle="English Enrichment Facilitator"
                                description="Favourite quote- The beautiful thing about learning is that no one can take it away from you.
                                Favourite book- Pride and Prejudice by Jane Austen
                                How would you describe yourself-  As a graduate in English Literature and B.Ed qualified educator, my passion truly revolves around the English language. Throughout my rewarding experience, I have been thrilled to witness incredible growth in children as they develop their proficiency in spoken English, polish their captivating public speaking skills, and unleash their limitless creativity in writing. The English language is not just a subject to me; it's a gateway to unlocking students' potential and empowering them to excel in communication and self-expression. I have an unwavering passion for teaching, and throughout my enriching experience, I have joyfully witnessed remarkable improvement in children, fostering their eloquence in spoken English, honing their captivating abilities in public speaking, and igniting their boundless creativity in writing.
                                "
                                onVideoPlay={() => handleVideoPlay(0)}
                                onVideoPause={() => handleVideoPause(0)}
                            ></TeacherTestimonial>
                        </div>
                        <div style={{ paddingBottom: '0px' }}>
                            <TeacherTestimonial
                                videoUrl={teacher2}
                                title="Larissa"
                                subTitle="English Enrichment Facilitator"
                                description="Favourite quote: What you are is God's gift to you. What you become is your gift to God.
                                Favourite book: Wonder, a novel by R. J. Palacio
                                Describe myself: I love animals! My specialization is in Renaissance in English Literature along with Zoology and Botany, and engaging with kids is the one thing that fulfills my heart- along with long nature walks. Imparting knowledge to younger audience is something I have always wanted to achieve and I believe learning never stops.
                                "
                                onVideoPlay={() => handleVideoPlay(1)}
                                onVideoPause={() => handleVideoPause(1)}
                            ></TeacherTestimonial>
                        </div>
                        <div style={{ paddingBottom: '0px' }}>
                            <TeacherTestimonial
                                videoUrl={teacher3}
                                title="Namrata"
                                subTitle="Senior English Enrichment Facilitator"
                                description="Favourite Quote: “You were born with wings, why prefer to crawl through life?” – Rumi
                                Favourite Book: And the Mountains Echoed, by Khaled Hosseini
                                How would you describe yourself: I am a dreamer – I often find myself indulging in a world of ideas and aspirations. I hold a Diploma in Early Childhood Education. I am currently pursuing Masters in English (3-year correspondence degree).  I always strive to maintain a sense of hope even in challenging circumstances. I have a positive outlook on life; I often see obstacles as a spring board to grow. I am passionate about teaching and creating content that inculcate 21st century skills into the learners and with the necessary tools to succeed and adapt in an ever-evolving world
                                "
                                onVideoPlay={() => handleVideoPlay(1)}
                                onVideoPause={() => handleVideoPause(1)}
                            ></TeacherTestimonial>
                        </div>
                        <div style={{ paddingBottom: '0px' }}>
                            <TeacherTestimonial
                                videoUrl={teacher4}
                                title="Niyosha"
                                subTitle="English Enrichment Facilitator"
                                description="Fav book- ‘Pygmalion’ by George Bernard Shaw and ‘Boy’ by Roald Dahl. 
                                Quote- “A single dream is more powerful than a thousand realities.” 
                                3 lines about self- 
                                Speech and Drama with the Trinity College of London. Been an ardent student of English Literature in college. Realised my dream of being a language coach at the young age of 15, when I was awarded the best orator of school. Ever since then, have worked towards that dream and today, I’m a language and communication skills coach.
                                "
                                onVideoPlay={() => handleVideoPlay(1)}
                                onVideoPause={() => handleVideoPause(1)}
                            ></TeacherTestimonial>
                        </div>
                        <div style={{ paddingBottom: '0px' }}>
                            <TeacherTestimonial
                                videoUrl={teacher5}
                                title="Shameera"
                                subTitle="Senior English Enrichment Facilitator"
                                description="Fav Book: Difficult to pick a favourite book. But I have  a favourite author Ruskin Bond. I love his stories which have vivid imagery and humour. I find myself transported into a different world with his books. Given a choice between James Bond and Ruskin Bond I would go on a date with Ruskin Bond. Ever so curious to know an author and what goes inside his mind that makes him write so effortlessly. 
                                Fav quotation/life philosophy: I believe one must sieze every opportunity that comes ones way and make the most of what life has to offer. You do your best and leave the rest. I keep telling my children it's important to be a good human being with the right values and ethics. Once you are good human being, being a good doctor, philosopher or whatever you want to be will come easily. 
                                About myself: I am Shameera Somani and I wear several hats. I am an award winning nutritionist, early childhood development specialist, content writer, teacher, travel writer, published author, and a macrame artist. I love to learn so I constantly am trying to study and learn something new. I have a Masters in Food Science and Nutrition but then my love for children got me into teaching Speech and  Drama, Elocution and Creative writing. I have taught at several schools in Mumbai and Pune since the past 13 years.  I am certified IELTS trainer from British Council. I have a Diploma in English Language Teaching and a P.G. Diploma in Pre Primary Teacher Training. My kids tease me, 'Mom when will you stop studying?' I love to learn and teach.
                                "
                                onVideoPlay={() => handleVideoPlay(1)}
                                onVideoPause={() => handleVideoPause(1)}
                            ></TeacherTestimonial>
                        </div>
                        <div style={{ paddingBottom: '0px' }}>
                            <TeacherTestimonial
                                videoUrl={teacher6}
                                title="Shrinkhala"
                                subTitle="English Enrichment Facilitator"
                                description="Favourite Quote : “In the end, the world always wins. That's just the way of things. (Kite Runner)
                                - Favourite Book - Kite Runner by Khaled Hosseini
                                I am an avid reader and passionate teacher, dedicated to nurturing children's English skills. With a curious spirit, I explore the world through travel and find solace in the melodies of music. My qualifications in English literature and language education enables me to create engaging and effective learning experiences, fostering a deeper understanding and love for the language among my students.
                                "
                                onVideoPlay={() => handleVideoPlay(1)}
                                onVideoPause={() => handleVideoPause(1)}
                            ></TeacherTestimonial>
                        </div>
                    </Carousel>
                </div>
            </Container>
        </>
    );
}
