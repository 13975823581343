import { http } from '../../../utils/http';
import {
    GET_ALL_SCHOOL_START,
    GET_ALL_SCHOOL_SUCCESS,
    GET_ALL_SCHOOL_ERROR,
    GET_ALL_SCHOOL_ENQUIRY_START,
    GET_ALL_SCHOOL_ENQUIRY_SUCCESS,
    GET_ALL_SCHOOL_ENQUIRY_ERROR,
    GET_SCHOOL_START,
    GET_SCHOOL_SUCCESS,
    GET_SCHOOL_ERROR,
    GET_SCHOOL_LIST_START,
    GET_SCHOOL_LIST_SUCCESS,
    GET_SCHOOL_LIST_ERROR
} from '../../constants';
import { setMessage } from '../MessageActions';

const getSchoolStart = () => {
    return { type: GET_SCHOOL_START };
};
const getSchoolSuccess = (payload) => {
    return { type: GET_SCHOOL_SUCCESS, payload };
};
const getSchoolError = (error) => {
    return { type: GET_SCHOOL_ERROR, error };
};

const getAllSchoolStart = () => {
    return { type: GET_ALL_SCHOOL_START };
};
const getAllSchoolSuccess = (payload) => {
    return { type: GET_ALL_SCHOOL_SUCCESS, payload };
};
const getAllSchoolError = (error) => {
    return { type: GET_ALL_SCHOOL_ERROR, error };
};

const getAllSchoolEnquiryStart = () => {
    return { type: GET_ALL_SCHOOL_ENQUIRY_START };
};
const getAllSchoolEnquirySuccess = (payload) => {
    return { type: GET_ALL_SCHOOL_ENQUIRY_SUCCESS, payload };
};
const getAllSchoolEnquiryError = (error) => {
    return { type: GET_ALL_SCHOOL_ENQUIRY_ERROR, error };
};

const getSchoolListStart = () => {
    return { type: GET_SCHOOL_LIST_START };
};
const getSchoolListSuccess = (payload) => {
    return { type: GET_SCHOOL_LIST_SUCCESS, payload };
};
const getSchoolListError = (error) => {
    return { type: GET_SCHOOL_LIST_ERROR, error };
};

const addSchool = (payload, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SCHOOL, 'POST', 'schools-service/schools', payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data.message ? err?.data.message : err?.data.error));
            });
    } catch (error) {
        console.log(error);
    }
};

const updateSchool = (payload, id, message, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SCHOOL, 'PUT', `schools-service/schools/${id}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(message));
                    successCB && successCB(res);
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Error!'));
            });
    } catch (error) {
        console.log(error);
    }
};
const getSchool = (id, successCB) => (dispatch) => {
    dispatch(getSchoolStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SCHOOL, 'GET', `schools-service/schools/${id}`, true)
            .then((res) => {
                const payload = {
                    getSchoolData: res
                };
                dispatch(getSchoolSuccess(payload));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(getSchoolError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getSchoolError('Something went wrong'));
    }
};
const getAllSchool =
    (rowsPerpage = 25, searchData = '', page = 1) =>
    (dispatch) => {
        dispatch(getAllSchoolStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SCHOOL,
                'GET',
                `schools-service/schools?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerpage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllSchoolData: res
                    };
                    dispatch(getAllSchoolSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllSchoolError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllSchoolError('Something went wrong'));
        }
    };
const getAllDeletedSchool =
    (rowsPerpage = 25, searchData = '', page = 1) =>
    (dispatch) => {
        dispatch(getAllSchoolStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SCHOOL,
                'GET',
                `schools-service/schools/deleted?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerpage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllSchoolData: res
                    };
                    dispatch(getAllSchoolSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllSchoolError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllSchoolError('Something went wrong'));
        }
    };
const getSelectAllSchool = () => (dispatch) => {
    dispatch(getAllSchoolStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SCHOOL, 'GET', `schools-service/schools/list`, true)
            .then((res) => {
                const payload = {
                    getAllSchoolData: res
                };
                dispatch(getAllSchoolSuccess(payload));
            })
            .catch((err) => {
                dispatch(getAllSchoolError(err));
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        dispatch(getAllSchoolError('Something went wrong'));
    }
};

const getAllSchoolEnquiry =
    (rowsPerpage = 100000, page = 1) =>
    (dispatch) => {
        dispatch(getAllSchoolEnquiryStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SCHOOL,
                'GET',
                `schools-service/schools/enquiry?pageNumber=${page}&pageSize=${rowsPerpage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllSchoolEnquiryData: res
                    };
                    dispatch(getAllSchoolEnquirySuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllSchoolEnquiryError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllSchoolEnquiryError('Something went wrong'));
        }
    };
const getSchoolList = () => (dispatch) => {
    dispatch(getSchoolListStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SCHOOL, 'GET', 'schools-service/schools/name-branch', true)
            .then((res) => {
                const payload = {
                    schoolList: res
                };
                dispatch(getSchoolListSuccess(payload));
            })
            .catch((err) => {
                dispatch(getSchoolListError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getSchoolListError('Something went wrong'));
    }
};

export { addSchool, getSelectAllSchool, getAllDeletedSchool, getAllSchool, updateSchool, getSchool, getSchoolList, getAllSchoolEnquiry };
