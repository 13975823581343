import {
    VIEW_STOCK_START,
    VIEW_STOCK_SUCCESS,
    VIEW_STOCK_ERROR,
    UPDATE_STOCK_ERROR,
    UPDATE_STOCK_START,
    UPDATE_STOCK_SUCCESS,
    ADD_STOCK_START,
    ADD_STOCK_SUCCESS,
    ADD_STOCK_ERROR,
    GET_LIBRARY_STOCK_START,
    GET_LIBRARY_STOCK_SUCCESS,
    GET_LIBRARY_STOCK_ERROR,
    UPDATE_LIBRARY_STOCK_START,
    UPDATE_LIBRARY_STOCK_SUCCESS,
    UPDATE_LIBRARY_STOCK_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    viewStockData: null,
    libraryStockData: null,
    message: ''
};

export default function StockReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case VIEW_STOCK_START:
        case UPDATE_STOCK_START:
        case ADD_STOCK_START:
        case GET_LIBRARY_STOCK_START:
        case UPDATE_LIBRARY_STOCK_START:
            return {
                ...state,
                loading: true
            };
        case VIEW_STOCK_SUCCESS:
        case UPDATE_STOCK_SUCCESS:
        case ADD_STOCK_SUCCESS:
        case GET_LIBRARY_STOCK_SUCCESS:
        case UPDATE_LIBRARY_STOCK_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case VIEW_STOCK_ERROR:
        case UPDATE_STOCK_ERROR:
        case ADD_STOCK_ERROR:
        case GET_LIBRARY_STOCK_ERROR:
        case UPDATE_LIBRARY_STOCK_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
