import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Divider, OutlinedInput, Typography, IconButton, Tooltip, Button } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import useStyles from '../../../../ViewTableStyles';
import { getInvoice, getSubscription } from '../../../../../store/actions/SubscriptionActions';
import { useParams } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import useUserRole from '../../../../../hooks/useUserRole';
import { updateSubscription } from '../../../../../services/PlanServices';

export default function ViewSubscription() {
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'SCHOOL_ADMIN', 'STUDENT','SUPER_ADMIN'] });
    const dispatch = useDispatch();
    const { id } = useParams();
    const { subscriptionData } = useSelector((state) => state?.subscriptions);
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);

    const handleInvoice = () => {
        dispatch(getInvoice(id));
    };

    const handleUpdate = async (id) => {
        try {
            await updateSubscription(id);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        dispatch(getSubscription(id));
    }, []);

    return (
        <>
            <Paper style={{ padding: 20 }}>
                <div className={classes.titleContainer}>
                    <SchoolIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; Library Subscription Details </span>
                </div>
                <Divider style={{ margin: '30px 0px' }} />
                <Button onClick={handleInvoice} style={{ marginLeft: 900 }}></Button>
                <Grid container rowSpacing={4} style={{ padding: 10 }}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Plan Type </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="type" disabled value={subscriptionData?.planLimitedInfo?.type} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Payment Mode </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="paymentMode" disabled value={subscriptionData?.paymentMode} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Payment Status </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput
                                name="paymentStatus"
                                disabled
                                value={subscriptionData?.paymentStatus ? subscriptionData?.paymentStatus : 'NA'}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {(loggedUserProfile?.role === 'ADMIN' || loggedUserProfile?.role === 'SUPER_ADMIN') &&
                                subscriptionData?.paymentStatus === 'unpaid' && (
                                    <Button onClick={() => handleUpdate(subscriptionData?.id)}>Update</Button>
                                )}
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Profile Id </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="profileId" disabled value={subscriptionData?.userLimitedInfo?.profileId} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Amount (₹)</label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="amount" disabled value={subscriptionData?.pricePaid} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Created At </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput
                                name="createdAt"
                                disabled
                                value={new Date(subscriptionData?.createdAt).toLocaleDateString('en-US', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Status</label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="amount" disabled value={subscriptionData?.status} fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
                <Divider style={{ margin: '30px 0px' }} />
                <Grid container rowSpacing={4} style={{ padding: 10 }}>
                    <Grid item xs={12}>
                        <Typography variant="h4"> Plan details </Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Plan </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="planId" disabled value={subscriptionData?.planLimitedInfo?.name} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Frequency </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="frequency" disabled value={subscriptionData?.planLimitedInfo?.frequency} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Max Pause Weeks </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput
                                name="maxPauseWeeks"
                                disabled
                                value={subscriptionData?.planOptionLimitedInfo?.maxPauseWeeks}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Duration (Months) </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput
                                name="durationInMonths"
                                disabled
                                value={subscriptionData?.planOptionLimitedInfo?.durationInMonths}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Books Count </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="booksCount" disabled value={subscriptionData?.planLimitedInfo?.booksCount} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Registration Fee (₹) </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput
                                name="registrationFee"
                                disabled
                                value={subscriptionData?.calculatedPlanPrice?.registrationFee}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Security Fee (₹) </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput
                                name="securityFee"
                                disabled
                                value={subscriptionData?.calculatedPlanPrice?.securityFee}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <label style={{ verticalAlign: 'sub' }}> Plan Option Type </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="type" disabled value={subscriptionData?.planOptionLimitedInfo?.type} fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
