import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const auth = useAuth();
    const token = Cookies.get('token');
    if (!auth?.user) {
        return <Redirect to="/home" />;
    }

    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
