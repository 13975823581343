import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import TrailForm from './freeTrail';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from '../../../../components/hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Container, Typography, Card, Button, Tab, Tabs, Box, OutlinedInput, Divider } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LanguageIcon from '@material-ui/icons/Language';
import { makeStyles } from '@material-ui/styles';
import './ModuleStyle.css';
import Modal from './Modal';
import Header from '../../components/header';
import BackgroundImg from '../../../../assets/images/moduleBack.jpg';
import SneakPeak from '../../components/SneakPeak';
import { RHFSelect } from '../../../../components/hook-form';
import PropTypes from 'prop-types';
import { getAllCourse, getAllModule, getModuleDetails, getAllBatch } from '../../../../store/actions/CourseActions';
import { moduleDetails } from '../../../../store/actions/ModuleDetailActions';
import { useLocation } from 'react-router-dom';
import { getAllActiveBatches } from '../../../../services/ModuleServices';
import { useMediaQuery, useTheme } from '@material-ui/core';
import FooterNew from '../../components/FooterNew';

const MIN_AGE = [
    // { id: 0, age: '1' },
    // { id: 1, age: '2' },
    { id: 2, age: '3' },
    { id: 3, age: '4' },
    { id: 4, age: '5' },
    { id: 5, age: '6' },
    { id: 6, age: '7' },
    { id: 7, age: '8' },
    { id: 8, age: '9' },
    { id: 9, age: '10' },
    { id: 10, age: '11' },
    { id: 11, age: '12' },
    { id: 12, age: '13' },
    { id: 13, age: '14' },
    { id: 14, age: '15' }
];

const validationSchema = yup.object({
    // age: yup.string('Choose Age').required('Age is required'),
    // moduleId: yup.string('Choose Module').required('Module is required')
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}
const useStyles = makeStyles((theme) => ({
    title: { fontSize: 66, fontFamily: 'Josefin Sans' },

    cardImg: {
        width: '59%',
        height: '100%',
        display: 'block',
        borderRadius: 8
    },
    heroTitle: {
        font: 'normal normal lighter 50px/80px Josefin Sans',
        letterSpacing: 0,
        color: '#272343',
        opacity: 1,
        textTransform: 'capitalize',
        marginLeft: 210,
        marginTop: 40
    },
    heroSubTitle: {
        font: 'normal normal lighter 20px/40px Josefin Sans',
        letterSpacing: 0,
        color: '#272343',
        opacity: 1,
        textTransform: 'capitalize',
        marginLeft: 210,
        marginTop: 5
    },
    cardDiv: {
        marginTop: '3%',
        width: 1240,
        height: 173,
        borderRadius: 10,
        opacity: 1,
        backgroundColor: '#f87e4e',
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    cardItem: {
        width: 250,
        alignSelf: 'center'
    },
    cardItemBtn: {
        width: 250,
        alignSelf: 'center',
        paddingTop: 45
    },
    formCardDiv: {
        // margin-left: 20%;
        width: '60%',
        height: '100%',
        opacity: 1,
        padding: 24,
        backgroundColor: '#f7fdfe'
    },

    submitBtn: {
        marginTop: '10%',
        marginLeft: '85px',
        width: '50%',
        height: '10%',
        backgroundColor: '#ff8f44',
        borderRadius: 10,
        opacity: 1
    },

    freeBtn: {
        marginTop: '5%',
        marginBottom: '10%',
        marginLeft: '85px',
        width: '50%',
        height: '10%',
        borderRadius: 10,
        opacity: 1
    },
    fieldGrid: {
        padding: 16
    },
    fieldValue: {
        paddingLeft: 60,
        marginBlockEnd: 'auto',
        marginTop: 5
    },
    dividerColor: {
        borderColor: 'darkgray'
    },
    titleMd: { fontSize: 44, fontFamily: 'Josefin Sans' },

    cardImgMd: {
        width: '59%',
        height: '100%',
        display: 'block',
        borderRadius: 8
    },
    heroTitleMd: {
        font: 'normal normal lighter 40px/60px Josefin Sans',
        letterSpacing: 0,
        color: '#272343',
        opacity: 1,
        textTransform: 'capitalize',
        marginLeft: 100,
        marginTop: 40
    },
    heroSubTitleMd: {
        font: 'normal normal lighter 20px/32px Josefin Sans',
        letterSpacing: 0,
        color: '#272343',
        opacity: 1,
        textTransform: 'capitalize',
        marginLeft: 100,
        marginTop: 5
    },
    cardDivMd: {
        marginTop: '3%',
        borderRadius: 10,
        opacity: 1,
        backgroundColor: '#f87e4e',
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    cardItemMd: {
        alignSelf: 'center'
    },
    cardItemBtnMd: {
        alignSelf: 'center',
        paddingTop: 70,
        marginRight: '4%'
    },
    formCardDivMd: {
        // margin-left: 20%;
        width: '100%',
        height: '100%',
        opacity: 1,
        padding: 40,
        backgroundColor: '#f7fdfe'
    },

    submitBtnMd: {
        marginTop: '10%',
        marginLeft: '65px',
        width: '50%',
        height: '10%',
        backgroundColor: '#ff8f44',
        borderRadius: 10,
        opacity: 1
    },

    freeBtnMd: {
        marginTop: '5%',
        marginBottom: '10%',
        marginLeft: '65px',
        width: '50%',
        height: '10%',
        borderRadius: 10,
        opacity: 1
    },
    fieldGridMd: {
        padding: 16
    },
    fieldValueMd: {
        paddingLeft: 30,
        marginBlockEnd: 'auto',
        marginTop: 5
    },
    dividerColorMd: {
        borderColor: 'darkgray'
    },
    titleSm: { fontSize: 28, fontFamily: 'Josefin Sans' },

    cardImgSm: {
        width: '59%',
        height: '100%',
        display: 'block',
        borderRadius: 8
    },
    heroTitleSm: {
        font: 'normal normal lighter 40px/60px Josefin Sans',
        letterSpacing: 0,
        color: '#272343',
        opacity: 1,
        textTransform: 'capitalize',
        marginLeft: 70,
        marginTop: 30
    },
    heroSubTitleSm: {
        font: 'normal normal lighter 20px/28px Josefin Sans',
        letterSpacing: 0,
        color: '#272343',
        opacity: 1,
        textTransform: 'capitalize',
        marginLeft: 70,
        marginTop: 5
    },
    cardDivSm: {
        marginTop: '3%',
        borderRadius: 10,
        opacity: 1,
        backgroundColor: '#f87e4e',
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    cardItemSm: {
        alignSelf: 'center'
    },
    cardItemBtnSm: {
        alignSelf: 'center',
        paddingTop: 70,
        marginRight: '4%'
    },
    formCardDivSm: {
        // margin-left: 20%;
        width: '100%',
        height: '100%',
        opacity: 1,
        padding: 30,
        backgroundColor: '#f7fdfe'
    },

    submitBtnSm: {
        marginTop: '10%',
        marginLeft: '55px',
        width: '50%',
        height: '10%',
        backgroundColor: '#ff8f44',
        borderRadius: 10,
        opacity: 1
    },

    freeBtnSm: {
        marginTop: '5%',
        marginBottom: '10%',
        marginLeft: '45px',
        width: '50%',
        height: '10%',
        borderRadius: 10,
        opacity: 1
    },
    fieldGridSm: {
        padding: 12
    },
    fieldValueSm: {
        paddingLeft: 20,
        marginBlockEnd: 'auto',
        marginTop: 5
    },
    dividerColorSm: {
        borderColor: 'darkgray'
    }
}));

export default function ModuleDetails() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const resultRef = useRef(null);
    const theme = useTheme();
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const id = location?.state?.id;

    const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const { allModules, allSessions } = useSelector((state) => state?.course);
    const { allBatches } = useSelector((state) => state?.course);
    // const batches = allBatches?.content;

    const moduleList = allModules?.content;
    console.log('module list=========', moduleList);
    const { allDetails } = useSelector((state) => state?.moduleDetail);

    const [openSchool, setOpenSchool] = useState(false);
    const [value, setValue] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [loading, setLoading] = useState(false);

    const initialValues = {
        age: '',
        moduleId: ''
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues
    });

    const { reset, handleSubmit, watch } = methods;
    const mId = watch('moduleId');
    const studentAge = watch('age');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleRegister = () => {
        setOpenModal(true);
        getAllActiveBatches(mId, studentAge);
    };

    const onSubmit = (data) => {
        const payload = {
            age: data?.age,
            moduleId: mId
        };

        dispatch(moduleDetails(mId, payload));
        setEnabled(true);
        resultRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        setEnabled(false);

        dispatch(getAllCourse(id,studentAge));
        if (mId) {
            dispatch(getAllModule(mId));
        }
    }, [studentAge]);

    return (
        <>
            {matchesLgUp && (
                <div style={{ overflowX: 'hidden', width: '100%', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                    <Header />
                    <Grid container style={{ backgroundImage: `url(${BackgroundImg})`, fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                        <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                            <Typography variant="h2" className={classes.heroTitle} style={{ color: '#2EBEE1' }}>
                                {location?.state?.title.replace(/_/g, ' ')}
                            </Typography>
                            <Typography variant="h4" align="left" className={classes.heroSubTitle}>
                                Open a world of imagination and creativity, enhance language to express your thoughts clearly
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            md={6}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{ padding: '0 20px', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                        >
                            <Box
                                height={300}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: 40,
                                    width: '100%',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans'
                                }}
                            >
                                <img src={location?.state?.imageUrl} className={classes.cardImg} alt="Course" />
                                &nbsp;
                            </Box>
                        </Grid>
                    </Grid>

                    <Container>
                        <div>
                            <Typography className="moduleDetail" style={{ color: '#2EBEE1', fontWeight: 'lighter' }}>
                                {' '}
                                Module Details{' '}
                            </Typography>
                            <Typography className="moduleDetailSub">{location?.state?.description}</Typography>
                        </div>

                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <div
                                className={classes.cardDiv}
                                style={{ backgroundColor: '#2EBEE1', width: '60%', marginLeft: '20%', height: 130, gridGap: '5%' }}
                            >
                                <div className={classes.cardItem} style={{ marginLeft: '12%' }}>
                                    <Typography className="age"> Select Your Age </Typography>

                                    <RHFSelect name="age" label="" className="ageDropdown" required>
                                        <option value="" disabled>
                                            Select Age
                                        </option>
                                        {MIN_AGE?.map((item, index) => (
                                            <option key={index} value={item?.age}>
                                                {item?.age}
                                            </option>
                                        ))}
                                    </RHFSelect>
                                </div>

                                <div className={classes.cardItem}>
                                    <Typography className="module"> Select Module </Typography>

                                    <RHFSelect name="moduleId" label="" className="ageDropdown" required disabled={!studentAge}>
                                        <option value="">Select a Module</option>
                                        {!moduleList?.length>0 ? (
                                            <option value="">No data</option>
                                        ) : (
                                            moduleList?.map((item, index) => (
                                                <option key={index} value={item?.id}>
                                                    {item?.title}
                                                </option>
                                            ))
                                        )}
                                    </RHFSelect>
                                </div>

                                <div className={classes.cardItemBtn}>
                                    <Button
                                        type="submit"
                                        className="btn"
                                        style={{
                                            backgroundColor: '#ff8f44',
                                            color: 'white',
                                            fontWeight: 'lighter',
                                            fontFamily: 'Josefin Sans',
                                            width: 120,
                                            borderRadius: 5
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </FormProvider>
                        <Grid container>
                            <Grid display="column">
                                <h3 style={{ marginTop: 40, marginBottom: 20, fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                                    {' '}
                                    {allDetails?.title}{' '}
                                </h3>
                            </Grid>
                        </Grid>
                        {enabled ? (
                            <Grid container spacing={2} style={{ marginBottom: 50 }}>
                                <Grid item xs={12} md={6} display="column">
                                    <Tabs
                                        value={value}
                                        variant={matchDownSM ? 'scrollable' : 'fullWidth'}
                                        onChange={handleChange}
                                        aria-label="simple tabs example"
                                        textColor="primary"
                                        scrollButtons="auto"
                                    >
                                        <Tab
                                            label="Description"
                                            {...a11yProps(0)}
                                            style={{ fontSize: 16, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                        <Tab
                                            label="Session Plan"
                                            {...a11yProps(1)}
                                            style={{ fontSize: 16, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                        <Tab
                                            label="Focus Skills"
                                            {...a11yProps(2)}
                                            style={{ fontSize: 16, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                        <Tab
                                            label="Sneak Peak"
                                            {...a11yProps(3)}
                                            style={{ fontSize: 16, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                    </Tabs>

                                    <TabPanel value={value} index={0}>
                                        <h5 style={{ flexWrap: 'wrap-reverse', fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}>
                                            {' '}
                                            {allDetails?.description}{' '}
                                        </h5>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {allDetails?.sessions?.map((item, index) => {
                                            return (
                                                <Card
                                                    elevation={2}
                                                    style={{
                                                        padding: 12,
                                                        marginBottom: 12,
                                                        fontWeight: 'lighter',
                                                        fontFamily: 'Josefin Sans'
                                                    }}
                                                    key={index}
                                                >
                                                    <h6 style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}> {item?.title} </h6>
                                                    <p> {item?.description} </p>
                                                </Card>
                                            );
                                        })}
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        {allDetails?.focusSkills?.map((item, index) => {
                                            return (
                                                <Card
                                                    style={{
                                                        marginBottom: 5,
                                                        display: 'flex',
                                                        fontWeight: 'lighter',
                                                        fontFamily: 'Josefin Sans'
                                                    }}
                                                    key={index}
                                                >
                                                    <img src={item?.imageUrl} width="50px" alt="Skill" height="50px" />{' '}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h6 style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans', fontSize: 20 }}>
                                                        {item?.title}
                                                    </h6>
                                                </Card>
                                            );
                                        })}
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <SneakPeak />
                                    </TabPanel>
                                </Grid>
                                <Grid item xs={12} md={6} display="flex" justifyContent="center" ref={resultRef}>
                                    <Card elevation={5} className={classes.formCardDiv}>
                                        <Typography
                                            style={{ color: '#2EBEE1', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                            variant="h3"
                                        >
                                            Course Details
                                        </Typography>
                                        <Grid item container className={classes.fieldGrid}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub', display: 'flex' }}>
                                                    <LocalOfferIcon style={{ color: '#2EBEE1' }} />
                                                    &nbsp; &nbsp;
                                                    <b wrap> Course Price </b>
                                                </label>
                                            </Grid>

                                            <Grid item xs={6} className={classes.fieldValue}>
                                                ₹ {allDetails?.price}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColor} />
                                        <Grid item container className={classes.fieldGrid}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <PermIdentityIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Instructor </b>
                                                </label>
                                            </Grid>

                                            <Grid item xs={6} className={classes.fieldValue}>
                                                {allDetails?.instructor}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColor} />
                                        <Grid item container className={classes.fieldGrid}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <AccessTimeIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Duration </b>
                                                </label>
                                            </Grid>

                                            <Grid item xs={6} className={classes.fieldValue}>
                                                <div> {allDetails?.duration} Minutes </div>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColor} />
                                        <Grid item container className={classes.fieldGrid}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <MenuBookIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Sessions </b>
                                                </label>
                                            </Grid>

                                            <Grid xs={6} className={classes.fieldValue}>
                                                {allDetails?.sessionsCount}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColor} />
                                        <Grid item container className={classes.fieldGrid}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <LanguageIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Language </b>
                                                </label>
                                            </Grid>

                                            <Grid xs={6} className={classes.fieldValue}>
                                                {allDetails?.language}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColor} />
                                        <Container>
                                            <Link
                                                to={{
                                                    pathname: loggedUserProfile?.role ? '/module-details' : '/login'
                                                }}
                                                style={{ textDecoration: 'none', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                            >
                                                <Button
                                                    onClick={handleRegister}
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submitBtn}
                                                    style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                                >
                                                    Register Now
                                                </Button>
                                            </Link>
                                            <br />
                                            {/* <Button
                                        style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                        variant="contained"
                                        color="primary"
                                        className={classes.freeBtn}
                                        onClick={() => {
                                            setOpenSchool(true);
                                        }}
                                    >
                                        Free Trial
                                    </Button> */}
                                        </Container>
                                    </Card>
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Container>
                    <FooterNew />
                    {openSchool && (
                        <TrailForm
                            openSchool={openSchool}
                            setOpenSchool={setOpenSchool}
                            title={allDetails?.title}
                            setLoading={setLoading}
                        />
                    )}

                    {openModal && <Modal openModal={openModal} setOpenModal={setOpenModal} mId={mId} studentAge={studentAge} />}
                    {loading && <Loader />}
                </div>
            )}

            {matchesLgDown && matchesSmUp && (
                <div style={{ overflowX: 'hidden', width: '100%', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                    <Header />
                    <Grid container style={{ backgroundImage: `url(${BackgroundImg})`, fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                        <Grid item xs={9} md={4} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                            <Typography className={classes.heroTitleMd} style={{ color: '#2EBEE1' }}>
                                {location?.state?.title.replace(/_/g, ' ')}
                            </Typography>
                            <Typography align="left" className={classes.heroSubTitleMd}>
                                Open a world of imagination and creativity, enhance language to express your thoughts clearly
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            container
                            xs={9}
                            md={4}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{ padding: '0 20px', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                        >
                            <Box
                                height={200}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: 40,
                                    width: '100%',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans'
                                }}
                            >
                                <img src={location?.state?.imageUrl} className={classes.cardImgMd} alt="Course" />
                                &nbsp;
                            </Box>
                        </Grid>
                    </Grid>

                    <Container>
                        <div>
                            <Typography className="moduleDetail" style={{ color: '#2EBEE1', fontWeight: 'lighter' }}>
                                {' '}
                                Module Details{' '}
                            </Typography>
                            <Typography className="moduleDetailSub">{location?.state?.description}</Typography>
                        </div>

                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <div
                                className={classes.cardDivMd}
                                style={{ backgroundColor: '#2EBEE1', width: '80%', marginLeft: '10%', height: 150, gridGap: '5%' }}
                            >
                                <div className={classes.cardItemMd} style={{ marginLeft: '4%' }}>
                                    <Typography className="age" style={{ fontSize: '24px' }}>
                                        {' '}
                                        Select Your Age{' '}
                                    </Typography>

                                    <RHFSelect name="age" label="" className="ageDropdown" required>
                                        <option value="" disabled>
                                            Select Age
                                        </option>
                                        {MIN_AGE?.map((item, index) => (
                                            <option key={index} value={item?.age} style={{ fontSize: '12px' }}>
                                                {item?.age}
                                            </option>
                                        ))}
                                    </RHFSelect>
                                </div>

                                <div className={classes.cardItemMd}>
                                    <Typography className="module" style={{ fontSize: '24px' }}>
                                        {' '}
                                        Select Module{' '}
                                    </Typography>

                                    <RHFSelect name="moduleId" label="" className="ageDropdown" required disabled={!studentAge}>
                                        <option value="" disabled>
                                            Select a Module
                                        </option>
                                        {!moduleList?.length>0 ? (
                                            <option value="">No data</option>
                                        ) : (
                                            moduleList?.map((item, index) => (
                                                <option key={index} value={item?.id}>
                                                    {item?.title}
                                                </option>
                                            ))
                                        )}
                                    </RHFSelect>
                                </div>

                                <div className={classes.cardItemBtnMd}>
                                    <Button
                                        type="submit"
                                        className="btn"
                                        style={{
                                            backgroundColor: '#ff8f44',
                                            color: 'white',
                                            fontWeight: 'lighter',
                                            fontFamily: 'Josefin Sans',
                                            width: 120,
                                            borderRadius: 5
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </FormProvider>
                        <Grid container>
                            <Grid display="column">
                                <h4 style={{ marginTop: 40, marginBottom: 20, fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                                    {' '}
                                    {allDetails?.title}{' '}
                                </h4>
                            </Grid>
                        </Grid>
                        {enabled ? (
                            <Grid container spacing={2} style={{ marginBottom: 50 }}>
                                <Grid item xs={12} md={6} display="column">
                                    <Tabs
                                        value={value}
                                        variant={matchDownSM ? 'scrollable' : 'fullWidth'}
                                        onChange={handleChange}
                                        aria-label="simple tabs example"
                                        textColor="primary"
                                        scrollButtons="auto"
                                    >
                                        <Tab
                                            label="Description"
                                            {...a11yProps(0)}
                                            style={{ fontSize: 12, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                        <Tab
                                            label="Session Plan"
                                            {...a11yProps(1)}
                                            style={{ fontSize: 12, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                        <Tab
                                            label="Focus Skills"
                                            {...a11yProps(2)}
                                            style={{ fontSize: 12, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                        <Tab
                                            label="Sneak Peak"
                                            {...a11yProps(3)}
                                            style={{ fontSize: 12, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                    </Tabs>

                                    <TabPanel value={value} index={0}>
                                        <h6 style={{ flexWrap: 'wrap-reverse', fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}>
                                            {' '}
                                            {allDetails?.description}{' '}
                                        </h6>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {allDetails?.sessions?.map((item, index) => {
                                            return (
                                                <Card
                                                    elevation={2}
                                                    style={{
                                                        padding: 12,
                                                        marginBottom: 12,
                                                        fontWeight: 'lighter',
                                                        fontFamily: 'Josefin Sans'
                                                    }}
                                                    key={index}
                                                >
                                                    <h6 style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}> {item?.title} </h6>
                                                    <p> {item?.description} </p>
                                                </Card>
                                            );
                                        })}
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        {allDetails?.focusSkills?.map((item, index) => {
                                            return (
                                                <Card
                                                    style={{
                                                        marginBottom: 5,
                                                        display: 'flex',
                                                        fontWeight: 'lighter',
                                                        fontFamily: 'Josefin Sans'
                                                    }}
                                                    key={index}
                                                >
                                                    <img src={item?.imageUrl} width="50px" alt="Skill" height="50px" />{' '}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h6 style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans', fontSize: 16 }}>
                                                        {item?.title}
                                                    </h6>
                                                </Card>
                                            );
                                        })}
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <SneakPeak />
                                    </TabPanel>
                                </Grid>
                                <Grid item xs={9} md={4} display="flex" justifyContent="center" ref={resultRef} marginLeft={10}>
                                    <Card elevation={5} className={classes.formCardDivMd}>
                                        <Typography style={{ color: '#2EBEE1', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                                            Course Details
                                        </Typography>
                                        <Grid item container className={classes.fieldGridMd}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub', display: 'flex' }}>
                                                    <LocalOfferIcon style={{ color: '#2EBEE1' }} />
                                                    &nbsp; &nbsp;
                                                    <b wrap> Course Price </b>
                                                </label>
                                            </Grid>

                                            <Grid item xs={6} className={classes.fieldValueMd}>
                                                ₹ {allDetails?.price}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColorMd} />
                                        <Grid item container className={classes.fieldGridMd}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <PermIdentityIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Instructor </b>
                                                </label>
                                            </Grid>

                                            <Grid item xs={6} className={classes.fieldValueMd}>
                                                {allDetails?.instructor}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColor} />
                                        <Grid item container className={classes.fieldGridMd}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <AccessTimeIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Duration </b>
                                                </label>
                                            </Grid>

                                            <Grid item xs={6} className={classes.fieldValueMd}>
                                                <div> {allDetails?.duration} Minutes </div>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColorMd} />
                                        <Grid item container className={classes.fieldGridMd}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <MenuBookIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Sessions </b>
                                                </label>
                                            </Grid>

                                            <Grid xs={6} className={classes.fieldValueMd}>
                                                {allDetails?.sessionsCount}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColorMd} />
                                        <Grid item container className={classes.fieldGridMd}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <LanguageIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Language </b>
                                                </label>
                                            </Grid>

                                            <Grid xs={6} className={classes.fieldValueMd}>
                                                {allDetails?.language}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColorMd} />
                                        <Container>
                                            <Link
                                                to={{
                                                    pathname: loggedUserProfile?.role ? '/module-details' : '/login'
                                                }}
                                                style={{ textDecoration: 'none', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                            >
                                                <Button
                                                    onClick={handleRegister}
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submitBtnMd}
                                                    style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                                >
                                                    Register Now
                                                </Button>
                                            </Link>
                                            <br />
                                            {/* <Button
                                        style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                        variant="contained"
                                        color="primary"
                                        className={classes.freeBtn}
                                        onClick={() => {
                                            setOpenSchool(true);
                                        }}
                                    >
                                        Free Trial
                                    </Button> */}
                                        </Container>
                                    </Card>
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Container>
                    <FooterNew />
                    {openSchool && (
                        <TrailForm
                            openSchool={openSchool}
                            setOpenSchool={setOpenSchool}
                            title={allDetails?.title}
                            setLoading={setLoading}
                        />
                    )}

                    {openModal && <Modal openModal={openModal} setOpenModal={setOpenModal} mId={mId} studentAge={studentAge} />}
                    {loading && <Loader />}
                </div>
            )}

            {matchesSmDown && (
                <div style={{ overflowX: 'hidden', width: '100%', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                    <Header />
                    <Grid container style={{ backgroundImage: `url(${BackgroundImg})`, fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                        <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
                            <Typography variant="h2" className={classes.heroTitleSm} style={{ color: '#2EBEE1' }}>
                                {location?.state?.title.replace(/_/g, ' ')}
                            </Typography>
                            <Typography align="left" className={classes.heroSubTitleSm}>
                                Open a world of imagination and creativity, enhance language to express your thoughts clearly
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            md={6}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            style={{ padding: '0 20px', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                        >
                            <Box
                                height={300}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: 40,
                                    width: '100%',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans'
                                }}
                            >
                                <img src={location?.state?.imageUrl} className={classes.cardImgSm} alt="Course" />
                                &nbsp;
                            </Box>
                        </Grid>
                    </Grid>

                    <Container>
                        <div>
                            <Typography className="moduleDetail" style={{ color: '#2EBEE1', fontWeight: 'lighter' }}>
                                {' '}
                                Module Details{' '}
                            </Typography>
                            <Typography className="moduleDetailSub">{location?.state?.description}</Typography>
                        </div>

                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <div
                                className={classes.cardDivSm}
                                style={{ backgroundColor: '#2EBEE1', width: '80%', marginLeft: '10%', height: 150, gridGap: '5%' }}
                            >
                                <div className={classes.cardItemSm} style={{ marginLeft: '8%' }}>
                                    <Typography className="age" style={{ fontSize: '20px' }}>
                                        {' '}
                                        Select Your Age{' '}
                                    </Typography>

                                    <RHFSelect name="age" label="" className="ageDropdown" required>
                                        <option value="" disabled>
                                            Select Age
                                        </option>
                                        {MIN_AGE?.map((item, index) => (
                                            <option key={index} value={item?.age} style={{ fontSize: '9px' }}>
                                                {item?.age}
                                            </option>
                                        ))}
                                    </RHFSelect>
                                </div>

                                <div className={classes.cardItemSm}>
                                    <Typography className="module" style={{ fontSize: '20px' }}>
                                        {' '}
                                        Select Module{' '}
                                    </Typography>

                                    <RHFSelect name="moduleId" label="" className="ageDropdown" required disabled={!studentAge}>
                                        <option value="" disabled>
                                            Select a Module
                                        </option>
                                        {moduleList?.map((item, index) => (
                                            <option key={index} value={item?.id} style={{ fontSize: '9px' }}>
                                                {item?.title}
                                            </option>
                                        ))}
                                    </RHFSelect>
                                </div>

                                <div className={classes.cardItemBtnSm}>
                                    <Button
                                        type="submit"
                                        className="btn"
                                        style={{
                                            backgroundColor: '#ff8f44',
                                            color: 'white',
                                            fontWeight: 'lighter',
                                            fontFamily: 'Josefin Sans',
                                            width: 100,
                                            borderRadius: 5
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </FormProvider>
                        <Grid container>
                            <Grid display="column">
                                <h6 style={{ marginTop: 40, marginBottom: 20, fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                                    {' '}
                                    {allDetails?.title}{' '}
                                </h6>
                            </Grid>
                        </Grid>
                        {enabled ? (
                            <Grid container spacing={2} style={{ marginBottom: 50 }}>
                                <Grid item xs={12} md={6} display="column">
                                    <Tabs
                                        value={value}
                                        variant={matchDownSM ? 'scrollable' : 'fullWidth'}
                                        onChange={handleChange}
                                        aria-label="simple tabs example"
                                        textColor="primary"
                                        scrollButtons="auto"
                                    >
                                        <Tab
                                            label="Description"
                                            {...a11yProps(0)}
                                            style={{ fontSize: 10, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                        <Tab
                                            label="Session Plan"
                                            {...a11yProps(1)}
                                            style={{ fontSize: 10, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                        <Tab
                                            label="Focus Skills"
                                            {...a11yProps(2)}
                                            style={{ fontSize: 10, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                        <Tab
                                            label="Sneak Peak"
                                            {...a11yProps(3)}
                                            style={{ fontSize: 10, fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}
                                        />
                                    </Tabs>

                                    <TabPanel value={value} index={0}>
                                        <h6 style={{ flexWrap: 'wrap-reverse', fontFamily: 'Josefin Sans', fontWeight: 'lighter' }}>
                                            {' '}
                                            {allDetails?.description}{' '}
                                        </h6>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {allDetails?.sessions?.map((item, index) => {
                                            return (
                                                <Card
                                                    elevation={2}
                                                    style={{
                                                        padding: 12,
                                                        marginBottom: 12,
                                                        fontWeight: 'lighter',
                                                        fontFamily: 'Josefin Sans'
                                                    }}
                                                    key={index}
                                                >
                                                    <h6 style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}> {item?.title} </h6>
                                                    <p> {item?.description} </p>
                                                </Card>
                                            );
                                        })}
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        {allDetails?.focusSkills?.map((item, index) => {
                                            return (
                                                <Card
                                                    style={{
                                                        marginBottom: 5,
                                                        display: 'flex',
                                                        fontWeight: 'lighter',
                                                        fontFamily: 'Josefin Sans'
                                                    }}
                                                    key={index}
                                                >
                                                    <img src={item?.imageUrl} width="50px" alt="Skill" height="50px" />{' '}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <h6 style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans', fontSize: 14 }}>
                                                        {item?.title}
                                                    </h6>
                                                </Card>
                                            );
                                        })}
                                    </TabPanel>
                                    <TabPanel value={value} index={3}>
                                        <SneakPeak />
                                    </TabPanel>
                                </Grid>
                                <Grid item xs={9} md={4} display="flex" justifyContent="center" marginLeft={8} ref={resultRef}>
                                    <Card elevation={5} className={classes.formCardDivSm}>
                                        <Typography style={{ color: '#2EBEE1', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                                            Course Details
                                        </Typography>
                                        <Grid item container className={classes.fieldGridSm}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub', display: 'flex' }}>
                                                    <LocalOfferIcon style={{ color: '#2EBEE1' }} />
                                                    &nbsp; &nbsp;
                                                    <b wrap> Course Price </b>
                                                </label>
                                            </Grid>

                                            <Grid item xs={6} className={classes.fieldValueSm}>
                                                ₹ {allDetails?.price}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColorSm} />
                                        <Grid item container className={classes.fieldGridSm}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <PermIdentityIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Instructor </b>
                                                </label>
                                            </Grid>

                                            <Grid item xs={6} className={classes.fieldValueSm}>
                                                {allDetails?.instructor}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColorSm} />
                                        <Grid item container className={classes.fieldGridSm}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <AccessTimeIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Duration </b>
                                                </label>
                                            </Grid>

                                            <Grid item xs={6} className={classes.fieldValueSm}>
                                                <div> {allDetails?.duration} Minutes </div>
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColorSm} />
                                        <Grid item container className={classes.fieldGridSm}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <MenuBookIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Sessions </b>
                                                </label>
                                            </Grid>

                                            <Grid xs={6} className={classes.fieldValueSm}>
                                                {allDetails?.sessionsCount}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColorSm} />
                                        <Grid item container className={classes.fieldGridSm}>
                                            <Grid item xs={6}>
                                                <label style={{ verticalAlign: 'sub' }}>
                                                    <LanguageIcon style={{ color: '#2EBEE1' }} /> &nbsp; <b> Language </b>
                                                </label>
                                            </Grid>

                                            <Grid xs={6} className={classes.fieldValueSm}>
                                                {allDetails?.language}
                                            </Grid>
                                        </Grid>
                                        <Divider className={classes.dividerColorSm} />
                                        <Container>
                                            <Link
                                                to={{
                                                    pathname: loggedUserProfile?.role ? '/module-details' : '/login'
                                                }}
                                                style={{ textDecoration: 'none', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                            >
                                                <Button
                                                    onClick={handleRegister}
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submitBtnSm}
                                                    style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                                >
                                                    Register Now
                                                </Button>
                                            </Link>
                                            <br />
                                            {/* <Button
                                        style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                        variant="contained"
                                        color="primary"
                                        className={classes.freeBtn}
                                        onClick={() => {
                                            setOpenSchool(true);
                                        }}
                                    >
                                        Free Trial
                                    </Button> */}
                                        </Container>
                                    </Card>
                                </Grid>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Container>
                    <FooterNew />
                    {openSchool && (
                        <TrailForm
                            openSchool={openSchool}
                            setOpenSchool={setOpenSchool}
                            title={allDetails?.title}
                            setLoading={setLoading}
                        />
                    )}

                    {openModal && <Modal openModal={openModal} setOpenModal={setOpenModal} mId={mId} studentAge={studentAge} />}
                    {loading && <Loader />}
                </div>
            )}
        </>
    );
}
