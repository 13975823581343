import React, { useState, useEffect } from 'react';
import { setMessage } from '../../../../store/actions/MessageActions';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import FreeTrailImg from '../../../../assets/images/free-trail.jpg';
import { Grid, Button, OutlinedInput, TextField, Box } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Dialog, Link } from '@material-ui/core';
import { addFreeTrail } from '../../../../services/FreeTrialService';
import { getAllActiveBatches, getAllActiveCoursesAge } from '../../../../services/courseCard';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    label: {
        marginBottom: '.0rem',
        fontSize: '15px',
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter',
        color: '#fff'
    },
    topSection: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter',
        justifyContent: 'space-evenly',
        marginLeft: 30,
        [theme.breakpoints.down('md')]: {
            height: 225,
            marginLeft: 0
        },
        [theme.breakpoints.down('sm')]: {
            height: 185,
            marginLeft: 0
        }
    },
    img: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    dialogPaper: {
        maxWidth: '900px',
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter',
        margin: 'auto',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '900px'
        },
        [theme.breakpoints.up('md')]: {
            overflow: 'hidden'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%'
        }
    },
    limitedSeat: {
        marginLeft: '10px',
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    title: {
        marginTop: '20px',
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter',
        alignSelf: 'left',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    }
}));

const validationSchema = yup.object({
    name: yup.string('Enter Your Name').required('Name is required').trim(),
    age: yup.string('Enter Your Age').required('Reg is required').trim(),
    phone: yup.string('Enter Your Number').required('Number  is required'),
    email: yup.string('Enter Email').required('Email is required').trim(),
    courseId: yup.string('Enter Course').required('Course is required'),
    comment: yup.string('Enter Comment').required('Comment is required').trim()
});

export default function TrailForm(props) {
    const { openSchool, setOpenSchool, id, setLoading } = props;
    const pathname = window.location.pathname;

    const dispatch = useDispatch();
    const classes = useStyles();
    const [courseData, setCourseData] = useState([]);
    const [batchData, setBatchData] = useState([]);

    const handlePopupClose = () => {
        setOpenSchool(false);
    };

    const [selectedBatchStartDate, setSelectedBatchStartDate] = useState('');
    const GetAllActiveBatch = async (courseId, age) => {
        await getAllActiveBatches(courseId, age)
            .then((res) => {
                setBatchData(res?.data);
                if (res?.data.length > 0) {
                    setSelectedBatchStartDate(res?.data[0]?.startDateTime);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const initialValues = {
        name: '',
        phone: '',
        email: '',
        courseId: id,
        batchId: id,
        comment: '',
        mode:''
        
    };
    const initialValues1 = {
        name: '',
        age: '',
        phone: '',
        email: '',
        courseId: '',
        batchId: '',
        comment: '',

    };

    const AddFreeTrail = async (values) => {
        console.log('first');

        setLoading(true);
        await addFreeTrail(values)
            .then((res) => {
                setLoading(false);
                dispatch(setMessage(res?.message ? res?.message : 'Successful!!'));
                handlePopupClose();
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    };

    const formik = useFormik({
        initialValues: pathname === '/onlineClasses' ? initialValues1 : initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: AddFreeTrail
    });

    function parseDateTime(input) {
        let date, time;

        if (input) {
            const datetime = new Date(input);
            date = datetime.toISOString().split('T')[0];
            time = datetime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }

        return { date, time };
    }

    useEffect(() => {
        const fetchData = async () => {
            if (formik.values.age) {
                await getAllActiveCoursesAge(formik.values.age)
                    .then((res) => {
                        setCourseData(res?.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        };

        fetchData();
    }, [formik.values.age]);

    useEffect(() => {
        if (formik.values.courseId && formik.values.age) {
            GetAllActiveBatch(formik.values.courseId, formik.values.age);
        }
    }, [formik.values.courseId, formik.values.age]);

    return (
        <div>
            <Dialog open={openSchool} onClose={handlePopupClose} classes={{ paper: classes.dialogPaper }} maxWidth={'md'}>
                <span
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'right',
                        paddingRight: 8
                    }}
                >
                    <Link to="#" onClick={handlePopupClose}>
                        <CloseIcon />
                    </Link>
                </span>

                <Grid container style={{ padding: '0 18px', width: 'auto' }}>
                    <Grid container item xs={12} md={5} className={classes.topSection}>
                        <Grid item xs={12} md={6}>
                            <h5 className={classes.title}>Your Child's Growth in the early critical years will not stop</h5>

                            <Button
                                style={{
                                    backgroundColor: '#ff8f44',
                                    borderRadius: 26,
                                    color: 'white',
                                    fontFamily: 'Josefin Sans',
                                    fontWeight: 'lighter'
                                }}
                                variant="contained"
                                disabled
                            >
                                Book your Free Trial Class today!
                            </Button>

                            <p className={classes.limitedSeat}> Limited seats only! </p>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.img}>
                            <Box sx={{ width: '225px', height: '180px', marginBottom: '5px' }}>
                                <img src={FreeTrailImg} alt=""></img>
                            </Box>
                        </Grid>
                    </Grid>
                    &nbsp;
                    <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        style={{
                            backgroundColor: '#2EBEE1',
                            borderRadius: '25px',
                            marginTop: '15px',
                            marginBottom: '45px'
                        }}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <Grid spacing={2} container style={{ padding: 18 }}>
                                <Grid item xs={12} sm={6}>
                                    <label className={classes.label}> Student Name </label>
                                    <OutlinedInput
                                        fullWidth
                                        required
                                        variant="outlined"
                                        margin="dense"
                                        id="name"
                                        name="name"
                                        size="small"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helpertext={formik.touched.name && formik.errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={classes.label}> Age </label>
                                    <OutlinedInput
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        id="age"
                                        name="age"
                                        size="small"
                                        value={formik.values.age}
                                        onChange={formik.handleChange}
                                        placeholder="Age Range 3-15 years"
                                        error={formik.touched.age && Boolean(formik.errors.age)}
                                        helpertext={formik.touched.age && formik.errors.age}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={classes.label}> Mobile </label>
                                    <OutlinedInput
                                        variant="outlined"
                                        required
                                        margin="dense"
                                        id="phone"
                                        fullWidth
                                        name="phone"
                                        size="small"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        helpertext={formik.touched.phone && formik.errors.phone}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={classes.label}> Email </label>
                                    <OutlinedInput
                                        variant="outlined"
                                        required
                                        margin="dense"
                                        id="email"
                                        fullWidth
                                        name="email"
                                        size="small"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helpertext={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={classes.label}> Course </label>
                                    {pathname === '/module-details' ? (
                                        <OutlinedInput
                                            disabled
                                            variant="outlined"
                                            margin="dense"
                                            id="courseId"
                                            fullWidth
                                            name="courseId"
                                            size="small"
                                            value={formik.values.courseId}
                                            onChange={formik.handleChange}
                                            error={formik.touched.courseId && Boolean(formik.errors.courseId)}
                                            helpertext={formik.touched.courseId && formik.errors.courseId}
                                        />
                                    ) : (
                                        <TextField
                                            id="courseId"
                                            name="courseId"
                                            SelectProps={{
                                                native: true
                                            }}
                                            select
                                            fullWidth
                                            value={formik.values.courseId}
                                            placeholder="Select Course"
                                            onChange={formik.handleChange}
                                            disabled={!formik.values.age || !courseData?.length}
                                        >
                                            <option value="" disabled>
                                                Select course
                                            </option>
                                            {courseData?.map((item, index) => (
                                                <option key={index} value={item?.id}>
                                                    {item?.title}
                                                </option>
                                            ))}
                                        </TextField>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <label className={classes.label}>Mode</label>
                                    <TextField
                                     
                                        native
                                        select
                                        disabled={!courseData?.length || !formik.values.age}
                                        SelectProps={{
                                            native: true
                                        }}
                                        fullWidth
                                        value={formik.values.mode}
                                        placeholder="Select mode"
                                        name="mode"
                                        onChange={formik.handleChange}
                                        InputProps={{
                                            style: {
                                                fontWeight: 'lighter',
                                                fontFamily: 'Josefin Sans',
                                                color: '#000'
                                            }
                                        }}
                                        labelProps={{
                                            style: {
                                                color: '#000'
                                            }
                                        }}
                                    >
                                        <option
                                            value=""
                                            disabled
                                        >
                                            Select mode
                                        </option>
                                        <option
                                            value="ONLINE"
                                        >
                                            Online
                                        </option>
                                        <option
                                            value="OFFLINE"
                                        >
                                            Offline
                                        </option>
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <label className={classes.label}> Comments </label>
                                    <OutlinedInput
                                        fullWidth
                                        required
                                        variant="outlined"
                                        margin="dense"
                                        id="comment"
                                        name="comment"
                                        size="small"
                                        value={formik.values.comment}
                                        onChange={formik.handleChange}
                                        error={formik.touched.comment && Boolean(formik.errors.comment)}
                                        helpertext={formik.touched.comment && formik.errors.comment}
                                    />
                                </Grid>
                                <Grid item xs={12} display="flex" justifyContent="center">
                                    <Button
                                        type="submit"
                                        // onClick={AddFreeTrail}
                                        size="small"
                                        style={{
                                            backgroundColor: '#ff8f44',
                                            fontSize: '15px',
                                            fontFamily: 'Josefin Sans',
                                            fontWeight: 'lighter',
                                            color: 'white'
                                        }}
                                        variant="contained"
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}
