import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import {
    GET_ORDER_START,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    GET_ALL_ORDER_START,
    GET_ALL_ORDER_SUCCESS,
    GET_ALL_ORDER_ERROR,
    GET_PROFILE_ORDER_START,
    GET_PROFILE_ORDER_SUCCESS,
    GET_PROFILE_ORDER_ERROR,
    GET_BOOK_ORDER_HISTORY_START,
    GET_BOOK_ORDER_HISTORY_SUCCESS,
    GET_BOOK_ORDER_HISTORY_ERROR,
    GET_ALL_PENDING_BOOKS_START,
    GET_ALL_PENDING_BOOKS_SUCCESS,
    GET_ALL_PENDING_BOOKS_ERROR
} from '../../constants';
import { Alert } from '@material-ui/core';

const getOrderStart = () => {
    return { type: GET_ORDER_START };
};
const getOrderSuccess = (payload) => {
    return { type: GET_ORDER_SUCCESS, payload };
};
const getOrderError = (error) => {
    return { type: GET_ORDER_ERROR, error };
};

const getAllOrderStart = () => {
    return { type: GET_ALL_ORDER_START };
};
const getAllPendingBooksStart = () => {
    return { type: GET_ALL_PENDING_BOOKS_START };
};
const getAllOrderSuccess = (payload) => {
    return { type: GET_ALL_ORDER_SUCCESS, payload };
};
const getAllPendingBooksSuccess = (payload) => {
    return { type: GET_ALL_PENDING_BOOKS_SUCCESS, payload };
};
const getAllOrderError = (error) => {
    return { type: GET_ALL_ORDER_ERROR, error };
};
const getAllPendingBooksError = (error) => {
    return { type: GET_ALL_PENDING_BOOKS_ERROR, error };
};

const getProfileOrderStart = () => {
    return { type: GET_PROFILE_ORDER_START };
};
const getProfileOrderSuccess = (payload) => {
    return { type: GET_PROFILE_ORDER_SUCCESS, payload };
};
const getProfileOrderError = (error) => {
    return { type: GET_PROFILE_ORDER_ERROR, error };
};

const getBookOrderHistoryStart = () => {
    return { type: GET_BOOK_ORDER_HISTORY_START };
};
const getBookOrderHistorySuccess = (payload) => {
    return { type: GET_BOOK_ORDER_HISTORY_SUCCESS, payload };
};
const getBookOrderHistoryError = (error) => {
    return { type: GET_BOOK_ORDER_HISTORY_ERROR, error };
};

const placeOrder = (payload, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', 'orders-service/orders', payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const placeProfileOrder = (id,deliveryDate='',nextDeliveryDate='', successCB) => (dispatch) => {
    try {
        return http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', `orders-service/orders/profiles/${id}?deliveryDate=${deliveryDate}&nextDeliveryDate=${nextDeliveryDate}`, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
                return res;
            })
            .catch((error) => {
                dispatch(setMessage(error?.data?.message));
                throw error;
            });
    } catch (error) {
        console.log(error);
    }
};

const updateOrderStatus = (id, status, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'PATCH', `orders-service/orders/${id}?orderStatus=${status}`, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message));
                    successCB && successCB(res);
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Already Delivered!'));
            });
    } catch (error) {
        console.log(error);
    }
};

const returnBook = (id, bookId, status, successCB) => (dispatch) => {
    try {
        http(
            process.env.REACT_APP_ROOT_URL_ORDER,
            'POST',
            `​orders-service/orders/${id}/books/${bookId}/return?returnBookStatus=${status}`,
            true
        )
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const getOrder = (id, successCB) => (dispatch) => {
    dispatch(getOrderStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'GET', `orders-service/orders/${id}`, true)
            .then((res) => {
                const payload = {
                    orderData: res
                };
                dispatch(getOrderSuccess(payload));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(getOrderError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getOrderError('Something went wrong'));
    }
};
const getProfileOrder =
    (id, page = 1, rowsPerPage = 10) =>
        (dispatch) => {
            dispatch(getProfileOrderStart());
            try {
                http(
                    process.env.REACT_APP_ROOT_URL_ORDER,
                    'GET',
                    `orders-service/orders/profiles/${id}?pageNumber=${page}&pageSize=${rowsPerPage}`,
                    true
                )
                    .then((res) => {
                        const payload = {
                            profileOrderData: res
                        };
                        dispatch(getProfileOrderSuccess(payload));
                    })
                    .catch((err) => {
                        dispatch(getProfileOrderError(err));
                        dispatch(setMessage(err?.message));
                    });
            } catch (error) {
                dispatch(getProfileOrderError('Something went wrong'));
            }
        };

const getAllOrder =
    (searchData = '', startDate = '', endDate = '') =>
        (dispatch) => {
            dispatch(getAllOrderStart());
            try {
                http(
                    process.env.REACT_APP_ROOT_URL_ORDER,
                    'GET',
                    `orders-service/orders/profiles?searchKey=${searchData}&startDeliveryDate=${startDate}&endDeliveryDate=${endDate}`,
                    true
                )
                    .then((res) => {
                        const payload = {
                            allOrderData: res
                        };
                        dispatch(getAllOrderSuccess(payload));
                    })
                    .catch((err) => {
                        dispatch(getAllOrderError(err));
                        dispatch(setMessage(err?.data?.message));
                    });
            } catch (error) {
                dispatch(getAllOrderError('Something went wrong'));
            }
        };

const getAllOrderBooks =
    (startDueDate = '', endDueDate = '', searchData = '', page = 1, rowsPerPage = 10) =>
        (dispatch) => {
            dispatch(getAllOrderStart());
            try {
                http(
                    process.env.REACT_APP_ROOT_URL_ORDER,
                    'GET',
                    `orders-service/orders/books/profiles?searchKey=${searchData}&startDueDate=${startDueDate}&endDueDate=${endDueDate}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                    true
                )
                    .then((res) => {
                        const payload = {
                            allOrderData: res
                        };
                        dispatch(getAllOrderSuccess(payload));
                    })
                    .catch((err) => {
                        dispatch(getAllOrderError(err));
                        dispatch(setMessage(err?.data?.message));
                    });
            } catch (error) {
                dispatch(getAllOrderError('Something went wrong'));
            }
        };


const getAllProfileBookOrder =
    (searchData = '', startDeliveryDate = '', endDeliveryDate = '', page = 1, rowsPerPage = 10) =>
        (dispatch) => {
            dispatch(getAllOrderStart());
            try {
                http(
                    process.env.REACT_APP_ROOT_URL_ORDER,
                    'GET',
                    `orders-service/orders/bookOrder/profiles?searchKey=${searchData}&startDeliveryDate=${startDeliveryDate}&endDeliveryDate=${endDeliveryDate}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                    true
                )
                    .then((res) => {
                        const payload = {
                            allOrderData: res
                        };
                        dispatch(getAllOrderSuccess(payload));
                    })
                    .catch((err) => {

                        dispatch(getAllOrderError(err));
                        dispatch(setMessage(err?.data?.message));
                    });
            } catch (error) {
                dispatch(getAllOrderError('Something went wrong'));
            }
        };

const getAllPendingBookOrder = (searchData = '', startDueDate = '', endDueDate = '', page = 1, rowsPerPage = 10) => (dispatch) => {
    dispatch(getAllPendingBooksStart());
    try {
        http(
            process.env.REACT_APP_ROOT_URL_ORDER,
            'GET',
            `orders-service/orders/pending/books?searchKey=${searchData}&startDueDate=${startDueDate}&endDueDate=${endDueDate}&pageNumber=${page}&pageSize=${rowsPerPage}`,
            true
        )
            .then((res) => {
                const payload = {
                    pendingBookData: res
                };
                dispatch(getAllPendingBooksSuccess(payload));
            })
            .catch((err) => {

                dispatch(getAllPendingBooksError(err));
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        dispatch(getAllPendingBooksError('Something went wrong'));
    }
};

const getBookOrderHistory = (id) => (dispatch) => {
    dispatch(getBookOrderHistoryStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'GET', `orders-service/orders/order-books/${id}`, true)
            .then((res) => {
                const payload = {
                    bookOrderHistory: res
                };
                dispatch(getBookOrderHistorySuccess(payload));
            })
            .catch((err) => {
                dispatch(getBookOrderHistoryError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getBookOrderHistoryError('Something went wrong'));
    }
};

const moveWishlist = (id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', `orders-service/carts/move-to-wishlist/${id}`, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                if (err?.status === 409) {
                    dispatch(setMessage('The Book already in wishlist'));
                }
            });
    } catch (error) {
        console.log(error);
    }
};

export {
    placeOrder,
    placeProfileOrder,
    updateOrderStatus,
    getOrder,
    getProfileOrder,
    getAllOrder,
    returnBook,
    getBookOrderHistory,
    moveWishlist,
    getAllOrderBooks, getAllProfileBookOrder, getAllPendingBookOrder
};
