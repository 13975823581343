import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import { GET_ALL_STUDENT_REFERRAL_START, GET_ALL_STUDENT_REFERRAL_SUCCESS, GET_ALL_STUDENT_REFERRAL_ERROR } from '../../constants';

const getAllStudentReferralStart = () => {
    return { type: GET_ALL_STUDENT_REFERRAL_START };
};
const getAllStudentReferralSuccess = (payload) => {
    return { type: GET_ALL_STUDENT_REFERRAL_SUCCESS, payload };
};
const getAllStudentReferralError = (error) => {
    return { type: GET_ALL_STUDENT_REFERRAL_ERROR, error };
};

const getAllStudentReferral = () => (dispatch) => {
    dispatch(getAllStudentReferralStart());
    try {
        http(process.env.REACT_APP_ROOT_URL, 'GET', `users-service/profiles/referrals?pageNumber=1&pageSize=10`, true)
            .then((res) => {
                const payload = {
                    allStudentReferrals: res
                };
                dispatch(getAllStudentReferralSuccess(payload));
            })
            .catch((err) => {
                dispatch(getAllStudentReferralError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getAllStudentReferralError('Something went wrong'));
    }
};

export { getAllStudentReferral };
