import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import configData from '../../../../config';
import { Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    Avatar,
    Card,
    CardContent,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItemIcon,
    ListItemText,
    OutlinedInput,
    Paper,
    Popper,
    Switch,
    Typography,
    Tooltip
} from '@material-ui/core';
import ListItemButton from '@material-ui/core/ListItemButton';

// project imports
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';
import { LOGOUT } from './../../../../store/actions';

// assets
import { IconLogout, IconSearch, IconSettings, IconKey, IconMan } from '@tabler/icons';
import User1 from './../../../../assets/images/users/user-round.svg';
import ChangePassword from '../../../../views/authentication/change-password';
import { useAuth } from '../../../../hooks/useAuth';
import { getLoggedUserProfile } from '../../../../store/actions/ProfileActions';
import AdminProfile from '../../../../views/my-profile/admin-profile';

// style const
const useStyles = makeStyles((theme) => ({
    navContainer: {
        width: '100%',
        maxWidth: '220px',
        minWidth: '220px',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            minWidth: '100%'
        }
    },
    headerAvatar: {
        cursor: 'pointer',
        ...theme.typography.mediumAvatar,
        margin: '8px 0 8px 8px !important'
    },
    profileChip: {
        height: '48px',
        alignItems: 'center',
        borderRadius: '27px',
        transition: 'all .2s ease-in-out',
        borderColor: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.light,
        '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main + '!important',
            color: theme.palette.primary.light,
            '& svg': {
                stroke: theme.palette.primary.light
            }
        }
    },
    profileLabel: {
        lineHeight: 0,
        padding: '12px'
    },
    listItem: {
        marginTop: '5px'
    },
    cardContent: {
        padding: '16px !important'
    },
    card: {
        backgroundColor: theme.palette.primary.light,
        marginBottom: '16px',
        marginTop: '16px'
    },
    searchControl: {
        width: '100%',
        paddingRight: '8px',
        paddingLeft: '16px',
        marginBottom: '16px',
        marginTop: '16px'
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500]
    },
    flex: {
        display: 'flex'
    },
    name: {
        marginLeft: '2px',
        fontWeight: 400
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 250px)',
        overflowX: 'hidden'
    },
    badgeWarning: {
        backgroundColor: theme.palette.warning.dark,
        color: '#fff'
    },
    link: {
        textDecoration: 'none'
    }
}));

//-----------------------|| PROFILE MENU ||-----------------------//

const ProfileSection = () => {
    const classes = useStyles();
    const auth = useAuth();
    const history = useHistory();
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const account = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const token = Cookies.get('token');
    const [sdm, setSdm] = React.useState(true);
    const [value, setValue] = React.useState('');
    const [notification, setNotification] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [active1, setActive1] = useState('SecondCard');

    const [open, setOpen] = React.useState(false);
    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    const anchorRef = React.useRef(null);

    const handleLogout = () => {
        auth.signout(() => history.push('/home'));
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const handleChangePassword = () => {
        setOpen(false);
        setOpenChangePassword(true);
    };

    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    React.useEffect(() => {
        if (token) {
            dispatch(getLoggedUserProfile());
        }
    }, [token, dispatch]);

    return (
        <React.Fragment>
            <Tooltip title="Profile Setting" aria-label="profile">
                <Chip
                    classes={{ label: classes.profileLabel }}
                    className={classes.profileChip}
                    icon={
                        <Avatar
                            // src={'https://bookeinstein-s3-bucket.s3.amazonaws.com/34.jpg'}
                            src={loggedUserProfile?.profilePictureUrl}
                            // src={User1}
                            className={classes.headerAvatar}
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            color="inherit"
                        />
                    }
                    label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
                    variant="outlined"
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="primary"
                />
            </Tooltip>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <CardContent className={classes.cardContent}>
                                        <Grid container direction="column" spacing={0}>
                                            <Grid item className={classes.flex}>
                                                <Typography variant="h4"> Welcome,</Typography>
                                                <Typography component="span" variant="h4" className={classes.name}>
                                                    {loggedUserProfile?.firstName}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle2">{loggedUserProfile?.email}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider style={{ marginTop: 10 }} />

                                        <List component="nav" className={classes.navContainer}>
                                            {loggedUserProfile?.role === 'TEACHER' && (
                                                <Link to="/admin/all-courses" style={{ textDecoration: 'none' }}>
                                                    <ListItemButton
                                                        className={classes.listItem}
                                                        sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleClose}
                                                    >
                                                        <ListItemIcon>
                                                            <IconMan stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2" style={{ textDecoration: 'none' }}>
                                                                    My Dashboard
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </Link>
                                            )}

                                            {loggedUserProfile?.role === 'SCHOOL_ADMIN' && (
                                                <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                                                    <ListItemButton
                                                        className={classes.listItem}
                                                        sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleClose}
                                                    >
                                                        <ListItemIcon>
                                                            <IconMan stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2" style={{ textDecoration: 'none' }}>
                                                                    My Dashboard
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </Link>
                                            )}

                                            {loggedUserProfile?.role === 'STUDENT' && (
                                                <Link to="/my-dashboard" style={{ textDecoration: 'none' }}>
                                                    <ListItemButton
                                                        className={classes.listItem}
                                                        sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                        selected={selectedIndex === 4}
                                                        onClick={handleClose}
                                                    >
                                                        <ListItemIcon>
                                                            <IconMan stroke={1.5} size="1.3rem" />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant="body2" style={{ textDecoration: 'none' }}>
                                                                    My Dashboard
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </Link>
                                            )}

                                            <Link to="/student-profile" style={{ textDecoration: 'none' }}>
                                                <ListItemButton
                                                    className={classes.listItem}
                                                    sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleClose}
                                                >
                                                    <ListItemIcon>
                                                        <IconMan stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2" style={{ textDecoration: 'none' }}>
                                                                My Profile
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </Link>
                                            {/* <Link to="/admin/my-address" style={{ textDecoration: 'none' }}>
                                                <ListItemButton
                                                    className={classes.listItem}
                                                    sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleClose}
                                                >
                                                    <ListItemIcon>
                                                        <IconMan stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            <Typography variant="body2" style={{ textDecoration: 'none' }}>
                                                                My Address
                                                            </Typography>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </Link> */}
                                            <ListItemButton
                                                className={classes.listItem}
                                                sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                selected={selectedIndex === 4}
                                                onClick={handleChangePassword}
                                            >
                                                <ListItemIcon>
                                                    <IconKey stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2"> Change Password </Typography>} />
                                            </ListItemButton>
                                            <ListItemButton
                                                className={classes.listItem}
                                                sx={{ borderRadius: customization.borderRadius + 'px' }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                            </ListItemButton>
                                        </List>
                                    </CardContent>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <ChangePassword openChangePassword={openChangePassword} setOpenChangePassword={setOpenChangePassword} />
        </React.Fragment>
    );
};

export default ProfileSection;
