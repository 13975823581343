import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import

import customizationReducer from './customizationReducer';
import accountReducer from './accountReducer';
import bookReducer from './bookReducer';
import loginReducer from './reducers/AuthReducers';
import MessageReducer from './reducers/MessageReducer';
import AddUserReducer from './reducers/AddUserReducers';
import ProfileReducer from './reducers/ProfileReducers';
import AddressReducer from './reducers/AddressReducers';
import GetAllCategoryReducer from './reducers/BookCategoryReducers';
import GetAllSchoolReducer from './reducers/SchoolReducers';
import DeliveryZoneReducer from './reducers/DeliveryReducers';
import GetAllLanguageReducer from './reducers/BookLanguageReducers';
import GetAllLeaveReducer from './reducers/LeaveReducers';
import BooksReducer from './reducers/BookReducers';
import GetAllTransactionReducer from './reducers/TransactionReducers';
import GetAllWishlistReducer from './reducers/WishlistReducers';
import GetAllCartReducer from './reducers/CartReducers';
import GetAllBulkReducer from './reducers/BulkReducers';
import SubscriptionReducer from './reducers/SubscriptionReducer';
import OrderReducer from './reducers/OrderReducers';
import StockReducer from './reducers/StockReducers';
import MyAddressReducer from './reducers/MyAddressReducers';
import GetAllCourseReducer from './reducers/CourseReducers';
import GetAllPlansWithOptionsReducer from './reducers/PlansWithOptionsReducers';
import GetAllFAQReducer from './reducers/FAQReducers';
import GetAllContactUsReducer from './reducers/ContactUsReducers';
import FeedbackReducer from './reducers/FeebackReducers';
import GetAllGradeReducer from './reducers/GradeReducers';
import ReportReducer from './reducers/ReportReducers';
import GetAllMeetingReducer from './reducers/MeetingReducers';
import GetAllAssignment_Submission_Reducer from './reducers/Assignment_Submission_Reducers';
import GetAllDiscountReducer from './reducers/DiscountReducers';
import GetAllVoucherReducer from './reducers/VoucherReducers';
import GetAllReferralReducer from './reducers/ReferralReducers';
import GetAllStudentReferralReducer from './reducers/StudentReferralReducers';
import GetAllStudentModuleReducer from './reducers/StudentModuleReducers';
import GetAllPendingHomeworkReducer from './reducers/PendingHomeworkReducers';
import GetAllFocusSkillsReducer from './reducers/FocusSkillReducers';
import GetAllDeliveryPlanReducer from './reducers/DeliveryPlanReducers';
import GetAllFocusSkillReducer from './reducers/FocusSkillAdminReducers';
import GetAllModuleDetailReducer from './reducers/ModuleDetailReducers';
//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
    account: persistReducer(
        {
            key: 'account',
            storage,
            keyPrefix: 'bookeinstein-'
        },
        accountReducer
    ),
    customization: customizationReducer,
    book: bookReducer,
    login: loginReducer,
    message: MessageReducer,
    addUser: AddUserReducer,
    category: GetAllCategoryReducer,
    school: GetAllSchoolReducer,
    leave: GetAllLeaveReducer,
    delivery: DeliveryZoneReducer,
    language: GetAllLanguageReducer,
    userProfile: ProfileReducer,
    address: AddressReducer,
    books: BooksReducer,
    transaction: GetAllTransactionReducer,
    wishlist: GetAllWishlistReducer,
    cart: GetAllCartReducer,
    bulk: GetAllBulkReducer,
    subscriptions: SubscriptionReducer,
    order: OrderReducer,
    stock: StockReducer,
    course: GetAllCourseReducer,
    plansWithOptions: GetAllPlansWithOptionsReducer,
    myAddress: MyAddressReducer,
    faq: GetAllFAQReducer,
    contact: GetAllContactUsReducer,
    feedback: FeedbackReducer,
    grade: GetAllGradeReducer,
    report: ReportReducer,
    meeting: GetAllMeetingReducer,
    assignmentSubmission: GetAllAssignment_Submission_Reducer,
    discount: GetAllDiscountReducer,
    voucher: GetAllVoucherReducer,
    referral: GetAllReferralReducer,
    studentReferral: GetAllStudentReferralReducer,
    studentModule: GetAllStudentModuleReducer,
    pendingHomework: GetAllPendingHomeworkReducer,
    focusSkills: GetAllFocusSkillsReducer,
    deliveryPlan: GetAllDeliveryPlanReducer,
    focusSkill: GetAllFocusSkillReducer,
    moduleDetail: GetAllModuleDetailReducer
});

export default reducer;
