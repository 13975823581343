import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import axios from 'axios';

import { 
    GET_ALL_DELIVERY_PLAN_START, 
    GET_ALL_DELIVERY_PLAN_SUCCESS, 
    GET_ALL_DELIVERY_PLAN_ERROR,
} from '../../constants';

const getAllDeliveryPlanStart = () => {
    return { type: GET_ALL_DELIVERY_PLAN_START };
};
const getAllDeliveryPlanSuccess = (payload) => {
    return { type: GET_ALL_DELIVERY_PLAN_SUCCESS, payload };
};
const getAllDeliveryPlanError = (error) => {
    return { type: GET_ALL_DELIVERY_PLAN_ERROR, error };
};

const getAllDeliveryPlan =
    (id, page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllDeliveryPlanStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_ORDER,
                'GET',
                `orders-service/delivery-plans/subscriptions/${id}?pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allDeliveryPlan: res
                    };
                    dispatch(getAllDeliveryPlanSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllDeliveryPlanError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllDeliveryPlanError('Something went wrong'));
        }
    };


export const getDeliveryPlan = (id) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${process.env.REACT_APP_ROOT_URL_ORDER}/orders-service/delivery-plans/profileId?profileId=${id}`)
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

export { getAllDeliveryPlan };
