import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    paper: {
        padding: 30
    },
    mandatory: {
        color: theme.palette.error.main
    },
    label: {
        marginBottom: 0,
        marginLeft: 10
    },
    input: {
        marginTop: 3,
        marginBottom: 0
    },
    submit: {
        width: 100,
        height: 40,
        marginTop: 30,
        boxShadow: '0 0.5em 0.5em -0.4em #68c2d9',
        '&:hover': {
            boxShadow: '0 0.5em 0.5em 0.1em #68c2d9'
        }
    },
    img: {
        border: '1px solid #D3D3D3',
        width: '200px',
        height: '160px',
        borderRadius: 12,
        marginTop: 0
    },
    divider: {
        marginTop: 10
    },
    form: {
        padding: '30px 60px'
    },
    title: {
        paddingLeft: 40,
        paddingTop: 20,
        paddingBottom: 5
    },
    loader: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000
    },
    loginInput: {
        ...theme.typography.customInput
    },
    schoolList: {
        marginTop: 8,
        marginBottom: 8,
        '& > label': {
            top: '10px',
            left: 0,
            color: theme.grey500,
            '&[data-shrink="false"]': {
                top: '5px'
            }
        },
        '& > div > input': {
            padding: '30.5px 14px 11.5px !important'
        },
        '& legend': {
            display: 'none'
        },
        '& fieldset': {
            top: 0
        }
    },
    em: {
        fontStyle: 'normal',
        color: '#D3D3D3'
    },
    ScrollHeight: {
        height: '100%',
        width: '100%',
        maxHeight: 'calc(100vh - 390px)',
        overflowX: 'hidden',
        border: '1px solid #D3D3D3',
        borderRadius: 8,
        marginTop: 8
    },
    categoryBox: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300
    },
    listSection: {
        backgroundColor: 'inherit'
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0
    },
    table: {
        minWidth: 650
    },
    tableContainer: {
        borderRadius: 15,
        marginTop: 16
    },
    tableHeaderCell: {
        fontWeight: 'lighter',
        backgroundColor: theme.palette.primary.light,
        color: '#ffffff'
    },
    status: {
        fontWeight: 'lighter',
        fontSize: '0.75rem',
        color: 'white',
        backgroundColor: 'gray',
        borderRadius: 8,
        padding: '3px 10px',
        display: 'inline-block'
    },
    root: {
        padding: 16,
        width: '100%'
    },
    btnAddPlanOptions: {
        cursor: 'pointer',
        boxShadow: '0 0.5em 0.5em 0.1em #68c2d9'
    },
    titleContainer: {
        background: theme.palette.primary.main,
        padding: 10,
        borderRadius: 8,
        marginBottom: 20
    },
    pageTitle: {
        fontSize: 16,
        color: theme.palette.background.paper,
        fontWeight: 'lighter'
    },

    viewIcon: {
        verticalAlign: 'sub',
        background: 'tranparent',
        color: '#fff'
    },
    searchControl: {
        // width: '234px',
        marginLeft: '16px',
        paddingRight: '16px',
        paddingLeft: '16px',
        '& input': {
            background: 'transparent !important',
            paddingLeft: '5px !important'
        },
        [theme.breakpoints.down('lg')]: {
            width: '250px'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            marginLeft: '4px',
            background: '#fff'
        }
    },
    startAdornment: {
        fontSize: '1rem',
        color: theme.palette.grey[500]
    },
    imageContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 40
        }
    },
    imageInput: {
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlignEnd: 'center'
        }
    },
    btn: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    }
}));
