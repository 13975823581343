import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssignment_Submission } from '../../../../store/actions/Assignment_Submission_Actions';
import { withStyles } from '@material-ui/styles';
import { IconSearch } from '@tabler/icons';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, OutlinedInput, InputAdornment } from '@material-ui/core';

import SchoolIcon from '@material-ui/icons/School';
import useStyles from '../../../ViewTableStyles';
import ConfirmDialog from '../../../../ui-component/ConfirmDialog/ConfirmDialog';
import useUserRole from '../../../../hooks/useUserRole';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const DocumentLinks = (props) => {
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'TEACHER','SUPER_ADMIN'] });
    const dispatch = useDispatch();

    const { getAssignment_Submission_Data } = useSelector((state) => state?.assignmentSubmission);
    const newData = getAssignment_Submission_Data;
    console.log('Submission Details', newData);

    useEffect(() => {
        dispatch(getAssignment_Submission(props.id));
    }, []);

    return (
        <>
            <div className={classes.titleContainer}>
                <SchoolIcon className={classes.viewIcon} />
                <span className={classes.pageTitle}>&nbsp; Submission Documents </span>
            </div>
            <Paper elevation={5} className={classes.root}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <OutlinedInput
                        className={classes.searchControl}
                        id="input-search-header"
                        placeholder="Search"
                        startAdornment={
                            <InputAdornment position="start">
                                <IconSearch stroke={1.5} size="1rem" className={classes.startAdornment} />
                            </InputAdornment>
                        }
                    />
                </div>
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHeaderCell}>
                            <StyledTableRow>
                                <TableCell> Document Name </TableCell>
                                <TableCell> Document Links </TableCell>

                                {/* <TableCell align="right"> Action </TableCell> */}
                            </StyledTableRow>
                        </TableHead>
                        {!newData ? (
                            <caption style={{ textAlign: 'center' }}> Sorry! No data available </caption>
                        ) : (
                            <TableBody>
                                {newData?.submissionDocumentObjects?.map((row) => (
                                    <StyledTableRow>
                                        <TableCell> {row?.submissionDocumentName} </TableCell>
                                        <TableCell>
                                            <a href={row?.submissionDocumentLink} target="_blank" download>
                                                {row?.submissionDocumentLink}
                                            </a>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
};

export default DocumentLinks;
