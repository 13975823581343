import React from 'react';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router';
import SuccessImg from '../../assets/images/success.gif';

const PaymentSuccess = () => {
    const { state } = useLocation();
    return (
        <div>
            {/* <p>Payment Succes</p>
            <p>razorpay_payment_id:{state?.detail?.razorpay_payment_id}</p>
            <p>razorpay_order_id:{state?.detail?.razorpay_order_id}</p>
            <p>razorpay_signature:{state?.detail?.razorpay_signature}</p> */}
            <center>
                <img src={SuccessImg} width="30%" />
                <h2> Thank You! </h2>
                <h4> Your Payment is Successfully Done. </h4>
                <br />
                <Button variant="contained" href="/home">
                    Back to Home
                </Button>
            </center>
        </div>
    );
};

export default PaymentSuccess;
