import React from 'react';

export default function Footer() {
    return (
        <footer className="section footer-classNameic footer-classNameic-md">
            <div className="footer-classNameic-main">
                <div className="container">
                    <div className="footer-classNameic-layout justify-content-sm-around justify-content-md-between divider-4">
                        <div className="footer-classNameic-layout-item">
                            <a className="navbar-brand d-flex" href="#">
                                <img
                                    src="images/happy-child-book.jpg"
                                    width="118"
                                    height="39"
                                    className="d-inline-block align-top"
                                    alt=""
                                />
                                <p className="mt-5"> BookEinstein </p>
                            </a>
                            <div className="footer-classNameic-item-block footer-classNameic-item-block-1">
                                <h6>10000+ Satisfied Students</h6>
                                <div className="owl-carousel-quote-light">
                                    <div
                                        className="owl-carousel"
                                        data-items="1"
                                        data-dots="false"
                                        data-nav="false"
                                        data-stage-padding="0"
                                        data-loop="true"
                                        data-margin="30"
                                        data-nav-custom="#footer-owl-nav"
                                        data-animation-in="fadeIn"
                                        data-animation-out="fadeOut"
                                        data-mouse-drag="false"
                                    >
                                        <blockquote className="quote-light quote-light-sm">
                                            <div className="icon linearicons-quote-open text-primary icon-md"></div>
                                            <div className="quote-light-main">
                                                <div className="quote-light-text">
                                                    <q>
                                                        The staff here is really supportive, teachers are great, the school has a good
                                                        structure. Thank you!
                                                    </q>
                                                </div>
                                                <cite className="quote-light-cite">Jane Smith</cite>
                                            </div>
                                        </blockquote>
                                        <blockquote className="quote-light quote-light-sm">
                                            <div className="icon linearicons-quote-open text-primary icon-md"></div>
                                            <div className="quote-light-main">
                                                <div className="quote-light-text">
                                                    <q>
                                                        I am really enjoying my experience here. Teachers are very friendly and there is a
                                                        friendly atmosphere.
                                                    </q>
                                                </div>
                                                <cite className="quote-light-cite">Peter McMillan</cite>
                                            </div>
                                        </blockquote>
                                        <blockquote className="quote-light quote-light-sm">
                                            <div className="icon linearicons-quote-open text-primary icon-md"></div>
                                            <div className="quote-light-main">
                                                <div className="quote-light-text">
                                                    <q>
                                                        I like that it has a busy atmosphere but it’s never stressful. Everyone is very nice
                                                        and you can ask anything at any time.
                                                    </q>
                                                </div>
                                                <cite className="quote-light-cite">Kate Wilson</cite>
                                            </div>
                                        </blockquote>
                                    </div>
                                    <div className="owl-nav" id="footer-owl-nav">
                                        <button className="owl-arrow owl-arrow-prev" aria-label="Prev">
                                            <svg width="20" height="16" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.7,15.1c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4L2.4,8l5.7-5.7c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L0.3,7.3										c-0.4,0.4-0.4,1,0,1.4L6.7,15.1z M20,7H1v2h19V7z"></path>
                                            </svg>
                                        </button>
                                        <div className="owl-nav-divider"></div>
                                        <button className="owl-arrow owl-arrow-next" aria-label="Next">
                                            <svg width="20" height="16" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.7071 8.70711C20.0976 8.31658 20.0976 7.68342 19.7071 7.29289L13.3431 0.928932C12.9526 0.538408 12.3195 0.538408 11.9289 0.928932C11.5384 1.31946 11.5384 1.95262 11.9289 2.34315L17.5858 8L11.9289 13.6569C11.5384 14.0474 11.5384 14.6805 11.9289 15.0711C12.3195 15.4616 12.9526 15.4616 13.3431 15.0711L19.7071 8.70711ZM0 9H19V7L0 7L0 9Z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-classNameic-layout-item">
                            <h6 className="footer-classNameic-title inset-3">Popular Courses</h6>
                            <div className="footer-classNameic-item-block footer-classNameic-item-block-3">
                                <ul className="list-pricing">
                                    <li>
                                        <a href="english-for-business.html">
                                            <span className="list-pricing-title">English for Kids</span>
                                            <span>$45</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="english-for-business.html">
                                            <span className="list-pricing-title">Online Learning</span>
                                            <span>$15</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="english-for-business.html">
                                            <span className="list-pricing-title">German Club</span>
                                            <span>$36</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="english-for-business.html">
                                            <span className="list-pricing-title">Personal Lessons</span>
                                            <span>$21</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="english-for-business.html">
                                            <span className="list-pricing-title">Group Lessons</span>
                                            <span>$35</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-classNameic-layout-item">
                            <h6 className="footer-classNameic-title inset-3">Get in Touch</h6>
                            <div className="footer-classNameic-item-block">
                                <ul className="list list-1">
                                    <li>
                                        <a href="#">Send a Message</a>
                                    </li>
                                    <li>
                                        <a href="contact-us.html">Contacts</a>
                                    </li>
                                    <li>
                                        <a href="#">Request a Callback</a>
                                    </li>
                                </ul>
                                <ul className="list-inline list-inline-md">
                                    <li>
                                        <a className="link-2 icon mdi mdi-instagram" href="#"></a>
                                    </li>
                                    <li>
                                        <a className="link-2 icon mdi mdi-facebook" href="#"></a>
                                    </li>
                                    <li>
                                        <a className="link-2 icon mdi mdi-youtube-play" href="#"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-0">
                <div className="divider-2"></div>
            </div>
            <div className="footer-classNameic-aside">
                <div className="container">
                    <p className="rights">
                        <span>&copy;&nbsp; </span>
                        <span className="copyright-year"></span>
                        <span>&nbsp;</span>
                        <span> BookEinstein </span>
                        <span>. All rights reserved.&nbsp;</span>
                        <a href="privacy-policy.html">Privacy Policy</a>
                        <span>.</span>
                        <span>
                            Design&nbsp;by&nbsp;<a href="https://zemez.io/">Zemez</a>
                        </span>
                    </p>
                </div>
            </div>
        </footer>
    );
}
