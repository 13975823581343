import { GET_ALL_WISHLIST_START, 
    GET_ALL_WISHLIST_SUCCESS, 
    GET_ALL_WISHLIST_ERROR,
    GET_WISHLIST_START,
    GET_WISHLIST_SUCCESS,
    GET_WISHLIST_ERROR,
 } from '../../constants';

const initialState = {
    loading: false,
    getAllWishlistData: null,
    getWishlistData: null,
    message: ''
};

export default function GetAllWishlistReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_WISHLIST_START:
        case GET_WISHLIST_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_WISHLIST_SUCCESS:
        case GET_WISHLIST_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_WISHLIST_ERROR:
        case GET_WISHLIST_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
