const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/books-react/react/default'
    basename: '',
    //defaultPath: '/admin/users',
    defaultPath: '/home',
    defaultAdminPath: '/admin/users',
    defaultSchoolAdminPath: '/dashboard/default',
    defaultStudentpath: '/my-dashboard',
    defaultTeacherPath: '/admin/all-courses',
    fontFamily: `Josefin Sans`,
    // fontFamilyPricingPlan: `Times New Roman`,
    borderRadius: 8,
    API_SERVER: 'http://localhost:5000/api/',

    //Book modules API
    BOOK_ROOT_PATH: process.env.REACT_APP_ROOT_URL + '/book',

    //Category Modules API_SERVER

    //School Modules API_SERVER
    SCHOOL_ROOT_PATH: 'http://localhost:8101/schools'
};

export default config;
