import React from 'react';
import { Box } from '@mui/material';
import listStyleImg from '../../../../assets/images/homepageOne/listStyleImage.png';

function LearnWithUsListItem({ children }) {
    return (
        <>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', marginBottom: '16px' }}>
                <Box sx={{ marginRight: '16px' }}>
                    <img src={listStyleImg} alt="bullet" width={20} height={20}></img>
                </Box>
                <Box style={{  fontWeight: 'lighter', color: '#545C6D', fontSize: '16px' ,
                                    fontFamily: 'Josefin Sans'}}>{children}</Box>
            </Box>
        </>
    );
}

export default LearnWithUsListItem;
