import Cookies from 'js-cookie';
import { http } from '../../../utils/http';
import {
    ADD_USER_START,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,
    GET_ALL_PROFILE_START,
    GET_ALL_PROFILE_SUCCESS,
    GET_ALL_PROFILE_ERROR,
    UPDATE_PROFILE_START,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    GET_ALL_USER_PROFILE_START,
    GET_ALL_USER_PROFILE_SUCCESS,
    GET_ALL_USER_PROFILE_ERROR,
    GET_USER_PROFILE_START,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_ERROR,
    GET_SEARCHED_PROFILE_START,
    GET_SEARCHED_PROFILE_SUCCESS,
    GET_SEARCHED_PROFILE_ERROR
} from '../../constants';
import { setMessage } from '../MessageActions';

const addUserStart = () => {
    return { type: ADD_USER_START };
};
const addUserSuccess = (payload) => {
    return { type: ADD_USER_SUCCESS, payload };
};
const addUserError = (error) => {
    return { type: ADD_USER_ERROR, error };
};
const getAllProfileStart = () => {
    return { type: GET_ALL_PROFILE_START };
};
const getAllProfileSuccess = (payload) => {
    return { type: GET_ALL_PROFILE_SUCCESS, payload };
};
const getAllProfileError = (error) => {
    return { type: GET_ALL_PROFILE_ERROR, error };
};
const getAllUserProfileStart = () => {
    return { type: GET_ALL_USER_PROFILE_START };
};
const getAllUserProfileSuccess = (payload) => {
    return { type: GET_ALL_USER_PROFILE_SUCCESS, payload };
};
const getAllUserProfileError = (error) => {
    return { type: GET_ALL_USER_PROFILE_ERROR, error };
};
const updateProfileStart = () => {
    return { type: UPDATE_PROFILE_START };
};
const updateProfileSuccess = (payload) => {
    return { type: UPDATE_PROFILE_SUCCESS, payload };
};
const updateProfileError = (error) => {
    return { type: UPDATE_PROFILE_ERROR, error };
};
const getUserProfileStart = () => {
    return { type: GET_USER_PROFILE_START };
};
const getUserProfileSuccess = (payload) => {
    return { type: GET_USER_PROFILE_SUCCESS, payload };
};
const getUserProfileError = (error) => {
    return { type: GET_USER_PROFILE_ERROR, error };
};

const getSearchedProfileStart = () => {
    return { type: GET_SEARCHED_PROFILE_START };
};
const getSearchedProfileSuccess = (payload) => {
    return { type: GET_SEARCHED_PROFILE_SUCCESS, payload };
};
const getSearchedProfileError = (error) => {
    return { type: GET_SEARCHED_PROFILE_ERROR, error };
};

const getUserProfile = () => (dispatch) => {
    dispatch(getUserProfileStart());
    try {
        http(process.env.REACT_APP_ROOT_URL, 'GET', `users-service/users/profiles`, true)
            .then((res) => {
                const payload = {
                    getUserProfileData: res
                };
                dispatch(getUserProfileSuccess(payload));
            })
            .catch((err) => {
                dispatch(getUserProfileError(err));
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        dispatch(getUserProfileError(error?.message));
    }
};
const switchUserProfile = (profileId, successCb) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL, 'POST', `users-service/users/profile/switch?profileId=${profileId}`, true)
            .then((res) => {
                if (res) {
                    Cookies.remove('token');
                    Cookies.remove('profileId');
                    Cookies.set('token', res?.access_token);
                    Cookies.set('profileId', res?.profile_id);
                }
                successCb && successCb(res);
            })
            .catch((err) => {
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        dispatch(setMessage(error?.data?.message));
    }
};
const addUser = (payload, role, successCB) => (dispatch) => {
    dispatch(addUserStart());
    try {
        http(process.env.REACT_APP_ROOT_URL, 'POST', `users-service/users/roles/${role}`, payload, true)
            .then((res) => {
                const payload = {
                    userInfo: res
                };
                dispatch(addUserSuccess(payload));
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })

            .catch((error) => {
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const getAllProfile =
    (rowsPerPage = 10, searchData = '', page = 1) =>
    (dispatch) => {
        dispatch(getAllProfileStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL,
                'GET',
                `users-service/users?pageNumber=${page}&pageSize=${rowsPerPage}&searchKey=${searchData}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllProfileData: res
                    };
                    dispatch(getAllProfileSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllProfileError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllProfileError('Something went wrong'));
        }
    };

    const getAllDeletedProfile =
    (rowsPerPage = 10, searchData = '', page = 1) =>
    (dispatch) => {
        dispatch(getAllProfileStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL,
                'GET',
                `users-service/users/deleted?pageNumber=${page}&pageSize=${rowsPerPage}&searchKey=${searchData}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllProfileData: res
                    };
                    dispatch(getAllProfileSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllProfileError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllProfileError('Something went wrong'));
        }
    };

    const getAllProfileByTime =
    (rowsPerPage = 10, searchData = '',addresstype='',timeframe='', page = 1) =>
    (dispatch) => {
        dispatch(getAllProfileStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL,
                'GET',
                `users-service/users/timeframe?pageNumber=${page}&pageSize=${rowsPerPage}&searchKey=${searchData}&timeframeCode=${timeframe}&addressType=${addresstype}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllProfileData: res
                    };
                    dispatch(getAllProfileSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllProfileError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllProfileError('Something went wrong'));
        }
    };

const userProfileSearch =
    (searchData = '', userRole) =>
    (dispatch) => {
        dispatch(getSearchedProfileStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL,
                'GET',
                `users-service/users/profiles/search?role=${userRole}&searchKey=${searchData}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getSearchedProfileData: res
                    };
                    dispatch(getSearchedProfileSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getSearchedProfileError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getSearchedProfileError('Something went wrong'));
        }
    };

const getAllUserProfile =
    (rowsPerPage = 10, searchData = '', page = 1, userRole) =>
    (dispatch) => {
        dispatch(getAllUserProfileStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL,
                'GET',
                `users-service/users/and/profiles?pageNumber=${page}&pageSize=${rowsPerPage}&role=${userRole}&searchKey=${searchData}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllUserProfileData: res
                    };
                    dispatch(getAllUserProfileSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllUserProfileError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllUserProfileError('Something went wrong'));
        }
    };

const updateProfile = (payload, userId, profileId, successCB) => (dispatch, getState) => {
    dispatch(updateProfileStart());
    try {
        http(process.env.REACT_APP_ROOT_URL, 'PUT', `users-service/users/${userId}/profiles/${profileId}`, payload, true)
            .then((res) => {
                dispatch(updateProfileSuccess(payload));
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(getAllProfileError(err));
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        dispatch(updateProfileError('Something went wrong'));
    }
};
const deleteUserProfile = (payload, userId, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL, 'DELETE', `users-service/users/${userId}`, payload, true)
            .then((res) => {
                dispatch(setMessage(payload?.isDeleted ? 'Deleted Successfully!' : 'Restore Successfully!'));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(getAllProfileError(err));
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        dispatch(updateProfileError('Something went wrong'));
    }
};


export { addUser,deleteUserProfile,getAllDeletedProfile, getAllProfile,getAllProfileByTime, updateProfile, getAllUserProfile, getUserProfile, switchUserProfile, userProfileSearch };
