import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import {
    GET_ALL_GRADE_START,
    GET_ALL_GRADE_SUCCESS,
    GET_ALL_GRADE_ERROR,
    GET_GRADE_START,
    GET_GRADE_SUCCESS,
    GET_GRADE_ERROR
} from '../../constants';

const getAllGradeStart = () => {
    return { type: GET_ALL_GRADE_START };
};
const getAllGradeSuccess = (payload) => {
    return { type: GET_ALL_GRADE_SUCCESS, payload };
};
const getAllGradeError = (error) => {
    return { type: GET_ALL_GRADE_ERROR, error };
};

const getGradeStart = () => {
    return { type: GET_GRADE_START };
};
const getGradeSuccess = (payload) => {
    return { type: GET_GRADE_SUCCESS, payload };
};
const getGradeError = (error) => {
    return { type: GET_GRADE_ERROR, error };
};

const updateGrade = (payload, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'PUT', `batches-service/grades`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message));
                    successCB && successCB(res);
                }
            })
            .catch((err) => {
                dispatch(setMessage(err?.data?.message));
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const getAllGrade = (id) => (dispatch, getState) => {
    dispatch(getAllGradeStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'GET', `batches-service/grades`, true)
            .then((res) => {
                const payload = {
                    allGrades: res
                };
                dispatch(getAllGradeSuccess(payload));
            })
            .catch((err) => {
                dispatch(getAllGradeError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getAllGradeError('Something went wrong'));
    }
};

export { updateGrade, getAllGrade };
