import React from 'react';
import { Grid, Container, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { IconBrandInstagram, IconBrandFacebook, IconBrandYoutube } from '@tabler/icons';
import Logo from '../../../assets/images/users/logo.png';

const useStyles = makeStyles((theme) => ({
    sections: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem',
        justifyContent: 'center',
        listStyle: 'none'
    },
    footerTitle: {
        fontWeight: 600,
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        paddingTop: 10,
        fontSize: 18,
        marginLeft: 30,
        marginBottom: 20,
        color: theme.palette.text.primary
    },
    footerBottom: {
        padding: '35px 0',
        textAlign: 'center'
    },
    logo: {
        marginLeft: 40,
        marginBottom: 10
    },

    listPricing: {
        textDecoration: 'none !important'
    },
    link: {
        pointer: 'cursor',
        textDecoration: 'none !important',
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    socialIcon: {
        display: 'inline-block',
        paddingRight: 10
    },
    copyright: {
        color: theme.palette.text.secondary
    }
}));

export default function Footer() {
    const classes = useStyles();
    const getYear = () => {
        return new Date().getFullYear();
    };
    return (
        <Container maxWidth="xl">
            <Divider style={{ margin: '30px 0px' }} />
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <img className={classes.logo} src={Logo} alt="" width="100" height="39" />
                    <h6>10000+ Satisfied Students</h6>
                </Grid>

                <Grid item xs={12} md={3}>
                    <h3 className={classes.footerTitle}>Popular Courses</h3>
                    <ul>
                        <li>
                            <Link to="#" className={classes.link}>
                                English for Kids
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className={classes.link}>
                                German Club
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className={classes.link}>
                                Personal Lessons
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className={classes.link}>
                                Group Lessons
                            </Link>
                        </li>
                    </ul>
                </Grid>

                <Grid item xs={12} md={3}>
                    <h6 className={classes.footerTitle}>Get in Touch</h6>
                    <div>
                        <ul>
                            <li>
                                <Link to="#" className={classes.link}>
                                    Send a Message
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className={classes.link}>
                                    Contacts
                                </Link>
                            </li>
                            <li>
                                <Link to="#" className={classes.link}>
                                    Request a Callback
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li className={classes.socialIcon}>
                                <Link to="#" className={classes.link}>
                                    <IconBrandInstagram stroke={1} size="1.7rem" />
                                </Link>
                            </li>
                            <li className={classes.socialIcon}>
                                <Link to="#" className={classes.link}>
                                    <IconBrandFacebook stroke={1} size="1.7rem" />
                                </Link>
                            </li>
                            <li className={classes.socialIcon}>
                                <Link to="#" className={classes.link}>
                                    <IconBrandYoutube stroke={1} size="1.7rem" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </Grid>
            </Grid>
            <Divider style={{ marginTop: 20 }} />
            <div className={classes.footerBottom}>
                <div>
                    <p className={classes.copyright}>
                        <span color="inherit">&copy; &nbsp; </span>
                        <span>{getYear()}</span>
                        <span>&nbsp; </span>
                        <span> BookEinstein</span>
                        <span>. All rights reserved.&nbsp; </span>
                        <Link to="#" className={classes.link}>
                            Privacy Policy
                        </Link>
                        <span>.</span>
                        <span>
                            &nbsp;Design by&nbsp;{' '}
                            <a className={classes.link} href="https://kibalabs.in/" target="_blank">
                                Kibalabs
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        </Container>
    );
}
