import Cookies from 'js-cookie';
import { http } from '../../../utils/http';
import {
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    CHANGE_PASSWORD_START,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR
} from '../../constants';
import { setMessage } from '../MessageActions';
import { viewLoggedUSerProfileSuccess } from '../ProfileActions';

const loginStart = () => {
    return { type: LOGIN_START };
};
const loginSuccess = (payload) => {
    return { type: LOGIN_SUCCESS, payload };
};
const loginError = (error) => {
    return { type: LOGIN_ERROR, error };
};
const changePasswordStart = () => {
    return { type: CHANGE_PASSWORD_START };
};
const changePasswordSuccess = (payload) => {
    return { type: CHANGE_PASSWORD_SUCCESS, payload };
};
const changePasswordError = (error) => {
    return { type: CHANGE_PASSWORD_ERROR, error };
};

const login = (payload, successCB, errorCB) => (dispatch, getState) => {
    dispatch(loginStart());
    try {
        http(process.env.REACT_APP_ROOT_URL, 'POST', `users-service/users/login`, payload, false)
            .then((res) => {
                // const tokenTime = new Date(new Date().getTime() + 10 * 3600 * 1000);
                Cookies.set('token', res?.access_token);
                Cookies.set('profileId', res?.profile_id);
                Cookies.set('refreshToken', res?.refresh_token);
                Cookies.set('expiryAt', res?.expiry_at);
                Cookies.set('userRole', res?.role);
                // localStorage.setItem('profileId', res?.profile_id);
                if (res?.user_id) Cookies.set('userId', res?.user_id);
                if (res?.school_id) Cookies.set('schoolId', res?.school_id);
                const payload = {
                    data: res,
                    isLogin: true
                };
                dispatch(loginSuccess(payload));
                dispatch(viewLoggedUSerProfileSuccess({ loggedUserProfile: res }));
                dispatch(setMessage(res.message));
                successCB && successCB(res);
                window.location.reload();

            })
            .catch((err) => {
                console.log(err);
                dispatch(loginError(err));
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Invalid UserName/Password'));
                errorCB && errorCB(err);
            });
    } catch (error) {
        dispatch(loginError('Something went wrong'));
        // dispatch(setMessage('Something went wrong'));
    }
};
const changePassword = (payload, successCB) => (dispatch, getState) => {
    dispatch(changePasswordStart());
    try {
        http(process.env.REACT_APP_ROOT_URL, 'PUT', `users-service/users/password`, payload, false)
            .then((res) => {
                dispatch(changePasswordSuccess(payload));
                dispatch(setMessage(res.message));
                successCB && successCB(res.data);
            })
            .catch((err) => {
                console.log(err);
                dispatch(loginError(err));
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Password incorrect'));
            });
    } catch (error) {
        dispatch(changePasswordError('Something went wrong'));
    }
};

export { login, changePassword };
