import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Divider, OutlinedInput } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import useStyles from '../../ViewTableStyles';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import useUserRole from '../../../hooks/useUserRole';

export default function ViewModules() {
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'STUDENT', 'TEACHER', 'SUPER_ADMIN'] });
    const dispatch = useDispatch();
    const { id } = useParams();
    const [data, setData] = useState(null);
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    //const { getModuleData } = useSelector((state) => state?.course);
    console.log('profile ======', loggedUserProfile);
    const getData = async () => {
        await axios
            .get(`${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/modules/${id}`)
            .then((res) => {
                setData(res?.data);
            })
            .catch((err) => {
                console.log(err);
            });
        //setData(getModuleData);
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            <Paper style={{ padding: 20 }}>
                <div className={classes.titleContainer}>
                    <SchoolIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; Module Details </span>
                </div>
                <Divider style={{ margin: '30px 0px' }} />
                <Grid container rowSpacing={4}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Title </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={data?.title} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Description </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={data?.description} fullWidth />
                        </Grid>
                    </Grid>
                    {(loggedUserProfile?.role === 'ADMIN' || loggedUserProfile?.role === 'SUPER_ADMIN') && (
                        <Grid item container spacing={2}>
                            <Grid item xs={12} sm={2}>
                                <label style={{ verticalAlign: 'sub' }}> Price </label>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <OutlinedInput name="name" disabled value={data?.price} fullWidth />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Duration </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={data?.duration} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Sessions Count </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={data?.sessionsCount} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Language </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={data?.language} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Focus Skills </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={data?.focusSkills} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Status </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={data?.status} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Created At </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={data?.createdAt} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Updated At </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={data?.updatedAt} fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
