import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Link,
    Divider,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../../ViewDialogStyles';
import { useState } from 'react';
import { Grid } from '@mui/material';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

export default function ModuleSubscriptionHistoryPopUp(props) {
    const { open, close, data } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const classes = useStyles();
    const handlePopupClose = () => {
        close(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
    };

    return (
        <div>
            <Dialog open={open} maxWidth={'md'}>
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.dialogTitleChild}>
                        <Typography style={{ fontSize: 'px' }}>Subscription History</Typography>
                        <Link to="#" onClick={handlePopupClose} style={{ fontSize: '16px' }}>
                            <CloseIcon className={classes.closeIcon} />
                        </Link>
                    </div>
                </DialogTitle>
                <DialogContent style={{ maxHeight: '350px' }}>
                    <Grid container>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead className={classes.tableHeaderCell}>
                                    <StyledTableRow>
                                        <TableCell align="center"> Course </TableCell>
                                        <TableCell align="center"> Module </TableCell>
                                        <TableCell align="center"> Start Date </TableCell>
                                        <TableCell align="right"> End Date </TableCell>
                                    </StyledTableRow>
                                </TableHead>
                                {data?.content?.length === 0 ? (
                                    <caption style={{ textAlign: 'center' }}> Sorry! No data available </caption>
                                ) : (
                                    <TableBody>
                                        {data?.content.map((item) => (
                                            <TableRow key={item.id}>
                                                <TableCell>{item.courseLimitedInfo.title}</TableCell>
                                                <TableCell>{item.moduleLimitedInfo.title}</TableCell>
                                                <TableCell>{item.startDate}</TableCell>
                                                <TableCell>{item.endDate}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Grid>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={data.totalElements}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </DialogContent>

                <Divider style={{ marginTop: 15 }} />
                <DialogActions className={classes.dialogAction}>
                    <Button variant="outlined" color="primary" className={classes.buttonCancel} onClick={handlePopupClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
