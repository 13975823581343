import {
    GET_ALL_LEAVE_START,
    GET_ALL_LEAVE_SUCCESS,
    GET_ALL_LEAVE_ERROR,
    GET_LEAVE_START,
    GET_LEAVE_SUCCESS,
    GET_LEAVE_ERROR,
} from '../../constants';

const initialState = {
    loading: false,
    allLeaves: null,
    leaves: null,
    message: ''
};

export default function GetAllLeaveReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_LEAVE_START:
        case GET_LEAVE_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_LEAVE_SUCCESS:
        case GET_LEAVE_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_LEAVE_ERROR:
        case GET_LEAVE_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
