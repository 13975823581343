import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormContext, Controller } from 'react-hook-form';

const RHFAutoComplete = ({ options = [], renderInput, getOptionLabel, onChange: ignored, defaultValue, name, renderOption }) => {
    const { control } = useFormContext();
    return (
        <Controller
            render={({ onChange, ...props }) => (
                <Autocomplete
                    options={options}
                    getOptionLabel={getOptionLabel}
                    renderOption={renderOption}
                    renderInput={renderInput}
                    onChange={(e, data) => onChange(data)}
                    {...props}
                />
            )}
            onChange={([, data]) => data}
            defaultValue={defaultValue}
            name={name}
            control={control}
        />
    );
}

export default RHFAutoComplete;

