import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStudentReferral } from '../../../store/actions/StudentReferralActions';
import MainCard from '../../../ui-component/cards/MainCard';
import { withStyles, useTheme } from '@material-ui/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@material-ui/core';
import SubjectIcon from '@material-ui/icons/Subject';
import useStyles from '../../ViewTableStyles';
import useUserRole from '../../../hooks/useUserRole';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

export default function StudentReferrals() {
    const classes = useStyles();
    const theme = useTheme();
    useUserRole({ roleList: ['STUDENT'] });

    const dispatch = useDispatch();
    const [value, setValue] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { allStudentReferrals } = useSelector((state) => state?.studentReferral);
    const newData = allStudentReferrals?.content;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        dispatch(getAllStudentReferral());
    }, []);

    return (
        <>
            <MainCard>
                <div className={classes.titleContainer}>
                    <SubjectIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; Your Referrals </span>
                </div>
                <Paper elevation={5} className={classes.root}>
                    {/* <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <OutlinedInput
                                label="hello"
                                className={classes.searchControl}
                                id="input-search-header"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" className={classes.startAdornment} />
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </Grid> */}
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.tableHeaderCell}>
                                <StyledTableRow>
                                    <TableCell> Name </TableCell>
                                    <TableCell align="center"> Email </TableCell>
                                    <TableCell align="center"> Referred For </TableCell>
                                    <TableCell align="right"> Date </TableCell>
                                </StyledTableRow>
                            </TableHead>
                            {!newData || newData.length === 0 ? (
                                <caption style={{ textAlign: 'center' }}> Sorry! No data available </caption>
                            ) : (
                                <TableBody>
                                    {newData?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <TableCell> {row?.name} </TableCell>
                                            <TableCell align="center"> {row?.email} </TableCell>
                                            <TableCell align="center"> {row?.referredFor} </TableCell>

                                            <TableCell align="right">
                                                <Moment format="YYYY/MM/DD hh:mm a" date={row?.createdAt}></Moment>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={newData?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-displayedRows': {
                                marginBottom: 0
                            },
                            '.MuiTablePagination-selectLabel': {
                                marginBottom: 0
                            }
                        }}
                    />
                </Paper>
            </MainCard>
        </>
    );
}
