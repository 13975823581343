import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Divider, OutlinedInput } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import useStyles from '../../../ViewTableStyles';
import { getAssignment_Submission } from '../../../../store/actions/Assignment_Submission_Actions';
import { useParams } from 'react-router-dom';
import DocumentLinks from '../documentLinks-view';
import FeedbackDocumentLinks from '../feedbackLinks-view';
import useUserRole from '../../../../hooks/useUserRole';

export default function DetailedSubmissionView() {
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'TEACHER','SUPER_ADMIN'] });
    const dispatch = useDispatch();
    const { id } = useParams();
    const { getAssignment_Submission_Data } = useSelector((state) => state?.assignmentSubmission);
    const newData = getAssignment_Submission_Data;
    useEffect(() => {
        dispatch(getAssignment_Submission(id));
    }, []);

    return (
        <>
            <Paper style={{ padding: 20 }}>
                <div className={classes.titleContainer}>
                    <SchoolIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; Assignment Submission Details </span>
                </div>
                <Divider style={{ margin: '30px 0px' }} />
                <Grid container rowSpacing={4}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Cust-Id </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={newData?.profile?.profileId} fullWidth />
                        </Grid>
                    </Grid>
                   
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Student FirstName </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={newData?.profile?.firstName} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Student LastName </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={newData?.profile?.lastName} fullWidth />
                        </Grid>
                    </Grid>
                    {/* <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Grade</label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={newData?.grade} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Feedback </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={newData?.feedback} fullWidth />
                        </Grid>
                    </Grid> */}
                    {/* <Divider style={{ margin: '30px 0px' }} />
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Teacher's Mobile </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={newData?.teacherProfile?.mobile} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Teacher's Email </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={newData?.teacherProfile?.email} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Teacher's FirstName </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={newData?.teacherProfile?.firstName} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Teacher's LastName </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={newData?.teacherProfile?.lastName} fullWidth />
                        </Grid>
                    </Grid> */}
                </Grid>
                <Divider style={{ margin: '30px 0px' }} />

                <DocumentLinks id={id} />

                <Divider style={{ margin: '30px 0px' }} />

                <FeedbackDocumentLinks id={id} />
            </Paper>
        </>
    );
}
