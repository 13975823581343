import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card, Container, Typography } from '@material-ui/core';
import Header from '../components/header';
import Footer from '../components/Footer';
import { useHistory } from 'react-router-dom';
import useUserRole from '../../../hooks/useUserRole';

const CATEGORY_DATA_LIST = [
    {
        id: 1,
        name: 'Newly Added Book Collection',
        image_url:
            'https://images.unsplash.com/photo-1491841550275-ad7854e35ca6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
    },
    {
        id: 2,
        name: 'Picture Books',
        image_url:
            'https://images.unsplash.com/photo-1472162072942-cd5147eb3902?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'
    },
    {
        id: 3,
        name: 'Novelty Books',
        image_url:
            'https://images.unsplash.com/photo-1608659597669-b45511779f93?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
    },
    {
        id: 4,
        name: 'Fiction',
        image_url:
            'https://images.unsplash.com/photo-1421338443272-0dde2463976a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
    },
    {
        id: 5,
        name: 'Early Readers',
        image_url:
            'https://images.unsplash.com/photo-1498712681408-fdcfb0eca86e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80'
    },
    {
        id: 6,
        name: 'Non Fiction',
        image_url:
            'https://images.unsplash.com/photo-1617817376017-3de702f0c35f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
    },
    {
        id: 7,
        name: 'Favourites',
        image_url:
            'https://images.unsplash.com/photo-1505377059067-e285a7bac49b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80'
    }
];
const useStyles = makeStyles((theme) => ({
    myStyle: {
        backgroundColor: theme.palette.primary.light,
        height: '100vh',
        fontSize: '50px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    MuiCardHeader: {
        root: {
            backgroundColor: 'red'
        }
    },
    imageItem: {
        width: '100%',
        height: 200,
        objectFit: 'cover',
        display: 'block'
    },
    cardRoot: {
        borderRadius: 12,
        cursor: 'pointer',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        '&:hover': {
            transform: 'scale(1.02)'
        }
    }
}));
export default function BookCategories() {
    const classes = useStyles();
    const history = useHistory();
    // useUserRole({ roleList: ['STUDENT'] });
    const handleCategoryList = () => {
        history.replace('/BOOK-SEARCH');
    };
    return (
        <>
            <Header />
            <Container maxWidth="xl">
                <Grid container spacing={4} style={{ marginTop: 0 }}>
                    {CATEGORY_DATA_LIST?.map((item, index) => (
                        <Grid item xs={12} sm={2} md={3} key={index}>
                            <Card className={classes.cardRoot} onClick={handleCategoryList}>
                                <img src={item?.image_url} className={classes.imageItem} alt='Books'/>
                                <Typography variant="h2" style={{ margin: '10px 0 10px 10px', textAlign: 'center' }}>
                                    {item?.name}
                                </Typography>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer />
        </>
    );
}
