import React, { useState, useEffect, createRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Snackbar } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { clearMessage } from '../store/actions/MessageActions';
import { useTheme } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    }
}));

const snackBarRef = createRef();
export default function AlertComponent({ isOpen, message }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleClose = (event, reason) => {
        dispatch(clearMessage());
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <Snackbar
                key={Math.random(4)}
                ref={snackBarRef}
                open={open}
                autoHideDuration={6000}
                anchorOrigin={{ 
                    vertical: 'top', 
                    horizontal: 'center' 
                }}
                sx={{ 
                    width: '100%' 
                }}
                onClose={handleClose}
                TransitionComponent={Slide}
                TransitionProps={{
                    direction: 'down'
                }}
                ContentProps={{
                    style: { 
                        backgroundColor: theme.palette.primary.main, 
                        fontFamily: 'Josefin Sans', 
                        fontWeight: 'lighter', 
                        fontSize: '16px',
                        borderRadius: '10px'
                     }
                }}
                message={message}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
        </div>
    );
}
