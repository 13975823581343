import React, { useState } from 'react';
import { Grid, Typography, CardMedia, Box, Tabs, Tab, Container, Button, Paper, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Header from '../components/header';
import Footer from '../components/Footer';
import PropTypes from 'prop-types';

//Icon Imports
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import useUserRole from '../../../hooks/useUserRole';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'whitesmoke'
    },
    imageText: {
        position: 'absolute',
        color: '#ffffff',
        top: 228,
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center'
    },
    btnDollar: {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
        fontSize: 21
    },
    paperStyle: {
        padding: 16,
        width: 650
    }
}));

export default function CourseDetails() {
    const classes = useStyles();
    const theme = useTheme();
    // useUserRole({ roleList: ['STUDENT'] });
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Header />
            <Grid container>
                <Grid item xs={12}>
                    <div style={{ position: 'relative' }}>
                        <CardMedia
                            component="img"
                            image="images/courses-detail-bg.jpg"
                            style={{ width: '100%', height: '445px', objectFit: 'cover' }}
                        />

                        <div className={classes.imageText}>
                            <Typography variant="h1"> Public Speaking </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.root}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="simple tabs example"
                            textColor="primary"
                            variant={matchDownSM ? 'scrollable' : 'fullWidth'}
                            scrollButtons="auto"
                        >
                            <Tab label="INFORMATION" {...a11yProps(0)} style={{ fontSize: 21 }} />
                            <Tab label="COURSE PLAN" {...a11yProps(1)} style={{ fontSize: 21 }} />
                            <Tab label="BATCH" {...a11yProps(2)} style={{ fontSize: 21 }} />
                            <Tab label="ASSIGNMENT" {...a11yProps(3)} style={{ fontSize: 21 }} />
                            <Tab label="REVIEW" {...a11yProps(4)} style={{ fontSize: 21 }} />
                        </Tabs>

                        <TabPanel value={value} index={0}>
                            <Container>
                                <h1 style={{ marginTop: 18 }}> Public Speaking</h1>
                                <Grid style={{ display: 'flex' }}>
                                    <Button className={classes.btnDollar}> $35 </Button> &nbsp;&nbsp;
                                    <Typography variant="h5" style={{ alignSelf: 'center' }}>
                                        per lesson
                                    </Typography>
                                </Grid>
                                <br />
                                <Paper variant="outlined" className={classes.paperStyle}>
                                    <Grid style={{ display: 'flex' }}>
                                        <AccessTimeIcon /> &nbsp;&nbsp;&nbsp;
                                        <Typography variant="h4" style={{ alignSelf: 'center' }}>
                                            90 min
                                        </Typography>
                                        &nbsp;&nbsp;| &nbsp;&nbsp;
                                        <StarBorderIcon /> &nbsp;&nbsp;&nbsp;
                                        <Typography variant="h4" style={{ alignSelf: 'center' }}>
                                            Shikha Kumari
                                        </Typography>
                                    </Grid>
                                </Paper>
                                <br />
                                <h6 style={{ lineHeight: 2 }}>
                                    In this course, you’ll get a behind-the-scenes look at what it takes to use public speaking to share
                                    <br />
                                    powerful ideas. Chris will walk you through real-life examples of TED Talks that worked and also share
                                    <br />
                                    firsthand stories of mistakes to avoid. You’ll get a series of worksheets and exercises to plan your own
                                    talk <br />
                                    that draws from Chris’s latest book TED Talks: The Official TED Guide to Public Speaking.
                                </h6>
                                <br />
                                <Divider style={{ width: 650 }} />
                                <h5 style={{ padding: 15 }}> Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Roo5 </h5>
                                <Divider style={{ width: 650 }} />

                                <Divider style={{ width: 650 }} />
                                <h5 style={{ padding: 15 }}> Teacher &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Shikha Kumari </h5>
                                <Divider style={{ width: 650 }} />

                                <Divider style={{ width: 650 }} />
                                <h5 style={{ padding: 15 }}>Time &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;6:30 P.M</h5>
                                <Divider style={{ width: 650 }} />
                            </Container>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            Item Two
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            Item Three
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            Item Four
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            Item Five
                        </TabPanel>
                    </div>
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}
