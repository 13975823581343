import { http } from '../utils/http';

export const calculateModule = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/module-subscriptions/calculate', payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const checkDiscountCode = (payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/discount/module/validate', payload, true)
            .then((data) => {
                resolve(data);
                dispatch(setMessage(data?.message ? data?.message : 'Code valid !!'));
            })
            .catch((err) => {
                reject(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Sorry, Code is not valid!!'));
            });
    });
};

export const checkVoucherCode = (payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/gift-voucher/module/validate', payload, true)
            .then((data) => {
                resolve(data);
                dispatch(setMessage('Code valid !!!'));
            })
            .catch((err) => {
                reject(err);
                dispatch(setMessage('Sorry, Code is not valid'));
            });
    });
};
