import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

import config from './../config';
import HomeRoutes from './HomeRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessage } from '../store/actions/MessageActions';
import AlertComponent from '../components/AlertComponent';
import StudentRoutes from './StudentRoutes';
import Payment from '../views/payment';
import PaymentSuccess from '../views/paymentSuccess';
import PageNotFound from '../views/PageNotFound';

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
    const dispatch = useDispatch();
    const { message } = useSelector((state) => state.message);
    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen((location) => {
            dispatch(clearMessage());
        });

        return () => {
            unlisten();
        };
    }, [history, dispatch]);

    useEffect(() => {
        if (window.location.pathname === '/') {
            history.push('/home');
            window.location.reload();
        }
    }, [history]);

    return (
        <>
            {message && <AlertComponent isOpen={true} message={message} />}
            <Switch>
                <React.Fragment>
                    <LoginRoutes />
                    <HomeRoutes />
                    <MainRoutes />
                    <StudentRoutes />
                    <Route exact path="/payment" component={() => <Payment />} />
                    <Route exact path="/paymentSuccess" component={() => <PaymentSuccess />} />
                    <Route exact path="/404" component={() => <PageNotFound />} />
                </React.Fragment>
            </Switch>
        </>
    );
};

export default Routes;
