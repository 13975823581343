import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CircularProgress from '@mui/material/CircularProgress';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultiselectChip(props) {
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState('');
    const loading = open && options.length === 0 && searchValue !== '';

    const getSelectedItems = (value) => {
        props.getSelectedItems(value);
    };

    React.useEffect(() => {
        setOptions(props.values);
    }, [props.values]);

    const handleSearchValueChange = (e) => {
        setSearchValue(e.target.value);

        props.handleSearch(e.target.value);
    };

    return (
        <Autocomplete
            defaultValue={props.defaultValue}
            defaultChecked={props.defaultChecked}
            onChange={(e, value) => getSelectedItems(value)}
            filterOptions={(x) => x}
            multiple
            disableCloseOnSelect
            id="checkboxes-tags"
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            isOptionEqualToValueIsbn={(option, value) => option.isbn === value.isbn}
            getOptionLabel={(option) => option.title}
            options={options}
            loading={loading}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.title}
                    {option.isbn ? ` (${option.isbn})` : ''}
                    {/* {option.title+" ("+option.isbn+")"} */}
                </li>
            )}
            // style={{ width: 500 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={searchValue}
                    placeholder={props.placeholder}
                    fullWidth
                    onChange={handleSearchValueChange}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />
    );
}
