import { GET_ALL_REFERRAL_START, GET_ALL_REFERRAL_SUCCESS, GET_ALL_REFERRAL_ERROR } from '../../constants';

const initialState = {
    loading: false,
    allReferrals: null,
    message: ''
};

export default function GetAllReferralReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_REFERRAL_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_REFERRAL_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_REFERRAL_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
