import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import School from '../../../../assets/images/users/School.png';
import { contactUs } from '../../../../services/Contact';
import { Grid, InputAdornment, Button, IconButton, Avatar, OutlinedInput, Container, Paper } from '@material-ui/core';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { Dialog, Link, DialogTitle, DialogContent } from '@material-ui/core';
import { setMessage } from '../../../../store/actions/MessageActions';

const useStyles = makeStyles((theme) => ({
    label: {
        marginBottom: '.0rem',
        fontSize: '15px',
        fontFamily: 'Josefin Sans',
        fontStyle: 'designationular Roman',
        color: '#fff'
    },
    imageContainer: {
        width: '320px',
        height: '250px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    formContainer: {
        padding: 20
    },
    dialogPaper: {
        padding: 10,
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    }
}));

const validationSchema = yup.object({
    name: yup.string('Enter Your Name').required('Name is required'),
    mail: yup.string('Enter Your Designation').required('Designation is required'),
    contactNo: yup.string('Enter Your Mobile').required('Mobile is required'),
    message: yup.string('Enter Email').required('Email is required')
});

export default function ContactUs(props) {
    const dispatch = useDispatch();
    const [snackBarOpened, setSnackBarOpened] = useState(false);

    const [errorMsg, setErrorMsg] = useState('');
    const { openContactUs, setOpenContactUs } = props;

    const classes = useStyles();
    const [errorSnackBarOpened, setErrorSnackBarOpened] = useState(false);

    const handlePopupClose = () => {
        setOpenContactUs(false);
        //reset();
    };

    const initialValues = {
        name: '',
        mail: '',
        contactNo: '',
        message: ''
    };

    const ContactUs = async (payload) => {
        await contactUs(payload)
            .then((res) => {
                setSnackBarOpened(true);
                dispatch(setMessage(res?.data?.message ? res?.data?.message : 'Submitted Successfully!!'));
                setOpenContactUs(false);
            })
            .catch((error) => {
                setErrorMsg(error?.data?.message);
                dispatch(setMessage(error?.data?.message ? error?.data?.message : 'Error !!'));
                setErrorSnackBarOpened(true);
            });
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: ContactUs
    });

    return (
        <div>
            <Dialog
                open={openContactUs}
                classes={{ paper: classes.dialogPaper }}
                maxWidth={'sm'}
                scroll={'paper'}
            >
                <span
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'right',
                        paddingRight: '7px'
                    }}
                >
                    <IconButton onClick={handlePopupClose}>
                        <CloseIcon />
                    </IconButton>
                </span>

                <DialogContent>
                    <Grid container>
                        <Grid item sm={6} style={{ display: 'flex', flexDirection: 'column', textAlign: 'justify', paddingLeft: '15px' }}>
                            <div>
                                <h2
                                    style={{
                                        textAlign: 'justify',
                                        color: '#272343',
                                        // marginTop: '10px',
                                        fontweight: 'lighter',
                                        color: '#FF6600'
                                    }}
                                >
                                    Connect with
                                    {
                                        <span>
                                            <br />
                                        </span>
                                    }
                                    us..?
                                </h2>
                            </div>
                            <div>
                                <p style={{ top: '130px', color: '#8D8D8D', fontWeight: 'lighter' }}>
                                    We are glad
                                    {
                                        <span>
                                            <br />
                                        </span>
                                    }
                                    to connect with you.
                                </p>
                            </div>

                            <img src={School} className={classes.imageContainer}></img>
                        </Grid>

                        <Grid
                            item
                            sm={6}
                            style={{
                                backgroundColor: '#2EBEE1',
                                borderRadius: '25px'
                            }}
                        >
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={2} className={classes.formContainer}>
                                    <Grid item xs={12}>
                                        <label className={classes.label}>
                                            <b> Name </b> <span className={classes.mandatory}> * </span>
                                        </label>

                                        <OutlinedInput
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            id="name"
                                            name="name"
                                            size="small"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            startAdornment={<InputAdornment position="start"></InputAdornment>}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className={classes.label}>
                                            <b> Email </b> <span className={classes.mandatory}>*</span>
                                        </label>

                                        <OutlinedInput
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            id="mail"
                                            name="mail"
                                            size="small"
                                            value={formik.values.mail}
                                            onChange={formik.handleChange}
                                            startAdornment={<InputAdornment position="start"></InputAdornment>}
                                            error={formik.touched.mail && Boolean(formik.errors.mail)}
                                            helperText={formik.touched.mail && formik.errors.mail}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className={classes.label}>
                                            <b> Mobile </b> <span className={classes.mandatory}>*</span>
                                        </label>

                                        <OutlinedInput
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            id="contactNo"
                                            name="contactNo"
                                            size="small"
                                            value={formik.values.contactNo}
                                            onChange={formik.handleChange}
                                            startAdornment={<InputAdornment position="start"></InputAdornment>}
                                            error={formik.touched.contactNo && Boolean(formik.errors.contactNo)}
                                            helperText={formik.touched.contactNo && formik.errors.contactNo}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label className={classes.label}>
                                            <b> Message </b> <span className={classes.mandatory}>*</span>
                                        </label>

                                        <OutlinedInput
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            id="message"
                                            name="message"
                                            size="small"
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            startAdornment={<InputAdornment position="start">{/* <Email /> */}</InputAdornment>}
                                            error={formik.touched.message && Boolean(formik.errors.message)}
                                            helperText={formik.touched.message && formik.errors.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="center">
                                        <Button
                                            type="submit"
                                            size="medium"
                                            style={{
                                                backgroundColor: '#FF8F44',
                                                fontFamily: 'Josefin Sans',
                                                fontWeight: 'lighter',
                                                color: 'white',
                                                top: '8px'
                                            }}
                                            variant="contained"
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}
