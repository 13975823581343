import {
    Grid,
    Box,
    Button,
    OutlinedInput,
    InputAdornment,
    Typography,
    Card,
    Snackbar,
    Alert,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    useMediaQuery,
    useTheme,
    TextField
} from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

import topBackground from '../../../assets/images/homepageOne/childBackground.png';
import childWithLaptop from '../../../assets/images/homepageOne/childImage.png';
import learnWithUsBg from '../../../assets/images/homepageOne/learnWithUsBg.png';
import learnWithUsImg from '../../../assets/images/homepageOne/learnWithUsImg.png';
import boyOnPc from '../../../assets/images/homepageOne/boyOnPc.png';
import girlWithBooks from '../../../assets/images/homepageOne/girl_with_books.png';
import childInSpecs from '../../../assets/images/homepageOne/childInSpecs.png';
import listImg1 from '../../../assets/images/homepageOne/listImg1.png';
import listImg2 from '../../../assets/images/homepageOne/listImg2.png';
import listImg3 from '../../../assets/images/homepageOne/personalized.jpg';
import listImg4 from '../../../assets/images/homepageOne/fun.jpg';
import popularModuleBg from '../../../assets/images/homepageOne/popularModuleBg.png';
import workDoneByStudentsBg from '../../../assets/images/homepageOne/workDoneByStudentsBg.png';
import bookLibraryCardImage from '../../../assets/images/homepageTwo/heroImg.png';
import onlineClassesCardImage from '../../../assets/images/users/eePic.png';
import schoolOfferingCardImage from '../../../assets/images/users/picSchool.png';
import LearnWithUsListItem from './components/LearnWithUsListItems';
import CoursesCard from './components/CoursesCard';
import PopularModulesCarousel from './components/PopularModulesCarousel';
import TestimonialCarousel from './components/TestimonialCarousel';
import WorkDoneCarousel from './components/WorkDoneCarousel';
import TeacherTestimonialCarousel from './components/TeacherTestimonialCaraosel';
import FooterNew from '../components/FooterNew';
import ContactUs from './components/ContactUs';
import { bookAFreeLesson, getAllServices } from '../../../services/LandingPageServices';
import { Link } from 'react-router-dom';
import { setMessage } from '../../../store/actions/MessageActions';
import { useDispatch } from 'react-redux';

import styles from './style.module.css';
import useStyles from './styles';
import { number } from 'prop-types';

const useStyles1 = makeStyles((theme) => ({
    lesson: {
        [theme.breakpoints.down('sm')]: { marginBottom: '20px', width: 'auto' }
    },
    services: {
        [theme.breakpoints.down('sm')]: { marginTop: 160 },
        [theme.breakpoints.up('sm')]: { marginTop: 30 },
        [theme.breakpoints.up('lg')]: { marginTop: 5 }
    },
    startedBtn: {
        [theme.breakpoints.down('sm')]: { justifyContent: 'center' }
    },
    teacherTitle: {
        textAlign: 'center',
        width: '100%',
        alignSelf: 'center',

        [theme.breakpoints.down('sm')]: { fontSize: 12 }
    },
    teacherTitleText: {
        marginBottom: 10,
        marginTop: 10,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 2,
            marginTop: 10
        }
    },
    whyChooseUs: {},
    whyTitle: {
        marginTop: 40,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            alignSelf: 'center'
        }
    }
}));

function HomepageOne() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
    const getAllAges = () => {
        const ageRange = [];
        for (let i = 3; i <= 15; i++) {
            ageRange.push(i);
        }
        return ageRange;
    };

    const [snackBarOpened, setSnackBarOpened] = useState(false);
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [comment, setComment] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [errorSnackBarOpened, setErrorSnackBarOpened] = useState(false);
    const [servicesList, setServicesList] = useState([]);
    const [openContactUs, setOpenContactUs] = useState(false);

    const handleCloseSnackbar = () => {
        setSnackBarOpened(false);
    };

    const handleBookNowClick = () => {
        if (name === '' || name === ' ' || !name || name === 'undefined') {
            setErrorMsg('Name can not be empty, please enter a valid name');
            setErrorSnackBarOpened(true);
        } else if (age === '' || age === ' ' || !age || age === 'undefined') {
            setErrorMsg('Age can not be empty, please select age ');
            setErrorSnackBarOpened(true);
        } else if (phoneNumber === '' || phoneNumber === ' ' || !phoneNumber || phoneNumber === 'undefined') {
            setErrorMsg('Phone No can not be empty, please enter a valid Phone No. ');
            setErrorSnackBarOpened(true);
        } else if (typeof phoneNumber !== number && phoneNumber.trim().length !== 10) {
            setErrorMsg('Not a valid Phone No, please enter a valid Phone No. ');
            setErrorSnackBarOpened(true);
        } else {
            const payload = {
                name: name,
                age: age,
                contact: phoneNumber,
                comment: comment
            };

            BookAFreeLesson(payload);
        }
    };

    const handleCloseErrorSnackbar = () => {
        setErrorSnackBarOpened(false);
    };

    const BookAFreeLesson = async (payload) => {
        await bookAFreeLesson(payload)
            .then((res) => {
                setName('');
                setAge('');
                setPhoneNumber('');
                setComment('');
                setSnackBarOpened(true);
                // dispatch(setMessage(res?.message ? res?.message : 'Successfull..!'));
            })
            .catch((error) => {
                setErrorMsg(error?.data?.error);
                setErrorSnackBarOpened(true);
                // dispatch(setMessage(error?.data?.message ? error?.data?.message : 'Error..!'));
            });
    };
    const GetAllServices = async () => {
        await getAllServices()
            .then((res) => {
                setServicesList(res?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        GetAllServices();
    }, []);

    return (
        <>
       
            {openContactUs && <ContactUs openContactUs={openContactUs} setOpenContactUs={setOpenContactUs} />}
            <Grid container direction="column">
                <Grid item>
                    <Header></Header>
                </Grid>
                <Grid className={classes.container}>
                    <Grid
                        container
                        sx={{
                            backgroundImage: `url(${topBackground})`,
                            backgroundSize: 'cover'
                        }}
                    >
                        <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
                            <Box className={classes.heroContentContainer}>
                                <Box
                                    className={classes.textInteractive}
                                    // style={{ fontFamily: 'Josefin Sans', fontSize: '56px', fontWeight: 'lighter' }}
                                >
                                    
                                    INTERACTIVE
                                </Box>
                                <Box
                                    className={classes.textOnlineClasses}
                                    // style={{ fontFamily: 'Josefin Sans', fontSize: '56px', fontWeight: 'lighter' }}
                                >
                                    {' '}
                                    SKILL BUILDING CLASSES{' '}
                                </Box>

                                <Box
                                    className={styles.textSubHeading}
                                    style={{ fontFamily: 'Josefin Sans', fontSize: '16px', fontWeight: 'lighter' }}
                                >
                                    {' '}
                                    Creative Writing | Story-Telling | Public Speaking{' '}
                                </Box>
                                <Box className={classes.buttonGetStartedContainer}>
                                    <a href="/onlineClasses" style={{ textDecoration: 'none' }}>
                                        <Button
                                            variant="contained"
                                            className={styles.btnGetStarted}
                                            style={{ fontFamily: 'Josefin Sans', fontWeight: 'lighter', fontSize: '12px', width: '120px' }}
                                        >
                                            Get Started
                                        </Button>
                                    </a>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} className={classes.childWithLaptop}>
                            <img src={childWithLaptop} style={{ width: '80%', height: '85%' }} alt="interactive" />
                        </Grid>
                    </Grid>
                    <Box className={classes.homeForm} sx={{ width: '40vw' }}>
                        <Grid container spacing={2} direction="column" className={classes.bookNow} style={{ marginTop: '30px' }}>
                            <Grid item sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                <Box
                                    className={classes.freeLessonHeader}
                                    style={{ fontSize: '30px', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}
                                >
                                    {' '}
                                    Book a free lesson{' '}
                                </Box>
                            </Grid>
                            <Grid item sx={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', direction: 'row' }}>
                                <Grid item xs={12} md={4} display="flex" justifyContent="center" margin="6px">
                                    <OutlinedInput
                                        fullWidth
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        id="outlined-adornment-weight"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <PersonIcon></PersonIcon>
                                            </InputAdornment>
                                        }
                                        placeholder="Name"
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'name'
                                        }}
                                        sx={{
                                            fontWeight: 'lighter',
                                            fontFamily: 'Josefin Sans',
                                            input: {
                                                color: '#000 !important',
                                                '&::placeholder': {
                                                    opacity: 1
                                                }
                                            },
                                            label: { color: '#000 !important' }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} display="flex" justifyContent="center" margin="6px" width="50%">
                                    <TextField
                                        select
                                        required
                                        value={age}
                                        // fullWidth
                                        SelectProps={{
                                            native: true
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CalendarMonthIcon></CalendarMonthIcon>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(event) => {
                                            setAge(event.target.value);
                                        }}
                                    >
                                        <option
                                            value=""
                                            style={{
                                                fontWeight: 'lighter',
                                                fontFamily: 'Josefin Sans',
                                                color: '#000'
                                            }}
                                        >
                                            {'Age'}
                                        </option>

                                        {getAllAges().map((age, index) => (
                                            <option
                                                key={index}
                                                value={age}
                                                style={{
                                                    fontWeight: 'lighter',
                                                    fontFamily: 'Josefin Sans'
                                                }}
                                            >
                                                {age}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={3} display="flex" justifyContent="center" margin="6px">
                                    <OutlinedInput
                                        value={phoneNumber}
                                        required
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        fullWidth
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <PhoneIphoneIcon></PhoneIphoneIcon>
                                            </InputAdornment>
                                        }
                                        placeholder="Phone"
                                        type="text"
                                        inputProps={{
                                            'aria-label': 'phone'
                                        }}
                                        sx={{
                                            fontWeight: 'lighter',
                                            fontFamily: 'Josefin Sans',
                                            input: {
                                                color: '#000',
                                                '&::placeholder': {
                                                    opacity: 1
                                                }
                                            },
                                            label: { color: '#000' }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={5} display="flex" justifyContent="center" margin="6px">
                                    <OutlinedInput
                                        fullWidth
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        id="outlined-adornment-weight"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <PersonIcon></PersonIcon>
                                            </InputAdornment>
                                        }
                                        placeholder="Comment"
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'comment'
                                        }}
                                        sx={{
                                            fontWeight: 'lighter',
                                            fontFamily: 'Josefin Sans',
                                            input: {
                                                color: '#000 !important',
                                                '&::placeholder': {
                                                    opacity: 1
                                                }
                                            },
                                            label: { color: '#000 !important' }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} display="flex" justifyContent="center" margin="6px">
                                    <Button
                                        variant="contained"
                                        sx={{
                                            fontWeight: 'lighter',
                                            fontFamily: 'Josefin Sans',
                                            fontSize: '16px',
                                            backgroundColor: '#FF8F44',
                                            paddingTop: '8px',
                                            paddingBottom: '8px',
                                            width: '120px',
                                            height: '40px'
                                        }}
                                        onClick={handleBookNowClick}
                                    >
                                        Book Now
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* offered courses */}
                    <Grid container spacing={3} direction="column" alignItems="center" sx={{ marginTop: '40px', width: '100%' }}>
                        <Grid item xs={12} justifyContent="center">
                            <Typography style={{ fontSize: '30px', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                                {' '}
                                Service We Offer{' '}
                            </Typography>
                        </Grid>
                        {/* courses cards */}
                        <Grid
                            item
                            container
                            spacing={3}
                            className={classes.coursesCard}
                            style={{ marginLeft: -12 }}
                            justifyContent="center"
                        >
                            {/* individual card */}

                            <Grid item>
                                <CoursesCard
                                    text="Book Library"
                                    imageUrl={bookLibraryCardImage}
                                    buttonLink={'/bookLibrary'}
                                    bgColor="#D5FFE0"
                                ></CoursesCard>
                            </Grid>
                            <Grid item>
                                <CoursesCard
                                    text="English Enrichment Classes"
                                    imageUrl={onlineClassesCardImage}
                                    buttonLink={'/onlineClasses'}
                                    bgColor="#CCF1EC"
                                ></CoursesCard>
                            </Grid>
                            <Grid item>
                                <CoursesCard
                                    text="School Offering"
                                    imageUrl={schoolOfferingCardImage}
                                    buttonLink={'/school'}
                                    bgColor="#F4F8E9"
                                ></CoursesCard>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={4}
                        sx={{
                            backgroundImage: `url(${learnWithUsBg})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            marginTop: '40px',
                            padding: '3% 15% 3% 15%'
                        }}
                    >
                        <Grid item sm={6}>
                            <Box
                                sx={{
                                    fontSize: '30px',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans',
                                    color: 'black'
                                }}
                            >
                                Learn With Us
                            </Box>
                            <br />
                            <p style={{ fontWeight: 'lighter', color: '#545C6D', fontSize: '18px', fontFamily: 'Josefin Sans' }}>
                                Let's build these lifelong skills for your child to <br></br>conquer the world
                            </p>

                            <LearnWithUsListItem> Solidify Writing Skills </LearnWithUsListItem>
                            <LearnWithUsListItem> Art of Articulation </LearnWithUsListItem>
                            <LearnWithUsListItem> Confidence to Express </LearnWithUsListItem>
                            <LearnWithUsListItem> Enhanced Communication Skills </LearnWithUsListItem>
                            <LearnWithUsListItem> Usage of Correct Grammar </LearnWithUsListItem>
                            <LearnWithUsListItem> Vocabulary Building </LearnWithUsListItem>
                        </Grid>
                        <Grid item sm={6}>
                            <img src={learnWithUsImg} style={{ width: '100%', height: '85%' }} alt="child with mom having class"></img>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        className={classes.whyChooseUs}
                        justifyContent="center"
                        sx={matchDownXL ? { padding: 4 } : { padding: '40px 120px' }}
                        columnGap={4}
                    >
                        <Grid item xs={12} sm={4} container direction="column">
                            <Grid item>
                                <img
                                    src={boyOnPc}
                                    alt=""
                                    style={{ width: '50%', height: '75%', objectFit: 'cover', borderRadius: '4%' }}
                                ></img>
                            </Grid>
                            <Grid item style={{ marginBottom: '10%' }}>
                                <img
                                    src={girlWithBooks}
                                    alt=""
                                    style={{ width: '50%', height: '75%', objectFit: 'cover', borderRadius: '4%' }}
                                ></img>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ marginLeft: '-15%' }}>
                            <h5
                                className={classes.whyTitle}
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans',
                                    marginTop: '5%',
                                    marginLeft: 'auto',
                                    marginBottom: '1%'
                                }}
                            >
                                {' '}
                                <br></br>{' '}
                            </h5>
                            <h2
                                style={{
                                    fontSize: '30px',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans',
                                    color: 'black',
                                    marginBottom: '2%',
                                    width: '100%'
                                }}
                            >
                                {' '}
                                <br></br>{' '}
                            </h2>

                            <img
                                src={childInSpecs}
                                alt=""
                                style={{
                                    width: '52%',
                                    height: '40%',
                                    objectFit: 'cover',
                                    borderRadius: '4%',
                                    marginLeft: '1%',
                                    marginTop: '5',
                                    display:'flex',
                                    justifyContent:'center'
                                }}
                            ></img>
                        </Grid>
                        <Grid item container justifyContent="center" spacing={2} xs={12} sm={8} md={3} display="flex" alignItems="center">
                            <List sx={{ width: '100%' }}>
                                <ListItem>
                                    <h5
                                        className={classes.whyTitle}
                                        style={{
                                            fontSize: '25px',
                                            fontWeight: 'lighter',
                                            fontFamily: 'Josefin Sans',
                                            marginTop: '1%',
                                            marginLeft: '20px',
                                            marginBottom: '1%'
                                        }}
                                    >
                                        {' '}
                                        WHY CHOOSE US
                                    </h5>
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar sx={{ marginRight: 2 }}>
                                        <img src={listImg1} alt="listImg" width={66} style={{ marginLeft: 5 }}></img>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <h5
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: 'lighter',
                                                    fontFamily: 'Josefin Sans',
                                                    color: '#000000'
                                                }}
                                            >
                                                {' '}
                                                Hybrid classes, small batch size{' '}
                                            </h5>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar sx={{ marginRight: 2 }}>
                                        <img src={listImg2} alt="listImg" width={66} style={{ marginLeft: 5 }}></img>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <h5
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: 'lighter',
                                                    fontFamily: 'Josefin Sans',
                                                    color: '#000000'
                                                }}
                                            >
                                                {' '}
                                                Experienced, passionate teachers{' '}
                                            </h5>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar sx={{ marginRight: 2 }} >
                                        <img src={listImg3} alt="listImg" width={71} style={{ marginLeft: 3 }}></img>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <h5
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: 'lighter',
                                                    fontFamily: 'Josefin Sans',
                                                    color: '#000000'
                                                }}
                                            >
                                                {' '}
                                                Fun, interactive curriculum designed by experts{' '}
                                            </h5>
                                        }
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar sx={{ marginRight: 2 }}>
                                        <img src={listImg4} alt="listImg" width={77} style={{ marginTop: -8 }}></img>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <h5
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: 'lighter',
                                                    fontFamily: 'Josefin Sans',
                                                    color: '#000000'
                                                }}
                                            >
                                                {' '}
                                                Personalised attention and individual feedback{' '}
                                            </h5>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Box
                        style={{
                            width: '100%',
                            backgroundImage: `url(${popularModuleBg})`,
                            backgroundSize: 'cover',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: '50px'
                        }}
                    >
                        <Box style={{ paddingTop: '72px' }}>
                            <h6
                                style={{
                                    fontSize: '18px',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans',
                                    color: '#000000'
                                }}
                            >
                                {' '}
                                OUR CLASSES{' '}
                            </h6>
                        </Box>
                        <Box style={{ marginBottom: '16px' }}>
                            <h3
                                style={{
                                    fontSize: '30px',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans',
                                    color: '#000000'
                                }}
                            >
                                {' '}
                                Popular Modules{' '}
                            </h3>
                        </Box>
                            <PopularModulesCarousel></PopularModulesCarousel>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: '50px'
                        }}
                    >
                        <Box>
                            <h3
                                style={{
                                    textAlign: 'center',
                                    fontSize: '30px',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans'
                                }}
                            >
                                {' '}
                                Testimonials{' '}
                            </h3>
                        </Box>
                        <Box className={classes.testimonialCarousel}>
                            <TestimonialCarousel />
                        </Box>
                    </Box>
                    <Box sx={{ width: '100%', backgroundImage: `url(${workDoneByStudentsBg})` }}>
                        <h2
                            style={{
                                textAlign: 'center',
                                padding: '32px 0 0 0',
                                fontSize: '30px',
                                fontWeight: 'lighter',
                                fontFamily: 'Josefin Sans'
                            }}
                            className={classes.workDone}
                        >
                            Work Done By Students
                        </h2>
                        <WorkDoneCarousel />
                    </Box>
                    <Card elevation={0} sx={{ backgroundColor: '#2EBEE1', padding: '14px', marginLeft: '18%', width: '63%' }}>
                        <Grid container spacing={2} direction="column" alignSelf="center" alignItems="center" justifyContent="center">
                            <Grid
                                item
                                xs={12}
                                // sx={{ alignItems: 'center', justifyContent: 'center' }}
                                style={{ width: '100%', height: '10%', marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }}
                            >
                                <h3
                                    style={{
                                        color: '#fff',
                                        marginBottom: '0px',
                                        textAlign: 'center',
                                        fontSize: '30px',
                                        fontWeight: 'lighter',
                                        fontFamily: 'Josefin Sans'
                                    }}
                                >
                                    Help our experts understand your child's reading and writing level
                                </h3>
                            </Grid>
                            <Grid item xs={12} alignSelf={'center'}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#FF8F44',
                                        width: '180px',
                                        height: '45px',
                                        color: 'white',
                                        fontWeight: 'lighter',
                                        fontFamily: 'Josefin Sans',
                                        fontSize: '16px'
                                    }}
                                    onClick={() => {
                                        setOpenContactUs(true);
                                    }}
                                >
                                    Request Call Back
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>

                    <div style={{ marginTop: '50px', alignSelf: 'center' }}>
                        <div className={classes.teacherTitle}>
                            <Typography
                                variant="h2"
                                textAlign="center"
                                mb={10}
                                fontSize="30px"
                                fontWeight="lighter"
                                fontFamily="Josefin Sans"
                                marginBottom="0.1%"
                            >
                                Meet Our Teachers
                            </Typography>
                        </div>
                        <div>
                            <TeacherTestimonialCarousel></TeacherTestimonialCarousel>
                        </div>
                    </div>
                    <FooterNew></FooterNew>
                    <Snackbar
                        open={snackBarOpened}
                        autoHideDuration={4000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                            Submitted successfully
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={errorSnackBarOpened}
                        autoHideDuration={4000}
                        onClose={handleCloseErrorSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                            {errorMsg}
                        </Alert>
                    </Snackbar>
                </Grid>
            </Grid>
        </>
    );
}

export default HomepageOne;
