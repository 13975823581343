import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { http } from '../../utils/http';

const Payment = () => {
    const history = useHistory();
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }
        //commented
        const result = await http(
            process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
            'POST',
            'subscriptions-service/plan-subscriptions/orders'
        );
        

        if (!result) {
            alert('Server error. Are you online?');
            return;
        }

        const { id, amount, currency } = result.data;

        const options = {
            key: 'rzp_live_hnvBKG74pmvVdS',
            amount: amount,
            currency: currency,
            name: 'Acme Corp',
            description: 'Transaction',
            order_id: id,
            handler: function (response) {
                history.push({ pathname: '/paymentSuccess', state: { detail: response } });
                
            },
            notes: {
                address: 'Razorpay Corporate Office'
            },
            theme: {
                color: '#3399cc'
            }
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.on('payment.failed', function (response) {
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
        });

        paymentObject.open();
    }

    return (
        <div>
            <button onClick={displayRazorpay}>Buy now</button>
        </div>
    );
};

export default Payment;
