import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import MinimalLayout from './../layout/MinimalLayout';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';
import AuthGuard from '../utils/route-guard/AuthGuard';
import { Cart, Wishlist, WishlistCart } from '../views/home/wishlist-cart';
import StudentReferrals from '../views/students/referrals';
//import ViewSubscriptions from '../views/subscriptions/viewSubscription';
import ViewSubscription from '../views/subscription/admin/all-subscription/view-subscription';
import Ref from '../views/ref';

const StudentDashboard = Loadable(lazy(() => import('../views/students/dashboard')));
const Refer = Loadable(lazy(() => import('../views/students/Refer')));

//const { Cart } = Loadable(lazy(() => import('../views/home/wishlist-cart')));
//const { Wishlist } = Loadable(lazy(() => import('../views/home/wishlist-cart')));
//const { WishlistCart } = Loadable(lazy(() => import('../views/home/wishlist-cart')));

const StudentRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/my-dashboard',
                '/my-cart',
                '/my-wishlist',
                '/my-wishlistcart',
                '/student-referrals',
                '/student/ViewSubscriptions/:id',
                '/refer',
                '/reff'
            ]}
        >
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <NavMotion>
                        <AuthGuard>
                            <Route path="/my-dashboard" component={StudentDashboard} />
                            <Route path="/my-cart" component={Cart} />
                            <Route path="/my-wishlist" component={Wishlist} />
                            <Route path="/my-wishlistcart" component={WishlistCart} />
                            <Route path="/student-referrals" component={StudentReferrals} />
                            <Route path="/student/ViewSubscriptions/:id" component={ViewSubscription} />
                            <Route path="/refer" component={Refer} />
                            {/* <Route path="/refer" component={Ref} /> */}
                        </AuthGuard>
                    </NavMotion>
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default StudentRoutes;
