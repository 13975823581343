import { http } from '../utils/http';
import axios from 'axios';

export const getModuleSubscriptions = (id, rowsPerPage = 10, searchData = '', page = 1) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/plan-subscriptions/profiles/${id}?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`
            )
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
