import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
import AllSchool from '../views/stock-management/AllSchool';
import Assignment_Submissions from '../views/online-classes/assignment-submission';
import DetailedSubmissionView from '../views/online-classes/assignment-submission/submission-detailed-view';
import BookCollection from '../views/admin/cart/stock/book-collection';
import GiftVouchers from '../views/vouchers';
import Referrals from '../views/referrals';
import ViewSubscriptions from '../views/subscriptions/viewSubscription';
import ViewModules from '../views/online-classes/courses';
import SecurityDeposits from '../views/subscription/admin/security-deposits';

// import OnlineSubscription from '../views/subscription/admin/all-subscription/onlineSubscription';
//import AllCourses from '../views/online-classes/courses/all-courses';
// import Subscriptions from '../views/subscriptions';
//import { Cart, Wishlist, WishlistCart } from '../views/home/wishlist-cart';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

const UserDashboard =Loadable(lazy(() => import('../views/admin/Dashboard/UserDashBoard')));
const LibraryDashboard=Loadable(lazy(() => import('../views/admin/Dashboard/LibrarySubscriptionDashboard')));

// books routing
const Books = Loadable(lazy(() => import('../views/books')));
const AllDeletedBooks = Loadable(lazy(() => import('../views/books/AllDeletedBooks')));
const SchoolAdminBooks = Loadable(lazy(() => import('../views/SchoolAdminBooks')));
const BooksLanguages = Loadable(lazy(() => import('../views/books/books-languages')));
const AddBook = Loadable(lazy(() => import('../views/books/add-book')));

//category routing
const ViewParentCategories = Loadable(lazy(() => import('../views/books/categories/parent-category/view')));
const BookCategories = Loadable(lazy(() => import('../views/books/categories')));
const AddBookCategory = Loadable(lazy(() => import('../views/books/categories/add-category')));

//orders routing
const AllOrders = Loadable(lazy(() => import('../views/orders/all-orders')));
const PlaceOrders = Loadable(lazy(() => import('../views/orders/place-orders')));
const DeliveryPlan = Loadable(lazy(() => import('../views/orders/delivery-plan')));
const OrderDetails = Loadable(lazy(() => import('../views/orders/order-details')));
const OrderBooks = Loadable(lazy(() => import('../views/orders/order-books')));
const PendingBooks = Loadable(lazy(() => import('../views/orders/pending-books')));
//bulk routing
const BulkOrdersPlace = Loadable(lazy(() => import('../views/orders/bulk-place-orders')));
const OrderBulkUpdate = Loadable(lazy(() => import('../views/orders/bulk-update-orders')));
const BookBulkUpdate = Loadable(lazy(() => import('../views/orders/bulk-book-return')));
const BulkBatches = Loadable(lazy(() => import('../views/orders/bulk-batches')));
const ViewBulkDetail = Loadable(lazy(() => import('../views/orders/bulk-batches/view-bulk-details')));

//cart routing

//subscriptions & plans routing
const AllSubscription = Loadable(lazy(() => import('../views/subscription/admin/all-subscription')));
const BookTrials = Loadable(lazy(() => import('../views/subscription/admin/book-trials')));
const DeletedBookTrials = Loadable(lazy(() => import('../views/subscription/admin/book-trials/DeletedBookTrials')));
const ViewSubscription = Loadable(lazy(() => import('../views/subscription/admin/all-subscription/view-subscription')));
const AllPlans = Loadable(lazy(() => import('../views/subscription/admin/plans')));
const AllDeletedPlans = Loadable(lazy(() => import('../views/subscription/admin/plans/AllDeletedPlan')));
const PlanOptions = Loadable(lazy(() => import('../views/subscription/admin/plan-options/')));

//schools routing
const Schools = Loadable(lazy(() => import('../views/schools')));
const DeletedSchools = Loadable(lazy(() => import('../views/schools/DeletedSchool')));
const SchoolEnquiry = Loadable(lazy(() => import('../views/schools/enquiry')));
const ViewSchool = Loadable(lazy(() => import('../views/schools/view-school')));
const SchoolLeaves = Loadable(lazy(() => import('../views/schools/school-leaves')));
const DeliveryZones = Loadable(lazy(() => import('../views/schools/delivery-zones')));
const ViewDeliveryZone = Loadable(lazy(() => import('../views/schools/delivery-zones/view-delivery-zone')));

//coupon discount routing
const CouponDiscount = Loadable(lazy(() => import('../views/discounts')));

//transactions routing
const Transactions = Loadable(lazy(() => import('../views/transactions')));

//admin profile routing
const AdminProfile = Loadable(lazy(() => import('../views/my-profile/admin-profile/index')));

//change password routing
const ChangePassword = Loadable(lazy(() => import('../views/authentication/change-password')));

//student profile routing
const StudentProfile = Loadable(lazy(() => import('../views/students/student-profile')));

//courses routing
const OnlineCourses = Loadable(lazy(() => import('../views/online-classes/online-courses')));
const DeletedCourses = Loadable(lazy(() => import('../views/online-classes/online-courses/DeletedCourses')));
const AllCourses = Loadable(lazy(() => import('../views/online-classes/courses/all-courses')));
const ViewCourse = Loadable(lazy(() => import('../views/online-classes/courses/view-course')));
const AllModules = Loadable(lazy(() => import('../views/online-classes/courses/modules')));
const Batches = Loadable(lazy(() => import('../views/online-classes/batches/all-batch-details')));
const Assignments = Loadable(lazy(() => import('../views/online-classes/assignments/assignment-details')));
const BatcheTeachers = Loadable(lazy(() => import('../views/online-classes/batches/teachers')));
const BatcheStudents = Loadable(lazy(() => import('../views/online-classes/batches/students')));

//FAQs
const AllFAQs = Loadable(lazy(() => import('../views/faqs/all-faqs')));
const AllQAs = Loadable(lazy(() => import('../views/faqs/qa')));

//meeting routing
const Meetings = Loadable(lazy(() => import('../views/online-classes/meetings')));

//grades routing
const GradeTable = Loadable(lazy(() => import('../views/online-classes/grades/grade-table')));

//assignments
const ViewAssignment = Loadable(lazy(() => import('../views/online-classes/assignments/view-assignment')));

//report-cart routings
const AllReportCard = Loadable(lazy(() => import('../views/online-classes/report-cards')));
const ReportCard = Loadable(lazy(() => import('../views/online-classes/report-cards/view-report')));

//course feedback routing
const CourseFeedback = Loadable(lazy(() => import('../views/online-classes/courses-feedbacks')));
const ViewFeedback = Loadable(lazy(() => import('../views/online-classes/courses-feedbacks/view-feedback')));
//Admin cart roiting
const AllCarts = Loadable(lazy(() => import('../views/admin/cart')));
const SchoolAdminCart = Loadable(lazy(() => import('../views/admin/cart/SchoolAdminCart')));
const ViewCart = Loadable(lazy(() => import('../views/admin/cart/view-cart')));
const AllWishlists = Loadable(lazy(() => import('../views/admin/wishlist')));
const ViewWishlist = Loadable(lazy(() => import('../views/admin/wishlist/view-wishlist')));

//Admin user management
const AdminUsers = Loadable(lazy(() => import('../views/user-management/admins')));
const DeletedAdminUsers = Loadable(lazy(() => import('../views/user-management/admins/DeletedUsers')));
const ProfileUser = Loadable(lazy(() => import('../views/user-management/profile/ProfileList')));
const AllProfile = Loadable(lazy(() => import('../views/user-management/profile/AllProfile')));
const ViewAddress = Loadable(lazy(() => import('../views/user-management/profile/ViewAddress')));

//School Stock management
const StockSchool = Loadable(lazy(() => import('../views/stock-management/AllSchool')));
const ViewStock = Loadable(lazy(() => import('../views/stock-management/ViewStock')));
const AddStock = Loadable(lazy(() => import('../views/stock-management/AllSelectBooks')));
const LibraryStock = Loadable(lazy(() => import('../views/stock-management/LibraryStock')));
const PlaceDeliveryOrder = Loadable(lazy(() => import('../views/orders/place-delivery-order')));
const OnlineSubscription = Loadable(lazy(() => import('../views/subscription/admin/all-subscription/onlineSubscription')));

//My Address
const MyAddress = Loadable(lazy(() => import('../views/my-address')));

//Focus Skill
const FocusSkill = Loadable(lazy(() => import('../views/online-classes/courses/focus-skill/index')));
const DeletedFocusSkill = Loadable(lazy(() => import('../views/online-classes/courses/focus-skill/DeletedFocusSkills')));
const Subscriptions = Loadable(lazy(() => import('../views/subscriptions/index')));
const ModuleTransactions = Loadable(lazy(() => import('../views/transactions/moduleTransactions/index')));
const FreeLessons = Loadable(lazy(() => import('../views/free-lessons/index')));
const ViewHistory = Loadable(lazy(() => import('../views/subscriptions/historyView')));

const AllContactUs = Loadable(lazy(() => import('../views/contact')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();
    return (
        <Route
            path={[
                '/dashboard',
                '/admin-profile',
                '/student-profile',
                '/admin/books/all-books',
                '/admin/books/all-deleted-books',
                '/schooladmin/books/all-books',
                '/admin/books/books-language-list',
                '/admin/books/add-book',
                '/admin/books/update-book/:id',
                '/admin/books/category',
                '/admin/books/add-books-category',
                '/change-password',
                '/admin/orders/all-orders',
                '/admin/orders/place-orders',
                '/admin/orders/delivery-plan/:id',
                '/admin/order/:id',
                '/admin/orders/order-books',
                '/admin/orders/pending-books',
                '/admin/orders/bulk-place-orders',
                '/admin/orders/bulk-orders-update',
                '/admin/orders/bulk-book-return',
                '/admin/orders/bulk-batches',
                '/admin/orders/bulk-details/:id',
                '/admin/books/parents-category',
                '/admin/carts',
                '/admin/cart/:id/:role?',
                '/schooladmin/carts',
                '/admin/wishlist',
                '/admin/wishlist/:id',
                '/admin/subscription-library',
                '/admin/book-trials',
                '/admin/deleted-book-trials',
                '/admin/security-deposits',
                '/admin/subscription-class',
                '/admin/subscription/:id',
                '/admin/schools',
                '/admin/deletedschools',
                '/admin/school-enquiry',
                '/admin/school/:id',
                '/schooladmin/school',
                '/admin/module-subscriptions/:id',
                '/admin/school-leaves',
                '/admin/coupon-discount',
                '/admin/gift-vouchers',
                '/admin/referrals',
                '/admin/plans',
                '/admin/deleted-plans',
                '/admin/plans/:id',
                '/admin/transactions',
                '/admin/all-courses',
                '/admin/deleted-courses',
                '/admin/courses/:id',
                '/admin/course/:id/module',
                '/admin/course/module/:id',
                '/admin/modules/:id',
                '/admin/module/:id/batch',
                '/admin/course/batch/meeting/:id/assignment',
                '/admin/assignment/:id',
                '/admin/assignment/:id/submission',
                '/admin/submission/:id',
                '/admin/faqs',
                '/admin/contact',
                '/admin/faqs/:id',
                '/admin/faq/:id/qa',
                '/admin/all-meetings',
                '/admin/all-assignments',
                '/admin/assignments-details',
                '/admin/all-grades',
                '/admin/batch/:id/reports',
                '/admin/report/:id/profile/:profileId',
                '/admin/course-feedback',
                '/admin/course/:id/feedback',
                '/admin/delivery-zones',
                '/admin/deleted-delivery-zones',
                '/admin/delivery-zone/:id',
                '/admin/users',
                '/admin/deleted-users',
                '/admin/users/profile/:id',
                '/admin/users/all-profile',
                '/admin/school-stock',
                '/admin/view-stock/:id',
                '/admin/add-stock/:id',
                '/admin/book-collection/:id/:role?',
                '/admin/all-address/:userId/:profileId',
                '/admin/library-stock',
                '/admin/my-address',
                '/admin/batch/:id/teachers',
                '/admin/batch/:id/students',
                '/admin/batch/:id/meetings/:mId',
                '/admin/orders/placeDeliveryOrder/:id/profiles/:pid',
                '/admin/focusSkill',
                '/admin/deletedfocusSkill',
                '/admin/subscriptions/all-subscriptions',
                '/admin/module-transactions',
                '/admin/subscriptions/free-lessons',
                '/admin/view-history',
                '/admin/library/dashboard',
                '/admin/users/dashboard',
                '/schooladmin/books/all-books'
                // '/my-cart'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard" component={DashboardDefault} />
                        <Route path="/admin/users/dashboard" component={UserDashboard} />
                        <Route path="/admin/library/dashboard" component={LibraryDashboard} />

                        <Route path="/admin/books/all-books" component={Books} />
                        <Route path="/admin/books/all-deleted-books" component={AllDeletedBooks} />
                        <Route path="/schooladmin/books/all-books" component={SchoolAdminBooks} />
                        <Route path="/admin/books/books-language-list" component={BooksLanguages} />
                        <Route path="/admin/books/add-book" component={AddBook} />
                        <Route path="/admin/books/update-book/:id" component={AddBook} />
                        <Route path="/admin/books/category" component={BookCategories} />
                        <Route path="/admin/books/add-books-category" component={AddBookCategory} />
                        <Route path="/admin-profile" component={AdminProfile} />
                        <Route path="/student-profile" component={StudentProfile} />
                        <Route path="/change-password" component={ChangePassword} />
                        <Route path="/admin/orders/all-orders" component={AllOrders} />
                        <Route path="/admin/orders/place-orders" component={PlaceOrders} />
                        <Route path="/admin/orders/delivery-plan/:id" component={DeliveryPlan} />
                        <Route path="/admin/order/:id" component={OrderDetails} />
                        <Route path="/admin/orders/order-books" component={OrderBooks} />
                        <Route path="/admin/orders/pending-books" component={PendingBooks} />
                        <Route path="/admin/orders/bulk-place-orders" component={BulkOrdersPlace} />
                        <Route path="/admin/orders/bulk-orders-update" component={OrderBulkUpdate} />
                        <Route path="/admin/orders/bulk-book-return" component={BookBulkUpdate} />
                        <Route path="/admin/orders/bulk-batches" component={BulkBatches} />
                        <Route path="/admin/orders/bulk-details/:id" component={ViewBulkDetail} />
                        <Route path="/admin/books/parents-category" component={ViewParentCategories} />
                        <Route path="/admin/subscription-library" component={AllSubscription} />
                        <Route path="/admin/book-trials" component={BookTrials} />
                        <Route path="/admin/deleted-book-trials" component={DeletedBookTrials} />
                        <Route path="/admin/security-deposits" component={SecurityDeposits} />
                        <Route path="/admin/subscription-class" component={OnlineSubscription} />
                        <Route path="/admin/subscription/:id" component={ViewSubscription} />
                        <Route path="/admin/schools" component={Schools} />
                        <Route path="/admin/deletedschools" component={DeletedSchools} />
                        <Route path="/admin/school-enquiry" component={SchoolEnquiry} />
                        <Route exact path="/admin/school/:id" component={ViewSchool} />
                        <Route exact path="/schooladmin/school" component={ViewSchool} />
                        <Route path="/admin/module-subscriptions/:id" component={ViewSubscriptions} />
                        <Route path="/admin/school-leaves" component={SchoolLeaves} />
                        <Route path="/admin/coupon-discount" component={CouponDiscount} />
                        <Route path="/admin/gift-vouchers" component={GiftVouchers} />
                        <Route path="/admin/referrals" component={Referrals} />
                        <Route path="/admin/transactions" component={Transactions} />
                        <Route exact path="/admin/plans" component={AllPlans} />
                        <Route exact path="/admin/deleted-plans" component={AllDeletedPlans} />
                        <Route path="/admin/plans/:id" component={PlanOptions} />
                        <Route path="/admin/all-courses" component={OnlineCourses} />
                        <Route path="/admin/deleted-courses" component={DeletedCourses} />
                        <Route path="/admin/courses/:id" component={ViewCourse} />
                        <Route path="/admin/course/:id/module" component={AllCourses} />
                        <Route path="/admin/course/module/:id" component={AllModules} />
                        <Route path="/admin/modules/:id" component={ViewModules} />
                        <Route path="/admin/module/:id/batch" component={Batches} />
                        <Route path="/admin/course/batch/meeting/:id/assignment" component={Assignments} />
                        <Route path="/admin/assignment/:id" component={ViewAssignment} />
                        <Route path="/admin/assignment/:id/submission" component={Assignment_Submissions} />
                        <Route exact path="/admin/submission/:id" component={DetailedSubmissionView} />
                        <Route path="/admin/batch/:id/teachers" component={BatcheTeachers} />
                        <Route path="/admin/batch/:id/students" component={BatcheStudents} />
                        <Route path="/admin/faqs" component={AllFAQs} />
                        <Route path="/admin/contact" component={AllContactUs} />
                        <Route path="/admin/faq/:id/qa" component={AllQAs} />
                        <Route path="/admin/all-meetings" component={Meetings} />
                        <Route path="/admin/all-assignments" component={Assignments} />
                        <Route path="/admin/all-grades" component={GradeTable} />
                        <Route path="/admin/batch/:id/reports" component={AllReportCard} />
                        <Route path="/admin/report/:id/profile/:profileId" component={ReportCard} />
                        <Route path="/admin/course-feedback" component={CourseFeedback} />
                        <Route path="/admin/carts" component={AllCarts} />
                        <Route path="/schooladmin/carts" component={SchoolAdminCart} />
                        <Route path="/admin/cart/:id/:role?" component={ViewCart} />
                        <Route exact path="/admin/wishlist" component={AllWishlists} />
                        <Route path="/admin/wishlist/:id" component={ViewWishlist} />
                        <Route exact path="/admin/delivery-zones" component={DeliveryZones} />
                        <Route path="/admin/delivery-zone/:id" component={ViewDeliveryZone} />
                        <Route exact path="/admin/users" component={AdminUsers} />
                        <Route exact path="/admin/deleted-users" component={DeletedAdminUsers} />
                        <Route exact path="/admin/users/profile/:id" component={ProfileUser} />
                        <Route exact path="/admin/users/profile/:id/:role" component={ProfileUser} />
                        <Route path="/admin/users/all-profile/:role" component={AllProfile} />
                        <Route path="/admin/school-stock" component={StockSchool} />
                        <Route path="/admin/view-stock/:id" component={ViewStock} />
                        <Route path="/admin/add-stock/:id" component={AddStock} />
                        <Route path="/admin/book-collection/:id/:role?" component={BookCollection} />
                        <Route path="/admin/all-address/:userId/:profileId" component={ViewAddress} />
                        <Route path="/admin/library-stock" component={LibraryStock} />
                        <Route path="/admin/my-address" component={MyAddress} />
                        <Route path="/admin/course/:id/feedback" component={ViewFeedback} />
                        <Route path="/admin/batch/:id/meetings/:mId" component={Meetings} />
                        <Route path="/admin/orders/placeDeliveryOrder/:id/profiles/:pid" component={PlaceDeliveryOrder} />
                        <Route path="/admin/focusSkill" component={FocusSkill} />
                        <Route path="/admin/deletedfocusSkill" component={DeletedFocusSkill} />
                        <Route path="/admin/subscriptions/all-subscriptions" component={Subscriptions} />
                        <Route path="/admin/module-transactions" component={ModuleTransactions} />
                        <Route path="/admin/subscriptions/free-lessons" component={FreeLessons} />
                        <Route path="/admin/view-history" component={ViewHistory} />
                        {/* <Route path="/my-cart" component={Cart} />
                        <Route path="/my-wishlist" component={Wishlist} />
                        <Route path="/my-wishlistcart" component={WishlistCart} /> */}
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
