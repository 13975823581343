import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import * as yup from 'yup';
import CloseIcon from '@material-ui/icons/Close';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, Link, Divider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import useStyles from '../../../DialogStyles';

import { getCart, addToCart, updateCart } from '../../../../store/actions/CartActions';
import { useHistory } from 'react-router-dom';

const validationSchema = yup.object({
    quantity: yup.string('Enter stock quantity').required('Stock quantity is required')
});

export default function AddEditCollection(props) {
    const { open, close, isEditData, isCartId, isBooksId } = props;
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const initialValues = {
        quantity: isEditData?.totalQuantity || ''
    };
    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues
    });
    const { handleSubmit } = methods;
    const onSubmit = (data) => {
        if (isEditData) {
            dispatch(
                updateCart(parseInt(data?.quantity), isEditData?.bookId, isCartId, () => {
                    close();
                    dispatch(getCart(isCartId));
                })
            );
        } else {
            dispatch(
                addToCart(parseInt(data?.quantity), isBooksId, isCartId, () => {
                    close();
                    history.push(`/admin/book-collection/${isCartId}`);
                })
            );
        }
    };

    return (
        <div>
            <Dialog open={open} classes={{ paper: classes.dialog }}>
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.dialogTitleChild}>
                        <Typography style={{ fontSize: '20px' }}>{!isEditData ? 'Add stock quantity' : 'Update stock quantity'}</Typography>
                        <Link to="#" onClick={close}>
                            <CloseIcon className={classes.closeIcon} />
                        </Link>
                    </div>
                </DialogTitle>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <label className={classes.label}>
                                    Increment or decrement stock quantity<span className={classes.mandatory}>*</span>
                                </label>
                                <RHFTextField name="quantity" placeholder="Add stock quantity" className={classes.textField} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider style={{ marginTop: 15 }} />
                    <DialogActions className={classes.dialogAction}>
                        <Button variant="outlined" color="primary" className={classes.buttonCancel} onClick={close}>
                            Close
                        </Button>
                        <Button variant="contained" color="primary" type="submit" className={classes.buttonSubmit}>
                            {!isEditData ? 'Add' : 'Update'}
                        </Button>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </div>
    );
}
