// import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/styles';
// import { Button, Grid, Paper } from '@material-ui/core';
// import SubjectIcon from '@material-ui/icons/Subject';
// import MainCard from '../../../ui-component/cards/MainCard';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import { FormProvider, RHFTextField } from '../../../components/hook-form';
// import {
//     getPlaceOrder,
//     postOrderBooks,
//     searchBooksForOrder,
//     postWishlistBooks,
//     updatePlaceOrderBook
// } from '../../../services/placeOrderServices';
// import MultiselectChip from './components/MultiselectChip';
// import { useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setMessage } from '../../../store/actions/MessageActions';
// import { useHistory } from 'react-router-dom';

// const useStyles = makeStyles((theme) => ({
//     titleContainer: {
//         background: theme.palette.primary.main,
//         padding: 10,
//         borderRadius: 8,
//         marginBottom: 20
//     },
//     pageTitle: {
//         fontSize: 16,
//         color: theme.palette.background.paper,
//         fontWeight: 'lighter'
//     },
//     titIeIcon: {
//         verticalAlign: 'sub',
//         background: 'tranparent',
//         color: '#fff'
//     },
//     rhf: {
//         width: '700px'
//     },
//     mainGrid: {
//         display: 'flex',
//         justifyContent: 'right',
//         alignItems: 'center',
//         marginLeft: '20px'
//     },
//     subGrid: {
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         marginTop: '55px'
//     },
//     subChildGrid: {
//         display: 'flex',
//         justifyContent: 'right',
//         alignItems: 'center'
//     },
//     label: {
//         color: '#555454',
//         fontWeight: 'lighter',
//         marginRight: '20px'
//     },
//     input: {
//         '&::placeholder': {
//             textOverflow: 'ellipsis !important',
//             color: 'black'
//         }
//     }
// }));

// export default function PlaceDeliveryOrder() {
//     const dispatch = useDispatch();
//     const history = useHistory();
//     const { id, pid } = useParams();
//     const classes = useStyles();
//     // useUserRole({ roleList: ['ADMIN','SUPER_ADMIN'] });
//     const [placeOrderData, setPlaceOrderData] = useState([]);

//     console.log(placeOrderData);

//     const initialValues = {
//         deliveryDate: placeOrderData?.deliveryDate || ''
//     };

//     const validationSchema = yup.object({
//         title: yup.string('').required(''),
//         deliveryDate: yup.string('Enter delivery date here')
//     });
//     const methods = useForm({
//         mode: 'onChange',
//         resolver: yupResolver(validationSchema),
//         defaultValues: initialValues
//     });
//     const { reset, handleSubmit, setValue, watch } = methods;

//     const books_Allowed = watch('booksAllowed');
//     const dilevery_Date = watch('deliveryDate');

//     const onSubmit = (data) => {
//         const payload = {
//             deliveryDate: data?.deliveryDate
//         };
//     };

//     const GetPlaceOrder = async (id) => {
//         await getPlaceOrder(id)
//             .then((res) => {
//                 setPlaceOrderData(res?.data);
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//     };

//     const GetSearchedBooksToOrder = async (searchKeyWord) => {
//         await searchBooksForOrder(searchKeyWord).then((res) => {
//             setBooksToOrder(res?.data);
//         });
//     };
//     const GetSearchedBooksToWishlist = async (searchKeyWord) => {
//         await searchBooksForOrder(searchKeyWord).then((res) => {
//             setBooksToWishlist(res?.data);
//         });
//     };

//     const PostOrderBooks = async (id, payload) => {
//         await postOrderBooks(id, payload)
//             .then((data) => {})
//             .then((error) => {
//                 console.log(error);
//             });
//     };
//     const PostWishListBooks = async (id, payload) => {
//         await postWishlistBooks(pid, payload)
//             .then((data) => {})
//             .then((error) => {
//                 console.log(error);
//             });
//     };

//     const updateOrderBook = async (id, payload) => {
//         await updatePlaceOrderBook(id, payload)
//             .then((data) => {})
//             .then((error) => {
//                 console.log(error);
//             });
//     };

//     useEffect(() => {
//         GetPlaceOrder(id);
//     }, []);

//     useEffect(() => {
//         console.log('data', placeOrderData);
//         if (true) {
//             setValue('customerName', '');
//             setValue('bookAllowed', 'placeOrder?.booksAllowed');
//             setValue('deliveryDate', placeOrderData?.deliveryDate);
//         }
//     }, [setValue, placeOrderData]);

//     const [booksToOrder, setBooksToOrder] = useState([]);
//     const [booksToWishlist, setBooksToWishlist] = useState([]);

//     const handleOrderBookSearch = async (title) => {
//         if (title !== '') {
//             GetSearchedBooksToOrder(title);
//         } else {
//             setBooksToOrder([]);
//         }
//     };
//     const handleWishlistBookSearch = async (title) => {
//         if (title !== '') {
//             GetSearchedBooksToWishlist(title);
//         } else {
//             setBooksToWishlist([]);
//         }
//     };

//     const [selectedBooksForOrder, setSelectedBooksForOrder] = useState([]);
//     const [selectedBooksForWishlist, setSelectedBooksForWishlist] = useState([]);

//     const getSelectedBooksForOrder = (items) => {
//         setSelectedBooksForOrder(items);
//     };
//     const getSelectedBooksForWishlist = (items) => {
//         setSelectedBooksForWishlist(items);
//     };

//     const submitForm = async () => {
//         let idArray = [];
//         let orderSuccess = false;
//         let wishlistSuccess = false;
//         let payloadOrders = { bookIds: [] };
//         let payloadWishlists = { bookIds: [] };
//         let payloadUpdateOrder = { deliveryDate: dilevery_Date, booksAllowed: books_Allowed };

//         selectedBooksForOrder.forEach((item) => {
//             idArray.push(item.id);
//         });

//         payloadOrders.bookIds = idArray;

//         selectedBooksForWishlist.forEach((item) => {
//             payloadWishlists.bookIds = [...payloadWishlists.bookIds, item?.id];
//         });

//         if (payloadOrders.bookIds.length !== 0) {
//             await PostOrderBooks(id, payloadOrders)
//                 .then((data) => {
//                     orderSuccess = true;
//                     dispatch(setMessage(data?.message ? data?.message : 'Books Ordered Successfully!'));
//                 })
//                 .catch((error) => {
//                     dispatch(
//                         setMessage(error?.data?.message ? error?.data?.message : 'Something went wrong while placing order for books')
//                     );
//                 });
//         }

//         if (payloadWishlists.bookIds.length !== 0) {
//             await PostWishListBooks(id, payloadWishlists)
//                 .then((data) => {
//                     wishlistSuccess = true;
//                     dispatch(setMessage(data?.message ? data?.message : 'Books Wishlisted Successfully'));
//                 })
//                 .catch((error) => {
//                     dispatch(setMessage(error?.data?.message ? error?.data?.message : 'Something went wrong while wishlisting books'));
//                 });
//         }

//         if (dilevery_Date !== placeOrderData?.deliveryDate || books_Allowed !== placeOrderData?.booksAllowed) {
//             await updateOrderBook(id, payloadUpdateOrder)
//                 .then((data) => {
//                     dispatch(setMessage(data?.message ? data?.message : 'Update Successfully'));
//                 })
//                 .catch((error) => {
//                     dispatch(setMessage(error?.data?.message ? error?.data?.message : 'Something went wrong while Update'));
//                 });
//         }

//         if (orderSuccess && wishlistSuccess) history.push('/admin/orders/place-orders');
//     };

//     return (
//         <>
//             <MainCard style={{ margin: '20px' }}>
//                 <div className={classes.titleContainer}>
//                     <SubjectIcon className={classes.titIeIcon} />
//                     <span className={classes.pageTitle}>&nbsp; Place Orders </span>
//                 </div>

//                 <Paper elevation={5} style={{ marginTop: '50px' }}>
//                     <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
//                         <Grid container spacing={6} className={classes.mainGrid}>
//                             <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                                 <Grid container xs={2} className={classes.subChildGrid}>
//                                     <label className={classes.label}> Customer Name </label>
//                                 </Grid>

//                                 <Grid container xs={10}>
//                                     <Grid container xs={10}>
//                                         <RHFTextField
//                                             name="customerName"
//                                             className={classes.rhf}
//                                             value={placeOrderData?.fullName ? placeOrderData?.fullName : 'N/A'}
//                                             disabled
//                                         />
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                             <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                                 {/* <label>
//                                     Book Allowed<span>*</span>
//                                 </label> */}
//                                 <Grid container xs={2} className={classes.subChildGrid}>
//                                     <label className={classes.label}> Book Allowed </label>
//                                 </Grid>
//                                 <Grid container xs={10}>
//                                     <Grid container xs={10}>
//                                         <RHFTextField
//                                             name="booksAllowed"
//                                             className={classes.rhf}
//                                             placeholder={placeOrderData?.booksAllowed}
//                                             InputProps={{
//                                                 classes: { input: classes.input }
//                                             }}
//                                         ></RHFTextField>
//                                     </Grid>
//                                 </Grid>
//                             </Grid>

//                             <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                                 {/* <label>
//                                     Book Allowed<span>*</span>
//                                 </label> */}
//                                 <Grid container xs={2} className={classes.subChildGrid}>
//                                     <label className={classes.label}> Delivery Date </label>
//                                 </Grid>
//                                 <Grid container xs={10}>
//                                     <Grid container xs={10}>
//                                         <RHFTextField
//                                             name="deliveryDate"
//                                             type="date"
//                                             className={classes.rhf}
//                                             InputProps={{
//                                                 classes: { input: classes.input }
//                                             }}
//                                         />
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                             <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                                 <Grid container xs={2} className={classes.subChildGrid}>
//                                     <label className={classes.label}> Comments </label>
//                                 </Grid>
//                                 <Grid container xs={10}>
//                                     <RHFTextField
//                                         name="comment"
//                                         className={classes.rhf}
//                                         placeholder={placeOrderData?.adminComment ? placeOrderData?.adminComment : 'N/A'}
//                                         InputProps={{
//                                             classes: { input: classes.input }
//                                         }}
//                                     />
//                                 </Grid>
//                             </Grid>
//                             <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                                 <Grid container xs={2} className={classes.subChildGrid}>
//                                     <label className={classes.label}> Wishlist </label>
//                                 </Grid>
//                                 <Grid container xs={10} className={classes.rhf} alignItems="center">
//                                     {placeOrderData?.wishListBooks?.length === 0 ? (
//                                         <p> No Book Available in Wishlist </p>
//                                     ) : (
//                                         placeOrderData?.wishListBooks?.map((item) => (
//                                             <Grid item xs={10}>
//                                                 <li>
//                                                     {item?.title}({item?.isbn})
//                                                 </li>
//                                             </Grid>
//                                         ))
//                                     )}
//                                 </Grid>
//                             </Grid>
//                             <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                                 <Grid container xs={2} className={classes.subChildGrid}>
//                                     <label className={classes.label}> Pending Cart </label>
//                                 </Grid>
//                                 <Grid container xs={10} className={classes.rhf}>
//                                     {placeOrderData?.cartBooks ? (
//                                         <p> No Pending Book Available in Cart </p>
//                                     ) : (
//                                         placeOrderData?.cartBooks?.map((item, index) => (
//                                             <Grid item xs={10} key={index}>
//                                                 <li> {item?.title} </li>
//                                             </Grid>
//                                         ))
//                                     )}
//                                 </Grid>
//                                 {/* <RHFTextField name="customerName" required /> */}
//                             </Grid>

//                             <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                                 <Grid container xs={2} className={classes.subChildGrid}>
//                                     <label className={classes.label}>Previous Delivered Book</label>
//                                 </Grid>
//                                 <Grid container xs={10} className={classes.rhf}>
//                                     {placeOrderData?.previousOrderedBooks?.length === 0 ? (
//                                         <p> No Previous Delivered Book Available </p>
//                                     ) : (
//                                         placeOrderData?.previousOrderedBooks?.map((item) => (
//                                             <Grid container xs={10}>
//                                                 <li>
//                                                     {item?.title}({item?.isbn})
//                                                 </li>
//                                             </Grid>
//                                         ))
//                                     )}
//                                 </Grid>
//                             </Grid>
//                             <br />
//                             <br />
//                             <br />

//                             <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                                 <Grid container xs={2} className={classes.subChildGrid}>
//                                     <label className={classes.label}> Place Order Book </label>
//                                 </Grid>
//                                 <Grid container xs={10} className={classes.rhf}>
//                                     <Grid container xs={7.2} style={{ width: '100%' }}>
//                                         <MultiselectChip
//                                             values={booksToOrder}
//                                             handleSearch={handleOrderBookSearch}
//                                             getSelectedItems={getSelectedBooksForOrder}
//                                             placeholder={'Search Books To Order'}
//                                         ></MultiselectChip>
//                                     </Grid>
//                                 </Grid>
//                             </Grid>

//                             <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                                 <Grid container xs={2} className={classes.subChildGrid}>
//                                     <label className={classes.label}> Add Books To Wishlist </label>
//                                 </Grid>
//                                 <Grid container xs={10} className={classes.rhf}>
//                                     <Grid container xs={7.2} style={{ width: '800px' }}>
//                                         <MultiselectChip
//                                             values={booksToWishlist}
//                                             handleSearch={handleWishlistBookSearch}
//                                             getSelectedItems={getSelectedBooksForWishlist}
//                                             placeholder={'Search Books To Add To Wishlist'}
//                                         ></MultiselectChip>
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                         </Grid>
//                         <Grid container xs={12} sm={12} spacing={4} className={classes.subGrid}>
//                             <Grid container className={classes.subChildGrid} sx={{ marginLeft: 'auto', paddingBottom: '16px' }}>
//                                 <Button variant="contained" type="submit" onClick={submitForm}>
//                                     Submit
//                                 </Button>
//                             </Grid>
//                         </Grid>
//                     </FormProvider>
//                 </Paper>
//             </MainCard>
//         </>
//     );
// }

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid, Paper } from '@material-ui/core';
import SubjectIcon from '@material-ui/icons/Subject';
import MainCard from '../../../ui-component/cards/MainCard';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import {
    getPlaceOrder,
    postOrderBooks,
    searchBooksMiniForOrder,
    searchBooksForWishlist,
    postWishlistBooks,
    updatePlaceOrderBook
} from '../../../services/placeOrderServices';
import MultiselectChip from './components/MultiselectChip';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../store/actions/MessageActions';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        background: theme.palette.primary.main,
        padding: 10,
        borderRadius: 8,
        marginBottom: 20
    },
    pageTitle: {
        fontSize: 16,
        color: theme.palette.background.paper,
        fontWeight: 'lighter'
    },
    titIeIcon: {
        verticalAlign: 'sub',
        background: 'tranparent',
        color: '#fff'
    },
    rhf: {
        width: '700px'
    },
    mainGrid: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        marginLeft: '20px'
    },
    subGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '55px'
    },
    subChildGrid: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center'
    },
    label: {
        color: '#555454',
        fontWeight: 'lighter',
        marginRight: '20px'
    },
    input: {
        '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: 'black'
        }
    }
}));

export default function PlaceDeliveryOrder() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { id, pid } = useParams();
    const classes = useStyles();
    const [placeOrderData, setPlaceOrderData] = useState([]);

    console.log("====id",id);
    console.log("====pid",pid);

    const initialValues = {
        deliveryDate: placeOrderData?.deliveryDate || ''
    };

    const validationSchema = yup.object({
        deliveryDate: yup.string('Enter delivery date here')
    });
    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues
    });
    const { reset, handleSubmit, setValue, watch } = methods;

    const books_Allowed = watch('booksAllowed');
    const weeks_Allowed = watch('weeksAllowed');
    const dilevery_Date = watch('deliveryDate');
    const adminComment = watch('comment');

    const onSubmit = async (data) => {
        let payloadUpdateOrder = { deliveryDate: dilevery_Date, booksAllowed: books_Allowed, weeksAllowed: weeks_Allowed, adminComment: adminComment };

        if (dilevery_Date !== placeOrderData?.deliveryDate || books_Allowed !== placeOrderData?.booksAllowed 
            || weeks_Allowed !== placeOrderData?.weeksAllowed) {
            await updateOrderBook(id, payloadUpdateOrder)
                .then((data) => {
                    dispatch(setMessage(data?.message ? data?.message : 'Update Successfully'));
                })
                .catch((error) => {
                    dispatch(setMessage(error?.data?.message ? error?.data?.message : 'Something went wrong while Update'));
                });
        }
    };

    const GetPlaceOrder = async (id) => {
        await getPlaceOrder(id)
            .then((res) => {
                setPlaceOrderData(res?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const GetSearchedBooksToOrder = async (searchKeyWord) => {
        await searchBooksMiniForOrder(searchKeyWord).then((res) => {
            if (res?.data.length === 0 ) {
                alert(searchKeyWord +' not available');
            }
            setBooksToOrder(res?.data);
        });
    };
    const GetSearchedBooksToWishlist = async (searchKeyWord) => {
        await searchBooksForWishlist(searchKeyWord).then((res) => {
            if (res?.data.length === 0 ) {
                alert(searchKeyWord +' not found');
            }
            setBooksToWishlist(res?.data);
        });
    };

    const PostOrderBooks = async (id, payload) => {
        await postOrderBooks(id, payload)
            .then((data) => {})
            .then((error) => {
                console.log(error);
            });
    };
    const PostWishListBooks = async (id, payload) => {
        await postWishlistBooks(placeOrderData.profileId, payload)
            .then((data) => {})
            .then((error) => {
                console.log(error);
            });
    };

    const updateOrderBook = async (id, payload) => {
        await updatePlaceOrderBook(id, payload)
            .then((data) => {})
            .then((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        GetPlaceOrder(id);
    }, [id]);

    useEffect(() => {
        console.log('data', placeOrderData);
        if (true) {
            setValue('customerName', '');
            setValue('bookAllowed', 'placeOrder?.booksAllowed');
            setValue('weekAllowed', 'placeOrder?.weeksAllowed');
            setValue('deliveryDate', placeOrderData?.deliveryDate);
        }
    }, [setValue, placeOrderData]);

    const [booksToOrder, setBooksToOrder] = useState([]);
    const [booksToWishlist, setBooksToWishlist] = useState([]);

    let searchTimeout;

    const handleOrderBookSearch = async (title) => {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(async () => {
            if (title !== '') {
                await GetSearchedBooksToOrder(title);
            } else {
                setBooksToOrder([]);
            }
        }, 2500);
    };

    const handleWishlistBookSearch = async (title) => {
        clearTimeout(searchTimeout);

        searchTimeout = setTimeout(async () => {
            if (title !== '') {
                await GetSearchedBooksToWishlist(title);
            } else {
                setBooksToWishlist([]);
            }
        }, 2500);
    };

    const [selectedBooksForOrder, setSelectedBooksForOrder] = useState([]);
    const [selectedBooksForWishlist, setSelectedBooksForWishlist] = useState([]);

    const getSelectedBooksForOrder = (items) => {
        setSelectedBooksForOrder(items);
    };
    const getSelectedBooksForWishlist = (items) => {
        setSelectedBooksForWishlist(items);
    };

    const submitForm = async () => {
        let payloadUpdateOrder = { deliveryDate: dilevery_Date, booksAllowed: books_Allowed, weeksAllowed: weeks_Allowed, 
            adminComment: adminComment };

        if (dilevery_Date !== placeOrderData?.deliveryDate || books_Allowed !== placeOrderData?.booksAllowed || 
            weeks_Allowed !== placeOrderData?.weeksAllowed) {
            await updateOrderBook(id, payloadUpdateOrder)
                .then((data) => {
                    dispatch(setMessage(data?.message ? data?.message : 'Update Successfully'));
                })
                .catch((error) => {
                    dispatch(setMessage(error?.data?.message ? error?.data?.message : 'Something went wrong while Update'));
                });
        }
    };
    const placeOrder = async () => {
        let idArray = [];
        let orderSuccess = false;

        let payloadOrders = { bookIds: [] };

        selectedBooksForOrder.forEach((item) => {
            idArray.push(item.id);
        });

        payloadOrders.bookIds = idArray;

        if (payloadOrders.bookIds.length !== 0) {
            await PostOrderBooks(id, payloadOrders)
                .then((data) => {
                    orderSuccess = true;
                    dispatch(setMessage(data?.message ? data?.message : 'Books Ordered Successfully!'));
                })
                .catch((error) => {
                    dispatch(
                        setMessage(error?.data?.message ? error?.data?.message : 'Something went wrong while placing order for books')
                    );
                });
        }

    };
    const addWishlist = async () => {
        let wishlistSuccess = false;
        let payloadWishlists = { bookIds: [] };

        selectedBooksForWishlist.forEach((item) => {
        
            payloadWishlists.bookIds = [...payloadWishlists.bookIds, item?.id];
        });
        if (payloadWishlists.bookIds.length !== 0) {
            await PostWishListBooks(placeOrderData.id, payloadWishlists)
                .then((data) => {
                    wishlistSuccess = true;
                    dispatch(setMessage(data?.message ? data?.message : 'Books Wishlisted Successfully'));
                })
                .catch((error) => {
                    dispatch(setMessage(error?.data?.message ? error?.data?.message : 'Something went wrong while wishlisting books'));
                });
        }
    };

    return (
        <>
            <MainCard style={{ margin: '20px' }}>
                <div className={classes.titleContainer}>
                    <SubjectIcon className={classes.titIeIcon} />
                    <span className={classes.pageTitle}>&nbsp; Place Orders </span>
                </div>
                <Grid container direction="column" spacing={4}>
                    <Grid item container spacing={3}>
                        <Grid item sm={6}>
                            <Paper elevation={5} style={{ padding: '30px' }}>
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item container direction="column">
                                            <Grid item>
                                                <label> Customer Name </label>
                                            </Grid>

                                            <Grid item>
                                                <RHFTextField
                                                    name="customerName"
                                                    value={placeOrderData?.fullName ? placeOrderData?.fullName : 'N/A'}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column">
                                            <Grid item>
                                                <label> Book Allowed </label>
                                            </Grid>
                                            <Grid item>
                                                <RHFTextField
                                                    name="booksAllowed"
                                                    placeholder={placeOrderData?.booksAllowed}
                                                    InputProps={{
                                                        classes: { input: classes.input }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container direction="column">
                                            <Grid item>
                                                <label> Weeks Allowed </label>
                                            </Grid>
                                            <Grid item>
                                                <RHFTextField
                                                    name="weeksAllowed"
                                                    placeholder={placeOrderData?.weeksAllowed}
                                                    InputProps={{
                                                        classes: { input: classes.input }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item container direction="column">
                                            <Grid item>
                                                <label> Delivery Date </label>
                                            </Grid>
                                            <Grid item>
                                                <RHFTextField
                                                    name="deliveryDate"
                                                    type="date"
                                                    InputProps={{
                                                        classes: { input: classes.input }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column">
                                            <Grid item>
                                                <label> Comments </label>
                                            </Grid>
                                            <Grid item>
                                                <RHFTextField
                                                    name="comment"
                                                    placeholder={placeOrderData?.adminComment ? placeOrderData?.adminComment : 'N/A'}
                                                    multiline
                                                    rows={4}
                                                    InputProps={{
                                                        classes: { input: classes.input }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container justifyContent="end">
                                            <Button variant="contained" type="submit" >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </FormProvider>
                            </Paper>
                        </Grid>
                        <Grid item sm={6}>
                            <Paper elevation={5} style={{ padding: '30px' }}>
                                <Grid container spacing={2}>
                                    <Grid item container direction="column" spacing={1}>
                                        <Grid item>
                                            <label> Place Order Book </label>
                                        </Grid>
                                        <Grid item container spacing={3} alignItems="center">
                                            <Grid item xs={8}>
                                                <MultiselectChip
                                                    values={booksToOrder}
                                                    handleSearch={handleOrderBookSearch}
                                                    getSelectedItems={getSelectedBooksForOrder}
                                                    placeholder={'Search Books To Order'}
                                                ></MultiselectChip>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Button variant="contained" type="submit" onClick={placeOrder}>
                                                    Place Order
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction="column" spacing={1}>
                                        <Grid item>
                                            <label> Add Books To Wishlist </label>
                                        </Grid>
                                        <Grid item container spacing={3} alignItems="center">
                                            <Grid item xs={8}>
                                                <MultiselectChip
                                                    values={booksToWishlist}
                                                    handleSearch={handleWishlistBookSearch}
                                                    getSelectedItems={getSelectedBooksForWishlist}
                                                    placeholder={'Search Books To Add To Wishlist'}
                                                ></MultiselectChip>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button variant="contained" type="submit" onClick={addWishlist}>
                                                    Add To Wishlist
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Paper elevation={5} style={{ padding: '30px' }}>
                            <Grid container spacing={3}>
                                <Grid item container>
                                    <Grid item xs={4}>
                                        <label className={classes.label}> Wishlist :</label>
                                    </Grid>
                                    <Grid item xs={8} alignItems="center">
                                        {placeOrderData?.wishListBooks?.length === 0 ? (
                                            <p> No Book Available in Wishlist </p>
                                        ) : (
                                            placeOrderData?.wishListBooks?.map((item) => (
                                                <Grid item xs={10}>
                                                    <li>
                                                        {item?.title}({item?.isbn})
                                                    </li>
                                                </Grid>
                                            ))
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    <Grid item xs={4}>
                                        <label className={classes.label}> Pending Cart :</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {placeOrderData?.cartBooks?.length === 0 ? (
                                            <p> No Pending Book Available in Cart </p>
                                        ) : (
                                            placeOrderData?.cartBooks?.map((item) => (
                                                <Grid item xs={10}>
                                                    <li> {item?.title}({item?.isbn}) </li>
                                                </Grid>
                                            ))
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid item container>
                                    <Grid item xs={4}>
                                        <label className={classes.label}>Previous Delivered Book :</label>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {placeOrderData?.previousOrderedBooks?.length === 0 ? (
                                            <p> No Previous Delivered Book Available </p>
                                        ) : (
                                            placeOrderData?.previousOrderedBooks?.map((item) => (
                                                <Grid container xs={10}>
                                                    <li>
                                                        {item?.title}({item?.isbn})
                                                    </li>
                                                </Grid>
                                            ))
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
}
