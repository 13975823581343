import { http } from '../utils/http';

export const profileUpload = (payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(
            process.env.REACT_APP_ROOT_URL,
            'POST',
            'users-service/users/profiles/pictures',
            payload,

            true,

            { 'content-type': 'multipart/form-data', accept: '*/*' },

            {}
        )
            .then((data) => {
                resolve(data);
                dispatch('Pic Upload Success');
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const profileDelete = (payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(
            process.env.REACT_APP_ROOT_URL,
            'DELETE',
            'users-service/users/profiles/pictures',
            payload,

            true,

            { 'content-type': 'multipart/form-data', accept: '*/*' },

            {}
        )
            .then((data) => {
                resolve(data);
                dispatch(setMessage('Deleted Successfully'));
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const csvUpload = (payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(
            process.env.REACT_APP_ROOT_URL,
            'POST',
            'users-service/users/bulkUsers/csv',
            payload,

            true,

            { 'content-type': 'multipart/form-data', accept: '*/*' },

            {}
        )
            .then((data) => {
                resolve(data);
                dispatch(setMessage('CSV Uploaded Successfully'));
            })
            .catch((err) => {
                reject(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Please select a valid file!'));
            });
    });
};

export const bookUpload = (payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(
            process.env.REACT_APP_ROOT_URL_BOOK,
            'POST',
            'books-service/books/bulkBooks/csv',
            payload,

            true,

            { 'content-type': 'multipart/form-data', accept: '*/*' },

            {}
        )
            .then((data) => {
                resolve(data);
                dispatch(setMessage('CSV Uploaded Successfully'));
            })
            .catch((err) => {
                reject(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Please select a valid file!'));
            });
    });
};

export const pdfUpload = (id, payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(
            process.env.REACT_APP_ROOT_URL_BATCHES,
            'POST',
            `batches-service/assignments/${id}/document`,
            payload,
            true,

            { 'content-type': 'multipart/form-data', accept: '*/*' },

            {}
        )
            .then((data) => {
                resolve(data);
                dispatch(setMessage(data?.message ? data?.message : 'PDF Uploaded Successfully'));
            })
            .catch((err) => {
                reject(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Please Upload a file'));
            });
    });
};

export const feedbackUpload = (id, payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(
            process.env.REACT_APP_ROOT_URL_BATCHES,
            'POST',
            `batches-service/assignments/submissions/${id}/feedback`,
            payload,
            true,

            { 'content-type': 'multipart/form-data', accept: '*/*' },

            {}
        )
            .then((data) => {
                resolve(data);
                dispatch(setMessage(data?.message ? data?.message : 'Feedback Doc Uploaded Successfully'));
            })
            .catch((err) => {
                reject(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Plaese select a file!'));
            });
    });
};

//STUDENT Assignment Submission
export const submissionUpload = (sid, payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(
            process.env.REACT_APP_ROOT_URL_BATCHES,
            'POST',
            `batches-service/assignments/${sid}/submissions`,
            payload,
            true,

            { 'content-type': 'multipart/form-data', accept: '*/*' },

            {}
        )
            .then((data) => {
                resolve(data);
                dispatch(setMessage(data?.message ? data?.message : 'Homework Uploaded Successfully'));
            })
            .catch((err) => {
                reject(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Please select a valid file!'));
            });
    });
};

export const submissionDocUpload = (sid, payload, dispatch, setMessage) => {
    return new Promise((resolve, reject) => {
        http(
            process.env.REACT_APP_ROOT_URL_BATCHES,
            'POST',
            `batches-service/assignments/submissions/${sid}/document`,
            payload,
            true,

            { 'content-type': 'multipart/form-data', accept: '*/*' },

            {}
        )
            .then((data) => {
                resolve(data);
                dispatch(setMessage(data?.message ? data?.message : 'Uploaded Successfully'));
            })
            .catch((err) => {
                reject(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Please select a valid file!'));
            });
    });
};
