import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import AliceCarousel from 'react-alice-carousel';
import { Container, Grid, Typography, Box, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import { makeStyles, useTheme } from '@material-ui/styles';
import 'react-alice-carousel/lib/alice-carousel.css';
import Header from '../components/header';
import FooterNew from '../components/FooterNew';
import './carousel.css';
import { useHistory, useParams } from 'react-router-dom';
import { getByIdBooks, getBooksDetails } from '../../../store/actions/BookActions';
import { addWishlist } from '../../../store/actions/WishlistActions';
import { addToCart } from '../../../store/actions/CartActions';
import { useDispatch, useSelector } from 'react-redux';
import useUserRole from '../../../hooks/useUserRole';

const useStyles = makeStyles((theme) => ({
    root: {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        marginTop: 10
    },
    rating: {
        color: theme.palette.warning.dark
    },
    btnContainer: {
        padding: '10px 0',
        margin: '20px 0px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    img: {
        borderRadius: 8,
        width: '300px',
        height: '400px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}));

const items = [
    <div className="item" data-value="1">
        1
    </div>,
    <div className="item" data-value="2">
        2
    </div>,
    <div className="item" data-value="3">
        3
    </div>,
    <div className="item" data-value="4">
        4
    </div>,
    <div className="item" data-value="5">
        5
    </div>
];
const thumbItems = (items, [setThumbIndex, setThumbAnimation]) => {
    return items?.map((item, i) => (
        <div className="thumb" onClick={() => (setThumbIndex(i), setThumbAnimation(true))}>
            {item}
        </div>
    ));
};
export default function BookDetails() {
    const [message, setMessage] = useState('');
    const classes = useStyles();
    const theme = useTheme();
    // useUserRole({ roleList: ['STUDENT'] });
    const history = useHistory();
    const { bookId } = useParams();
    const dispatch = useDispatch();
    let profileId = Cookies.get('profileId');
    const { bookDetails } = useSelector((state) => state.books);

    const [mainIndex, setMainIndex] = useState(0);
    const [mainAnimation, setMainAnimation] = useState(false);
    const [thumbIndex, setThumbIndex] = useState(0);
    const [thumbAnimation, setThumbAnimation] = useState(false);
    const [thumbs] = useState(thumbItems(items, [setThumbIndex, setThumbAnimation]));
    const slideNext = () => {
        if (!thumbAnimation && thumbIndex < thumbs.length - 1) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex + 1);
        }
    };

    const slidePrev = () => {
        if (!thumbAnimation && thumbIndex > 0) {
            setThumbAnimation(true);
            setThumbIndex(thumbIndex - 1);
        }
    };

    const syncMainBeforeChange = (e) => {
        setMainAnimation(true);
    };

    const syncMainAfterChange = (e) => {
        setMainAnimation(false);

        if (e.type === 'action') {
            setThumbIndex(e.item);
            setThumbAnimation(false);
        } else {
            setMainIndex(thumbIndex);
        }
    };
    let bookImage = bookDetails?.imageUrls;
    if (bookImage === null) {
        bookImage = bookDetails?.googleUrl;
    }

    const syncThumbs = (e) => {
        setThumbIndex(e.item);
        setThumbAnimation(false);

        if (!mainAnimation) {
            setMainIndex(e.item);
        }
    };
    const handleAddToCart = () => {
        let payload = {
            bookId: bookDetails?.id
        };
        if (profileId) {
            dispatch(
                addToCart(payload, profileId, () => {
                    setMessage('Book added successfully to cart');
                    setTimeout(() => {
                        setMessage('');
                    //history.push(`/book/cart/${profileId}`);
                    history.push('/book-search');
                },3000);
             })
            );
        } else {
            history.push('/login');
        }
    };

    const handleAddToWishlist = () => {
        let payload = {
            bookId: bookDetails?.id
        };
        if (profileId) {
            dispatch(
                addWishlist(payload, profileId, () => {
                    //history.push(`/book/wishlist/${profileId}`);
                    setTimeout(() => {
                        history.push('/book-search');
                    }, 3000);
                    
                })
            );
        } else {
            history.push('/login');
        }
    };

    useEffect(() => {
        dispatch(getBooksDetails(bookId));
    }, []);

    return (
        <>
            <Header />
            <div style={{ marginTop: 40 }}>
                <Container maxWidth="lg" style={{ display: 'flex' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            {/* <AliceCarousel
                                activeIndex={mainIndex}
                                animationType="fadeout"
                                animationDuration={800}
                                disableDotsControls
                                disableButtonsControls
                                infinite
                                items={items}
                                mouseTracking={!thumbAnimation}
                                onSlideChange={syncMainBeforeChange}
                                onSlideChanged={syncMainAfterChange}
                                touchTracking={!thumbAnimation}
                            />
                            <div className="thumbs">
                                <AliceCarousel
                                    activeIndex={thumbIndex}
                                    autoWidth
                                    disableDotsControls
                                    disableButtonsControls
                                    items={thumbs}
                                    mouseTracking={false}
                                    onSlideChanged={syncThumbs}
                                    touchTracking={!mainAnimation}
                                />
                                <div className="btn-prev" onClick={slidePrev}>
                                    &lang;
                                </div>
                                <div className="btn-next" onClick={slideNext}>
                                    &rang;
                                </div>
                            </div> */}
                            <img src={bookImage} className={classes.img} alt="Book" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <Box>
                                <Typography variant="h2" color="primary">
                                    {bookDetails?.title?.toUpperCase()}
                                </Typography>
                                <Box display="flex">
                                    <StarIcon className={classes.rating} />
                                    <StarIcon className={classes.rating} />
                                    <StarIcon className={classes.rating} />
                                    <StarIcon className={classes.rating} />
                                    <StarHalfIcon className={classes.rating} />
                                </Box>
                                <Box display="flex" alignItems="center" style={{ margin: '10px 0 10px' }}>
                                    <Typography variant="body1" fontWeight="lighter">
                                        Author :
                                    </Typography>
                                    &nbsp;
                                    <Typography variant="subtitle1" color="primary">
                                        {bookDetails?.author}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body1" fontWeight="lighter">
                                        Availability :
                                    </Typography>
                                    &nbsp;
                                    <Typography
                                        variant="subtitle1"
                                        style={{
                                            color:
                                                bookDetails?.availableStatus === 'AVAILABLE'
                                                    ? theme.palette.success.main
                                                    : theme.palette.error.main
                                        }}
                                    >
                                        {bookDetails?.availableStatus.replaceAll('_', ' ')}
                                    </Typography>
                                </Box>

                                <Box style={{ marginTop: 10 }}>
                                    <Typography variant="body1" fontWeight="lighter">
                                        Description :
                                    </Typography>
                                    <Typography variant="subtitle1" color="success">
                                        {bookDetails?.description}
                                    </Typography>
                                </Box>
                                <Box className={classes.btnContainer}>
                                    <Button variant="contained" style={{ marginRight: 15 }} onClick={() => handleAddToWishlist()}>
                                        Add to wishlist
                                    </Button>
                                    <Button variant="contained" color="success" onClick={() => handleAddToCart()}>
                                        Add to cart
                                    </Button>
                                </Box>
                                <Box>
                                    <Accordion className={classes.root} expanded>
                                        <AccordionSummary
                                            // expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography> Book Details </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ marginTop: '-3%' }}>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body1" fontWeight="lighter">
                                                    Category :
                                                </Typography>
                                                &nbsp;
                                                <Typography variant="subtitle1" color="success">
                                                {bookDetails?.categoryNames.map((item, i) => 
                                                
                                                     <span> {item}{i < bookDetails?.categoryNames?.length -1 ? ",": "."}</span>  
                                                 
                                                )}
                                                    {/* {bookDetails?.categoryNames[0]}, {bookDetails?.categoryNames[1]} */}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body1" fontWeight="lighter">
                                                    Paper Type:
                                                </Typography>
                                                &nbsp;
                                                <Typography variant="subtitle1" color="success">
                                                    {bookDetails?.paperType}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body1" fontWeight="lighter">
                                                    Publisher:
                                                </Typography>
                                                &nbsp;
                                                <Typography variant="subtitle1" color="success">
                                                    {bookDetails?.publisher}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body1" fontWeight="lighter">
                                                    Book ISBN:
                                                </Typography>
                                                &nbsp;
                                                <Typography variant="subtitle1" color="success">
                                                    {bookDetails?.isbn}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body1" fontWeight="lighter">
                                                    Book Language:
                                                </Typography>
                                                &nbsp;
                                                <Typography variant="subtitle1" color="success">
                                                    {bookDetails?.languageName}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body1" fontWeight="lighter">
                                                    Age Group:
                                                </Typography>
                                                &nbsp;
                                                <Typography variant="subtitle1" color="success">
                                                    {bookDetails?.minAge}-{bookDetails?.maxAge}
                                                </Typography>
                                            </Box>
                                            {/* <Box display="flex" alignItems="center">
                                                <Typography variant="body1" fontWeight="lighter">
                                                    Book Condition:
                                                </Typography>
                                                &nbsp;
                                                <Typography variant="subtitle1" color="success">
                                                    Excellent
                                                </Typography>
                                            </Box> */}
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion className={classes.root}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Read Reviews</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <iframe
                                                id="the_iframe"
                                                src={`https://www.goodreads.com/api/reviews_widget_iframe?did=DEVELOPER_ID&amp;format=html&amp;isbn=${bookDetails?.isbn}&amp;links=660&amp;min_rating=&amp;review_back=fff&amp;stars=000&amp;text=000`}
                                                width="100%"
                                                frameborder="0"
                                            ></iframe>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <br />
            <FooterNew />
        </>
    );
}
