import {
    VIEW_PROFILE_START,
    VIEW_PROFILE_SUCCESS,
    VIEW_PROFILE_ERROR,
    LOGGED_USER_PROFILE_START,
    LOGGED_USER_PROFILE_SUCCESS,
    LOGGED_USER_PROFILE_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    viewProfileData: null,
    loggedUserProfile: null,
    message: ''
};

export default function ProfileReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case VIEW_PROFILE_START:
        case LOGGED_USER_PROFILE_START:
            return {
                ...state,
                loading: true
            };
        case VIEW_PROFILE_SUCCESS:
        case LOGGED_USER_PROFILE_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case VIEW_PROFILE_ERROR:
        case LOGGED_USER_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
