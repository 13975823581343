import React from 'react';
import Header from './header';
import FooterNew from './FooterNew';
import { Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#2ebee1',
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter'
    }
}));
export default function AboutUs() {
    const classes = useStyles();
    return (
        <>
            <Header />
            <Container>
                <h3 style={{ paddingTop: 80 }} className={classes.title}>
                    About Us
                </h3>
                <p>
                    BookEinstein is a curated children’s library started in 2016 with a goal to inculcate the habit of reading in children.
                    Based in Mumbai, we have tied up with several top-tier schools and have been influential in setting-up/running school
                    libraries, book-fairs and after-school classes.
                </p>

                <p>
                    BookEinstein was born with a sole purpose of instilling a love for books in order to create passionate readers &
                    writers. We at BookEinstein aim to convert this ambition into reality.
                </p>

                <p> “Books are a uniquely portable magic” - Stephen King </p>

                <p> It is this magic that I want to create at BookEinstein - Smita Mehta (Founder - BookEinstein) </p><br></br>
                <h3 className={classes.title}> Book Library Collection </h3>

                <p>
                    Our Goal is to offer a variety of books at an early age to help develop children’s reading and vocabulary skills and
                    help them find the right book. Our wide variety of books include internationally acclaimed and award winning books from
                    the UK, best sellers, top international publishers, children’s favorite authors and books which are difficult to find
                    locally.
                </p>

                <p>
                    We also run specialized reading programs for children with curated hand-picked books that are sure to engage our young
                    readers. We provide expert guidance from start to end, not just to help children read successfully, but to also build
                    their confidence and progress their level of reading, vocabulary and comprehension. We work with parents to monitor
                    their child’s progress and garner their valuable feedback about it.
                </p>

                <p>
                    We closely work and run supplementary libraries for schools including Cathedral and John Connon, Campion, Mount Litera
                    School International, St Mary’s I.C.S.E, JBCN International, Don Bosco International School, Villa Teresa, BIS, Lodha
                    World School, Mumbai High World School to name a few. We are strong in South & Central Mumbai and have now gradually
                    expanded our wings into the Suburbs.
                </p><br></br>
                <h3 className={classes.title}> Enrichment Classes for Children </h3>
                <p>
                    Tailored to engage the child’s interest & inspire learning. Our teachings are committed to challenging children to
                    confidently analyze information, think critically & boost their effectiveness. Fun online sessions with lots of
                    activities & games will help children excel in specific areas of English. These interactive programmes along with proper
                    training help children gain a higher advantage in terms of language proficiency such as developing fluency in reading
                    and speech, good vocabulary, and so on. The English Enrichment courses will also improve their written & oral skills,
                    better their concentration & cooperation skills along with enhancing their communication skills. Each session of the
                    course is planned differently & taught by highly qualified teachers to ensure that children are learning from the best &
                    in turn contributing to their long-term success.
                </p><br></br>
                <br></br>
            </Container>
            <FooterNew />
        </>
    );
}
