import React from 'react';

import { Card, Box } from '@mui/material';

export default function JustAddedCard({ imgUrl, title, author }) {
    return (
        <Card sx={{ backgroundColor: '#add8e6' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', width: '225px', padding: '8px', height: 400 }}>
                <Box style={{ flex: 3 }}>
                    <img src={imgUrl} alt="top pick 1" style={{ height: '275px', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}></img>
                </Box>
                <Box style={{ flex: 2 }}>
                    <Box
                        style={{
                            color: '#272343',
                            // fontWeight: 600,
                            textAlign: 'center',
                            padding: '10px',
                            fontWeight: 'lighter', fontFamily: 'Josefin Sans'
                        }}
                    >
                        {' '}
                        {title}{' '}
                    </Box>
                    <Box style={{ textAlign: 'center', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}> {author} </Box>
                </Box>
            </Box>
        </Card>
    );
}
