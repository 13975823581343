import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';
import MinimalLayout from './../layout/MinimalLayout';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';
import { BookCategories } from '../views/home/book-categories';
import CreativeWriting from '../views/home/courses/creativeWriting';
import { CategoriesList } from '../views/home/book-categories-list';
import { TopBooksList } from '../views/home/top-books-list';
import { BookDetails } from '../views/home/book-details';
import { WishlistCart } from '../views/home/wishlist-cart';
import { PricingPlans } from '../views/home/pricing-plan';
import { CourseCategories } from '../views/home/course-categories';
import { CourseDetails } from '../views/home/course-categories';
import HomepageOne from '../views/home/homepage1';
import HomepageTwo from '../views/home/homepage2';
import ModuleDetails from '../views/home/module-categories/module-details';
import PlaceDeliveryOrder from '../views/orders/place-delivery-order';
import TermsConditions from '../views/home/components/TermsConditions';
import PrivacyPolicy from '../views/home/privacy-policy';
import ModuleSubscription from '../views/home/module-subscription';
import AboutUs from '../views/home/components/AboutUs';
// import Refer from '../views/students/Refer';
// import OnlineClasses from '../views/home/onlineClasses/index';

const StudentDashboard = Loadable(lazy(() => import('../views/students/dashboard')));
const StudentProfile = Loadable(lazy(() => import('../views/students/student-profile')));
const ViewSubscription = Loadable(lazy(() => import('../views/students/view_subscription')));
// const ViewHistory = Loadable(lazy(() => import('../views/subscriptions/historyView/index')));

//Student Orders
const StudentAllOrder = Loadable(lazy(() => import('../views/students/orders/all-orders')));
const StudentOrderDetails = Loadable(lazy(() => import('../views/students/orders/view-order')));

// home routing
const Home = Loadable(lazy(() => import('../views/home')));
const AssignmentSubmission = Loadable(lazy(() => import('../views/students/courses/assignments/AssignmentSubmission/index')));

//school
const School = Loadable(lazy(() => import('../views/home/school/index')));
const OnlineClasses = Loadable(lazy(() => import('../views/home/onlineClasses/index')));
const GiftVoucher = Loadable(lazy(() => import('../views/vouchers/add-voucher')));
const SubscriptionPlan = Loadable(lazy(() => import('../views/home/subcription-plan/index')));
const Faq = Loadable(lazy(() => import('../views/students/faq/index')));
const Refer = Loadable(lazy(() => import('../views/students/Refer')));
const Ref = Loadable(lazy(() => import('../views/ref/index')));

//-----------------------|| AUTH ROUTING ||-----------------------//

const HomeRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/home',
                '/book-categories',
                '/book-search',
                '/book-search-top',
                '/book-details/:bookName',
                '/book/:type/:id',
                '/creative-writing',
                '/pricing-plans',
                '/voucher',
                '/course-categories',
                '/course-details',
                '/module-details',
                '/my-dashboard',
                '/my-profile',
                '/subscriptions',
                '/orders',
                '/order/:id',
                '/assignment-submission',
                '/assignments',
                // '/home1',
                '/bookLibrary',
                '/school',
                '/onlineClasses',
                '/subscriptionPlan/:flag',
                '/moduleSubscription',
                // '/view-history',
                '/faq',
                '/refer',
                '/ref',
                '/termsConditions',
                '/privacy-policy',
                '/about-us'
            ]}
        >
            <MinimalLayout>
                <Switch location={location} key={location.pathname} forceRefresh>
                    {/* <NavMotion> */}
                    {/* <GuestGuard> */}
                    {/* <Route path="/home" component={Home} /> */}
                    <Route path="/home" component={HomepageOne} />
                    <Route exact path="/book-categories" component={BookCategories} />
                    <Route path="/book-search" component={CategoriesList} />
                    <Route path="/book-search-top" component={TopBooksList} />
                    <Route path="/book-details/:bookId" component={BookDetails} />
                    <Route path="/book/:type/:id" component={WishlistCart} />
                    <Route path="/creative-writing" component={CreativeWriting} />
                    <Route path="/pricing-plans" component={PricingPlans} />
                    <Route path="/course-categories" component={CourseCategories} />
                    <Route path="/course-details" component={CourseDetails} />
                    {/* <Route path="/module-details/:id" component={ModuleDetails} /> */}
                    <Route path="/module-details" component={ModuleDetails} />
                    {/* <Route path="/my-dashboard" component={StudentDashboard} /> */}
                    <Route path="/my-profile" component={StudentProfile} />
                    <Route path="/subscriptions" component={ViewSubscription} />
                    <Route path="/orders" component={StudentAllOrder} />
                    <Route path="/order/:id" component={StudentOrderDetails} />
                    {/* <Route path="/home1" component={HomepageOne} /> */}
                    <Route path="/bookLibrary" component={HomepageTwo} />
                    {/* </GuestGuard> */}
                    <Route path="/assignment-submission" component={AssignmentSubmission} />
                    <Route path="/school" component={School} />
                    <Route path="/voucher" component={GiftVoucher} />
                    <Route path="/onlineClasses" component={OnlineClasses} />

                    <Route path="/subscriptionPlan/:flag" component={SubscriptionPlan} />
                    <Route path="/moduleSubscription" component={ModuleSubscription} />
                    {/* <Route path="/view-history" component={ViewHistory} /> */}
                    <Route path="/faq" component={Faq} />
                    <Route path="refer" component={Refer} />
                    <Route path="/ref" component={Ref} />
                    <Route path="/termsConditions" component={TermsConditions} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/about-us" component={AboutUs} />
                    {/* </NavMotion> */}
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default HomeRoutes;
