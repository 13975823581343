import axios from 'axios';


export const getProfileCart = (id, searchKey = '', page = 1, rowsPerPage = 1000) => {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${process.env.REACT_APP_ROOT_URL_ORDER}/orders-service/carts/profiles/${id}?searchKey=${searchKey}&pageNumber=${page}&pageSize=${rowsPerPage}`
                )
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };