import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Grid, Container, Card, Box, useTheme, useMediaQuery } from '@mui/material';
import TopPickCard from './TopPickCard';
import './myStyle.css';
import { getTopPicks } from '../../../../services/LandingPageServices';
import { useHistory } from 'react-router-dom';

export default function TopPicksCarousel() {
    const [topPicks, setTopPicks] = useState([]);
    const history = useHistory();
    const theme = useTheme();
    const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const GetTopPicks = async () => {
        await getTopPicks()
            .then((data) => {
                setTopPicks(data?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleDetails = (bookId) => {
        history.push(`/book-details/${bookId}`);
    };

    useEffect(() => {
        GetTopPicks();
    }, []);
    return (
        <>
            {matchesLgUp && (
                <Container>
                    <div className="carousel-wrapper" style={{ marginBottom: '15px' }}>
                        <Carousel
                            autoPlay={true}
                            infiniteLoop
                            interval={4000}
                            showArrows={false}
                            showStatus={false}
                            width={'100%'}
                            sx={{ width: '100%' }}
                        >
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i < 5 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 5 &&
                                        i < 10 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 10 &&
                                        i < 15 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 15 &&
                                        i < 20 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 20 &&
                                        i < 25 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                        </Carousel>
                    </div>
                </Container>
            )}

            {matchesLgDown && matchesSmUp && (
                <Container>
                    <div className="carousel-wrapper" style={{ marginBottom: '15px' }}>
                        <Carousel
                            autoPlay={true}
                            infiniteLoop
                            interval={4000}
                            showArrows={false}
                            showStatus={false}
                            width={'100%'}
                            sx={{ width: '100%' }}
                        >
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i < 3 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 3 &&
                                        i < 6 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 6 &&
                                        i < 9 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 9 &&
                                        i < 12 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 12 &&
                                        i < 15 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 15 &&
                                        i < 18 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 18 &&
                                        i < 21 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 21 &&
                                        i < 24 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 24 &&
                                        i < 27 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                        </Carousel>
                    </div>
                </Container>
            )}

            {matchesSmDown && (
                <Container>
                    <div className="carousel-wrapper" style={{ marginBottom: '15px' }}>
                        <Carousel
                            autoPlay={true}
                            infiniteLoop
                            interval={4000}
                            showArrows={true}
                            showIndicators={false}
                            showStatus={false}
                            width={'100%'}
                            sx={{ width: '100%' }}
                        >
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i < 1 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 1 &&
                                        i < 2 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 2 &&
                                        i < 3 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 3 &&
                                        i < 4 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 4 &&
                                        i < 5 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 5 &&
                                        i < 6 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 6 &&
                                        i < 7 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 7 &&
                                        i < 8 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 8 &&
                                        i < 9 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 9 &&
                                        i < 10 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 10 &&
                                        i < 11 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 11 &&
                                        i < 12 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 12 &&
                                        i < 13 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 13 &&
                                        i < 14 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 14 &&
                                        i < 15 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 15 &&
                                        i < 16 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 16 &&
                                        i < 17 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 17 &&
                                        i < 18 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 18 &&
                                        i < 19 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 19 &&
                                        i < 20 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 20 &&
                                        i < 21 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 21 &&
                                        i < 22 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 22 &&
                                        i < 23 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 23 &&
                                        i < 24 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 24 &&
                                        i < 25 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {topPicks?.map((row, i) => {
                                    return (
                                        i >= 25 &&
                                        i < 26 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <TopPickCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></TopPickCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                        </Carousel>
                    </div>
                </Container>
            )}
        </>
    );
}
