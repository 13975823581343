import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSecurityDeposits } from '../../../../store/actions/SubscriptionActions';
import MainCard from '../../../../ui-component/cards/MainCard';
import { withStyles, useTheme } from '@material-ui/styles';
import {
    Grid,
    IconButton,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    OutlinedInput,
    InputAdornment,
    Typography,
    Tooltip
} from '@material-ui/core';
import { IconSearch } from '@tabler/icons';

import SubjectIcon from '@material-ui/icons/Subject';
import AddDeductions from './add-deductions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Link, useHistory } from 'react-router-dom';
import { getSecurityDeposits, refund } from '../../../../services/SecurityDeposits';
import ConfirmDialog from '../../../../ui-component/ConfirmDialog/ConfirmDialog';
import useStyles from '../../../ViewTableStyles';
import useUserRole from '../../../../hooks/useUserRole';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

export default function SecurityDeposits() {
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'SCHOOL_ADMIN','SUPER_ADMIN'] });
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    const { allSecurityDeposit } = useSelector((state) => state?.subscriptions);
    const [securityData, setSecurityData] = useState(allSecurityDeposit);
    const dispatch = useDispatch();
    const theme = useTheme();
    const [value, setValue] = useState('');
    const [openDeduct, setOpenDeduct] = useState(false);
    const [rowData, setRowData] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
    };

    const handleDeductions = (data) => {
        setOpenDeduct(true);
        setRowData(data);
    };
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });

    const handleDelete = async (mid) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        dispatch(
            refund(mid, () => {
                dispatch(getAllSecurityDeposits(rowsPerPage, value ? value : '', page + 1 ));
            })
        );
    };

    const GetSecurityDeposits = async () => {
        await getSecurityDeposits()
            .then((res) => {
                setSecurityData(res?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        GetSecurityDeposits();
    }, []);

    useEffect(() => {
        setSecurityData(allSecurityDeposit);
    }, [allSecurityDeposit]);

    useEffect(() => {
        dispatch(getAllSecurityDeposits(rowsPerPage, value ? value : '', page + 1 ));
    }, [rowsPerPage, value, page]);

    return (
        <>
            <MainCard>
                <div className={classes.titleContainer}>
                    <SubjectIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; All Security Deposits </span>
                </div>
                <Paper elevation={5} className={classes.root}>
                    <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <OutlinedInput
                                className={classes.searchControl}
                                id="input-search-header"
                                value={value}
                                onChange={(e) => {
                                    setValue(e?.target?.value);
                                    setPage(0);
                                }}
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" className={classes.startAdornment} />
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </Grid>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.tableHeaderCell}>
                                <StyledTableRow>
                                    <TableCell> Full Name(Id) </TableCell>
                                    <TableCell> Email </TableCell>
                                    <TableCell align="center"> Deposit Amount </TableCell>
                                    <TableCell align="center"> Deposit Date </TableCell>
                                    <TableCell align="center"> Status </TableCell>
                                    <TableCell align="right"> Action </TableCell>
                                </StyledTableRow>
                            </TableHead>

                            {!allSecurityDeposit ? (
                                <caption style={{ textAlign: 'center' }}>Sorry! No data available</caption>
                            ) : (
                                <TableBody>
                                    {allSecurityDeposit?.content?.map((row) => (
                                        <StyledTableRow key={row?.id}>
                                            <TableCell>
                                                {row?.fullName}-{row?.profileId}
                                            </TableCell>
                                            <TableCell>{row?.email}</TableCell>
                                            <TableCell align="center"> {row?.deposit}</TableCell>
                                            <TableCell align="center"> {row?.depositDate}</TableCell>

                                            <TableCell align="center">
                                                <Typography
                                                    className={classes.status}
                                                    style={{
                                                        backgroundColor:
                                                            (row?.status === 'DEPOSITED' && theme.palette.success.main) ||
                                                            (row?.status === 'REFUNDED' && theme.palette.warning.dark)
                                                    }}
                                                >
                                                    {row?.status}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="View Security Deposits">
                                                    <Link
                                                        to={{
                                                            pathname: `/admin/view-history`,
                                                            state: {
                                                                id: row?.id,
                                                                fullName: row?.fullName,
                                                                email: row?.email,
                                                                profileId: row?.profileId
                                                            }
                                                        }}
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        <IconButton>
                                                            <VisibilityIcon color="primary" style={{ cursor: 'pointer' }} />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>

                                                <Tooltip title="Deduct">
                                                    <IconButton>
                                                        <MoneyOffIcon
                                                            color="primary"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleDeductions(row)}
                                                        />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Refund">
                                                    <IconButton
                                                        onClick={() => {
                                                            setConfirmDialog({
                                                                isOpen: true,
                                                                title: 'Refund Amount',
                                                                subTitle: `Are you sure you want to refund amount ${row?.deposit}?`,
                                                                onConfirm: () => {
                                                                    handleDelete(row?.id);
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        <MonetizationOnIcon color="error" style={{ cursor: 'pointer' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={allSecurityDeposit?.totalElements || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-displayedRows': {
                                marginBottom: 0
                            },
                            '.MuiTablePagination-selectLabel': {
                                marginBottom: 0
                            }
                        }}
                    />
                </Paper>

                {openDeduct && (
                    <AddDeductions isOpen={openDeduct} setOpen={setOpenDeduct} rowData={rowData}
                     getAllDeposits={GetSecurityDeposits} 
                     />
                )}

                {confirmDialog && <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
            </MainCard>
        </>
    );
}
