import {
    GET_ALL_COURSE_START,
    GET_ALL_COURSE_SUCCESS,
    GET_ALL_COURSE_ERROR,
    GET_COURSE_START,
    GET_COURSE_SUCCESS,
    GET_COURSE_ERROR,
    GET_ALL_MODULE_START,
    GET_ALL_MODULE_SUCCESS,
    GET_ALL_MODULE_ERROR,
    GET_MODULE_START,
    GET_MODULE_SUCCESS,
    GET_MODULE_ERROR,
    GET_ALL_SESSION_START,
    GET_ALL_SESSION_SUCCESS,
    GET_ALL_SESSION_ERROR,
    GET_ALL_BATCH_START,
    GET_ALL_BATCH_SUCCESS,
    GET_ALL_BATCH_ERROR,
    GET_BATCH_START,
    GET_BATCH_SUCCESS,
    GET_BATCH_ERROR,
    GET_ALL_ASSIGNMENT_START,
    GET_ALL_ASSIGNMENT_SUCCESS,
    GET_ALL_ASSIGNMENT_ERROR,
    GET_ASSIGNMENT_START,
    GET_ASSIGNMENT_SUCCESS,
    GET_ASSIGNMENT_ERROR,
    GET_ALL_DOCUMENT_START,
    GET_ALL_DOCUMENT_SUCCESS,
    GET_ALL_DOCUMENT_ERROR,
    GET_DOCUMENT_START,
    GET_DOCUMENT_SUCCESS,
    GET_DOCUMENT_ERROR,
    GET_ALL_TEACHERS_START,
    GET_ALL_TEACHERS_SUCCESS,
    GET_ALL_TEACHERS_ERROR,
    GET_ALL_STUDENTS_START,
    GET_ALL_STUDENTS_SUCCESS,
    GET_ALL_STUDENTS_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    allCourses: null,
    getCourseData: null,
    allModules: null,
    allSessions: null,
    getModuleData: null,
    allBatches: null,
    getBatchData: null,
    allAssignments: null,
    getAssignmentData: null,
    allDocuments: null,
    getDocumentData: null,
    allTeachersData: null,
    allStudentsData: null,
    message: ''
};

export default function GetAllCourseReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_COURSE_START:
        case GET_COURSE_START:
        case GET_ALL_MODULE_START:
        case GET_MODULE_START:
        case GET_ALL_SESSION_START:
        case GET_ALL_BATCH_START:
        case GET_BATCH_START:
        case GET_ALL_ASSIGNMENT_START:
        case GET_ASSIGNMENT_START:
        case GET_ALL_DOCUMENT_START:
        case GET_DOCUMENT_START:
        case GET_ALL_TEACHERS_START:
        case GET_ALL_STUDENTS_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_COURSE_SUCCESS:
        case GET_COURSE_SUCCESS:

        case GET_ALL_MODULE_SUCCESS:
        case GET_MODULE_SUCCESS:

        case GET_ALL_SESSION_SUCCESS:

        case GET_ALL_BATCH_SUCCESS:
        case GET_BATCH_SUCCESS:

        case GET_ALL_ASSIGNMENT_SUCCESS:
        case GET_ASSIGNMENT_SUCCESS:
        case GET_ALL_DOCUMENT_SUCCESS:
        case GET_DOCUMENT_SUCCESS:
        case GET_ALL_TEACHERS_SUCCESS:
        case GET_ALL_STUDENTS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_COURSE_ERROR:
        case GET_COURSE_ERROR:
        case GET_ALL_MODULE_ERROR:
        case GET_MODULE_ERROR:

        case GET_ALL_SESSION_ERROR:

        case GET_ALL_BATCH_ERROR:
        case GET_BATCH_ERROR:
        case GET_ALL_ASSIGNMENT_ERROR:
        case GET_ASSIGNMENT_ERROR:

        case GET_ALL_DOCUMENT_ERROR:
        case GET_DOCUMENT_ERROR:
        case GET_ALL_TEACHERS_ERROR:
        case GET_ALL_STUDENTS_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
