import {
    GET_ALL_BOOKS_START,
    GET_ALL_BOOKS_SUCCESS,
    GET_ALL_BOOKS_ERROR,
    GET_ID_BOOKS_START,
    GET_ID_BOOKS_SUCCESS,
    GET_ID_BOOKS_ERROR,
    GET_SEARCH_FILTERS_ERROR,
    GET_SEARCH_FILTERS_SUCCESS,
    GET_SEARCH_FILTERS_START,
    CLEAR_GET_ID_BOOKS_DATA,
    SEARCH_DATA_ERROR,
    SEARCH_DATA_START,
    SEARCH_DATA_SUCCESS,
    GET_SEARCH_FILTERS_TOP,
    GET_SEARCH_FILTERS_TOP_ERROR,
    GET_SEARCH_FILTERS_TOP_SUCCESS
} from '../../constants';

const initialState = {
    loading: false,
    allBooks: null,
    bookByIdData: null,
    bookDetails: null,
    searchFilterData: null,
    searchFilterDataTop: null,
    message: '',
    searchBooksData: null
};

export default function BooksReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_BOOKS_START:
        case GET_ID_BOOKS_START:
        case GET_SEARCH_FILTERS_START:
        case SEARCH_DATA_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_BOOKS_SUCCESS:
        case GET_ID_BOOKS_SUCCESS:
        case GET_SEARCH_FILTERS_SUCCESS:
        case SEARCH_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_BOOKS_ERROR:
        case GET_ID_BOOKS_ERROR:
        case GET_SEARCH_FILTERS_ERROR:
        case SEARCH_DATA_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        // case CLEAR_GET_ID_BOOKS_DATA:
        //     return {
        //         ...state,
        //         loading: false,
        //         bookByIdData: ''
        //     };
        default:
            return state;
    }
}
