import React from 'react';
import HeroSection from './components/HeroSection';
import BodySection from './components/BodySection';
import Footer from './components/Footer';

export default function CreativeWriting() {
    return (
        <>
            <HeroSection /> <BodySection /> <Footer />
        </>
    );
}
