// assets
import {
    IconDisc,
    IconWorld,
    IconReport,
    IconFileReport,
    IconNotes,
    IconReportSearch,
    IconReportAnalytics,
    IconNotebook,
    IconVocabulary,
    IconTable,
    IconBusinessplan
} from '@tabler/icons';

// constant
const icons = {
    IconDisc: IconDisc,
    IconWorld: IconWorld,
    IconReport: IconReport,
    IconFileReport: IconFileReport,
    IconNotes: IconNotes,
    IconReportSearch: IconReportSearch,
    IconReportAnalytics: IconReportAnalytics,
    IconNotebook: IconNotebook,
    IconVocabulary: IconVocabulary,
    IconTable: IconTable,
    IconBusinessplan: IconBusinessplan
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const onlineClassesTeacher = {
    id: 'onlineClasses',
    title: 'Enrichment Classes',
    type: 'group',
    children: [
        {
            id: 'courses',
            title: 'Courses',
            type: 'collapse',
            icon: icons['IconVocabulary'],
            children: [
                {
                    id: 'all-courses',
                    title: 'All Courses',
                    type: 'item',
                    url: '/admin/all-courses',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'focus-skill',
                    title: 'Focus Skill',
                    type: 'item',
                    url: '/admin/focusSkill',
                    icon: icons['IconLanguage'],
                    breadcrumbs: false
                }
            ]
        },

        // {
        //     id: 'moduleTransaction',
        //     title: 'Transactions',
        //     type: 'item',
        //     icon: icons['IconBusinessplan'],
        //     url: '/admin/module-transactions',
        //     breadcrumbs: false
        // },
        {
            id: 'grades',
            title: 'Grades',
            type: 'item',
            icon: icons['IconReportAnalytics'],
            url: '/admin/all-grades',
            breadcrumbs: false
        }
    
    ]
};
