import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FormControl from '@material-ui/core/FormControl';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../components/hook-form';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from '../../../DialogStyles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Link,
    Divider,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Tooltip,
    IconButton
} from '@material-ui/core';
import { getAllActiveBatches } from '../../../../services/ModuleServices';
import { useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import Moment from 'react-moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

let profileId = Cookies.get('profileId');

export default function Modal(props) {
    const { openModal, setOpenModal, mId, studentAge } = props;
    const history = useHistory();
    const classes = useStyles();

    const handlePopupClose = () => {
        setOpenModal(false);
    };

    const [batchId, setBatchId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    const handleChange = (event, row) => {
        setBatchId(parseInt(event?.target?.value));
        setSelectedDate(row?.startDateTime);
    };

    const [batchList, setBatchList] = useState([]);

    const GetAllActiveBatches = async (mId) => {
        await getAllActiveBatches(mId, studentAge)
            .then((res) => {
                setBatchList(res);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (studentAge) console.log('useEffect', studentAge);

        GetAllActiveBatches(mId);
    }, []);

    const handleSubscriptionNavigation = () => {
        history.push({
            pathname: !Cookies.get('token') ? `/login` : `/moduleSubscription`,
            state: { mId: mId, batchId: batchId, selectedDate: selectedDate }
        });
    };

    return (
        <div>
            <Dialog open={openModal} classes={{ paper: classes.dialog }}>
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.dialogTitleChild}>
                        <Typography style={{ fontSize: '20px', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                            {' '}
                            Select Batches{' '}
                        </Typography>
                        <Link to="#" onClick={handlePopupClose}>
                            <CloseIcon className={classes.closeIcon} />
                        </Link>
                    </div>
                </DialogTitle>

                <PerfectScrollbar className={classes.ScrollHeight}>
                    <DialogContent>
                        <Grid container>
                            <TableContainer className={classes.tableContainer}>
                                {batchList && batchList?.length > 0 ? (
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead className={classes.tableHeaderCell}>
                                            <StyledTableRow>
                                                <TableCell> Batch ID </TableCell>
                                                <TableCell align="center"> Start Date-Time </TableCell>
                                                <TableCell align="center"> WeekDays </TableCell>
                                                <TableCell align="right"> Action </TableCell>
                                            </StyledTableRow>
                                        </TableHead>

                                        <TableBody>
                                            {batchList?.map((row, index) => (
                                                <StyledTableRow key={index}>
                                                    <TableCell> {row?.id} </TableCell>
                                                    <TableCell>
                                                        <Moment format="YYYY/MM/DD hh:mm a" date={row?.startDateTime} />
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {row?.weekDays?.map((row1, index) => (
                                                            <Typography key={index}> {row1} </Typography>
                                                        ))}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Radio
                                                            value={row?.id}
                                                            onChange={(e) => handleChange(e, row)}
                                                            checked={batchId === row?.id}
                                                        />
                                                    </TableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    
                                    <DialogContent style={{ textAlign: 'center' }}>
                                    Sorry! No Batch available
                                </DialogContent>
                                    
                                )}
                            </TableContainer>
                        </Grid>
                    </DialogContent>
                </PerfectScrollbar>
                <Divider style={{ marginTop: 15, fontWeight: 'lighter', fontFamily: 'Josefin Sans' }} />
                {batchList && batchList?.length > 0 && (
                    <DialogActions className={classes.dialogAction}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.buttonCancel}
                            onClick={handlePopupClose}
                        >
                            Close
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.buttonCancel}
                            onClick={handleSubscriptionNavigation}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
}
