import { Button, MenuItem, Paper, Select } from '@material-ui/core';
import React, { useState } from 'react';
import MainCard from '../../ui-component/cards/MainCard';
import DeleteIcon from '@material-ui/icons/Delete';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};
// const options = [{ Library: 'Library' }, { OnlineClass: 'Online Class' }];

export default function TableRows({ rowsData, deleteTableRows, handleChange }) {
    // const [value, setValue] = useState('Library');

    return rowsData?.map((data, index) => {
        const { name, referralFor, email, mobile, message } = data;
        return (
            <>
                <br />

                <tr key={index}>
                    <td style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <input
                            type="text"
                            value={name}
                            onChange={(evnt) => handleChange(index, evnt)}
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            style={{ margin: '0px 3px' }}
                        />
                    </td>
                    <td>
                        <Select
                            fullWidth
                            variant="outlined"
                            displayEmpty
                            id="referralFor"
                            style={{ margin: '0px 8px' }}
                            name="referralFor"
                            placeholder="Select Referred For"
                            // value={x.referredFor}
                            value={referralFor}
                            renderValue={(selected) => {
                                return selected;
                            }}
                            MenuProps={MenuProps}
                            // inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="LIBRARY"> LIBRARY </MenuItem>
                            <MenuItem value="ONLINE_CLASS"> ONLINE CLASS </MenuItem>
                            {/* {options.map((element) => {
                                const [[key]] = Object.entries(element);
                                return (
                                    <MenuItem value={key} key={key}>
                                        {key}
                                    </MenuItem>
                                );
                            })} */}
                        </Select>
                    </td>
                    <td>
                        <input
                            placeholder="email"
                            type="text"
                            style={{ margin: '0px 13px' }}
                            value={email}
                            onChange={(evnt) => handleChange(index, evnt)}
                            name="email"
                            className="form-control"
                        />
                    </td>

                    <td>
                        <input
                            placeholder="number"
                            type="number"
                            style={{ margin: '0px 16px' }}
                            value={mobile}
                            onChange={(evnt) => handleChange(index, evnt)}
                            name="mobile"
                            className="form-control"
                        />
                    </td>
                    <td>
                        <textarea
                            placeholder="Short Message"
                            width="550px"
                            style={{ margin: '0px 20px', height: '100px', width: '250px', resize: 'none' }}
                            type="text"
                            value={message}
                            onChange={(evnt) => handleChange(index, evnt)}
                            name="message"
                            className="form-control"
                        />
                    </td>
                    <td>
                        <Button
                            variant="contained"
                            // color="red"
                            startIcon={<DeleteIcon />}
                            style={{ borderRadius: '16px', backgroundColor: '#d44444' }}
                            onClick={() => deleteTableRows(index)}
                        >
                            Remove
                        </Button>
                    </td>
                </tr>
                <br />

                <br />
            </>
        );
    });
}
