import React from 'react';
import Header from './header';
import FooterNew from './FooterNew';
import { Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#2ebee1',
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter'
    },
    subContentHighlight: {
        fontWeight: 'lighter'
    }
}));
export default function TermsConditions() {
    const classes = useStyles();
    return (
        <>
            <Header />
            <Container>
                <h3 style={{ paddingTop: 80 }} className={classes.title}>
                    Terms & Conditions
                </h3>
                <p>
                    The Terms and Conditions (defined below) govern your access and use of the Site (defined below), use of our services and
                    Subscription to the Books from Book Einstein through the Site. By using at Bookeinstein.com ("Site"), you AGREE to be
                    bound by CONDITIONS OF USE (set out below) and PRIVACY POLICY, all disclaimers and terms and conditions that appear on
                    the Site (collectively "Terms and Conditions") which form an integral part of the agreement between Bookeinstein.com and
                    you on use of the Site, our services provided and the Subscription Plans for Books through the Site. If you do not agree
                    with the Terms and Conditions, please discontinue your use and access to the Site immediately. You may also terminate
                    this Agreement by written notice to us at any time. Bookeinstein.com reserves the right to amend any provisions of the
                    Terms and Conditions without prior notice. Your continued use of the Site after such amendment shall be deemed
                    acceptance of the amended Terms and Conditions.
                </p>
                <br></br>
                <h3 className={classes.title}> Conditions of Use Definitions </h3>
                <p>
                    In the Terms and Conditions, "we" and "us" means Bookeinstein.com and "you" and "User(s)" means the person, firm or
                    corporation who access and use the Site. Except to the extent that the subject matter or context may otherwise require
                    in the Terms and Conditions, (i) expressions including the singular may indicate the plural and vice versa; (ii)
                    expressions indicating any particular gender may indicate all other genders; (iii) expressions indicating bodies
                    corporate may also indicate natural persons and vice versa; (iv) all references to Clauses are to the clauses of the
                    CONDITIONS OF USE; and (v) the following expressions shall have their respective meanings set out as follows:
                    "Acknowledgment" means our email acknowledgment of receipt of your Booking Order. "Agreement" means the agreement
                    between Bookeinstein.com and you on use of the Site, our services provided and the Subscription Plan purchased through
                    the Site and which comprises of the Terms and Conditions, your Booking Order and the Booking Confirmation; Clause 16.1;
                    "Content" shall have the meaning set out in Clause 12.2; "Subscription Plans and Services" means the books and/or orders
                    that are subscribed `through the Site Bookeinstein.com "Guest" shall have the meaning set out in Clause 1.1; "Invoice"
                    means the invoice issued by us to you showing the price of the Subscription Plans and Services purchased which is your
                    proof of purchase; "Member" shall have the meaning set out in Clause 1.2; "Price" means the subscription price of the
                    Book Plans listed for Orders in the Site ; "Booking Confirmation" means the written confirmation from Bookeinstein.com
                    confirming that the Booking Order has been accepted and is being processed; "Booking Order" means the order for the
                    Books that has been electronically placed on the Site; "Booking Order ID" means the Order confirmation number issued by
                    us to you upon our acceptance of the Booking Order. "Site" means Book Einstein online storefront at Bookeinstein.com
                    "Terms and Conditions" shall have the meaning set out at the beginning of the CONDITIONS OF USE. "Territory" means
                    India. "Third-Party Site(s)" shall have the meaning set out at Clause 13.
                </p><br></br>
                <h3 className={classes.title}> 1. User, User Details, Account And Registration</h3>
                <h3 className={classes.title}> 1.1 User </h3>
                <p>
                    Only persons who are 18 years and above who are entitled to enter into legally binding contracts may access and use the
                    Site as a User and submit the Booking Order or make any orders, subscribe or any other form of request on the Site. A
                    Parent or a Guardian may enter into a contract on behalf of a minor and the minor can enjoy the services provided by
                    Book Einstien.com As a User, you may access and use the Site as well as make orders or as a Member and a Guest is
                    restricted to only access the site. If as a User, you elect not to register an account with us when access or use the
                    Site, you will be reflected as a guest on the Site ("Guest"). If however you have elected to register an account with us
                    and be a Member (as described in Clause 2 below), you will enjoy certain specified benefits exclusively granted only to
                    Members. The Site and our services are directed solely to Users who access the Site from the Territory. If you choose to
                    access the Site or use our services from locations outside India, you do so at your own risk and shall be solely
                    responsible for complying with applicable laws and all legal consequences arising therefrom.
                </p><br></br>
                <h3 className={classes.title}> 1.2 User Details, Account and Registration </h3>
                <p>
                    In order to Subscribe any Plans or Services on the Site, you need to provide certain particulars which include your
                    name, postcode, a valid email address, billing address, delivery address, payment details, personal information so as to
                    enable us to process your orders or purchases. You are required to register an account with us in order to purchase any
                    Goods and Services on the Site. As a registered member ("Member"), you have certain specified benefits, which are
                    exclusively enjoyed by Members. We reserve the right to accept or decline a new registration and to cancel an account at
                    any time. Please refer to our PRIVACY POLICY for more details. You must keep your account password confidential and
                    notify us immediately once your password or account has been compromised.
                </p><br></br>
                <h3 className={classes.title}> 2.Payment </h3>
                <h3 className={classes.title}> 2.1 Booking Order </h3>
                <p>
                    All Booking Orders submitted to Bookeinstein.com through the Site are subject to our acceptance. Upon confirmation of
                    the Booking Order, we will issue a Booking Order ID and provide you with a Booking Confirmation. The Agreement between
                    you and Bookeinstein.com in respect of the Books ordered will be deemed completed upon and delivery of the said Books.
                    Until such time, we reserve the right to reject your Booking Order without providing any reason. Bookeinstein.com shall
                    be entitled to cancel or terminate the obligations to fulfill any Booking Order, for any reason whatsoever, including
                    shortage of Books even after a Booking Order is confirmed by, and paid for by you, with or without notice and we shall
                    not be liable for any loss and damage to any party for such termination or cancellation. In such event, we shall
                    reimburse to you in the form of a Service addition or Plan Extension.
                </p><br></br>
                <h3 className={classes.title}> 2.2. Payment Terms </h3>
                <p>
                    Unless otherwise stated, all payments to be made through the Site shall be made using Net Banking, Online payment and
                    shall be made in accordance with such procedures as we may from time to time specify. Digital coupons will be issued
                    with their own set of conditions stated therein and which are specific to each digital coupon. Please refer to the
                    specific terms that come with the usage of digital coupons.
                </p><br></br>
                <h3 className={classes.title}> 2.3. Payment Processing </h3>
                <p>
                    Payments may be made via iBanking, CCAavenue and through the use of internationally accepted credit cards which include
                    VISA, MASTERCARD, and AMERICAN EXPRESS. Gift cards / vouchers / reward points are not exchangeable for cash and
                    unutilised amounts will not be refunded. And any other applicable terms and conditions stated on the gift cards and
                    vouchers.
                </p><br></br>
                <h3 className={classes.title}> 2.4. Digital Coupons </h3>
                <p>
                    We may from time to time, offer discounts for Subscription Purchases on the Site through the use of promotional discount
                    codes found on digital coupons and which may apply in respect of certain specified purchases made through the Site. Only
                    one promotional discount code found on a single digital coupon can be applied to a single order or online transaction.
                    Once a promotional discount code is used when placing an order, the relevant digital coupon will automatically be
                    redeemed against the total purchase amount for the relevant order. Digital coupons must be used prior to their
                    expiration date indicated therein. Digital coupons cannot be replaced if lost, stolen, deleted, or if you should decide
                    to cancel or return your purchase. In the event where the total purchase value is below the value of the digital coupon,
                    the excess value of the digital coupon will not be refunded. Digital coupons will not be applicable with any other
                    promotions that may be promoted in the Site, unless otherwise stated. If there is a conflict between the Terms and
                    Conditions and that stated in the digital coupon, the terms and conditions stated in each digital coupon shall be final
                    and conclusive.
                </p><br></br>
                <h3 className={classes.title}> 3. Errors on our site </h3>
                <p>
                    Prices and availability of Subscriptions and Services provided are subject to change without notice. Users acknowledge
                    and agree to each. Errors on the Site, which may include without limitation to errors in the Price and description of
                    Subscription Plans & Books will be corrected when discovered, and Bookeinstein.com reserves the right to revoke any
                    stated offer and to correct any errors, inaccuracies or omissions including after a Booking Order has been submitted and
                    whether or not the Booking Order has been confirmed and your credit card has been charged. If your credit card has
                    already been charged for the purchase and your Booking Order is canceled, Bookeinstein.com will credit to your credit
                    card account for the amount that was charged. Individual bank policies will dictate when this said amount is eventually
                    credited to your bank account. Whilst Bookeinstein.com shall use its best endeavors to ensure the accuracy of such
                    listings on the Site and to promptly correct any errors, inaccuracies or omissions, it shall not be liable for any loss
                    or damage arising from the same. You acknowledge and agree that such errors may sometimes occur due to software default
                    or otherwise and is not in any way intended to mislead or misrepresent to you in any way and as such, Bookeinstein.com
                    shall not be liable to you for any loss or damage arising from such error.
                </p><br></br>
                <h3 className={classes.title}> 4. Cancellation, Exchanges & Returns </h3>
                <p>
                    You agree to be bound by the respective cancellation/refund, exchanges and return policies of Bookeinstein.com Goods and
                    Services and the Experience Provider Goods and Services set out in this clause 4. Hence if you are not fully satisfied
                    with your purchase, you may return it in accordance with the terms set out below.
                </p><br></br>
                <h3 className={classes.title}> 4.1 Purchases of Bookeinstein.com Goods and Services </h3>
                <p>
                    All Booking of Bookeinstein.com Goods and Services shall be subject to the cancellation/refund, exchanges and return
                    policies as set below.
                </p><br></br>
                <h3 className={classes.title}> 4.1.1 Cancellation/Refund </h3>
                <p>
                   Bookeinstein.com reserves the right to cancel any booking transaction on the Site and refund to you, the Price of any
                    Subscription Plans and Services purchased from the Site even after the relevant Booking Order is confirmed and payment
                    has been received by us. We do not provide any refund for the Subscription Plans purchased, however the paid amount can
                    be adjusted in-case if you want to upgrade your plan. However in the below mentioned cases we’ll refund the amount
                    deducted: Booking done due to system error. Booking not done but amount deducted. All refunds will be made to your
                    Bookeinstein.com A/C number.<br></br><br></br>
                    In case of books not exchanged for 2 consecutive weeks, the month/week will still be counted, in the Plan.

                    Registration Fee of Rs 500 is non-refundable.<br></br>

                    Security Deposit of Rs 500 is refunded only after all books have been returned to BookEinstein.<br></br><br></br>

                    The plans can be extended as follows:<br></br>
                    The 3 month plan will be extended by 1 month only (not any number of weeks)<br></br>
                    The 6 month plan will be extended by 2 months only (not any number of weeks/months)<br></br>
                    The 12 months plan will be extended by 3 months only (not any number of weeks/months)<br></br>
                </p><br></br>

                <h3 className={classes.title}> 5. Representation, Warranty And Undertaking </h3>
                <p>
                    ou represent, warrant and undertake that: (a) You are of 18 years or above and legally entitled to enter into legally
                    binding contracts or that you are accessing the Site with the approval and under the supervision of one of your parents
                    or a legal guardian; (b) All information which you have provided to us for registration is true correct and accurate and
                    that you will promptly inform us of any change by updating the details in your account; (c) You have obtained all
                    necessary right, power and authority to enter into the Agreement and to perform the acts required of you under the
                    Agreement; (d) You own or otherwise have the right to use, post, and disclose the content and that the use of such
                    content will not infringe any of the rights of any third party, including without limitation to intellectual property
                    rights pursuant to applicable laws (and hereby grant us the right to use, reproduce, display, perform, adapt, modify,
                    distribute and have the content distributed); (e) You will not harass or cause distress or inconvenience to any person
                    via the transmission of obscene or offensive content; (f) You will not disrupt the normal flow of any access to, or use
                    of our services; (g) You agree to comply with all local rules regarding online conduct and acceptable content; and (h)
                    You will comply with all applicable laws regarding the transmission of technical data exported from India or the country
                    in which you reside.
                </p><br></br>

                <h3 className={classes.title}> 6. Intellectual Property Rights </h3>
                <h3 className={classes.title}> 6.1 Right to Download </h3>
                <p>
                    The Site is created, operated and controlled by Book Einstein. You are granted a non-exclusive, non-transferable and
                    revocable license to access the Site and use our services. You may only download Content displayed on the Site for
                    non-commercial, personal use provided you also retain all copyright, trademark and other proprietary notices contained
                    in the material, do not modify or alter the material and do not copy or post the material on any network computer or
                    broadcast the material in any media. It is strictly prohibited to modify, transmit, distribute, reuse, repost, "frame"
                    or use the Content of the Site including the text, images, audio and/or video for public or commercial purposes without
                    written permission from an authorized representative of Book Einstein. It is strictly prohibited to download the product
                    images of the Books for sale within this Site. In the event of breach, we shall be entitled to suspend or terminate your
                    account with us and bring necessary legal action against you to recover any loss or damage arising from such breach.
                </p><br></br>

                <h3 className={classes.title}> 6.2 Proprietary Rights </h3>
                <p>
                    All content found on the Site or used in the provision of our services, including without limitation to logos, design,
                    drawings, trade names, information, data, software, photographs, graphs, videos, typefaces, graphics, music, sounds and
                    other material of whatever nature and form (collectively "Content") is protected by copyright, trademark, patent,
                    design, industrial property or other proprietary intellectual property rights, and these rights are validly owned by
                    Bookeinstein.com or its licensors and protected in all forms, media and technologies existing now or developed in the
                    future. All Content is copyrighted as a collective work under the Indian Laws and international copyright laws, and
                    Bookeinstein.com owns, to the fullest extent allowed by such laws, the copyright in the selection, coordination,
                    arrangement, and enhancement of all content. Except as expressly authorized or licensed, you may not copy, modify,
                    remove, delete, augment, add to, publish, transmit, participate in the transfer or sale, lease or rental of, create
                    derivative works from or in any way exploit any of the Content, in whole or in part. The Bookeinstein.com logo, trade
                    names and other trademarks on the Site are owned by, licensed to, or, where required, used with permission by
                    Bookeinstein.com and its licensors may not be reproduced, copied, or manipulated in any manner without the express,
                    written approval of the relevant trademark owner. By using the Site, you agree that any information (except for purchase
                    information), materials, suggestions, ideas or comments you send to Bookeinstein.com or any other third party using the
                    Site is non-confidential. By submitting any solicited or unsolicited information using the Site, you grant
                    Bookeinstein.com an irrevocable, worldwide, unrestricted and perpetual license to use, modify, reproduce, transmit,
                    display and distribute such materials, information, suggestions, ideas or comments for any purpose whatsoever to the
                    extent permitted by law. Save as otherwise expressly provided herein, any rights not expressly granted to you herein
                    remain reserved by us. Any unauthorized use of the Content or other materials in respect thereof is expressly prohibited
                    and constitutes infringement of intellectual property rights. You acknowledge that upon occurrence of such infringement,
                    as monetary damages may not be an adequate remedy, that you agree that we are entitled to apply to court for the
                    appropriate permanent or temporary injunctive relief to stop such breach or further breaches. This is without prejudice
                    to our right to recover damages arising from such breach or to avail us of any other remedy at law or in equity. For the
                    purposes of the Terms and Conditions, a breach of intellectual property rights is a material breach of the Terms and
                    Conditions entitling us to suspend or terminate your account.
                </p><br></br>

                <h3 className={classes.title}> 7. Links To Third-party Websites </h3>
                <p>
                    The Site contains links to other sites operated by third-parties, including but not limited to third-party sites that
                    display the Bookeinstein.com ("Third-Party Site(s)"). These links are available for your convenience and are intended
                    only to enable access to these Third-Party Sites and for no other purpose. We do not have control over such Third-Party
                    Site and you access such Third-Party Site as your own risk. Bookeinstein.com does not warrant or make any representation
                    about the substance, quality, functionality, accuracy, fitness for a particular purpose, merchantability or any other
                    representation about any Third-Party Site or its content. A link to a Third-Party Site on the Site does not constitute
                    sponsorship, endorsement, approval or responsibility for any Third-Party Site. Bookeinstein.com makes no representation
                    or warranty as to any products or services offered on any Third-Party Site. Bookeinstein.com is therefore not liable for
                    any loss or damage (direct or indirect) of any kind or nature arising out of or related to or incurred in reliance upon
                    any such interactions, links, resources or content.
                </p><br></br>

                <h3 className={classes.title}> 8. Providers of Experiences </h3>
                <p>
                    Gift Vouchers are sold by Bookeinstein.comas agent for businesses that provide lifestyle and recreational services to
                    customers ('Experience Providers'). Bookeinstein.com is not a provider of such services or experiences and has no
                    responsibility for any services or experiences provided or not The experience providers provide services directly to
                    customers. Bookeinstein.com makes no warranty or representation regarding the standard of any service to be provided.
                    All Bookeinstein.com vouchers are issued subject to the terms and conditions of each experience provider and these
                    Bookeinstein.com terms and conditions. Bookeinstein.com does not control the business operations of any experience
                    provider. Bookeinstein.com makes no guarantees or warranties about the services that are provided to you by the
                    experience providers.
                </p><br></br>
                <h3 className={classes.title}> 9. Book Einstein 's liability </h3>
                <p> Bookeinstein.com has: </p>
                <p>
                    No liability for any act, omission or default, whether negligent or otherwise of any experience provider or third party.
                    no liability for any loss or damage occasioned by any negligent act or omission of any experience provider or third
                    party. Where Book Einstein liability cannot be excluded, such liability is limited to the value of the Goods and
                    Services purchased. Bookeinstein.com is a coordination platform that brings people together to experience activities
                    together. There will be no liability for any incidents that occur during the execution of the activities. For example,
                    any accidents, conflicts, fights, injuries, loss of personal belongings that occur during the activities.
                </p><br></br>

                <h3 className={classes.title}> 10. Availability of Experiences </h3>
                <p>
                    All experiences are offered by experience providers subject to availability. Events are encouraged to be created at
                    least 2 weeks in advance. If you would like to experience the service from the experience providers during peak times
                    (such as weekends or holidays), you should book further in advance. Bookings are not finalised until you have received
                    the confirmation email from Bookeinstein.com.
                </p><br></br>

                <h3 className={classes.title}> 11. Contact Details Given to CCavenue and Experience Providers </h3>
                <p>
                    It is your responsibility to ensure you enter your email address and other contact details correctly when you complete
                    the experience booking process on a Bookeinstein.com. Failure to do so may result in loss or incorrect delivery of the
                    email confirmation of your experience. As the experience provider will be expecting you, you may end up forfeiting your
                    purchase (subject to the experience provider's terms and conditions). Whenever your event is confirmed and the goods and
                    services provided by the experience providers is / are booked, you agree to give us the rights to share your contact and
                    personal details (such as full name, email address, mobile phone number, home address) with the experience providers.
                </p><br></br>

                <h3 className={classes.title}> 12. Complaints or Problems During Your Experience </h3>
                <p>
                    If you have a problem or a complaint on the day of your experience, please bring this to the experience provider's
                    attention immediately so that issues may be addressed on the spot, where possible. You could also feedback to us, so
                    that we can try to prevent such problems in the future, if possible.
                </p><br></br>

                <h3 className={classes.title}> 13. Security </h3>
                <p>
                    The conditions of use and privacy policy of any Third-Party Site may differ substantially from the CONDITIONS OF USE and
                    PRIVACY POLICY that apply to your use of the Site. Please review the conditions of use for all Third-Party Sites for
                    more information about the terms and conditions that apply to your use of Third-Party Sites.
                </p><br></br>

                <h3 className={classes.title}> 13.1 Confidentiality of Account and password </h3>
                <p>
                    If you use the Site, you are solely responsible for maintaining the confidentiality of your account and password and for
                    restricting access to your computer. You agree to accept responsibility for all activities that occur under your account
                    and password. The Site may only be used for lawful purposes. If your User name and/or password have been compromised,
                    you are to immediately change your password and notify us of any unauthorized access. You also agree to immediately
                    notify us of any of any attempted or actual unauthorized access or use of our services by third parties and/or any other
                    breaches of security. You acknowledge and agree that we will not be in any way, liable, directly or indirectly, for any
                    loss or damages arising from such unauthorized use or any acts or omissions on your part in maintaining the
                    confidentiality of your account and password.
                </p><br></br>

                <h3 className={classes.title}> 13.2 Technical Processing and Transmission of Electronic Communications </h3>
                <p>
                    You acknowledge that the technical processing and transmission of electronic communications is necessary for your use of
                    our services. You consent to our interception and storage of electronic communications and/or your data and acknowledge
                    that your electronic communications will involve transmission over the Internet, and over various networks, some of
                    which may not be owned and/or operated by us. In the context of Book Einstein , electronic payment transmission refers
                    to transactions done via PayPal, credit card or ibanking. You further acknowledge that changes to your electronic
                    communications may occur so as to conform and adapt such data to the technical requirements of connecting networks and
                    devices. You also acknowledge that such electronic communications may be accessed by unauthorized parties when
                    communicated across the Internet, network communications facilities, telephone, or other electronic means. As such, we
                    are not liable for any electronic communication and/or data which are lost, altered, intercepted or stored without
                    authorization during such transmission whatsoever across networks not owned and/or operated by us.
                </p><br></br>

                <h3 className={classes.title}> 13.3 Prohibited Activities </h3>
                <p>
                    You are prohibited from violating or attempting to violate the security of Book Einstein , including, without limitation
                    to carrying out any of the following prohibited activities as they infringe upon the intellectual property rights or
                    other rights of third parties, are defamatory, illegal or may otherwise harm our goodwill, and reputation: (a) accessing
                    data not intended for such User or logging onto a server or an account which the User is not authorised to access or
                    accessing illegally or without authorization, the computers or networks belonging to another party, or attempting to
                    hack into another individual's account or any activity that might be used as a precursor to an attempted system
                    penetration (i.e. port scan, stealth scan, or other information gathering activity); or (b) using the Site for
                    unintended purposes or trying to change the behaviour of the Site; (c) attempting to probe scan or test the
                    vulnerability of a system or network or breach security or authentication measures without proper authorization; (d)
                    attempting to interfere with service to any User, host or network, including without limitation via means of submitting
                    a virus, worms, Trojan horses to the Site, overloading, "pinging", "flooding," "spamming," "mail bombing", "denial or
                    service attacks" or "crashing;" or activities that disrupt the use of or interfere with the ability of third parties to
                    effectively use the network or any connected network, system, service, or equipment; or (e) forging any TCP/IP packet
                    header or any part of the header information in any e-mail or newsgroup posting; or (f) forging communications on behalf
                    of the Site (impersonating Book Einstein ) or to the Site (impersonating as a legitimate User) or forging or
                    misrepresenting message headers, whether in whole or in part, to mask the originator of the message; or (g) sending
                    unsolicited bulk and/or unauthorized commercial messages or e-mail on behalf of Book Einstein , including promotions
                    and/or advertising of products or services, is expressly prohibited; or (h) engaging in any activity that infringes upon
                    or misappropriates the copyright, trade marks, patents, design or any intellectual property rights of third parties
                    whatsoever; or (i) engaging in activity that infringes upon the privacy or other personal rights of third parties; or
                    (j) using the Site to advertise, transmit, store, post, display, or otherwise make available pornography or obscene
                    speech or material; or (k) using the Site to transmit or post defamatory, harassing, abusive, or threatening language or
                    promoting physical harm or injury against any group or individual, or promoting any act of cruelty to animals; or (l)
                    advertising, transmitting, or otherwise making available any software, program, product, or service that is designed to
                    spam, initiation of pinging, flooding, mailbombing, denial of service attacks, and piracy of software; or (n) exporting
                    encryption software over the Internet or otherwise; or (m) engaging in activities that are illegal, including
                    advertising, transmitting, or otherwise making available Ponzi schemes, pyramid schemes, fraudulently charging credit
                    cards, and pirating software or; promoting or providing instructional information about illegal activities; or (n)
                    offering for sale any item, goods or services that breaches any applicable laws or regulations; or (o) any attempts to
                    decompile, reverse engineer, disassemble, modify or hack the Site or its application; or (p) using the Site as a
                    forwarding service to another website thereby promoting the Site with inappropriate links, titles or descriptions; or
                    (q) engaging in activities, whether lawful or unlawful, that we determine to be harmful to our Users, operations,
                    reputation, goodwill, or customer relations. Violations of system or network security may result in civil or criminal
                    liability. You agree not to use any device, software or routine or data to interfere or attempt to interfere with the
                    proper working of the Site or any activity being conducted on this Site. You agree, further, not to use or attempt to
                    use any engine, software, tool, agent, data or other device or mechanism (including without limitation browsers,
                    spiders, robots, avatars or intelligent agents) to navigate or search the Site other than the search engine and search
                    agents or interfaces provided by Bookeinstein.com or generally publicly available browsers.
                </p><br></br>

                <h3 className={classes.title}> 13.4 Fraud Protection and Compliance with Law </h3>
                <p>
                    We may disclose information, including personally identifiable information, with other companies and organisations for
                    credit fraud protection and risk reduction in accordance with applicable laws. We may also disclose any information, in
                    our sole discretion, to comply with any applicable law, regulation, legal process or governmental request. In cases of
                    fraud, the proper authorities will be informed and legal action will be taken.
                </p><br></br>

                <h3 className={classes.title}> 13.5 Data Security </h3>
                <p>
                    At Book Einstein , protecting your information is a priority. To prevent unauthorised access and maintain data accuracy,
                    we have put in place appropriate procedures to safeguard and secure the information we collect online. Please refer to
                    the PRIVACY POLICY for more details on the collection, use and protection of personal information.
                </p><br></br>

                <h3 className={classes.title}> 14. Variation </h3>
                <p>
                    We reserve the right to amend, vary and change the Terms and Conditions at our discretion at any time. If any changes
                    were made by Book Einstein , you will only notify via an information bar at the top right hand corner of the page after
                    you log in to the account. Each time you see the notification, you should visit and review the then current Terms and
                    Conditions, notably, the CONDITIONS OF USE and PRIVACY POLICY that apply to your online transactions and use of the
                    Site. If you are dissatisfied with the Site, its Content or do not accept the Terms and Conditions, please discontinue
                    your use of the Site. Your continued use of the Site and your submission of any Booking Order or other request to us
                    following the amendments, variations and changes to the Terms and Conditions shall be construed as your acceptance of
                    the amended, varied and changed Terms and Conditions. In the event that any provision of this Agreement is found to by
                    any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal for any reason, such
                    invalidity, unenforceability or illegality shall not affect the remaining provisions of this Agreement which shall
                    remain in full force and effect.
                </p><br></br>

                <h3 className={classes.title}> 15. Disclaimers AND Exclusions </h3>
                <h3 className={classes.title}> 15.1 Exclusion of warranties </h3>
                <p>
                    To the extent permitted by applicable laws, the Terms and Conditions set out the full extent of our obligations and
                    liabilities in respect of provision of the Goods and Services and performance of our services in relation thereto and
                    there are no other warranties, conditions or terms that are binding on us, our affiliates, subsidiaries, licensors,
                    Retail Merchants, third party content providers, designers, contractors, distributors or each of the respective
                    officers, directors, agents, employees of the aforesaid parties (collectively, "Associates") except as expressly stated
                    herein. Any warranty, condition or term concerning any defect in our services and the Goods and Services which might
                    otherwise be implied or incorporated in the Terms and Conditions by statute, common law or otherwise is hereby expressly
                    excluded to the maximum extent permitted by applicable laws; in particular (but without limitation of the foregoing), we
                    grant no warranties (other than as provided set out above) regarding the fitness for purpose, performance, use, quality
                    or merchantability, title, non-infringement, whether express or implied, by statute at common law or otherwise howsoever
                    in respect of the Goods and Services. By using the Site, you expressly agree that use of the Site is at your sole risk.
                    The Site is provided on an "AS IS" and "as available" basis. Neither Bookeinstein.com nor its Associates warrant that
                    use of the Site will meet your requirements or will be timely, secure or operate in an uninterrupted fashion or be
                    error-free. Neither Bookeinstein.comnor its Associates warrant the accuracy, reliability, integrity, usefulness or
                    completeness of the Content provided on the Bookeinstein.com or the Goods and Services offered for sale on the
                    Bookeinstein.com and that all errors will be corrected. Further, Bookeinstein.com makes no representation that Content
                    provided on the Bookeinstein.com is applicable to, or appropriate for use in, locations outside of the Indian Laws.
                    Bookeinstein.com and its Associates specifically disclaim all warranties, whether expressed or implied, including but
                    not limited to warranties of title, merchantability or fitness for a particular purpose. No oral advice or written
                    information given by Bookeinstein.com or its Associates shall create a warranty. Any information and materials obtained
                    via our services shall be solely at your risk and you will be solely responsible for any damage to your computer,
                    systems or otherwise or loss of data that results from anything obtained via the services. You also understand if you
                    downgrade features relating to your account, you may lose portions of your content and agree that we will have no
                    liability to you of any kind or nature arising out of or related to loss of your content.
                </p><br></br>

                <h3 className={classes.title}> 15.2 Exclusion and Limitation of Liability </h3>
                <p>
                    To the maximum extent permitted by applicable laws, Bookeinstein.com and its Associates shall not be liable to you or
                    any third party for any direct, indirect, incidental, special, punitive or consequential loss and damages whatsoever and
                    howsoever caused arising from this Agreement or that result from your access, use of or inability to use the Site, its
                    Content and services, any system, server or connection failure, error, omission, interruption, delay in transmission, or
                    computer virus or otherwise including but not limited to reliance by you on any information obtained from the Site that
                    results in mistakes, omissions, interruptions, deletion or corruption of files, viruses, delays in operation or
                    transmission, or any failure of performance or for any loss of data, profit, revenue, income or business, whatsoever and
                    howsoever caused whether arising out of any negligence (with the exception of death or personal injury directly
                    resulting from our own negligence) or breach of these terms and conditions even if such loss and damage was foreseeable
                    by or the possibility was brought to the attention of Book Einstein . The foregoing limitation of liability shall apply
                    in any action, whether in contract, tort or any other claim, even if an authorized representative of Bookeinstein.com
                    has been advised of or should have knowledge of the possibility of such damages. You hereby acknowledge that this
                    exclusion of liability shall apply to all content, merchandise and services available through the Site. To the maximum
                    extent permitted by applicable laws, in the event that we are liable for any loss, damage or claim to you or any third
                    party caused by our act, omission, default or neglect, our liability shall not exceed 100% of the Price for the affected
                    sale transaction for which loss, damage and claim are raised thereunder. Such limitation does not however apply to the
                    case of death or personal injury directly resulting from our own negligence.
                </p><br></br>

                <h3 className={classes.title}> 16. Entire Agreement </h3>
                <p>
                    The Terms and Conditions together with your Booking Order and the Booking Confirmation constitute the entire agreement
                    between us and you in relation to your purchase of the Goods and Services and supersede any and all statements or other
                    agreements (oral or written) between you and us.
                </p><br></br>

                <h3 className={classes.title}> 17. Termination of Use </h3>
                <p>
                    You may also cancel your use of our services at any time following the instructions located at My Account page found on
                    the Site. Termination will be immediate and result in irreversible deletion of all account data. Bookeinstein.com may,
                    in its sole discretion, suspend, block access, delete the content, suspend or terminate your right to use our services
                    or terminate your account or your use of the Site anytime without notice and for any reason including without limitation
                    to the following instances: (a) When your supplied information is proven or suspected to be untrue, inaccurate,
                    falsified or incomplete; or (b) You infringe or Bookeinstein.com suspects that you are infringing upon our intellectual
                    property rights; or (c) You infringe or Bookeinstein.com suspects that you are infringing upon the intellectual property
                    rights or other rights of third parties or; or (d) You breach your confidentiality obligations in respect of your
                    password; or (e) pursuant to requests or directives by law enforcement, government, statutory or regulatory body or
                    authority or for complying with applicable laws pursuant to a court order or directive of any government, statutory or
                    regulatory body or authority; or (f) Due to security and technical issues or problems; or (g) When there is non-payment
                    of the Price for Goods and Services purchased by you; or (h) When you are engaged in or Bookeinstein.com suspects that
                    you are engaged in any illegal activity or one of the prohibited activities described herein; or (i) If you tamper with
                    the Site, misrepresent the identity of a User, use buying agents to resell or conduct fraudulent activities on the Site;
                    or (j) Due to discontinuation of service offerings in respect of the Goods and Services; or (k) Due to extended periods
                    of your account inactivity; or (l) Your breach of any other material term of the Terms and Conditions. In the event of
                    suspension or termination of your account, we shall not be held liable for any loss or damage which you may suffer as a
                    result of such suspension or termination, including without limitation to loss of data, loss of use of our services or
                    for deletion or loss of the content. Upon termination, we shall have no obligation to grant you access to or use of our
                    services. Termination of your account for whatever reason shall however not prejudice or impair any antecedent right or
                    obligation of parties that accrued prior to termination. You are personally liable for any orders that you place or
                    charges that you incur prior to termination. Bookeinstein.com reserves the right to change, suspend or discontinue all
                    or any aspects of the Site at any time without prior notice.
                </p><br></br>

                <h3 className={classes.title}> 18. Assignment </h3>
                <p>
                    You shall not assign nor transfer your obligations under this Agreement without the prior written consent of Book
                    Einstein . Bookeinstein.com is however free to assign or transfer this Agreement to any third party. This Agreement
                    shall be binding upon and inure to the benefit of the heirs, executors, administrators, successors and assigns of
                    parties.
                </p><br></br>

                <h3 className={classes.title}> 19. Force Majeure </h3>
                <p>
                    Neither party will be liable for any delay or failure to perform its obligations pursuant to this Agreement as a result
                    of any cause beyond its reasonable control; including but not limited to acts of God, unusually severe weather,
                    destruction by fire or flood, power failure, interruption or failure of electricity and power, malfunction or damage to
                    computer systems, acts of governments, acts of terrorism, hostilities between nations, war, strikes, boycotts, lockouts,
                    industrial and labour dispute, infectious diseases, epidemics as well as travel restrictions due to such events provided
                    that such performance shall be excused only to the extent of and during such disability. If such delay or failure
                    continues for at least ninety (90) days, either party will be entitled to terminate this Agreement by notice in writing.
                </p><br></br>

                <h3 className={classes.title}> 20. Notices </h3>
                <p>
                    Unless otherwise expressly stated in the Terms and Conditions, notices under the Terms and Conditions shall be in
                    writing and may be delivered by hand, or by ordinary or registered mail or by facsimile or by electronic mail to the
                    addresses last made known by one party to the other party. Notice will be deemed given: (a) in the case of hand delivery
                    or registered mail, upon written acknowledgment of receipt by an officer or other duly authorized employee, agent or
                    representative of the receiving party; (b) in the case of ordinary mail, forty-eight (48) hours after posting; (c) in
                    the case of facsimile, at the time stated in the printout of the facsimile transmission report; and (d) in the case of
                    electronic mail, at the time when the mail was sent and stored in the information system of the sender.
                </p><br></br>

                <h3 className={classes.title}> 21. Waiver </h3>
                <p>
                    Any waiver of any right or consent given under this Agreement is only effective if it is in writing and signed by the
                    waiving or consenting party. It shall apply only in the circumstances for which it is given and shall not prevent the
                    party giving it from subsequently relying on the relevant provision. No delay or failure to exercise any right under
                    this Agreement shall operate as a waiver of that right. No single or partial exercise of any right under this Agreement
                    shall prevent any further exercise of that right (or any other right under this Agreement).
                </p><br></br>

                <h3 className={classes.title}> 22. Remedies </h3>
                <p>
                    No remedy conferred by any of the provisions of this Agreement is intended to be exclusive of any other remedy which is
                    otherwise available at law, in equity, by statute or otherwise, and each and every other remedy shall be cumulative and
                    shall be in addition to every other remedy given hereunder or now or hereafter existing at law, in equity, by statute or
                    otherwise. The election of any one or more of such remedies by any of the parties shall not constitute a waiver by such
                    party of the right to pursue any other available remedies.
                </p><br></br>

                <h3 className={classes.title}> 23. Survival </h3>
                <p>
                    Provisions of this Agreement which either are expressed to survive its termination or from their nature or context it is
                    contemplated that they are to survive such termination shall remain in full force and effect notwithstanding such
                    termination.
                </p><br></br>

                <h3 className={classes.title}> 24. Governing Law And Jurisdiction </h3>
                <p>
                    These Terms and Conditions shall be governed by the Indian Laws without giving effect to any principles or conflicts of
                    laws. Both parties irrevocably and unconditionally submit to the exclusive jurisdiction of the Indian courts.
                </p><br></br>
                <br></br>
            </Container>
            <FooterNew />
        </>
    );
}
