import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import { GET_ALL_MODULE_DETAIL_START, GET_ALL_MODULE_DETAIL_SUCCESS, GET_ALL_MODULE_DETAIL_ERROR } from '../../constants';

const getAllModuleStart = () => {
    return { type: GET_ALL_MODULE_DETAIL_START };
};
const getAllModuleSuccess = (payload) => {
    return { type: GET_ALL_MODULE_DETAIL_SUCCESS, payload };
};
const getAllModuleError = (error) => {
    return { type: GET_ALL_MODULE_DETAIL_ERROR, error };
};

const moduleDetails = (mid, payload) => (dispatch) => {
    dispatch(getAllModuleStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/modules/${mid}/detail`, payload, false)
            .then((res) => {
                const payload = {
                    allDetails: res
                };
                dispatch(getAllModuleSuccess(payload));
            })
            .catch((err) => {
                dispatch(getAllModuleError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getAllModuleError('Something went wrong'));
    }
};

export { moduleDetails };
