import { GET_MY_ADDRESS_START, GET_MY_ADDRESS_SUCCESS, GET_MY_ADDRESS_ERROR } from '../../constants';

const initialState = {
    loading: false,
    myAddressData: null,
    message: ''
};

export default function MyAddressReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_MY_ADDRESS_START:
            return {
                ...state,
                loading: true
            };
        case GET_MY_ADDRESS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_MY_ADDRESS_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
