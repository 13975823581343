import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllAssignment_Submission } from '../../../store/actions/Assignment_Submission_Actions';
import MainCard from '../../../ui-component/cards/MainCard';
import ConfirmDialog from '../../../ui-component/ConfirmDialog/ConfirmDialog';
import { withStyles, useTheme } from '@material-ui/styles';
import {
    Grid,
    Tooltip,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    OutlinedInput,
    InputAdornment
} from '@material-ui/core';

import { IconSearch } from '@tabler/icons';
import EditIcon from '@material-ui/icons/Edit';
import { Visibility } from '@material-ui/icons';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SubjectIcon from '@material-ui/icons/Subject';
import useStyles from '../../ViewTableStyles';
import UpdateFeedback from './update-feedback';
import { useParams } from 'react-router-dom';
import useUserRole from '../../../hooks/useUserRole';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

export default function Assignment_Submissions() {
    const classes = useStyles();
    const history = useHistory();
    useUserRole({ roleList: ['ADMIN', 'TEACHER','SUPER_ADMIN'] });

    const dispatch = useDispatch();
    const { id } = useParams();
    const [value, setValue] = useState('');
    const [openUpdateFeedback, setOpenUpdateFeedback] = useState(false);

    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isFeedbackUpdateData, setFeedbackUpdateData] = useState(null);

    const { allAssignment_Submissions } = useSelector((state) => state?.assignmentSubmission);
    const newData = allAssignment_Submissions?.content;
    const totalCount = allAssignment_Submissions?.totalElements;

    const handleViewSubmissions = (id) => {
        history.push(`/admin/submission/${id}`);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        //setPage(0);
    };
    const handleFeedbacks = (mid) => {
        setOpenUpdateFeedback(true);
        setFeedbackUpdateData(mid);
    };

    useEffect(() => {
        dispatch(getAllAssignment_Submission(id, value ? value : '', page + 1, rowsPerPage));
    }, [value, page, rowsPerPage]);

    return (
        <>
            <MainCard>
                <div className={classes.titleContainer}>
                    <SubjectIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; All Submissions </span>
                </div>
                <Paper elevation={5} className={classes.root}>
                    <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <OutlinedInput
                                label="hello"
                                className={classes.searchControl}
                                id="input-search-header"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" className={classes.startAdornment} />
                                    </InputAdornment>
                                }
                            />
                        </div>
                        <Grid style={{ display: 'flex' }}>&nbsp;&nbsp;</Grid>
                    </Grid>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.tableHeaderCell}>
                                <StyledTableRow>
                                    <TableCell> Cust-Id </TableCell>
                                    <TableCell> FirstName </TableCell>
                                    <TableCell> LastName </TableCell>

                                    <TableCell> Grade </TableCell>
                                    <TableCell> Feedback </TableCell>
                                    <TableCell> Action </TableCell>
                                </StyledTableRow>
                            </TableHead>
                            {!newData ? (
                                <caption style={{ textAlign: 'center' }}>Sorry! No data available</caption>
                            ) : (
                                <TableBody>
                                    {newData?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <TableCell> {row?.profile?.profileId} </TableCell>
                                            <TableCell> {row?.profile?.firstName} </TableCell>
                                            <TableCell> {row?.profile?.lastName} </TableCell>

                                            <TableCell> {row?.grade} </TableCell>
                                            <TableCell> {row?.feedback} </TableCell>

                                            <TableCell>
                                                <Tooltip title="View Submissions">
                                                    <IconButton>
                                                        <Visibility
                                                            color="primary"
                                                            onClick={() => handleViewSubmissions(row?.id)}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Add/Update Feedback and Grade">
                                                    <IconButton onClick={() => handleFeedbacks(row?.id)}>
                                                        <EditIcon color="primary" style={{ cursor: 'pointer' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-displayedRows': {
                                marginBottom: 0
                            },
                            '.MuiTablePagination-selectLabel': {
                                marginBottom: 0
                            }
                        }}
                    />
                </Paper>
                {openUpdateFeedback && (
                    <UpdateFeedback
                        openUpdateFeedback={openUpdateFeedback}
                        setOpenUpdateFeedback={setOpenUpdateFeedback}
                        submissionId={isFeedbackUpdateData}
                    />
                )}
            </MainCard>
        </>
    );
}
