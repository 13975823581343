import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAssignment_Submission, deleteFeedbackDoc } from '../../../../store/actions/Assignment_Submission_Actions';
import { withStyles } from '@material-ui/styles';
import { IconSearch } from '@tabler/icons';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    OutlinedInput,
    InputAdornment,
    Button,
    Grid,
    Tooltip,
    Typography
} from '@material-ui/core';

import SchoolIcon from '@material-ui/icons/School';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import useStyles from '../../../ViewTableStyles';
//import AddFeedbackDocLink from './add-feedbackDoc';
import ConfirmDialog from '../../../../ui-component/ConfirmDialog/ConfirmDialog';
import useUserRole from '../../../../hooks/useUserRole';
import { IconButton } from '@mui/material';
import { feedbackUpload } from '../../../../services/ProfileUpload';
import { setMessage } from '../../../../store/actions/MessageActions';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const FeedbackDocumentLinks = (props) => {
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'TEACHER','SUPER_ADMIN'] });
    const dispatch = useDispatch();
    const { id } = useParams();
    // const [addFeedbackDocPopup, setAddFeedbackDocPopup] = useState(false);
    //const [docId, setDocId] = useState('');

    const [file, setFile] = useState(null);
    const { getAssignment_Submission_Data } = useSelector((state) => state?.assignmentSubmission);
    const newData = getAssignment_Submission_Data;

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });
    const clearGroup = async (link) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        dispatch(
            deleteFeedbackDoc(id, link, () => {
                dispatch(getAssignment_Submission(id));
            })
        );
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const FeedbackUpload = async (id) => {
        const formData = new FormData();
        formData.append('document', file);
        await feedbackUpload(id, formData, dispatch, setMessage)
            .then((res) => {
                dispatch(getAssignment_Submission(id));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handlePDF = (event) => {
        FeedbackUpload(id, dispatch, setMessage);
    };

    useEffect(() => {
        dispatch(getAssignment_Submission(id));
    }, []);

    return (
        <>
            <div className={classes.titleContainer}>
                <SchoolIcon className={classes.viewIcon} />
                <span className={classes.pageTitle}>&nbsp; Feedback Document Links </span>
            </div>
            <Paper elevation={5} className={classes.root}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <OutlinedInput
                            className={classes.searchControl}
                            id="input-search-header"
                            placeholder="Search"
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" className={classes.startAdornment} />
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div>
                        <input accept=".pdf " id="contained-button-file" multiple type="file" onChange={handleFileChange} />
                        <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{ boxShadow: '0 0.5em 0.5em 0.1em #68c2d9' }}
                            onClick={handlePDF}
                        >
                            Upload
                        </Button>
                    </div>
                </Grid>
                <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHeaderCell}>
                            <StyledTableRow>
                                <TableCell> Document Name </TableCell>
                                <TableCell> Feedback Document Links </TableCell>
                                <TableCell> Grade </TableCell>
                                <TableCell> Feedback </TableCell>

                                <TableCell align="right"> Action </TableCell>
                            </StyledTableRow>
                        </TableHead>
                        {!newData ? (
                            <caption style={{ textAlign: 'center' }}> Sorry! No data available </caption>
                        ) : (
                            <TableBody>
                                {newData?.feedbackDocumentObjects?.map((row) => (
                                    <StyledTableRow>
                                        <TableCell> {row?.feedbackDocumentName} </TableCell>
                                        <TableCell>
                                            <a href={row?.feedbackDocumentLink} target="_blank" download>
                                                {row?.feedbackDocumentLink}
                                            </a>
                                        </TableCell>
                                        <TableCell>
                                            {newData?.grade}
                                        </TableCell>
                                        <TableCell>
                                            {newData?.feedback}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Tooltip title="Delete Feedback Doc">
                                                <IconButton
                                                    onClick={() => {
                                                        setConfirmDialog({
                                                            isOpen: true,
                                                            title: 'Delete Feedback Doc',
                                                            subTitle: 'Are you sure you want to delete this Doc.',
                                                            onConfirm: () => {
                                                                clearGroup(row?.feedbackDocumentLink);
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <DeleteForeverIcon color="error" style={{ cursor: 'pointer' }} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
            {/* {addFeedbackDocPopup && (
                <AddFeedbackDocLink
                    openAddFeedbackDocs={addFeedbackDocPopup}
                    setOpenAddFeedbackDocs={setAddFeedbackDocPopup}
                    subId={id}
                    data={getAssignment_Submission_Data}
                />
            )} */}

            {confirmDialog && <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
        </>
    );
};

export default FeedbackDocumentLinks;
