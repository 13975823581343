import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Grid, Container, Box, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import JustAddedCard from './JustAddedCard';
import './myStyle.css';
import { useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { getJustAdded } from '../../../../services/LandingPageServices';

const useStyles = makeStyles((theme) => ({
    cardImg: {
        width: '100%',
        height: '100%',
        display: 'block',
        borderRadius: 8,
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter'
    }
}));

export default function JustAdded() {
    const [justAdded, setJustAdded] = useState([]);
    const theme = useTheme();
    const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const history = useHistory();

    const handleDetails = (bookId) => {
        history.push(`/book-details/${bookId}`);
    };

    const GetJustAdded = async () => {
        await getJustAdded()
            .then((data) => {
                setJustAdded(data?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        GetJustAdded();
    }, []);
    return (
        <>
            {matchesLgUp && (
                <Container>
                    <div className="carousel-wrapper" style={{ marginBottom: '15px' }}>
                        <Carousel
                            autoPlay={true}
                            infiniteLoop
                            interval={4000}
                            showArrows={false}
                            showStatus={false}
                            width={'100%'}
                            sx={{ width: '100%' }}
                        >
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i < 5 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 5 &&
                                        i < 10 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 10 &&
                                        i < 15 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 15 &&
                                        i < 20 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 20 &&
                                        i < 25 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 25 &&
                                        i < 30 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 30 &&
                                        i < 35 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 35 &&
                                        i < 40 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 40 &&
                                        i < 45 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 45 &&
                                        i < 50 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 50 &&
                                        i < 55 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 55 &&
                                        i < 60 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 60 &&
                                        i < 65 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 65 &&
                                        i < 70 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 70 &&
                                        i < 75 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 75 &&
                                        i < 80 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 80 &&
                                        i < 85 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 85 &&
                                        i < 90 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 90 &&
                                        i < 95 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '15px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 95 &&
                                        i < 100 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                        </Carousel>
                    </div>
                </Container>
            )}

            {matchesLgDown && matchesSmUp && (
                <Container>
                    <div className="carousel-wrapper" style={{ marginBottom: '15px' }}>
                        <Carousel
                            autoPlay={true}
                            infiniteLoop
                            interval={4000}
                            showArrows={false}
                            showStatus={false}
                            width={'100%'}
                            sx={{ width: '100%' }}
                        >
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i < 3 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 3 &&
                                        i < 6 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 6 &&
                                        i < 9 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 9 &&
                                        i < 12 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 12 &&
                                        i < 15 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 15 &&
                                        i < 18 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 18 &&
                                        i < 21 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 21 &&
                                        i < 24 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 24 &&
                                        i < 27 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 27 &&
                                        i < 30 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 30 &&
                                        i < 33 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 33 &&
                                        i < 36 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 36 &&
                                        i < 39 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 39 &&
                                        i < 42 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 42 &&
                                        i < 45 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 45 &&
                                        i < 48 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 48 &&
                                        i < 51 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 51 &&
                                        i < 54 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 54 &&
                                        i < 57 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 57 &&
                                        i < 60 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 60 &&
                                        i < 63 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 63 &&
                                        i < 66 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 66 &&
                                        i < 69 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 69 &&
                                        i < 72 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 72 &&
                                        i < 75 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 75 &&
                                        i < 78 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 78 &&
                                        i < 81 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 81 &&
                                        i < 84 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 84 &&
                                        i < 87 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 87 &&
                                        i < 90 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 90 &&
                                        i < 93 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 93 &&
                                        i < 96 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 96 &&
                                        i < 99 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                            <Grid
                                container
                                sx={{ justifyContent: 'center', width: '100%', paddingBottom: '45px' }}
                                columnGap={3}
                                rowGap={3}
                            >
                                {justAdded?.map((row, i) => {
                                    return (
                                        i >= 99 &&
                                        i < 102 && (
                                            <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                                <JustAddedCard
                                                    imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                    title={row?.title}
                                                    author={row?.author}
                                                ></JustAddedCard>
                                                &nbsp;
                                            </Link>
                                        )
                                    );
                                })}
                            </Grid>
                        </Carousel>
                    </div>
                </Container>
            )}

            {matchesSmDown && (
                <Container>
                <div className="carousel-wrapper" style={{ marginBottom: '15px' }}>
                    <Carousel
                        autoPlay={true}
                        infiniteLoop
                        interval={4000}
                        showArrows={true}
                        showIndicators={false}
                        showStatus={false}
                        width={'100%'}
                        sx={{ width: '100%' }}
                    >
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i < 1 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 1 &&
                                    i < 2 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 2 &&
                                    i < 3 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 3 &&
                                    i < 4 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 4 &&
                                    i < 5 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 5 &&
                                    i < 6 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 6 &&
                                    i < 7 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 7 &&
                                    i < 8 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 8 &&
                                    i < 9 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 9 &&
                                    i < 10 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 10 &&
                                    i < 11 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 11 &&
                                    i < 12 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 12 &&
                                    i < 13 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 13 &&
                                    i < 14 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 14 &&
                                    i < 15 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 15 &&
                                    i < 16 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 16 &&
                                    i < 17 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 17 &&
                                    i < 18 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 18 &&
                                    i < 19 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 19 &&
                                    i < 20 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 20 &&
                                    i < 21 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 21 &&
                                    i < 22 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 22 &&
                                    i < 23 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 23 &&
                                    i < 24 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 24 &&
                                    i < 25 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 25 &&
                                    i < 26 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 26 &&
                                    i < 27 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 27 &&
                                    i < 28 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 28 &&
                                    i < 29 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 29 &&
                                    i < 30 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 30 &&
                                    i < 31 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 31 &&
                                    i < 32 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 32 &&
                                    i < 33 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 33 &&
                                    i < 34 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 34 &&
                                    i < 35 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 35 &&
                                    i < 36 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 36 &&
                                    i < 37 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 38 &&
                                    i < 39 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 39 &&
                                    i < 40 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 40 &&
                                    i < 41 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 41 &&
                                    i < 42 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 42 &&
                                    i < 43 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 43 &&
                                    i < 44 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 44 &&
                                    i < 45 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 45 &&
                                    i < 46 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 46 &&
                                    i < 47 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 47 &&
                                    i < 48 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 48 &&
                                    i < 49 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 49 &&
                                    i < 50 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 50 &&
                                    i < 51 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 51 &&
                                    i < 52 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 52 &&
                                    i < 53 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 53 &&
                                    i < 54 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 54 &&
                                    i < 55 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 55 &&
                                    i < 56 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 56 &&
                                    i < 57 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 57 &&
                                    i < 58 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 58 &&
                                    i < 59 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 59 &&
                                    i < 60 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 60 &&
                                    i < 61 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 61 &&
                                    i < 62 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 62 &&
                                    i < 63 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 63 &&
                                    i < 64 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 64 &&
                                    i < 65 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 65 &&
                                    i < 66 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 66 &&
                                    i < 67 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 67 &&
                                    i < 68 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 68 &&
                                    i < 69 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 69 &&
                                    i < 70 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 70 &&
                                    i < 71 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 71 &&
                                    i < 72 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 72 &&
                                    i < 73 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 73 &&
                                    i < 74 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 74 &&
                                    i < 75 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 75 &&
                                    i < 76 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 76 &&
                                    i < 77 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 76 &&
                                    i < 77 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 77 &&
                                    i < 78 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 78 &&
                                    i < 79 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 79 &&
                                    i < 80 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 80 &&
                                    i < 81 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 81 &&
                                    i < 82 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 82 &&
                                    i < 83 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 83 &&
                                    i < 84 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 84 &&
                                    i < 85 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 85 &&
                                    i < 86 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 86 &&
                                    i < 87 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 87 &&
                                    i < 88 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 88 &&
                                    i < 89 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 89 &&
                                    i < 90 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 90 &&
                                    i < 91 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 91 &&
                                    i < 92 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 92 &&
                                    i < 93 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 93 &&
                                    i < 94 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 94 &&
                                    i < 95 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 95 &&
                                    i < 96 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 96 &&
                                    i < 97 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 97 &&
                                    i < 98 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 98 &&
                                    i < 99 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid
                            container
                            sx={{ justifyContent: 'center', width: '100%', paddingBottom: '10px' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {justAdded?.map((row, i) => {
                                return (
                                    i >= 99 &&
                                    i < 100 && (
                                        <Link onClick={() => handleDetails(row?.id)} style={{ textDecoration: 'none' }}>
                                            <JustAddedCard
                                                imgUrl={row?.imageUrls?.length ? row.imageUrls[0] : row.googleUrl}
                                                title={row?.title}
                                                author={row?.author}
                                            ></JustAddedCard>
                                            &nbsp;
                                        </Link>
                                    )
                                );
                            })}
                        </Grid>
                    </Carousel>
                </div>
            </Container>
            )}
        </>
    );
}
