import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import FooterNew from '../components/FooterNew';
import axios from 'axios';
import { makeStyles, withStyles } from '@material-ui/styles';
import MainCard from '../../../ui-component/cards/MainCard';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { setMessage } from '../../../store/actions/MessageActions';
import { http } from '../../../utils/http';

import {
    Radio,
    Grid,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel
} from '@material-ui/core';
import { RHFTextField, FormProvider } from '../../../components/hook-form';

import { getModuleDetails } from '../../../services/ModuleServices';
import { getAvaialableCoupons } from '../../../services/PlanServices';
import { checkDiscountCode, calculateModule, checkVoucherCode } from '../../../services/modulePlanSubscriptions';
import { addModuleSubscription, addCashModuleSubscription } from '../../../services/AddModuleSubscription';
import Moment from 'react-moment';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    typography: {
        color: '#5A3399',
        fontSize: '28px',
        lineHeight: '36px'
    },
    activeCategoryCards: {
        width: '264px',
        height: 'auto',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '24PX',
        padding: '16px',
        cursor: 'pointer'
    },
    categoryCards: {
        width: '264px',
        height: 'auto',
        borderRadius: '24PX',
        padding: '16px',
        cursor: 'pointer'
    },
    tableHeaderCell: {
        background: theme.palette.primary.main,
        padding: 10,
        borderRadius: 8,
        marginBottom: 20
    },
    tabelCell: {
        color: '#fff',
        size: '25px'
    },
    clearBtn: {
        alignSelf: 'baseline'
    }
}));

export default function ModuleSubscription(props) {
    const history = useHistory();
    const location = useLocation();
    const [giftVoucherCode, setGiftVoucherCode] = useState('');
    const [storedprice, setStoredprice] = useState('');
    const [total, setTotal] = useState('');
    const selectedModuleId = location?.state?.mId;
    const batchId = location?.state?.batchId;
    const selectedDate = location?.state?.selectedDate;
    const [payment, setPayment] = useState();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [moduleDetails, setModuleDetails] = useState({});
    const [availableCoupons, setAvailableCoupons] = useState([]);

    const [isLoading, setLoading] = useState(true);
    const [price, setPrice] = useState('');
    const [couponCode, setCouponCode] = useState('');

    console.log('bid---', batchId);

    const GetModuleDetails = async (selectedModuleId) => {
        await getModuleDetails(selectedModuleId)
            .then((data) => {
                setModuleDetails(data);
            })
            .catch((error) => {});
    };

    const GetAvailableCoupons = async () => {
        await getAvaialableCoupons()
            .then((data) => {
                setAvailableCoupons(data?.content);
            })
            .catch((error) => {});
    };

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    useEffect(() => {
        GetModuleDetails(selectedModuleId);
        GetAvailableCoupons();
    }, []);

    useEffect(() => {
        const uid = Cookies.get('userId');
        const pid = Cookies.get('profileId');

        if (moduleDetails?.id) {
            CalculatePrice(pid, uid, couponCode);
        }
    }, [moduleDetails]);

    const [selectedCoupon, setSelectedCoupon] = useState('');

    const handleCouponChange = (e) => {
        setSelectedCoupon(e.target.value);
        setCouponCode(availableCoupons.filter((item) => item.id === e?.target?.value)[0]?.code);
    };

    const CalculateSchema = yup.object().shape({
        modulePrice: yup.string('Enter Module Price').required('Required'),
        priceToPay: yup.string('Enter Price To Pay').required('Required'),
        totalPrice: yup.string('Enter Total Price').required('Required')
    });

    const defaultValues = {
        modulePrice: '',
        priceToBePaid: '',
        totalPrice: ''
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(CalculateSchema),
        defaultValues
    });

    const onSubmit = (data) => {};

    const { handleSubmit } = methods;

    const CalculatePrice = async (pid, uid, cupCode, giftVoucherCode) => {
        const DiscountCodepayload = {
            moduleId: moduleDetails?.id,
            userId: uid,
            profileId: pid,
            discountCode: cupCode,
            giftVoucherCode: giftVoucherCode
        };

        await calculateModule(DiscountCodepayload, dispatch, setMessage)
            .then((res) => {
                setTotal(res);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const CouponcodeCheckAndApply = async () => {
        const DiscountCodepayload = {
            moduleId: moduleDetails?.id,
            userId: Cookies.get('userId'),
            profileId: Cookies.get('profileId'),
            discountCode: couponCode,
            giftVoucherCode: giftVoucherCode
        };
        if (couponCode !== '') {
            await checkDiscountCode(DiscountCodepayload, dispatch, setMessage).then((res) => {
                calculateModule(DiscountCodepayload, dispatch, setMessage)
                    .then((res) => {
                        setTotal(res);
                        setStoredprice(res?.priceToBePaid);

                        if (!storedprice) {
                            setPrice(res?.priceToBePaid);
                        } else {
                            let a = price - res?.priceToBePaid;
                            setPrice(a);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
        }
    };

    const handleDiscountCode = (e) => {
        CouponcodeCheckAndApply(dispatch, setMessage);
    };

    const VouchercodeCheckAndApply = async () => {
        const Voucherpayload = {
            moduleId: moduleDetails?.id,
            profileId: Cookies.get('profileId'),
            userId: Cookies.get('userId'),
            giftVoucherCode: giftVoucherCode,
            discountCode: couponCode
        };
        await checkVoucherCode(Voucherpayload, dispatch, setMessage).then((res) => {
            calculateModule(Voucherpayload, dispatch, setMessage)
                .then((res) => {
                    setTotal(res);
                    setStoredprice(res?.priceToBePaid);
                    if (!storedprice) {
                        setPrice(res?.priceToBePaid);
                    } else {
                        let b = price - res?.priceToBePaid;

                        setPrice(b);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    const handleVoucherCode = (e) => {
        VouchercodeCheckAndApply(dispatch, setMessage);
    };

    const CashPay = async () => {
        const payload = {
            moduleId: moduleDetails?.id,
            userId: Cookies.get('userId'),
            profileId: Cookies.get('profileId'),
            batchId: batchId,
            discountCode: couponCode,
            giftVoucherCode: giftVoucherCode,

            paymentMode: 'CASH',
            pricePaid: total?.priceToBePaid,
            paymentId: 'xxx',
            orderId: 'xxx',
            signature: 'xxx'
        };

        try {
            await addCashModuleSubscription(payload);
            dispatch(setMessage('Payment successful'));
            setTimeout(() => {
                history.push('/onlineClasses');
            }, 2000);
        } catch (error) {
            console.log(error);
            dispatch(setMessage(error?.data?.message));
        }
    };

    const PayNow = async () => {
        const orderPayload = {
            amount: total?.priceToBePaid * 100,
            currency: 'INR'
        };
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        const apiUrl = `${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/plan-subscriptions/orders`;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(orderPayload)
        };

        try {
            const response = await fetch(apiUrl, requestOptions);

            if (!response.ok) {
                alert('Server error. Are you online?');
                return;
            }

            const result = await response.json();

            if (!result) {
                alert('Server error. Are you online?');
                return;
            }

            const { id, amount, currency } = result;

            const options = {
                key: 'rzp_live_hnvBKG74pmvVdS',
                amount: amount,
                currency: currency,
                name: 'Acme Corp',
                description: 'Plan Transaction',
                order_id: id,
                handler: async function (response) {
                    setPayment(response);

                    const payload = {
                        moduleId: moduleDetails?.id,
                        userId: Cookies.get('userId'),
                        profileId: Cookies.get('profileId'),
                        batchId: batchId,
                        discountCode: couponCode,
                        giftVoucherCode: giftVoucherCode,

                        paymentMode: 'ONLINE',
                        pricePaid: total?.priceToBePaid,
                        paymentId: response?.razorpay_payment_id,
                        orderId: response?.razorpay_order_id,
                        signature: response?.razorpay_signature
                    };

                    await addModuleSubscription(payload)
                        .then((data) => {})
                        .catch((error) => {
                            console.log(error);
                            dispatch(setMessage(error?.data?.message));
                        });
                    history.push({ pathname: '/paymentSuccess', state: { detail: response } });
                },

                notes: {
                    address: 'Razorpay Corporate Office'
                },
                theme: {
                    color: '#3399cc'
                }
            };

            const paymentObject = new window.Razorpay(options);

            paymentObject.on('payment.failed', function (response) {
                dispatch.setMessage(response?.error.description);
            });

            paymentObject.open();
        } catch (error) {
            console.error(error);
            alert('An error occurred while making the API request.');
        }
    };

    const clearDiscount = () => {
        setCouponCode('');
        setSelectedCoupon('');
        const uid = Cookies.get('userId');
        const pid = Cookies.get('profileId');
        CalculatePrice(pid, uid, '', giftVoucherCode);
    };

    const clearVoucher = () => {
        setGiftVoucherCode('');
        const uid = Cookies.get('userId');
        const pid = Cookies.get('profileId');
        CalculatePrice(pid, uid, couponCode, '');
    };

    return (
        <>
            <Header />
            <MainCard>
                <div style={{ borderRadius: '15px', padding: '16px', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', minWidth: '100%' }}>
                    <Typography sx={{ marginBottom: '16px', color: '#5A3399' }} variant="h3">
                        Module Subscription
                    </Typography>

                    <Paper elevation={5} className={classes.root}>
                        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}></Grid>
                        <TableContainer className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead className={classes.tableHeaderCell}>
                                    <StyledTableRow>
                                        <TableCell className={classes.tabelCell} style={{ fontSize: 16 }}>
                                            {' '}
                                            Name{' '}
                                        </TableCell>
                                        <TableCell className={classes.tabelCell} style={{ fontSize: 16 }}>
                                            {' '}
                                            Start Date{' '}
                                        </TableCell>
                                        <TableCell className={classes.tabelCell} style={{ fontSize: 16 }}>
                                            {' '}
                                            Duration{' '}
                                        </TableCell>
                                        <TableCell className={classes.tabelCell} style={{ fontSize: 16 }}>
                                            {' '}
                                            Focus Skills{' '}
                                        </TableCell>
                                        <TableCell className={classes.tabelCell} style={{ fontSize: 16 }}>
                                            {' '}
                                            Language{' '}
                                        </TableCell>
                                        <TableCell className={classes.tabelCell} style={{ fontSize: 16 }}>
                                            {' '}
                                            Sessions{' '}
                                        </TableCell>
                                        <TableCell className={classes.tabelCell} style={{ fontSize: 16 }}>
                                            {' '}
                                            Total Price{' '}
                                        </TableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow>
                                        <TableCell style={{ fontSize: 16 }}> {moduleDetails?.title} </TableCell>
                                        <TableCell style={{ fontSize: 16 }}>
                                            <Moment format="YYYY/MM/DD hh:mm a" date={selectedDate} />
                                        </TableCell>
                                        <TableCell style={{ fontSize: 16 }}> {moduleDetails?.duration} </TableCell>

                                        <TableCell style={{ fontSize: 16 }}>
                                            {moduleDetails?.focusSkills?.map((row) => (
                                                <Typography style={{ fontSize: 16 }}> {row}</Typography>
                                            ))}
                                        </TableCell>
                                        <TableCell style={{ fontSize: 16 }}> {moduleDetails?.language} </TableCell>
                                        <TableCell style={{ fontSize: 16 }}> {moduleDetails?.sessionsCount}</TableCell>
                                        <TableCell style={{ fontSize: 16 }}> {`₹ ${moduleDetails?.price}`} </TableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <br />
                    <>
                        <Grid container spacing={4} style={{ padding: '20px' }}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                spacing={2}
                                padding={2}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: '0px'
                                }}
                            >
                                <MainCard>
                                    <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                                        {/* <Paper style={{ height: '150px', width: '300px', display: 'flex', justifyContent: 'center' }}> */}
                                        <FormProvider methods={methods}>
                                            <Typography variant="h3" style={{ marginTop: '5px', color: '#3fb2cf', marginBottom: 5 }}>
                                                Voucher Code
                                            </Typography>
                                            <Grid sx={{ display: 'flex' }}>
                                                <div>
                                                    <div>
                                                        <RHFTextField
                                                            name="giftVoucherCode"
                                                            className={classes.rhf}
                                                            value={giftVoucherCode}
                                                            onChange={(e) => setGiftVoucherCode(e?.target?.value)}
                                                        />
                                                    </div>
                                                    <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleVoucherCode}
                                                            size="small"
                                                            disabled={!giftVoucherCode}
                                                        >
                                                            Apply
                                                        </Button>
                                                    </div>
                                                </div>
                                                &nbsp;
                                                <Button onClick={clearVoucher} className={classes.clearBtn}>
                                                    Clear
                                                </Button>
                                            </Grid>
                                        </FormProvider>
                                        {/* </Paper> */}
                                        {/* </Grid> */}
                                        <br />

                                        {/* <Grid style={{ display: 'flex', justifyContent: 'center' }}> */}
                                        <Paper style={{ height: '150px', width: '300px', display: 'flex', justifyContent: 'center' }}>
                                            <FormProvider methods={methods}>
                                                <Typography variant="h3" style={{ marginTop: '5px', color: '#3fb2cf', marginBottom: 5 }}>
                                                    Discount Coupon
                                                </Typography>
                                                <Grid sx={{ display: 'flex' }}>
                                                    <div>
                                                        <div>
                                                            <RHFTextField
                                                                name="couponCode"
                                                                className={classes.rhf}
                                                                value={couponCode}
                                                                onChange={(e) => {
                                                                    setCouponCode(e?.target?.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                                                            <Button
                                                                variant="contained"
                                                                onClick={handleDiscountCode}
                                                                size="small"
                                                                disabled={!couponCode}
                                                            >
                                                                Apply
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    &nbsp;
                                                    <Button onClick={clearDiscount} className={classes.clearBtn}>
                                                        Clear
                                                    </Button>
                                                </Grid>
                                            </FormProvider>
                                        </Paper>
                                    </Grid>
                                    <Grid container style={{ justifyContent: 'center' }}>
                                        <FormControl>
                                            <Typography variant="h4" style={{ marginTop: '5px', color: '#3fb2cf', marginBottom: 5 }}>
                                                Available Offer Coupons
                                            </Typography>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={selectedCoupon}
                                                onChange={handleCouponChange}
                                            >
                                                <Grid container rowGap={2} columnGap={2}>
                                                    {availableCoupons?.map((coupon) => {
                                                        return (
                                                            <Grid
                                                                id={coupon?.id}
                                                                sx={{
                                                                    borderRadius: '10px',
                                                                    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                                                                    padding: '16px'
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    value={coupon?.id}
                                                                    control={<Radio />}
                                                                    label={coupon?.code}
                                                                />
                                                                <h6
                                                                    style={{
                                                                        color: 'green',
                                                                        fontWeight: 'lighter',
                                                                        fontSize: '14px',
                                                                        marginBottom: '0rem'
                                                                    }}
                                                                >
                                                                    {coupon?.description}
                                                                </h6>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </MainCard>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={6}
                                spacing={2}
                                padding={2}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
                            >
                                <MainCard>
                                    <Paper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="h3" style={{ marginTop: '10px', color: '#3fb2cf' }}>
                                            Order Total:
                                        </Typography>
                                        <br />

                                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                            <div
                                                style={{
                                                    flexDirection: 'row',
                                                    marginTop: '0 rem',
                                                    marginBottom: '8px',
                                                    display: moduleDetails?.registrationFee > 0 ? 'flex' : 'none',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <h6 style={{ color: '#3fb2cf', width: '40%' }}> Registration Fee </h6> &nbsp;
                                                <RHFTextField
                                                    style={{ marginLeft: '20px' }}
                                                    placeholder={'₹'}
                                                    id="registrationFee"
                                                    name="registrationFee"
                                                    className={classes.rhf}
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: moduleDetails?.securityFee > 0 ? 'flex' : 'none',
                                                    flexDirection: 'row',
                                                    marginTop: '0 rem',
                                                    marginBottom: '8px',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <h6 style={{ color: '#3fb2cf', width: '40%' }}> Security Fee </h6> &nbsp;
                                                <RHFTextField
                                                    style={{ marginLeft: '20px' }}
                                                    placeholder={'₹'}
                                                    id="securityFee"
                                                    name="securityFee"
                                                    className={classes.rhf}
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <h6 style={{ color: '#3fb2cf', width: '40%' }}> Module Price </h6> &nbsp;
                                                <RHFTextField
                                                    style={{ marginLeft: '20px' }}
                                                    placeholder={'₹'}
                                                    id="modulePrice"
                                                    value={`${moduleDetails?.price}`}
                                                    name="modulePrice"
                                                    className={classes.rhf}
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                <h6 style={{ color: '#3fb2cf', width: '40%' }}> GST </h6> &nbsp;
                                                <RHFTextField
                                                    style={{ marginLeft: '20px' }}
                                                    placeholder={'₹'}
                                                    id="modulePrice"
                                                    value={`${total?.gstPrice}`}
                                                    name="modulePrice"
                                                    className={classes.rhf}
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <h6 style={{ color: '#3fb2cf', width: '40%' }}> Price Pay </h6> &nbsp;
                                                <RHFTextField
                                                    style={{ marginLeft: '20px' }}
                                                    placeholder={'₹'}
                                                    id="priceToBePaid"
                                                    value={`${total?.priceToBePaid}`}
                                                    name="priceToBePaid"
                                                    className={classes.rhf}
                                                    variant="outlined"
                                                    disabled
                                                />
                                            </div>
                                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={PayNow}
                                                    style={{
                                                        marginTop: '25px',
                                                        marginBottom: '10px',
                                                        alignSelf: 'center',
                                                        marginLeft: '110px'
                                                    }}
                                                >
                                                    Pay Now
                                                </Button>
                                            </div>

                                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                                <Button
                                                    variant="contained"
                                                    onClick={CashPay}
                                                    style={{
                                                        marginTop: '25px',
                                                        marginBottom: '10px',
                                                        alignSelf: 'center',
                                                        marginLeft: '110px'
                                                    }}
                                                >
                                                    Cash / Cheque
                                                </Button>
                                            </div>
                                        </FormProvider>
                                    </Paper>
                                </MainCard>
                            </Grid>
                        </Grid>
                    </>
                </div>
            </MainCard>
            <FooterNew></FooterNew>
        </>
    );
}
