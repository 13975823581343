import React from 'react';
import { useHistory } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { changePassword } from '../../../store/actions/UserActions';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    TextField,
    Link,
    Divider,
    IconButton,
    InputAdornment,
    CircularProgress
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useForm } from 'react-hook-form';

// style constant
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    buttonSubmit: {
        height: 40,
        width: 100,
        marginRight: 10
    },
    buttonCancel: {
        height: 40,
        width: 100
    },
    dialog: {
        position: 'absolute',
        // width: 550,
        [theme.breakpoints.down('xs')]: {
            width: '80vw'
        }
    },
    dialogAction: {
        justifyContent: 'right',
        marginRight: 10,
        marginBottom: 10,
        marginTop: 10
    },
    addUserDialog: {
        width: 800,
        height: 800,
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            width: 'auto',
            height: 'auto'
        }
    },
    closeIcon: {
        color: '#FFFFFF',
        marginBottom: '-5px',
        cursor: 'pointer'
    },
    dialogTitle: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFFFFF',
        marginBottom: 20
    },
    dialogTitleChild: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

export default function ChangePassword(props) {
    const { openChangePassword, setOpenChangePassword } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const { loading } = useSelector((state) => state.login);

    const ChangePasswordSchema = Yup.object().shape({
        currentPassword: Yup.string('Enter Current Password').required('Current Password is required'),
        newPassword: Yup.string().required('Password is required'),
        confirmPassword: Yup.string('Enter Confirm Password')
            .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
            .required('Confirm Password is required')
    });

    const defaultValues = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    };

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(ChangePasswordSchema),
        defaultValues
    });

    const { handleSubmit } = methods;

    const handlePopupClose = () => {
        setOpenChangePassword(false);
    };
    const onSubmit = (data) => {
        const payload = {
            currentPassword: data?.currentPassword,
            newPassword: data?.newPassword,
            confirmPassword: data?.confirmPassword
        };
        dispatch(
            changePassword(payload, () => {
                handlePopupClose();
            })
        );
        history.push('/login');
    };

    return (
        <div>
            <Dialog open={openChangePassword} classes={{ paper: classes.dialog }}>
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.dialogTitleChild}>
                        <Typography style={{ fontSize: '20px' }}> Change Password </Typography>
                        <Link to="#" onClick={handlePopupClose}>
                            <CloseIcon className={classes.closeIcon} />
                        </Link>
                    </div>
                </DialogTitle>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RHFTextField
                                    autoFocus
                                    className={classes.loginInput}
                                    name="currentPassword"
                                    placeholder="Current Password"
                                    type={showCurrentPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} edge="end">
                                                    {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RHFTextField
                                    className={classes.loginInput}
                                    name="newPassword"
                                    placeholder="New Password"
                                    type={showNewPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RHFTextField
                                    className={classes.loginInput}
                                    name="confirmPassword"
                                    placeholder="Confirm New Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider style={{ marginTop: 15 }} />
                    <DialogActions className={classes.dialogAction}>
                        <Button variant="outlined" color="secondary" className={classes.buttonCancel} onClick={handlePopupClose}>
                            Close
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" className={classes.buttonSubmit}>
                            {loading ? <CircularProgress color="primary" size={25} /> : 'Save'}
                        </Button>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </div>
    );
}
