import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';

const changePassword = (payload, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL, 'PUT', 'users-service/users/password', payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Password incorrect'));
            });
    } catch (error) {
        console.log(error);
    }
};

export { changePassword };
