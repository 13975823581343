import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
import { useHistory } from 'react-router';
// material-ui
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, ButtonBase } from '@material-ui/core';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// style constant
const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    headerAvatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.mediumAvatar,
        transition: 'all .2s ease-in-out',
        background: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        '&:hover': {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.light
        }
    },
    boxContainer: {
        width: '228px',
        display: 'flex',

        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },
    box: {
        backgroundColor: theme.palette.primary.light,
        marginLeft: '24px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '16px'
        },
        '&:hover': {
            background: theme.palette.primary.light,
            color: theme.palette.primary.main
        }
    }
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ handleLeftDrawerToggle }) => {
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    const classes = useStyles();
    const history = useHistory();
    return (
        <React.Fragment>
            <div className={classes.boxContainer}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {loggedUserProfile?.role !== 'STUDENT' && (
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar variant="rounded" className={classes.headerAvatar} onClick={handleLeftDrawerToggle} color="inherit">
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                )}
            </div>
            <div className={classes.grow} />
            <div className={classes.grow} />
            <ProfileSection />
        </React.Fragment>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
