import { http } from '../utils/http';

export const addModuleSubscription = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/module-subscriptions', payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const addCashModuleSubscription = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/module-subscriptions/cash', payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updateModuleSubscription = (id) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'PUT', `subscriptions-service/module-subscriptions/subscriptionUpdate/${id}`,{}, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getModuleSubscriptionById = (id) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/module-subscriptions/${id}`,true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};