import React from 'react';
import { Container, Button, Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/Footer';
import Cart from './Cart';
import Wishlist from './Wishlist';
import useUserRole from '../../../hooks/useUserRole';

export default function WishlistCart() {
    const { type } = useParams();
    useUserRole({ roleList: ['STUDENT'] });
    return (
        <>
            <Header />
            <div style={{ marginTop: 40 }}>
                <Container maxWidth="lg">
                    {type === 'cart' ? <Cart /> : <Wishlist />}
                    {/* <Box style={{ marginTop: 30 }} display="flex" alignItems="center" justifyContent="left">
                        <Button variant="contained" size="large">
                            {type === 'cart' ? `Add to wishlist` : `Add to cart`}
                        </Button>
                        <Button variant="contained" color="error" size="large" style={{ marginLeft: 15 }}>
                            Delete
                        </Button>
                    </Box> */}
                </Container>
            </div>
            <Footer />
        </>
    );
}
