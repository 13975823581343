import {
    ADDRESS_START,
    ADDRESS_SUCCESS,
    ADDRESS_ERROR,
    VIEW_ALL_ADDRESS_ERROR,
    VIEW_ALL_ADDRESS_START,
    VIEW_ALL_ADDRESS_SUCCESS
} from '../../constants';

const initialState = {
    loading: false,
    allAddress: null,
    viewAddressData: null,
    message: ''
};

export default function AddressReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case ADDRESS_START:
        case VIEW_ALL_ADDRESS_START:
            return {
                ...state,
                loading: true
            };
        case ADDRESS_SUCCESS:
        case VIEW_ALL_ADDRESS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case ADDRESS_ERROR:
        case VIEW_ALL_ADDRESS_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
