import { GET_ALL_CART_START, 
    GET_ALL_CART_SUCCESS, 
    GET_ALL_CART_ERROR,
    GET_CART_START,
    GET_CART_SUCCESS,
    GET_CART_ERROR
 } from '../../constants';

const initialState = {
    loading: false,
    getAllCartData: null,
    getCartData: null,
    message: ''
};

export default function GetAllCartReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_CART_START:
        case GET_CART_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_CART_SUCCESS:
        case GET_CART_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_CART_ERROR:
        case GET_CART_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
