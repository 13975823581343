import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

export default function useUserRole({ roleList = [], redirectTo = '/404' }) {
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    const history = useHistory();

    useEffect(() => {
        if (loggedUserProfile?.role) {
            if (!roleList.includes(loggedUserProfile?.role)) {
                
                history.push(redirectTo);
            }
        }
    
    }, [loggedUserProfile?.role]);
}
