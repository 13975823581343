import { http } from '../utils/http';
import axios from 'axios';

export const bookAFreeLesson = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/book-trials', payload, false)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getAllServices = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/courses/active`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getAllBookCategories = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_BOOK}/books-service/parent-categories/active`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getSelectedParentCategories = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_BOOK}/books-service/parent-categories/active/selected`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getSelectedCourses = ( page = 1, rowsPerPage = 1000) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/courses?pageNumber=${page}&pageSize=${rowsPerPage}`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getJustAdded = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_BOOK}/books-service/books/latest`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const getTopPicks = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_BOOK}/books-service/books/top-picked`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
