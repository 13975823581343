import {
    GET_ALL_BULK_START,
    GET_ALL_BULK_SUCCESS,
    GET_ALL_BULK_ERROR,
    GET_BULK_START,
    GET_BULK_SUCCESS,
    GET_BULK_ERROR,
} from '../../constants';

const initialState = {
    loading: false,
    allBulkData: null,
    bulkData: null,
    message: ''
};

export default function GetAllBulkReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_BULK_START:
        case GET_BULK_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_BULK_SUCCESS:
        case GET_BULK_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_BULK_ERROR:
        case GET_BULK_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
