import React, { useEffect, useState } from 'react';
import Header from '../../home/components/header';
import FooterNew from '../../home/components/FooterNew';
import { useSelector } from 'react-redux';
import { Grid, Paper, Divider, OutlinedInput, Button } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import useStyles from '../../ViewTableStyles';
import { useParams } from 'react-router-dom';
import useUserRole from '../../../hooks/useUserRole';
import { getModuleSubscriptionById, updateModuleSubscription } from './../../../services/AddModuleSubscription';
import { getSubscriptionsListByProfileId } from '../../../services/subscriptions';
import ModuleSubscriptionHistoryPopUp from './viewModuleSubScriptionHistoryPopUp/index';

export default function ViewSubscriptions() {
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'STUDENT','SUPER_ADMIN'] });
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    const params = useParams();
    const [subData, setSubData] = useState(null);
    const [subscriptionHistory, setSubscriptionHistory] = useState([]);
    const [openSubHistoryPopUp, setOpenSubHistoryPopUp] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const getData = async (id) => {
        try {
            let res = await getModuleSubscriptionById(id);
           
            setSubData(res);
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdate = async (id) => {
        try {
            await updateModuleSubscription(id);
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getData(params?.id);
    }, []);

    const getModuleSubscriptionHistoryById = async (id) => {
        try {
            let res = await getSubscriptionsListByProfileId(id, page + 1, rowsPerPage);
       
            setSubscriptionHistory(res?.data);
        } catch (error) {
            console.log(error);
        }
        setOpenSubHistoryPopUp(true);
    };

    const toggleDropdown = async () => {
        getModuleSubscriptionHistoryById(subData?.userLimitedInfo.profileId);
    };
  

    return (
        <>
            {(loggedUserProfile?.role !== 'ADMIN' && loggedUserProfile?.role !== 'SUPER_ADMIN') && <Header />}
            <Paper style={{ padding: 20 }}>
                <div className={classes.titleContainer}>
                    <SchoolIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; Module Subscription Details </span>
                </div>
                <Divider style={{ margin: '30px 0px' }} />
                <Grid container rowSpacing={4}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Full Name </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.userLimitedInfo?.fullName} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Email </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.userLimitedInfo?.email} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Mobile </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.userLimitedInfo?.mobile} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Course Title </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.courseLimitedInfo?.title} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Module Title </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.moduleLimitedInfo?.title} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Start Date </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.startDate} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> End Date </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.endDate} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Discount Code </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.discountCode ? subData?.discountCode : 'N/A'} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Gift Voucher Code </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput
                                name="name"
                                disabled
                                value={subData?.giftVoucherCode ? subData?.giftVoucherCode : 'N/A'}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Module Price </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.calculatedModulePrice?.modulePrice} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> GST </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.calculatedModulePrice?.gstPrice} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Total Price </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.calculatedModulePrice?.totalPrice} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Payable Amount </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.calculatedModulePrice?.priceToBePaid} fullWidth />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Payment Mode </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput name="name" disabled value={subData?.paymentMode} fullWidth />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Subscription History</label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" color="secondary" size="small" onClick={toggleDropdown}>
                                Subscription History
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <label style={{ verticalAlign: 'sub' }}> Payment Status </label>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <OutlinedInput
                                name="paymentStatus"
                                disabled
                                value={subData?.paymentStatus ? subData?.paymentStatus : 'NA'}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {(loggedUserProfile?.role === 'ADMIN' || loggedUserProfile?.role === 'SUPER_ADMIN') &&
                                subData?.paymentStatus === 'unpaid' && <Button onClick={() => handleUpdate(subData?.id)}>Update</Button>}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            {openSubHistoryPopUp && (
                <ModuleSubscriptionHistoryPopUp
                    data={subscriptionHistory}
                    open={openSubHistoryPopUp}
                    close={() => setOpenSubHistoryPopUp(false)}
                />
            )}
            {loggedUserProfile?.role !== 'ADMIN' && <FooterNew />}
        </>
    );
}
