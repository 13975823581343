import { http } from '../utils/http';
import axios from 'axios';

export const getPlaceOrder = (id) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_ORDER}/orders-service/delivery-plans/${id}/book-detail`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const searchBooksForOrder = (searchKeyWord) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_BOOK}/books-service/books/allAvailable?searchKey=${searchKeyWord}`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const searchBooksMiniForOrder = (searchKeyWord) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_BOOK}/books-service/books/allAvailableMini?searchKey=${searchKeyWord}`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const searchBooksForWishlist = (searchKeyWord) => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_ROOT_URL_BOOK}/books-service/books/forWishlist?searchKey=${searchKeyWord}`)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const searchFocusSkill = (searchKeyWord = '', page = '1') => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/focus-skills?searchKey=${searchKeyWord}&pageNumber=${page}&pageSize=20`
            )
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const postOrderBooks = (dpid, data) => {
    console.log('api payload', data);
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', `orders-service/orders/deliveryPlans/${dpid}/books`, data, false)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
export const postWishlistBooks = (id, data) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', `orders-service/wish-lists/profiles/${id}/books`, data, false)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const updatePlaceOrderBook = (id, data) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'PUT', `orders-service/delivery-plans/${id}`, data, false)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
