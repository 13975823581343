import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
    useMediaQuery,
    Grid,
    Typography,
    Button,
    Container,
    ButtonGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio
} from '@material-ui/core';

import CardPricingStandard from './CardPricingStandard';
import Header from '../components/header';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPlansWithOptions } from '../../../store/actions/PlansWithOptionsActions';
import FooterNew from '../components/FooterNew';
import { useHistory } from 'react-router-dom';
import { Stack } from '@mui/material';
import { getModuleSubscriptions } from '../../../services/moduleSubscription';

const useStyles = makeStyles((theme) => ({
    root: {},
    cardBorderlighter: {
        border: '2px solid #2EBEE1',
        borderRadius: 12
    },
    heroContent: {
        padding: theme.spacing(5, 0, 2)
    }
}));

const Pricings = (props) => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    let profileId = Cookies.get('profileId');
    const { getAllPlansWithOptionsData } = useSelector((state) => state?.plansWithOptions);
    const newData = getAllPlansWithOptionsData;
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });

    const [isBuy, setIsBuy] = useState({
        type: '',
        typeId: '',
        planOptionId: '',
        profileId: '',
        paymentMode: '',
        amount: '',
        transactionId: ''
    });

    const [bookPlan, setBookPlan] = useState(String(''));

    const [selectedPlan, setSelectedPlan] = useState();
    const [selectedPlanOption, setSelectedPlanOption] = useState();
    const [isEnabled, setEnabled] = useState(false);

    const [selectedId, setSelectedId] = useState();

    const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [subscriptionData1, setSubscriptionData1] = useState([]);
    const [pricedata, setPrice] = useState([]);
    const GetModuleSubscriptions = async (profile_id) => {
        await getModuleSubscriptions(profile_id)
            .then((res) => {
                setPrice(res?.data?.content);
                const ids = res?.data?.content.map((item) => item?.planOptionLimitedInfo?.id);
                setSubscriptionData(ids);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    let highestPricePlan = null;

    pricedata?.forEach((plan) => {
        const planPrice = plan?.calculatedPlanPrice?.planPrice;

        if (highestPricePlan === null || planPrice > highestPricePlan?.price) {
            highestPricePlan = {
                price: planPrice,
                durationInMonths: plan?.planOptionLimitedInfo?.durationInMonths,
                bookCount: plan?.planLimitedInfo?.booksCount,
                planId: plan?.planLimitedInfo.id,
                planOptionId: plan?.planOptionLimitedInfo.id
            };
        }
    });
    useEffect(() => {
        GetModuleSubscriptions(profileId);
    }, [profileId]);

    useEffect(() => {
        const highestPrice = highestPricePlan?.price;
        const optionIds = [];
        newData?.forEach((plan) => {
            plan?.options?.forEach((option) => {
                if (option?.price <= highestPrice) {
                    optionIds.push(option?.id);
                    setSubscriptionData1(optionIds);
                }
            });
        });
    }, [newData]);
    const handleBookPlan = (data, event) => {
        const selectedValue = event?.target?.value;
        setSelectedId(selectedValue);

        const arr = data?.options?.filter((item) => {
            if (item?.id == selectedValue) {
                return item;
            }
        });

        setIsBuy({
            type: data?.name,
            typeId: data?.id,
            planOptionId: arr[0]?.id,
            profileId: profileId,
            paymentMode: 'ONLINE',
            amount: arr[0]?.price,
            transactionId: '54321'
        });

        setBookPlan((prevValue) => arr[0]?.id);

        setSelectedPlan(data);
        setSelectedPlanOption(arr[0]);
    };

    useEffect(() => {
        dispatch(getAllPlansWithOptions());
    }, []);
    const handleSubscriptionNavigation = () => {
        if (subscriptionData && subscriptionData?.length !== 0) {
            history.push({
                pathname: !Cookies.get('token') ? `/login` : `/subscriptionPlan/upgrade`,
                state: { selectedPlan, selectedPlanOption }
            });
        } else {
            history.push({
                pathname: !Cookies.get('token') ? `/login` : `/subscriptionPlan/buy`,
                state: { selectedPlan, selectedPlanOption }
            });
        }
    };

    return (
        <>
            {matchesLgUp && (
                <div className={clsx(classes.root, className)} {...rest}>
                    <Header />
                    <Container maxWidth="sm" component="main" className={classes.heroContent}>
                        <Typography
                            gutterBottom
                            style={{
                                fontSize: '35px',
                                fontWeight: 'lighter',
                                fontFamily: 'Josefin Sans',
                                textAlign: 'center',
                                color: 'textPrimary'
                            }}
                        >
                            Subscription Plans
                        </Typography>
                    </Container>
                    <ButtonGroup
                        disableElevation
                        variant="contained"
                        color="primary"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 'lighter',
                            fontFamily: 'Josefin Sans',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: 150,
                                height: 40,
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 5,
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 8,
                                color: '#fff',
                                fontFamily: 'Josefin Sans',
                                fontWeight: 'lighter'
                            }}
                        >
                            Book Library
                        </div>
                    </ButtonGroup>
                    <br />

                    <Container maxWidth="lg">
                        <Grid container spacing={isMd ? 2 : 1}>
                            {newData?.map((row) => {
                                const hasId = row?.options?.some((option) => subscriptionData.includes(option?.id));
                                const labelColor = hasId ? theme.palette.primary.main : 'black';
                                return (
                                    <Grid item md={3} data-aos="fade-up">
                                        <CardPricingStandard
                                            className={classes.cardBorderlighter}
                                            variant="outlined"
                                            withShadow
                                            liftUp
                                            priceComponent={
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.main,
                                                        fontFamily: 'Josefin Sans',
                                                        fontSize: '20px'
                                                    }}
                                                >
                                                    {`${row?.name}`}
                                                </Typography>
                                            }
                                            features={
                                                <FormControl component="fieldset" style={{ height: '270px' }}>
                                                    <FormLabel
                                                        component="legend"
                                                        className={classes.fontWeight900}
                                                        style={{
                                                            fontFamily: 'Josefin Sans',
                                                            fontSize: '20px',
                                                            color: labelColor
                                                        }}
                                                    >
                                                        {row?.booksCount === 1
                                                            ? `${row?.booksCount} BOOK / ${row?.frequency.slice(0, -2)}`
                                                            : `${row?.booksCount} BOOKS / ${row?.frequency.slice(0, -2)}`}
                                                    </FormLabel>
                                                    <br />
                                                    <Typography
                                                        component="span"
                                                        className={classes.fontWeight900}
                                                        style={{
                                                            fontFamily: 'Josefin Sans',
                                                            fontSize: '20px',
                                                            color: 'black'
                                                        }}
                                                    >
                                                        {' '}
                                                        Plans{' '}
                                                    </Typography>
                                                    <RadioGroup name="gender1" value={bookPlan} onChange={(e) => handleBookPlan(row, e)}>
                                                    {row?.options?.map((row1) => {
                                                            const isRadioDisabled = subscriptionData1.includes(row1?.id);
                                                            const isHighestPriceOption = highestPricePlan?.planOptionId === row1?.id;
                                                            return (
                                                                <FormControlLabel
                                                                    onClick={() => setEnabled(true)}
                                                                    value={String(row1?.id)}
                                                                    control={
                                                                        <Radio
                                                                            sx={{
                                                                                '&.Mui-disabled': {
                                                                                    color: isHighestPriceOption ? '#1f1f' : ''
                                                                                }
                                                                            }}
                                                                            disabled={isRadioDisabled}
                                                                        />
                                                                    }
                                                                    label={`₹ ${row1?.price} - ${row1?.type.replaceAll('_', ' ')}`}
                                                                    style={{
                                                                        color: isHighestPriceOption ? 'green' : 'black',
                                                                        '&[class*="Mui-disabled"]': { color: 'green !important' }
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </RadioGroup>
                                                </FormControl>
                                            }
                                            cta={
                                                <Button
                                                    onClick={handleSubscriptionNavigation}
                                                    color="primary"
                                                    variant="contained"
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    disabled={!row.options.some((item) => item.id === selectedId)}
                                                    style={{
                                                        boxShadow: '0 0.5em 0.5em 0.1em #68c2d9',
                                                        fontWeight: 'lighter',
                                                        fontFamily: 'Josefin Sans',
                                                        fontSize: '18px',
                                                        color: 'black'
                                                    }}
                                                >
                                                    {subscriptionData && subscriptionData?.length === 0 ? 'Buy Now' : 'Upgrade Now'}
                                                </Button>
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <br></br>
                    </Container>
                    <FooterNew></FooterNew>
                </div>
            )}

            {matchesLgDown && matchesSmUp && (
                <div className={clsx(classes.root, className)} {...rest}>
                    <Header />
                    <Container maxWidth="sm" component="main" className={classes.heroContent}>
                        <Typography
                            gutterBottom
                            style={{
                                fontSize: '30px',
                                fontWeight: 'lighter',
                                fontFamily: 'Josefin Sans',
                                textAlign: 'center',
                                color: 'textPrimary'
                            }}
                        >
                            Subscription Plans
                        </Typography>
                    </Container>
                    <ButtonGroup
                        disableElevation
                        variant="contained"
                        color="primary"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 'lighter',
                            fontFamily: 'Josefin Sans',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: 120,
                                height: 30,
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 5,
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 8,
                                color: '#fff',
                                fontFamily: 'Josefin Sans',
                                fontWeight: 'lighter'
                            }}
                        >
                            Book Library
                        </div>
                    </ButtonGroup>
                    <br />

                    <Container maxWidth="md">
                        <Grid container spacing={1}>
                            {newData?.map((row) => {
                                const hasId = row?.options?.some((option) => subscriptionData.includes(option?.id));
                                const labelColor = hasId ? theme.palette.primary.main : 'black';
                                return (
                                    <Grid item md={4} data-aos="fade-up">
                                        <CardPricingStandard
                                            className={classes.cardBorderlighter}
                                            variant="outlined"
                                            withShadow
                                            liftUp
                                            priceComponent={
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.main,
                                                        fontFamily: 'Josefin Sans',
                                                        fontSize: '18px'
                                                    }}
                                                >
                                                    {`${row?.name}`}
                                                </Typography>
                                            }
                                            features={
                                                <FormControl component="fieldset" style={{ height: '270px' }}>
                                                    <FormLabel
                                                        component="legend"
                                                        style={{
                                                            fontFamily: 'Josefin Sans',
                                                            fontSize: '18px',
                                                            color: labelColor
                                                        }}
                                                    >
                                                        {row?.booksCount === 1
                                                            ? `${row?.booksCount} BOOK / ${row?.frequency.slice(0, -2)}`
                                                            : `${row?.booksCount} BOOKS / ${row?.frequency.slice(0, -2)}`}
                                                    </FormLabel>
                                                    <br />
                                                    <Typography
                                                        component="span"
                                                        style={{
                                                            fontFamily: 'Josefin Sans',
                                                            fontSize: '18px',
                                                            color: 'black'
                                                        }}
                                                    >
                                                        {' '}
                                                        Plans{' '}
                                                    </Typography>
                                                    <RadioGroup name="gender1" value={bookPlan} onChange={(e) => handleBookPlan(row, e)}>
                                                        {row?.options?.map((row1) => {
                                                            const isRadioDisabled = subscriptionData1.includes(row1?.id);
                                                            const isHighestPriceOption = highestPricePlan?.planOptionId === row1?.id;
                                                            return (
                                                                <FormControlLabel
                                                                    onClick={() => setEnabled(true)}
                                                                    value={String(row1?.id)}
                                                                    control={
                                                                        <Radio
                                                                            sx={{
                                                                                '&.Mui-disabled': {
                                                                                    color: isHighestPriceOption ? '#1f1f' : ''
                                                                                }
                                                                            }}
                                                                            disabled={isRadioDisabled}
                                                                        />
                                                                    }
                                                                    label={`₹ ${row1?.price} - ${row1?.type.replaceAll('_', ' ')}`}
                                                                    style={{
                                                                        color: isHighestPriceOption ? 'green' : 'black',
                                                                        '&[class*="Mui-disabled"]': { color: 'green !important' }
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </RadioGroup>
                                                </FormControl>
                                            }
                                            cta={
                                                <Button
                                                    onClick={handleSubscriptionNavigation}
                                                    color="primary"
                                                    variant="contained"
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    style={{
                                                        boxShadow: '0 0.5em 0.5em 0.1em #68c2d9',
                                                        fontWeight: 'lighter',
                                                        fontFamily: 'Josefin Sans',
                                                        fontSize: '16px',
                                                        color: 'black'
                                                    }}
                                                    disabled={!row.options.some((item) => item.id === selectedId)}
                                                >
                                                    {subscriptionData && subscriptionData?.length === 0 ? 'Buy Now' : 'Upgrade Now'}
                                                </Button>
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <br></br>
                    </Container>
                    <FooterNew></FooterNew>
                </div>
            )}

            {matchesSmDown && (
                <div className={clsx(classes.root, className)} {...rest}>
                    <Header />
                    <Container maxWidth="sm" component="main" className={classes.heroContent}>
                        <Typography
                            gutterBottom
                            style={{
                                fontSize: '35px',
                                fontWeight: 'lighter',
                                fontFamily: 'Josefin Sans',
                                textAlign: 'center',
                                color: 'textPrimary'
                            }}
                        >
                            Subscription Plans
                        </Typography>
                    </Container>
                    <ButtonGroup
                        disableElevation
                        variant="contained"
                        color="primary"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 'lighter',
                            fontFamily: 'Josefin Sans',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: 150,
                                height: 40,
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 5,
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 8,
                                color: '#fff',
                                fontFamily: 'Josefin Sans',
                                fontWeight: 'lighter'
                            }}
                        >
                            Book Library
                        </div>
                    </ButtonGroup>
                    <br />

                    <Container maxWidth="sm">
                        <Grid container spacing={1}>
                            {newData?.map((row) => {
                                const hasId = row?.options?.some((option) => subscriptionData.includes(option?.id));
                                const labelColor = hasId ? theme.palette.primary.main : 'black';
                                return (
                                    <Grid item sm={3} data-aos="fade-up">
                                        <CardPricingStandard
                                            className={classes.cardBorderlighter}
                                            variant="outlined"
                                            withShadow
                                            liftUp
                                            priceComponent={
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.main,
                                                        fontFamily: 'Josefin Sans',
                                                        fontSize: '20px'
                                                    }}
                                                >
                                                    {`${row?.name}`}
                                                </Typography>
                                            }
                                            features={
                                                <FormControl component="fieldset" style={{ height: '270px' }}>
                                                    <FormLabel
                                                        component="legend"
                                                        style={{
                                                            fontFamily: 'Josefin Sans',
                                                            fontSize: '20px',
                                                            color: labelColor
                                                        }}
                                                    >
                                                        {row?.booksCount === 1
                                                            ? `${row?.booksCount} BOOK / ${row?.frequency.slice(0, -2)}`
                                                            : `${row?.booksCount} BOOKS / ${row?.frequency.slice(0, -2)}`}
                                                    </FormLabel>
                                                    <br />
                                                    <Typography
                                                        component="span"
                                                        style={{
                                                            fontFamily: 'Josefin Sans',
                                                            fontSize: '20px',
                                                            color: 'black'
                                                        }}
                                                    >
                                                        {' '}
                                                        Plans{' '}
                                                    </Typography>
                                                    <RadioGroup name="gender1" value={bookPlan} onChange={(e) => handleBookPlan(row, e)}>
                                                    {row?.options?.map((row1) => {
                                                            const isRadioDisabled = subscriptionData1.includes(row1?.id);
                                                            const isHighestPriceOption = highestPricePlan?.planOptionId === row1?.id;
                                                            return (
                                                                <FormControlLabel
                                                                    onClick={() => setEnabled(true)}
                                                                    value={String(row1?.id)}
                                                                    control={
                                                                        <Radio
                                                                            sx={{
                                                                                '&.Mui-disabled': {
                                                                                    color: isHighestPriceOption ? '#1f1f' : ''
                                                                                }
                                                                            }}
                                                                            disabled={isRadioDisabled}
                                                                        />
                                                                    }
                                                                    label={`₹ ${row1?.price} - ${row1?.type.replaceAll('_', ' ')}`}
                                                                    style={{
                                                                        color: isHighestPriceOption ? 'green' : 'black',
                                                                        '&[class*="Mui-disabled"]': { color: 'green !important' }
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </RadioGroup>
                                                </FormControl>
                                            }
                                            cta={
                                                <Button
                                                    onClick={handleSubscriptionNavigation}
                                                    color="primary"
                                                    variant="contained"
                                                    fullWidth
                                                    size="large"
                                                    type="submit"
                                                    style={{
                                                        boxShadow: '0 0.5em 0.5em 0.1em #68c2d9',
                                                        fontWeight: 'lighter',
                                                        fontFamily: 'Josefin Sans',
                                                        fontSize: '18px',
                                                        color: 'black'
                                                    }}
                                                    disabled={!row.options.some((item) => item.id === selectedId)}
                                                >
                                                    {subscriptionData && subscriptionData?.length === 0 ? 'Buy Now' : 'Upgrade Now'}
                                                </Button>
                                            }
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <br></br>
                    </Container>
                    <FooterNew></FooterNew>
                </div>
            )}
        </>
    );
};

Pricings.propTypes = {
    className: PropTypes.string
};

export default Pricings;
