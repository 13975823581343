import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Grid, Container, Card, Box } from '@mui/material';
import TestimonialCard from './TestimonialCard';

import Image1 from '../../../../assets/images/homepageOne/testimonial-image1.jpeg';
import Image2 from '../../../../assets/images/homepageOne/testimonial-image2.jpeg';
import Image3 from '../../../../assets/images/homepageOne/testimonial-image3.jpeg';
import Image4 from '../../../../assets/images/homepageOne/testimonial-image4.jpeg';
import Image5 from '../../../../assets/images/homepageOne/testimonial-image5.jpeg';
import Image6 from '../../../../assets/images/homepageOne/testimonial-image6.jpeg';
import Image7 from '../../../../assets/images/homepageOne/testimonial-image7.jpeg';

export default function TestimonialCarousel() {
    return (
        <>
            <Container maxWidth="md">
                <div className="carousel-wrapper" style={{ marginBottom: '30px' }}>
                    <Carousel
                        showThumbs={false}
                        autoPlay={true}
                        infiniteLoop
                        interval={6000}
                        showArrows={false}
                        showStatus={false}
                        // width="100%"
                    >
                        <div>
                            <TestimonialCard
                                title="Samaira"
                                user="Student of Girls High School"
                                description="Samaira really enjoyed the classes, the teachers were well organized and had a very child-friendly approach making learning very interesting and interactive. 
                                Book Library offers a wide variety of books that is helping Samaira build a healthy relationship with reading and books. She eagerly waits for her set of books. Thank you so much BookEinstein & team."
                                testImage={Image7}
                            ></TestimonialCard>
                        </div>
                        <div>
                            <TestimonialCard
                                title="Sharaya Mehta"
                                user="Mother"
                                description="It was a great learning experience, my daughter enjoyed the sessions, they were informative, Ms Dhwani is a fabulous teacher, extremely encouraging and supportive, and always motivating and positive."
                                testImage={Image6}
                            ></TestimonialCard>
                        </div>
                        <div>
                            <TestimonialCard
                                title="Ambeka"
                                user="Mother"
                                description="Bookeinstein has been a great experience for my child. They help kids think out of the box and creatively express themselves by teaching them various techniques of writing."
                                testImage={Image5}
                            ></TestimonialCard>
                        </div>
                        <div>
                            <TestimonialCard
                                title="Niyosha George Avabai"
                                user="Mother"
                                description="It has been a great run with BookEinstein. Thankyou for the wonderful sessions all these weeks of staying home. It just made it a little more meaningful for both my kids. Ivan and Natanya say a big thankyou for the wonderful memories you have created for both of them. Wish you all the very best."
                                testImage={Image2}
                            ></TestimonialCard>
                        </div>
                        <div>
                            <TestimonialCard
                                title="Taha Lakdawala"
                                user="Mother"
                                description="Taha looked forward to every session. He now feels more confident with creative writing & is able to elucidate his ideas clearly & beautifully on paper."
                                testImage={Image1}
                            ></TestimonialCard>
                        </div>
                        <div>
                            <TestimonialCard
                                title="Ira Sheth"
                                user="Mother"
                                description="My daughter Ira loves to make up stories & this was a really good class for her as she was actually able to come up with creative ways & found many other ways to improve on it!"
                                testImage={Image4}
                            ></TestimonialCard>
                        </div>
                        <div>
                            <TestimonialCard
                                title="Shaurya Nakashe"
                                user="Mother"
                                description="Shaurya looks forward to the BookEinstein Classes! Though he isn't an avid reader, the exercises planned in the module has improved his vocabulary & I see him using the words learned in the day-to-day speech as well."
                                testImage={Image3}
                            ></TestimonialCard>
                        </div>
                        <div>
                            <TestimonialCard
                                title="Adhiraj Kripesh"
                                user="Mother"
                                description="It was a great experience for us and specially Adhiraj as he could put words to his imagination after this session. He has always been a big story teller but to sit and to write even a page was a big challenge. Thanks to Bookseinstein for having these sessions and a big Thankyou to Niyosha for being so wonderful with the kids."
                                testImage={Image3}
                            ></TestimonialCard>
                        </div>
                        <div>
                            <TestimonialCard
                                title="Ayesha Advani"
                                user="Mother"
                                description="I recommend Book Einstein as I learnt a lot, found it very interesting and it was fun too!"
                                testImage={Image3}
                            ></TestimonialCard>
                        </div>
                        <div>
                            <TestimonialCard
                                title="Anushka Karnik"
                                user="Mother"
                                description="It was a very enlightening and entertaining module. My child learnt a lot effortlessly and happily did the home tasks and looked forward to the sessions. Superb experience. Keep it up. Thank you!"
                                testImage={Image3}
                            ></TestimonialCard>
                        </div>
                    </Carousel>
                </div>
            </Container>
        </>
    );
}
