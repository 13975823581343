import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Grid, Container, Card, Box, useTheme, useMediaQuery } from '@mui/material';
import publicSpeakingCardImg from '../../../../assets/images/homepageOne/publicSpeakingCardImg.png';
import storyTellingImg from '../../../../assets/images/homepageOne/story-telling1.png';
import figurativeImg from '../../../../assets/images/homepageOne/figurative1.png';
import criticalRWImg from '../../../../assets/images/homepageOne/criticalRW.png';
import creativeWritingCardImg from '../../../../assets/images/homepageOne/creativeWritingCardImg.png';
import grammarCardImg from '../../../../assets/images/homepageOne/grammerCardImg.png';
import PopularModuleCard from './PopularModuleCard';
import { makeStyles } from '@material-ui/styles';
import useStyles from '.././styles';

const useStyles1 = makeStyles((theme) => ({}));

const publicSpeakingFeaturesList = [
    'Boost confidence',
    'Enhance content & delivery while speaking',
    'Build your vocabulary, voice modulation and fluency',
    'Debates, elocution, research and presentations, JAMs, poetry recitation, stand-ups etc'
];

const creativeWritingFeaturesList = [
    'Write with clarity and purpose',
    'Create credible stories',
    'Understand characterisation and settings ',
    'Book discussion',
    'Techniques like show not tell, scaffolding etc'
];

const vocabularyBuilding = [
    'Introduction of parts of speech, spellings & punctuation',
    'New vocabulary, meaning & usage',
    'Book discussion',
    'Better construction of sentences, pronunciation, pauses while speaking'
];

const storyTelling = [
    'Discover wonderful ideas, places',
    'Introduces exciting characters, plot & setting',
    'Spark curiosity, imagination & communication skills',
    'Enhance listening & social skills'
];

const figurativeLanguage = [
    'Different figures of speech',
    'Fun guided writing sessions',
    'Enhance vocabulary & story writing skills',
    'Book discussion',
    'Improves sentence structure for composition writing'
];

const criticalReadingWriting = [
    'Reading & writing techniques',
    'Vocabulary building & book discussion',
    'Introduction to mind maps & simple graphic organizers',
    'Finding reader’s and author’s purpose, context clues',
    'Make connections - what does this text remind of?'
];

function PopularModulesCarousel() {
    const classes = useStyles();
    const theme = useTheme();
    const matchesSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <>
            {matchesLgUp && (
                <Container>
                    <div className={classes.modules}>
                        <Carousel
                            showThumbs={false}
                            autoPlay={true}
                            infiniteLoop
                            interval={6000}
                            showArrows={false}
                            showStatus={false}
                            width={'100%'}
                        >
                            <div className={classes.modulesCard}>
                                <PopularModuleCard img={publicSpeakingCardImg} listOfFeatures={publicSpeakingFeaturesList} />
                                <PopularModuleCard img={creativeWritingCardImg} listOfFeatures={creativeWritingFeaturesList} />
                                <PopularModuleCard img={grammarCardImg} listOfFeatures={vocabularyBuilding} />
                            </div>

                            <div className={classes.modulesCard}>
                                <PopularModuleCard img={storyTellingImg} listOfFeatures={storyTelling} />
                                <PopularModuleCard img={figurativeImg} listOfFeatures={figurativeLanguage} />
                                <PopularModuleCard img={criticalRWImg} listOfFeatures={criticalReadingWriting} />
                            </div>
                        </Carousel>
                    </div>
                </Container>
            )}

            {matchesLgDown && matchesSmUp && (
                <Container>
                    <div className={classes.modules}>
                        <Carousel
                            showThumbs={false}
                            autoPlay={true}
                            infiniteLoop
                            interval={6000}
                            showArrows={false}
                            showStatus={false}
                            width={'100%'}
                        >
                            <div className={classes.modulesCard}>
                                <PopularModuleCard img={publicSpeakingCardImg} listOfFeatures={publicSpeakingFeaturesList} />
                                <PopularModuleCard img={creativeWritingCardImg} listOfFeatures={creativeWritingFeaturesList} />
                            </div>

                            <div className={classes.modulesCard}>
                                <PopularModuleCard img={grammarCardImg} listOfFeatures={vocabularyBuilding} />
                                <PopularModuleCard img={storyTellingImg} listOfFeatures={storyTelling} />
                            </div>

                            <div className={classes.modulesCard}>
                                <PopularModuleCard img={figurativeImg} listOfFeatures={figurativeLanguage} />
                                <PopularModuleCard img={criticalRWImg} listOfFeatures={criticalReadingWriting} />
                            </div>
                        </Carousel>
                    </div>
                </Container>
            )}

            {matchesSmDown && (
                <Container>
                    <Carousel
                        showThumbs={false}
                        autoPlay={true}
                        infiniteLoop
                        interval={6000}
                        showArrows={false}
                        showStatus={false}
                        width={'100%'}
                    >
                        <div className={classes.modulesCard}>
                            <PopularModuleCard img={publicSpeakingCardImg} listOfFeatures={publicSpeakingFeaturesList} />
                        </div>
                        <div className={classes.modulesCard}>
                            <PopularModuleCard img={creativeWritingCardImg} listOfFeatures={creativeWritingFeaturesList} />
                        </div>
                        <div className={classes.modulesCard}>
                            <PopularModuleCard img={grammarCardImg} listOfFeatures={vocabularyBuilding} />
                        </div>
                        <div className={classes.modulesCard}>
                            <PopularModuleCard img={storyTellingImg} listOfFeatures={storyTelling} />
                        </div>
                        <div className={classes.modulesCard}>
                            <PopularModuleCard img={figurativeImg} listOfFeatures={figurativeLanguage} />
                        </div>
                        <div className={classes.modulesCard}>
                            <PopularModuleCard img={criticalRWImg} listOfFeatures={criticalReadingWriting} />
                        </div>
                    </Carousel>
                </Container>
            )}
        </>
    );
}

export default PopularModulesCarousel;
