import React from 'react';

export default function HeroSection() {
    return (
        <section className="breadcrumbs-custom">
            <div className="breadcrumbs-custom-inner">
                <div className="container">
                    <div className="breadcrumbs-custom-main m-parallax-content">
                        <svg className="breadcrumbs-custom-line" width="70" height="8" viewBox="0 0 70 8" fill="none">
                            <path
                                d="M0 1C5 1 5 7 9.92 7C14.84 7 14.92 1 19.85 1C24.78 1 24.85 7 29.77 7C34.69 7 34.77 1 39.71 1C44.65 1 44.71 7 49.63 7C54.55 7 54.63 1 59.57 1C64.51 1 64.57 7 69.5 7"
                                stroke-width="1.98"
                                stroke-miterlimit="10"
                            ></path>
                        </svg>
                        <h2 className="breadcrumbs-custom-title text-uppercase">English for Business</h2>
                        <div className="group group-sm group-middle">
                            <a
                                className="button button-sm button-icon button-icon-left button-light"
                                data-lightgallery="item"
                                href="https://www.youtube.com/watch?v=I5FlP07kdvM"
                            >
                                <span className="icon mdi mdi-play"></span>presentation
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
