import {
    ADD_USER_START,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR,
    GET_ALL_PROFILE_START,
    GET_ALL_PROFILE_SUCCESS,
    GET_ALL_PROFILE_ERROR,
    UPDATE_PROFILE_START,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    GET_ALL_USER_PROFILE_START,
    GET_ALL_USER_PROFILE_SUCCESS,
    GET_ALL_USER_PROFILE_ERROR,
    GET_USER_PROFILE_START,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_ERROR,
    GET_SEARCHED_PROFILE_START,
    GET_SEARCHED_PROFILE_SUCCESS,
    GET_SEARCHED_PROFILE_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    userInfo: null,
    getAllUserProfileData: null,
    getAllProfileData: null,
    getUserProfileData: null,
    getSearchedProfileData: [],
    message: ''
};

export default function AddUserReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case ADD_USER_START:
        case GET_ALL_PROFILE_START:
        case UPDATE_PROFILE_START:
        case GET_ALL_USER_PROFILE_START:
        case GET_USER_PROFILE_START:
        case GET_SEARCHED_PROFILE_START:
            return {
                ...state,
                loading: true
            };
        case ADD_USER_SUCCESS:
        case GET_ALL_PROFILE_SUCCESS:
        case UPDATE_PROFILE_SUCCESS:
        case GET_ALL_USER_PROFILE_SUCCESS:
        case GET_USER_PROFILE_SUCCESS:
        case GET_SEARCHED_PROFILE_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case ADD_USER_ERROR:
        case GET_ALL_PROFILE_ERROR:
        case UPDATE_PROFILE_ERROR:
        case GET_ALL_USER_PROFILE_ERROR:
        case GET_USER_PROFILE_ERROR:
        case GET_SEARCHED_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
