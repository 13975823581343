import { http } from '../../../utils/http';
import { GET_ALL_PLANS_WITH_OPTIONS_START, GET_ALL_PLANS_WITH_OPTIONS_SUCCESS, GET_ALL_PLANS_WITH_OPTIONS_ERROR } from '../../constants';
import { setMessage } from '../MessageActions';
import Cookies from 'js-cookie';

const getAllPlansWithOptionsStart = () => {
    return { type: GET_ALL_PLANS_WITH_OPTIONS_START };
};
const getAllPlansWithOptionsSuccess = (payload) => {
    return { type: GET_ALL_PLANS_WITH_OPTIONS_SUCCESS, payload };
};
const getAllPlansWithOptionsError = (error) => {
    return { type: GET_ALL_PLANS_WITH_OPTIONS_ERROR, error };
};

const token = Cookies.get('token');

const getAllPlansWithOptions = () => (dispatch) => {

    dispatch(getAllPlansWithOptionsStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/plans/options`, {}, token ? true : false, {}, {})
            .then((res) => {
                const payload = {
                    getAllPlansWithOptionsData: res
                };
                dispatch(getAllPlansWithOptionsSuccess(payload));
            })
            .catch((err) => {
                dispatch(getAllPlansWithOptionsError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getAllPlansWithOptionsError('Something went wrong'));
    }
};

export { getAllPlansWithOptions };
