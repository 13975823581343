import { http } from '../utils/http';

export const referAFriend = (payload) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL, 'POST', 'users-service/referrals', payload, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
