import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSchool, getSchool } from '../../store/actions/SchoolActions';
import { useHistory } from 'react-router-dom';
import { withStyles, useTheme } from '@material-ui/styles';
import {
    IconButton,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    OutlinedInput,
    InputAdornment,
    Tooltip
} from '@material-ui/core';
import { IconSearch } from '@tabler/icons';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SchoolIcon from '@material-ui/icons/School';
import useStyles from '../ViewTableStyles';
import MainCard from '../../ui-component/cards/MainCard';
import useUserRole from '../../hooks/useUserRole';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

export default function AllSchool() {
    const classes = useStyles();
    useUserRole({ roleList: ['ADMIN', 'SCHOOL_ADMIN','SUPER_ADMIN'] });
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [page, setPage] = useState(0);

    const [value, setValue] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const { getAllSchoolData } = useSelector((state) => state?.school);
    const newData = getAllSchoolData?.content;

    const totalCount = getAllSchoolData?.totalElements;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
    };

    const handleViewStock = (id) => {
        history.push(`/admin/view-stock/${id}`);
    };

    useEffect(() => {
        dispatch(getAllSchool(rowsPerPage, value ? value : '', page + 1));
    }, [rowsPerPage, value, page]);

    return (
        <>
            <MainCard>
                <div className={classes.titleContainer}>
                    <SchoolIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; Stock All Schools </span>
                </div>
                <Paper elevation={5} className={classes.root}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <OutlinedInput
                            className={classes.searchControl}
                            id="input-search-header"
                            //value={value}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Search"
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconSearch stroke={1.5} size="1rem" className={classes.startAdornment} />
                                </InputAdornment>
                            }
                        />
                    </div>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.tableHeaderCell}>
                                <StyledTableRow>
                                    <TableCell> Name </TableCell>
                                    <TableCell> Branch </TableCell>
                                    <TableCell> School Address </TableCell>
                                    <TableCell> PinCode </TableCell>
                                    <TableCell align="center"> Status </TableCell>
                                    <TableCell align="right"> Action </TableCell>
                                </StyledTableRow>
                            </TableHead>
                            {!newData ? (
                                <caption style={{ textAlign: 'center' }}>Sorry! No data available</caption>
                            ) : (
                                <TableBody>
                                    {newData?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <TableCell> {row?.name || '-'} </TableCell>
                                            <TableCell> {row?.branch} </TableCell>
                                            <TableCell> {row?.address || '-'} </TableCell>
                                            <TableCell> {row?.pincode} </TableCell>
                                            <TableCell align="center">
                                                <Typography
                                                    className={classes.status}
                                                    style={{
                                                        backgroundColor:
                                                            (row?.status === 'ACTIVE' && theme.palette.success.main) ||
                                                            (row?.status === 'INACTIVE' && theme.palette.warning.dark)
                                                    }}
                                                >
                                                    {row?.status}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="View Stock">
                                                    <IconButton onClick={() => handleViewStock(row.id)}>
                                                        <VisibilityIcon color="primary" fontSize="small" style={{ cursor: 'pointer' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={totalCount || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-displayedRows': {
                                marginBottom: 0
                            },
                            '.MuiTablePagination-selectLabel': {
                                marginBottom: 0
                            }
                        }}
                    />
                </Paper>
            </MainCard>
        </>
    );
}
