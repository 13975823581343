import React from 'react';

export default function BodySection() {
    return (
        <section className="section">
            <div className="tabs-custom tabs-complex" id="tabs-1">
                <button className="tabs-complex-nav-toggle" data-multitoggle="#tabs-complex-nav" data-isolate="#tabs-complex-nav">
                    <span>Navigation</span>
                    <span className="icon mdi mdi-chevron-down"></span>
                </button>

                <ul className="nav nav-tabs" id="tabs-complex-nav">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active" href="#tabs-1-1" data-toggle="tab">
                            <span className="icon mdi mdi-information-outline"></span>
                            <span className="font-weight-medium">Information</span>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" href="#tabs-1-2" data-toggle="tab">
                            <span className="icon mdi mdi-map"></span>
                            <span className="font-weight-medium">Course plan</span>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" href="#tabs-1-3" data-toggle="tab">
                            <span className="icon mdi mdi-map-marker"></span>
                            <span className="font-weight-medium">Location</span>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" href="#tabs-1-4" data-toggle="tab">
                            <span className="icon mdi mdi-image-filter"></span>
                            <span className="font-weight-medium">Gallery</span>
                        </a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" href="#tabs-1-5" data-toggle="tab">
                            <span className="icon mdi mdi-comment-outline"></span>
                            <span>Reviews</span>
                        </a>
                    </li>
                </ul>

                <div className="tab-content">
                    <div className="tab-pane fade show active" id="tabs-1-1">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-8">
                                    <article className="post-info inset-1">
                                        <h2 className="text-uppercase font-weight-lighter post-info-title">English for Business</h2>
                                        <div className="post-info-details">
                                            <p className="post-info-price">$35</p>
                                            <p className="big">per lesson</p>
                                        </div>
                                        <div className="post-info-meta">
                                            <ul className="post-info-meta-list">
                                                <li>
                                                    <span className="icon mdi mdi-clock"></span>
                                                    <span>90 min</span>
                                                </li>
                                                <li>
                                                    <span className="icon mdi mdi-star-outline"></span>
                                                    <span>Sam Johnson</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <p>
                                            Pellentesque quis dui varius, dapibus velit id, iaculis ipsum. Morbi ac eros feugiat, lacinia
                                            elit ut elementum turpis. Curabitur justo sapien, tempus sit amet rutrum eu, commodo eu lacus.
                                            Morbi in ligula nibh. Maecenas ut mi at odio hendrerit eleifend tempor vitae augue. Fusce eget
                                            arcu et nibh dapibus maximus consectetur in est.
                                        </p>
                                        <table className="post-info-table">
                                            <tr>
                                                <td>Location</td>
                                                <td>Room 5</td>
                                            </tr>
                                            <tr>
                                                <td>Teacher</td>
                                                <td>Sam Johnson</td>
                                            </tr>
                                            <tr>
                                                <td>Time</td>
                                                <td>6:30 PM</td>
                                            </tr>
                                        </table>
                                        <h2 className="text-uppercase font-weight-lighter mt-md-30 mt-lg-50 mt-xl-80 offset-top-40">
                                            Gallery
                                        </h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere metus et tortor pulvinar
                                            venenatis. Aliquam erat volutpat. Nam ultrices semper felis
                                        </p>
                                        <div className="row row-6 row-x-6" data-lightgallery="group">
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-1-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-1-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-2-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-2-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-3-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-3-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-lg-4">
                                    <article className="box-4">
                                        <div className="box-4-inner">
                                            <h3>Sign up for a className</h3>

                                            <form
                                                className="rd-form rd-mailform form-lg"
                                                data-form-output="form-output-global"
                                                data-form-type="contact"
                                                method="post"
                                                action="bat/rd-mailform.php"
                                            >
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-1-title">
                                                        Full Name
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-1-title"
                                                        type="text"
                                                        name="name"
                                                        data-constraints="@Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-border-color"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-1-date">
                                                        Date
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-1-date"
                                                        type="text"
                                                        name="date"
                                                        data-constraints="@Required"
                                                        data-time-picker="date"
                                                    />
                                                    <div className="icon form-icon mdi mdi-calendar-text"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label" for="form-1-email">
                                                        E-mail
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-1-email"
                                                        type="email"
                                                        name="email"
                                                        data-constraints="@Email @Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-email-outline"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <input
                                                        className="form-input"
                                                        id="form-1-phone"
                                                        type="text"
                                                        name="phone"
                                                        data-constraints="@PhoneNumber"
                                                    />
                                                    <label className="form-label" for="form-1-phone">
                                                        Phone
                                                    </label>
                                                    <div className="icon form-icon mdi mdi-phone"></div>
                                                </div>
                                                <div className="form-wrap mt-xl-55">
                                                    <button className="button button-lg button-primary button-block" type="submit">
                                                        send request
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tabs-1-2">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-8">
                                    <div className="inset-1">
                                        <div
                                            className="card-group-custom card-group-timeline"
                                            id="accordion1"
                                            role="tablist"
                                            aria-multiselectable="false"
                                        >
                                            <article className="card card-custom card-timeline">
                                                <div className="card-header" role="tab">
                                                    <div className="card-title">
                                                        <a
                                                            className="card-link"
                                                            id="accordion1-card-head-amtggsyd"
                                                            data-toggle="collapse"
                                                            data-parent="#accordion1"
                                                            href="#accordion1-card-body-iyaphjiu"
                                                            aria-controls="accordion1-card-body-iyaphjiu"
                                                            aria-expanded="true"
                                                            role="button"
                                                        >
                                                            Module 1: Management
                                                            <div className="card-arrow"></div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    className="collapse show"
                                                    id="accordion1-card-body-iyaphjiu"
                                                    aria-labelledby="accordion1-card-head-amtggsyd"
                                                    data-parent="#accordion1"
                                                    role="tabpanel"
                                                >
                                                    <div className="card-body">
                                                        <article className="box-float-1">
                                                            <img src="images/single-tour-10-162x119.jpg" alt="" width="162" height="119" />
                                                            <p>
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                                tempor incididunt ut labore et dolore magna aliqua. Velit ut tortor pretium
                                                                viverra. Et tortor consequat id porta.
                                                            </p>
                                                        </article>
                                                        <div className="row row-10">
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Ut tellus elementum</li>
                                                                    <li>Sagittis vitae et</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Congue quisque egestas</li>
                                                                    <li>Diam in arcu cursus</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>

                                            <article className="card card-custom card-timeline">
                                                <div className="card-header" role="tab">
                                                    <div className="card-title">
                                                        <a
                                                            className="card-link collapsed"
                                                            id="accordion1-card-head-ypvmpuro"
                                                            data-toggle="collapse"
                                                            data-parent="#accordion1"
                                                            href="#accordion1-card-body-uhmkxafo"
                                                            aria-controls="accordion1-card-body-uhmkxafo"
                                                            aria-expanded="false"
                                                            role="button"
                                                        >
                                                            Module 2: Production
                                                            <div className="card-arrow"></div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    className="collapse"
                                                    id="accordion1-card-body-uhmkxafo"
                                                    aria-labelledby="accordion1-card-head-ypvmpuro"
                                                    data-parent="#accordion1"
                                                    role="tabpanel"
                                                >
                                                    <div className="card-body">
                                                        <article className="box-float-1">
                                                            <img src="images/single-tour-11-162x119.jpg" alt="" width="162" height="119" />
                                                            <p>
                                                                Urna cursus eget nunc scelerisque viverra mauris. Egestas maecenas pharetra
                                                                convallis posuere. Rhoncus est pellentesque elit ullamcorper dignissim cras
                                                                tincidunt lobortis feugiat.
                                                            </p>
                                                        </article>
                                                        <div className="row row-10">
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Sed id semper</li>
                                                                    <li>Risus in hendrerit</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Pharetra diam sit amet nisl</li>
                                                                    <li>Tempus imperdiet nulla</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>

                                            <article className="card card-custom card-timeline">
                                                <div className="card-header" role="tab">
                                                    <div className="card-title">
                                                        <a
                                                            className="card-link collapsed"
                                                            id="accordion1-card-head-kxjplurk"
                                                            data-toggle="collapse"
                                                            data-parent="#accordion1"
                                                            href="#accordion1-card-body-igihdafv"
                                                            aria-controls="accordion1-card-body-igihdafv"
                                                            aria-expanded="false"
                                                            role="button"
                                                        >
                                                            Module 3: Marketing
                                                            <div className="card-arrow"></div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    className="collapse"
                                                    id="accordion1-card-body-igihdafv"
                                                    aria-labelledby="accordion1-card-head-kxjplurk"
                                                    data-parent="#accordion1"
                                                    role="tabpanel"
                                                >
                                                    <div className="card-body">
                                                        <article className="box-float-1">
                                                            <img src="images/single-tour-12-162x119.jpg" alt="" width="162" height="119" />
                                                            <p>
                                                                Sed euismod nisi porta lorem mollis aliquam ut porttitor leo. Volutpat
                                                                consequat mauris nunc congue nisi vitae suscipit. At imperdiet dui accumsan
                                                                sit amet nulla facilisi.
                                                            </p>
                                                        </article>
                                                        <div className="row row-10">
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Eu augue ut lectu</li>
                                                                    <li>Mauris nunc congue nisi vitae</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Dignissim suspendisse</li>
                                                                    <li>Fringilla urna porttitor</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>

                                            <article className="card card-custom card-timeline">
                                                <div className="card-header" role="tab">
                                                    <div className="card-title">
                                                        <a
                                                            className="card-link collapsed"
                                                            id="accordion1-card-head-oadvntlt"
                                                            data-toggle="collapse"
                                                            data-parent="#accordion1"
                                                            href="#accordion1-card-body-jvicijud"
                                                            aria-controls="accordion1-card-body-jvicijud"
                                                            aria-expanded="false"
                                                            role="button"
                                                        >
                                                            Module 4: Finance
                                                            <div className="card-arrow"></div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    className="collapse"
                                                    id="accordion1-card-body-jvicijud"
                                                    aria-labelledby="accordion1-card-head-oadvntlt"
                                                    data-parent="#accordion1"
                                                    role="tabpanel"
                                                >
                                                    <div className="card-body">
                                                        <article className="box-float-1">
                                                            <img src="images/single-tour-13-162x119.jpg" alt="" width="162" height="119" />
                                                            <p>
                                                                Ut placerat orci nulla pellentesque. Elementum integer enim neque volutpat
                                                                ac tincidunt. Elementum nibh tellus molestie nunc non blandit massa. Leo in
                                                                vitae turpis massa. Tincidunt augue interdum velit euismod in pellentesque
                                                                massa placerat.
                                                            </p>
                                                        </article>
                                                        <div className="row row-10">
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Volutpat commodo sed</li>
                                                                    <li>Purus gravida quis blandit</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Pulvinar etiam non</li>
                                                                    <li>Nunc mattis enim</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>

                                            <article className="card card-custom card-timeline">
                                                <div className="card-header" role="tab">
                                                    <div className="card-title">
                                                        <a
                                                            className="card-link collapsed"
                                                            id="accordion1-card-head-atyelkgf"
                                                            data-toggle="collapse"
                                                            data-parent="#accordion1"
                                                            href="#accordion1-card-body-qepegelr"
                                                            aria-controls="accordion1-card-body-qepegelr"
                                                            aria-expanded="false"
                                                            role="button"
                                                        >
                                                            Module 5: Economics
                                                            <div className="card-arrow"></div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div
                                                    className="collapse"
                                                    id="accordion1-card-body-qepegelr"
                                                    aria-labelledby="accordion1-card-head-atyelkgf"
                                                    data-parent="#accordion1"
                                                    role="tabpanel"
                                                >
                                                    <div className="card-body">
                                                        <article className="box-float-1">
                                                            <img src="images/single-tour-14-162x119.jpg" alt="" width="162" height="119" />
                                                            <p>
                                                                Donec adipiscing tristique risus nec. Curabitur gravida arcu ac tortor
                                                                dignissim convallis. At elementum eu facilisis sed. Placerat vestibulum
                                                                lectus mauris ultrices eros.
                                                            </p>
                                                        </article>
                                                        <div className="row row-10">
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Consectetur purus ut</li>
                                                                    <li>Eget aliquet nibh praesent</li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <ul className="list-marked">
                                                                    <li>Tempor orci eu lobortis</li>
                                                                    <li>Mauris rhoncus aenean vel</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <article className="box-4">
                                        <div className="box-4-inner">
                                            <h3>Sign up for a className</h3>

                                            <form
                                                className="rd-form rd-mailform form-lg"
                                                data-form-output="form-output-global"
                                                data-form-type="contact"
                                                method="post"
                                                action="bat/rd-mailform.php"
                                            >
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-2-title">
                                                        Full Name
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-2-title"
                                                        type="text"
                                                        name="name"
                                                        data-constraints="@Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-border-color"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-2-date">
                                                        Date
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-2-date"
                                                        type="text"
                                                        name="date"
                                                        data-constraints="@Required"
                                                        data-time-picker="date"
                                                    />
                                                    <div className="icon form-icon mdi mdi-calendar-text"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label" for="form-2-email">
                                                        E-mail
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-2-email"
                                                        type="email"
                                                        name="email"
                                                        data-constraints="@Email @Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-email-outline"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <input
                                                        className="form-input"
                                                        id="form-2-phone"
                                                        type="text"
                                                        name="phone"
                                                        data-constraints="@PhoneNumber"
                                                    />
                                                    <label className="form-label" for="form-2-phone">
                                                        Phone
                                                    </label>
                                                    <div className="icon form-icon mdi mdi-phone"></div>
                                                </div>
                                                <div className="form-wrap mt-xl-55">
                                                    <button className="button button-lg button-primary button-block" type="submit">
                                                        send request
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tabs-1-3">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-8">
                                    <div className="inset-1">
                                        <div
                                            className="google-map-container"
                                            data-center="9870 St Vincent Place, Glasgow, DC 45 Fr 45."
                                            data-zoom="4"
                                            data-styles='[{"featureType":"landscape.man_made","elementType":"geometry","stylers":[{"color":"#f7f1df"}]},{"featureType":"landscape.natural","elementType":"geometry","stylers":[{"color":"#d0e3b4"}]},{"featureType":"landscape.natural.terrain","elementType":"geometry","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.medical","elementType":"geometry","stylers":[{"color":"#fbd3da"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#bde6ab"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffe15f"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#efd151"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"color":"black"}]},{"featureType":"transit.station.airport","elementType":"geometry.fill","stylers":[{"color":"#cfb2db"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#a2daf2"}]}]'
                                            data-icon="images/gmap_marker.png"
                                            data-icon-active="images/gmap_marker_active.png"
                                        >
                                            <div className="google-map"></div>
                                            <ul className="google-map-markers">
                                                <li
                                                    data-location="9870 St Vincent Place, Glasgow, DC 45 Fr 45."
                                                    data-description="9870 St Vincent Place, Glasgow"
                                                ></li>
                                            </ul>
                                        </div>
                                        <h3 className="mt-md-35">Location</h3>
                                        <p>
                                            Pellentesque quis dui varius, dapibus velit id, iaculis ipsum. Morbi ac eros feugiat, lacinia
                                            elit ut elementum turpis. Curabitur justo sapien, tempus sit amet rutrum eu, commodo eu lacus.
                                            Morbi in ligula nibh. Maecenas ut mi at odio hendrerit eleifend tempor vitae augue. Fusce eget
                                            arcu et nibh dapibus maximus consectetur in est.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <article className="box-4">
                                        <div className="box-4-inner">
                                            <h3>Sign up for a className</h3>

                                            <form
                                                className="rd-form rd-mailform form-lg"
                                                data-form-output="form-output-global"
                                                data-form-type="contact"
                                                method="post"
                                                action="bat/rd-mailform.php"
                                            >
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-3-title">
                                                        Full Name
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-3-title"
                                                        type="text"
                                                        name="name"
                                                        data-constraints="@Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-border-color"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-3-date">
                                                        Date
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-3-date"
                                                        type="text"
                                                        name="date"
                                                        data-constraints="@Required"
                                                        data-time-picker="date"
                                                    />
                                                    <div className="icon form-icon mdi mdi-calendar-text"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label" for="form-3-email">
                                                        E-mail
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-3-email"
                                                        type="email"
                                                        name="email"
                                                        data-constraints="@Email @Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-email-outline"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <input
                                                        className="form-input"
                                                        id="form-3-phone"
                                                        type="text"
                                                        name="phone"
                                                        data-constraints="@PhoneNumber"
                                                    />
                                                    <label className="form-label" for="form-3-phone">
                                                        Phone
                                                    </label>
                                                    <div className="icon form-icon mdi mdi-phone"></div>
                                                </div>
                                                <div className="form-wrap mt-xl-55">
                                                    <button className="button button-lg button-primary button-block" type="submit">
                                                        send request
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tabs-1-4">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-8">
                                    <div className="inset-1">
                                        <h2 className="text-uppercase font-weight-lighter">Gallery</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere metus et tortor pulvinar
                                            venenatis. Aliquam erat volutpat. Nam ultrices semper felis, at laoreet metus
                                        </p>
                                        <div className="row row-6 row-x-6" data-lightgallery="group">
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-4-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-4-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-7-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-7-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-9-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-9-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-2-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-2-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-5-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-5-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-6-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-6-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-1-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-1-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-8-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-8-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a
                                                    className="thumbnail-light"
                                                    href="images/single-tour-3-original.jpg"
                                                    data-lightgallery="item"
                                                >
                                                    <img
                                                        className="thumbnail-light-image"
                                                        src="images/single-tour-3-355x359.jpg"
                                                        alt=""
                                                        width="355"
                                                        height="359"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <article className="box-4">
                                        <div className="box-4-inner">
                                            <h3>Sign up for a className</h3>

                                            <form
                                                className="rd-form rd-mailform form-lg"
                                                data-form-output="form-output-global"
                                                data-form-type="contact"
                                                method="post"
                                                action="bat/rd-mailform.php"
                                            >
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-4-title">
                                                        Full Name
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-4-title"
                                                        type="text"
                                                        name="name"
                                                        data-constraints="@Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-border-color"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-4-date">
                                                        Date
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-4-date"
                                                        type="text"
                                                        name="date"
                                                        data-constraints="@Required"
                                                        data-time-picker="date"
                                                    />
                                                    <div className="icon form-icon mdi mdi-calendar-text"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label" for="form-4-email">
                                                        E-mail
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-4-email"
                                                        type="email"
                                                        name="email"
                                                        data-constraints="@Email @Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-email-outline"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <input
                                                        className="form-input"
                                                        id="form-4-phone"
                                                        type="text"
                                                        name="phone"
                                                        data-constraints="@PhoneNumber"
                                                    />
                                                    <label className="form-label" for="form-4-phone">
                                                        Phone
                                                    </label>
                                                    <div className="icon form-icon mdi mdi-phone"></div>
                                                </div>
                                                <div className="form-wrap mt-xl-55">
                                                    <button className="button button-lg button-primary button-block" type="submit">
                                                        send request
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="tabs-1-5">
                        <div className="container">
                            <div className="row row-50">
                                <div className="col-lg-8">
                                    <div className="inset-1">
                                        <article className="box-5">
                                            <div className="box-5-aside">
                                                <p className="box-5-rating">9,5</p>
                                                <h5 className="box-5-title">Superb</h5>
                                            </div>
                                            <div className="box-5-main">
                                                <ul className="box-5-list">
                                                    <li>
                                                        <article className="progress-linear tab-progress-bar" data-formatter="rating">
                                                            <div className="progress-header">
                                                                <p className="progress-title">Content</p>
                                                            </div>
                                                            <div className="progress-bar-linear-wrap">
                                                                <div className="progress-bar-linear">
                                                                    <span className="progress-value">57</span>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </li>
                                                    <li>
                                                        <article className="progress-linear tab-progress-bar" data-formatter="rating">
                                                            <div className="progress-header">
                                                                <p className="progress-title">Educational Resources</p>
                                                            </div>
                                                            <div className="progress-bar-linear-wrap">
                                                                <div className="progress-bar-linear">
                                                                    <span className="progress-value">78</span>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </li>
                                                    <li>
                                                        <article className="progress-linear tab-progress-bar" data-formatter="rating">
                                                            <div className="progress-header">
                                                                <p className="progress-title">Received Knowledge</p>
                                                            </div>
                                                            <div className="progress-bar-linear-wrap">
                                                                <div className="progress-bar-linear">
                                                                    <span className="progress-value">68</span>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </li>
                                                    <li>
                                                        <article className="progress-linear tab-progress-bar" data-formatter="rating">
                                                            <div className="progress-header">
                                                                <p className="progress-title">Results</p>
                                                            </div>
                                                            <div className="progress-bar-linear-wrap">
                                                                <div className="progress-bar-linear">
                                                                    <span className="progress-value">81</span>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </li>
                                                    <li>
                                                        <article className="progress-linear tab-progress-bar" data-formatter="rating">
                                                            <div className="progress-header">
                                                                <p className="progress-title">Value for Money</p>
                                                            </div>
                                                            <div className="progress-bar-linear-wrap">
                                                                <div className="progress-bar-linear">
                                                                    <span className="progress-value">72</span>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </li>
                                                </ul>
                                            </div>
                                        </article>
                                        <div className="comment-review-group">
                                            <article className="comment-review">
                                                <img
                                                    className="comment-review-avatar"
                                                    src="images/single-tour-15-86x86.jpg"
                                                    alt=""
                                                    width="86"
                                                    height="86"
                                                />
                                                <div className="comment-review-main">
                                                    <p className="heading-5 comment-review-name">Jane Smith</p>
                                                    <time datetime="2019">October 5, 2019 at 1:44 pm</time>
                                                    <div className="comment-review-text">
                                                        <p>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                                            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                            Duis aute irure dolor in reprehenderit in voluptate
                                                        </p>
                                                    </div>
                                                    <ul className="comment-review-meta review-meta">
                                                        <li>
                                                            <p>Overall</p>
                                                            <ul className="comment-review-rating review-rating">
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <p>Knowledge</p>
                                                            <ul className="comment-review-rating review-rating">
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <p>Resources</p>
                                                            <ul className="comment-review-rating review-rating">
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <p>Content</p>
                                                            <ul className="comment-review-rating review-rating">
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </article>
                                            <article className="comment-review">
                                                <img
                                                    className="comment-review-avatar"
                                                    src="images/single-tour-16-86x86.jpg"
                                                    alt=""
                                                    width="86"
                                                    height="86"
                                                />
                                                <div className="comment-review-main">
                                                    <p className="heading-5 comment-review-name">Peter Wilson</p>
                                                    <time datetime="2019">October 5, 2019 at 2:15 pm</time>
                                                    <div className="comment-review-text">
                                                        <p>
                                                            Silva, impositio, et eleates. Contencios experimentum, tanquam fatalis zelus.
                                                            Bromiums cantare, tanquam varius eleates. Competition ires, tanquam bi-color
                                                            adiurator. Eleates, devatio, et caesium. Zelus velums, tanquam salvus aonides.
                                                            Bubos sunt eposs de secundus sectam.
                                                        </p>
                                                    </div>
                                                    <ul className="comment-review-meta review-meta">
                                                        <li>
                                                            <p>Overall</p>
                                                            <ul className="comment-review-rating review-rating">
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <p>Knowledge</p>
                                                            <ul className="comment-review-rating review-rating">
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <p>Resources</p>
                                                            <ul className="comment-review-rating review-rating">
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <p>Content</p>
                                                            <ul className="comment-review-rating review-rating">
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star text-primary"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                                <li className="icon mdi mdi-star"></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </article>
                                        </div>
                                        <h3 className="mt-40 mt-md-45 mt-xl-70">Write a Review</h3>
                                        <article className="box-6">
                                            <ul className="comment-review-meta review-meta">
                                                <li>
                                                    <p>Overall</p>
                                                    <ul className="comment-review-rating review-rating">
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star"></li>
                                                        <li className="icon mdi mdi-star"></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p>Knowledge</p>
                                                    <ul className="comment-review-rating review-rating">
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star"></li>
                                                        <li className="icon mdi mdi-star"></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p>Resources</p>
                                                    <ul className="comment-review-rating review-rating">
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star"></li>
                                                        <li className="icon mdi mdi-star"></li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <p>Content</p>
                                                    <ul className="comment-review-rating review-rating">
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star text-primary"></li>
                                                        <li className="icon mdi mdi-star"></li>
                                                        <li className="icon mdi mdi-star"></li>
                                                    </ul>
                                                </li>
                                            </ul>

                                            <form
                                                className="rd-form rd-mailform form-lg"
                                                data-form-output="form-output-global"
                                                data-form-type="contact"
                                                method="post"
                                                action="bat/rd-mailform.php"
                                            >
                                                <div className="row row-30">
                                                    <div className="col-md-6">
                                                        <div className="form-wrap form-wrap-icon">
                                                            <input
                                                                className="form-input"
                                                                id="form-name"
                                                                type="text"
                                                                name="name"
                                                                data-constraints="@Required"
                                                            />
                                                            <label className="form-label" for="form-name">
                                                                Name
                                                            </label>
                                                            <div className="icon form-icon mdi mdi-account-outline text-primary"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-wrap form-wrap-icon">
                                                            <input
                                                                className="form-input"
                                                                id="form-email"
                                                                type="email"
                                                                name="email"
                                                                data-constraints="@Email @Required"
                                                            />
                                                            <label className="form-label" for="form-email">
                                                                E-mail
                                                            </label>
                                                            <div className="icon form-icon mdi mdi-email-outline text-primary"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-wrap form-wrap-icon">
                                                            <label className="form-label" for="form-review">
                                                                Review
                                                            </label>
                                                            <textarea
                                                                className="form-input"
                                                                id="form-review"
                                                                name="review"
                                                                data-constraints="@Required"
                                                            ></textarea>
                                                            <div className="icon form-icon mdi mdi-message-outline text-primary"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-wrap form-wrap-button">
                                                    <button className="button button-lg button-primary" type="submit">
                                                        Send
                                                    </button>
                                                </div>
                                            </form>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <article className="box-4">
                                        <div className="box-4-inner">
                                            <h3>Sign up for a className</h3>

                                            <form
                                                className="rd-form rd-mailform form-lg"
                                                data-form-output="form-output-global"
                                                data-form-type="contact"
                                                method="post"
                                                action="bat/rd-mailform.php"
                                            >
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-5-title">
                                                        Full Name
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-5-title"
                                                        type="text"
                                                        name="name"
                                                        data-constraints="@Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-border-color"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label form-label-outside" for="form-5-date">
                                                        Date
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-5-date"
                                                        type="text"
                                                        name="date"
                                                        data-constraints="@Required"
                                                        data-time-picker="date"
                                                    />
                                                    <div className="icon form-icon mdi mdi-calendar-text"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <label className="form-label" for="form-5-email">
                                                        E-mail
                                                    </label>
                                                    <input
                                                        className="form-input"
                                                        id="form-5-email"
                                                        type="email"
                                                        name="email"
                                                        data-constraints="@Email @Required"
                                                    />
                                                    <div className="icon form-icon mdi mdi-email-outline"></div>
                                                </div>
                                                <div className="form-wrap form-wrap-icon">
                                                    <input
                                                        className="form-input"
                                                        id="form-5-phone"
                                                        type="text"
                                                        name="phone"
                                                        data-constraints="@PhoneNumber"
                                                    />
                                                    <label className="form-label" for="form-5-phone">
                                                        Phone
                                                    </label>
                                                    <div className="icon form-icon mdi mdi-phone"></div>
                                                </div>
                                                <div className="form-wrap mt-xl-55">
                                                    <button className="button button-lg button-primary button-block" type="submit">
                                                        send request
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
