import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    root: {
        "& .MuiInputBase-root": {
            fontFamily: "Josefin Sans",
        }
    },
    childWithLaptop: {
        paddingRight: '5%',
        width: '100%',
        height: 'auto',
        paddingBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            // marginRight: '5%',
            justifyContent: 'center'
        }
    },
    homeForm: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    testimonialCarousel: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }
    },
    coursesCard: {
        display: 'flex',
        // width: '40%',
        // height: '20%',

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '20px'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    serviceCard: {
        display: 'flex',

        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '20px'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    whyChooseUs: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    teacherTestimonial: {
        [theme.breakpoints.down('sm')]: { width: 'auto', justifyContent: 'center' }
    },
    freeLesson: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    bookNow: {
        backgroundColor: '#2EBEE1',
        width: '100%',
        maxWidth: 950,
        borderRadius: '8px',
        top: '-75px',
        padding: '0 0px 16px 0px',
        paddingRight: 15,
        marginLeft: 5,

        [theme.breakpoints.down('sm')]: {
            padding: ' 10% 0%',
            paddingRight: 15,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%%',
            margin: '0px 37px',
            marginTop: '-20px'
        }
    },
    bookForm: { alignItems: 'center', justifyContent: 'center' },

    heroContentContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        paddingTop: '15%',
        lineHeight: '1.1',
        paddingLeft: '23%',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            marginRight: '5%',
            justifyContent: 'center'
        },
        [theme.breakpoints.down('md')]: {
            paddingBottom: '50px'
        },
        [theme.breakpoints.up('md')]: {
            paddingBottom: '50px'
        }
    },

    container: {
        height: '100vh', width: '100vw',
        [theme.breakpoints.down('387')]: {
            fontSize: '20px',
            height: '100vh', width: '60vw'
        },
        [theme.breakpoints.down('sm')]: {
            height: '100vh', width: '130vw',
        },
        [theme.breakpoints.down('md')]: {
            height: '100%', width: '130vw',
        }
    },

    modules: {
        marginBottom: '40px',
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter',
        marginLeft: '100px',
        [theme.breakpoints.down('sm')]: { marginBottom: '10px', display: 'flex', flexDirection: 'column', marginLeft: '10px' },
        [theme.breakpoints.down('md')]: { marginBottom: '10px', marginLeft: '10px' }
    },
    modulesCard: {
        display: 'flex',
        gap: '1%',
        justifyContent: 'center',
        paddingBottom: '80px',
        width: '100%',
    },

    buttonGetStartedContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '32px',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'flex-start'
        }
    },
    textInteractive: {
        width: '100%',
        color: '#272343',
        fontSize: '56px',
        fontWeight: 'lighter',
        display: 'flex',
        fontFamily: 'Josefin Sans',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: { fontSize: 30 }
    },
    textOnlineClasses: {
        width: '100%',
        color: '#2ebee1',
        fontFamily: 'Josefin Sans',
        fontSize: '56px',
        fontWeight: 'lighter',
        [theme.breakpoints.down('sm')]: { fontSize: 30 }
    },
    freeLessonHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fffef9',
        fontSize: 28,
        margin: 0,
        [theme.breakpoints.down('sm')]: { fontSize: 23, marginTop: -15 }
    },
    liveIcons: {
        borderRadius: '50%',
        width: '100px',
        height: '65px',
        marginRight: '24px',
        [theme.breakpoints.down('sm')]: { borderRadius: '50%', width: '100px', height: '65px' }
    },
    expIcons: {
        borderRadius: '50%',
        width: '110px',
        height: '65px',
        marginRight: '33px',
        [theme.breakpoints.down('sm')]: { borderRadius: '50%', width: '110px', height: '65px' }
    },

    funIcon: {
        //backgroundColor: '#FD4D40',
        //opacity: '70%',
        borderRadius: '50%',
        width: '185px',
        height: '75px',
        marginRight: '24px',
        [theme.breakpoints.down('sm')]: { borderRadius: '50%', width: '150px', height: '65px' }
    },
    personalisedIcon: {
        // backgroundColor: '#FDB62F',
        // opacity: '70%',
        borderRadius: '50%',
        width: '185px',
        height: '75px',
        marginRight: '24px',
        [theme.breakpoints.down('sm')]: { borderRadius: '50%', width: '150px', height: '65px' }
    },
    whyTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 17,
            marginTop: 9
        }
    },
    workDone: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: 23
        }
    },
    parentContainer: {
        overflowX: 'clip',
        [theme.breakpoints.down('lg')]: {
            overflowX: 'clip'
        }
    },
    whyTitle: {
        marginTop: '34%',
        [theme.breakpoints.down('md')]: {
            marginTop: '4%'
        }
    }
}))
