import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card, Container, Typography } from '@material-ui/core';
import Header from '../components/header';
import Footer from '../components/Footer';
import { useHistory } from 'react-router-dom';
import useUserRole from '../../../hooks/useUserRole';

const CATEGORY_DATA_LIST = [
    {
        id: 1,
        name: 'Public Speaking',
        image_url:
            'https://images.unsplash.com/photo-1502781252888-9143ba7f074e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80'
    },
    {
        id: 2,
        name: 'Summer Workshop',
        image_url:
            'https://images.unsplash.com/photo-1517164850305-99a3e65bb47e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
    },
    {
        id: 3,
        name: 'Creative Writing',
        image_url:
            'https://images.unsplash.com/photo-1485546246426-74dc88dec4d9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'
    },
    {
        id: 4,
        name: 'Grammar, Vocabulary & Fluency',
        image_url:
            'https://images.unsplash.com/photo-1603354350317-6f7aaa5911c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
    },
    {
        id: 5,
        name: 'Story Telling',
        image_url:
            'https://images.unsplash.com/photo-1504151932400-72d4384f04b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80'
    }
];
const useStyles = makeStyles((theme) => ({
    myStyle: {
        backgroundColor: theme.palette.primary.light,
        height: '100vh',
        fontSize: '50px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    MuiCardHeader: {
        root: {
            backgroundColor: 'red'
        }
    },
    imageItem: {
        width: '100%'
    },
    cardRoot: {
        borderRadius: 12,
        cursor: 'pointer',
        border: '1px solid #68c2d9',
        transition: 'box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease',
        '&:hover': {
            boxShadow: '0 0.5em 0.5em 0.2em #68c2d9',
            transform: 'translate3d(0,-5px,0)',
            borderColor: theme.palette.primary.main
        }
    }
}));
export default function CourseCategories() {
    const classes = useStyles();
    const history = useHistory();
    // useUserRole({ roleList: ['STUDENT'] });
    const handleCourseDetails = () => {
        // history.replace('/course-details');
        history.replace('/module-details');
    };
    return (
        <>
            <Header />
            <Container maxWidth="xl">
                <Grid container spacing={4} style={{ marginTop: 0 }}>
                    {CATEGORY_DATA_LIST?.map((item, index) => (
                        <Grid item xs={12} sm={2} md={4} key={index}>
                            <Card className={classes.cardRoot} onClick={handleCourseDetails}>
                                <img src={item?.image_url} className={classes.imageItem} alt='Course'/>
                                <Typography variant="h2" style={{ margin: '10px 0 10px 10px', textAlign: 'center' }}>
                                    {item?.name}
                                </Typography>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footer />
        </>
    );
}
