import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import './components/myStyle.css';
import { Container, Grid, Item, Box, Button, FormControl, OutlinedInput, InputAdornment, Typography, Card } from '@mui/material';
import Header from '../components/header';
import { makeStyles } from '@material-ui/styles';
import heroBg from '../../../assets/images/homepageTwo/heroBg.png';
import heroImg from '../../../assets/images/homepageTwo/heroImg.png';
import icon1 from '../../../assets/images/homepageTwo/icon1.png';
import icon2 from '../../../assets/images/homepageTwo/icon2.png';
import icon3 from '../../../assets/images/homepageTwo/icon3.png';
import booksSelectionBg from '../../../assets/images/homepageTwo/booksSelectionBg.png';
import BooksCategoriesCard from './components/BooksCategoriesCard';
import TopPickCard from './components/TopPickCard';
import TopPicksCarousel from './components/TopPicksCarousel';
import TestimonialCarousel from '../homepage1/components/TestimonialCarousel';
import FooterNew from '../components/FooterNew';
import { Link, useHistory } from 'react-router-dom';
import { getSelectedParentCategories, getJustAdded, getTopPicks } from '../../../services/LandingPageServices';
import JustAdded from './components/JustAdded';
import '../../../fonts/font.css';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        transition: 'box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease',
        '&:hover': {
            boxShadow: 'rgba(63,178,207,255) 0px 20px 30px -10px',
            transform: 'translate3d(0,-5px,0)'
        },
        cursor: 'pointer'
    },
    bookCollection: {
        height: '100vh',
        width: '100vw',
        [theme.breakpoints.down('387')]: {
            fontSize: '20px',
            height: '100vh',
            width: '100%'
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
            width: '100%'
        },
        [theme.breakpoints.down('md')]: {
            height: '100%',
            width: '100%'
        }
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('387')]: {
            fontSize: '20px',
            // marginLeft: '10px',
            display: 'flex',
            flexDirection: 'row'
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column'
        },
        [theme.breakpoints.down('md')]: {
            // marginLeft: '10px',
            display: 'flex',
            flexDirection: 'row'
        }
    },
    cardImg: {
        width: '100%',
        height: '100%',
        display: 'block',
        borderRadius: 8
    },
    curatedBook: {
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '32px',
        paddingBottom: '64px'
    },
    footer: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    titleText: {
        width: '100%',
        color: '#2ebee1',
        fontSize: '48px',
        fontWeight: 'lighter',
        marginLeft: 110,
        [theme.breakpoints.down('sm')]: { fontSize: '25px', marginLeft: 10 }
    },
    subTitleText1: {
        width: '100%',
        color: '#ffa4a2',
        margin: '20px',
        fontWeight: 'lighter',
        marginLeft: 110,
        fontSize: '24px',
        [theme.breakpoints.down('sm')]: { fontSize: '15px', marginLeft: 10 }
    },
    subTitleText2: {
        width: '100%',
        color: 'black',
        margin: '20px',
        fontWeight: 'lighter',
        marginLeft: 110,
        fontSize: '24px',
        [theme.breakpoints.down('sm')]: { fontSize: '14px', marginLeft: 10 }
    },
    categoryTitle: {
        [theme.breakpoints.down('sm')]: { fontSize: 21, textAlign: 'center' }
    },
    heroImage: {
        marginRight: '0em',
        maxWidth: '35em',
        marginTop: '0.5em',
        [theme.breakpoints.down('md')]: {
            maxWidth: '20em',
            minWidth: '35em'
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '20em',
            minWidth: '22em'
        }
    },
    descText: {
        textAlign: 'center',
        color: '#5A5F70',
        fontWeight: 'lighter',
        marginTop: 10,
        [theme.breakpoints.down('sm')]: { marginTop: 15 }
    },
    startBtn: {
        marginTop: '32px',
        fontWeight: 'lighter',
        fontFamily: 'Josefin Sans',
        width: '110px',
        paddingTop: '8px',
        paddingBottom: '8px',
        marginLeft: 110,
        [theme.breakpoints.down('sm')]: { marginLeft: 10 }
    },

    tryNow: {
        width: '100%',
        padding: '24px 240px',
        [theme.breakpoints.down('md')]: { width: '100%', padding: '10px 20px' }
    }
}));

export default function HomepageTwo() {
    const classes = useStyles();
    const [booksCategoriesList, setBooksCategoriesList] = React.useState([]);
    const [justAdded, setJustAdded] = useState([]);

    const GetAllBooksCategories = async () => {
        await getSelectedParentCategories()
            .then((data) => {
                setBooksCategoriesList(data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const history = useHistory();

    let profileId = Cookies.get('profileId');

    const handleDetails = (bookId) => {
        history.push(`/book-details/${bookId}`);
    };

    const GetJustAdded = async () => {
        await getJustAdded()
            .then((data) => {
                setJustAdded(data?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        GetAllBooksCategories();
        GetJustAdded();
    }, []);

    const colors = ['#FF5454', '#F16EA8', '#7D6EFA', '#00C2CB', '#98CE00', '#014B7D', '#FAC80F'];

    return (
        <div style={{ overflowX: 'clip', width: '100%' }}>
            <div>
                <Header></Header>
            </div>
            <Grid className={classes.bookCollection}>
                <Grid
                    flexShrink={1}
                    container
                    sx={{ backgroundImage: `url(${heroBg})`, backgroundSize: 'cover', padding: '5% 0%' }}
                    className={classes.title}
                >
                    <Grid item flexShrink={0}>
                        <Grid className={classes.titleText}> BOOK COLLECTION </Grid>
                        <Grid item>
                            <Typography className={classes.subTitleText1}>
                                Enter the magical world of books! <br />
                            </Typography>
                            <Typography className={classes.subTitleText2}>
                                Extensive collection of imported, international authors <br />
                            </Typography>
                        </Grid>

                        <Grid className={classes.startBtn}>
                            <a href={'/login'}>
                                <Button variant="contained" sx={{ backgroundColor: '#ff8f44' }}>
                                    Get Started
                                </Button>
                            </a>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ flex: 1, padding: '0% 5%', marginRight: 0 }}>
                        <img src={heroImg} alt="hero img"></img>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '32px' }}
                >
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 4 }}>
                        <Typography
                            variant="h1"
                            fontSize="30px"
                            fontWeight="lighter"
                            fontFamily="Josefin Sans"
                            className={classes.categoryTitle}
                        >
                            Book Categories
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '100%',
                            alignItems: 'center'
                        }}
                    >
                        <Grid
                            container
                            style={{ marginBottom: '16px', display: 'flex', justifyContent: 'center' }}
                            columnGap={3}
                            rowGap={3}
                        >
                            {booksCategoriesList.map((category, i) => (
                                <Link
                                    to={{
                                        pathname: category.id === '64475ee15d2f5612dda89576' ? '/book-search-top' : '/book-search',
                                        state: {
                                            id: category.id
                                        }
                                    }}
                                    style={{ textDecoration: 'none' }}
                                    key={category.id}
                                >
                                    <BooksCategoriesCard
                                        id={category.id}
                                        title={category.title}
                                        imageUrl={category.imageUrl}
                                        url={'/book-search'}
                                        bgColor={colors[i % colors.length]}
                                    ></BooksCategoriesCard>
                                </Link>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.tryNow}>
                    <Card elevation={0} sx={{ backgroundColor: '#2EBEE1' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px' }}>
                            <h5
                                style={{
                                    color: '#fff',
                                    marginBottom: '16px',
                                    textAlign: 'center',
                                    fontWeight: 'lighter',
                                    fontFamily: 'Josefin Sans'
                                }}
                            >
                                Designed to help parents choose appropriate books according to their child's age and reading ability.
                            </h5>
                            <a href="/book-search" style={{ textDecoration: 'none' }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontWeight: 'lighter',
                                        fontFamily: 'Josefin Sans',
                                        fontSize: '16px',
                                        backgroundColor: '#FF8F44',
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                        width: '180px',
                                        height: '40px',
                                        color: 'white'
                                    }}
                                >
                                    Try Now
                                </Button>
                            </a>
                        </Box>
                    </Card>
                </Grid>

                <Grid
                    item
                    container
                    sx={{
                        backgroundImage: `url(${booksSelectionBg})`
                    }}
                    className={classes.curatedBook}
                >
                    <Grid item sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
                        <h2 style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}>
                            {' '}
                            Enjoy our curated book selection at the comfort of your home!{' '}
                        </h2>
                        <p
                            style={{
                                textAlign: 'center',
                                color: '#5A5F70',
                                fontWeight: 'lighter',
                                fontFamily: 'Josefin Sans',
                                fontSize: 20
                            }}
                        >
                            Books are sent in sanitized and sealed bags
                        </p>
                    </Grid>
                    <Grid container sx={{ justifyContent: 'center', width: '100%' }} columnGap={10}>
                        <Grid
                            item
                            sx={{
                                width: '220px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ width: '100px', height: '100px', borderRadius: '50%', bgColor: '#FFB400' }}>
                                <img src={icon1} alt="icon1"></img>
                            </Box>
                            <h5 style={{ textAlign: 'center', fontWeight: 'lighter', fontFamily: 'Josefin Sans', marginTop: '16px' }}>
                                {' '}
                                Select books online and place your order{' '}
                            </h5>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                width: '220px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ width: '100px', height: '100px', borderRadius: '50%', bgColor: '#FD4D40' }}>
                                <img src={icon2} alt="icon1"></img>
                            </Box>
                            <h5 style={{ textAlign: 'center', fontWeight: 'lighter', fontFamily: 'Josefin Sans', marginTop: '16px' }}>
                                {' '}
                                Get your books delivered in a sealed, sanitised bag{' '}
                            </h5>
                        </Grid>
                        <Grid
                            item
                            sx={{
                                width: '220px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Box sx={{ width: '100px', height: '100px', borderRadius: '50%', bgColor: '#388200' }}>
                                <img src={icon3} alt="icon1"></img>
                            </Box>
                            <h5 style={{ textAlign: 'center', fontWeight: 'lighter', fontFamily: 'Josefin Sans', marginTop: '16px' }}>
                                {' '}
                                Read books and return them next month{' '}
                            </h5>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '16px'
                    }}
                >
                    <Grid item sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
                        {/* <Link style={{ fontFamily: 'ShortBaby-Mg2w', fontSize: 60, textDecoration: 'none' }} to={'/book-search-top'}>
                            JUST ADDED
                        </Link> */}

                        <Button
                            component="a"
                            href="/book-search-top"
                            variant="contained"
                            sx={{
                                fontWeight: 'lighter',
                                fontFamily: 'Josefin Sans',
                                fontSize: '30px',
                                backgroundColor: '#ff8f44',
                                paddingTop: '8px',
                                paddingBottom: '8px'
                            }}
                        >
                            <a href="/book-search-top" style={{ textDecoration: 'none', color: 'inherit' }}>
                                JUST ADDED
                            </a>
                        </Button>
                    </Grid>
                    <JustAdded />
                </Grid>

                <Grid
                    item
                    container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: -5
                    }}
                >
                    <Grid item sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
                        <h1 style={{ fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}> TOP PICKS </h1>
                    </Grid>

                    <TopPicksCarousel></TopPicksCarousel>
                </Grid>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: -5
                        //paddingLeft: '80px',
                        //paddingRight: '80px'
                    }}
                >
                    <Box>
                        <h3 style={{ textAlign: 'center', fontWeight: 'lighter', fontFamily: 'Josefin Sans' }}> Testimonials </h3>
                    </Box>
                    <Box className={classes.testimonialCarousel}>
                        <TestimonialCarousel></TestimonialCarousel>
                    </Box>
                </Box>
                <Grid item>
                    <FooterNew></FooterNew>
                </Grid>
            </Grid>
        </div>
    );
}
