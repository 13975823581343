import axios from 'axios';
import { http } from '../utils/http';
import { handleResponse, handleError } from './ServiceUtils';

export const getAllActiveCourses = () => {
    return new Promise((resolve, reject) => {
        axios

            .get(`${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/courses/active`)

            .then((data) => {
                resolve(data);
            })

            .catch((err) => {
                reject(err);
            });
    });
};

export const getAllActiveCoursesAge = (age) => {
    return new Promise((resolve, reject) => {
        axios

            .get(`${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/courses/active/age?age=${age}`)

            .then((data) => {
                resolve(data);
            })

            .catch((err) => {
                reject(err);
            });
    });
};

export const getAllActiveBatches = (id, age) => {
    return new Promise((resolve, reject) => {
        axios

            .get(`${process.env.REACT_APP_ROOT_URL_BATCHES}/batches-service/batches/course/${id}?age=${age}`)

            .then((data) => {
                resolve(data);
            })

            .catch((err) => {
                reject(err);
            });
    });
};

export const getAllActiveModules = (id) => {
    return new Promise((resolve, reject) => {
        axios

            .get(`${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/modules/active/without-pagination/course/${id}`)

            .then((data) => {
                resolve(data);
            })

            .catch((err) => {
                reject(err);
            });
    });
};
