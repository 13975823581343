import {
    GET_ALL_PARENT_CATEGORY_START,
    GET_ALL_PARENT_CATEGORY_SUCCESS,
    GET_ALL_PARENT_CATEGORY_ERROR,
    GET_ALL_CATEGORY_START,
    GET_ALL_CATEGORY_SUCCESS,
    GET_ALL_CATEGORY_ERROR,
} from '../../constants';

const initialState = {
    loading: false,
    allParentCategories: null,
    allSubCategories: null,
    message: '',
};

export default function GetAllCategoryReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_PARENT_CATEGORY_START:
        case GET_ALL_CATEGORY_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_PARENT_CATEGORY_SUCCESS:
        case GET_ALL_CATEGORY_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_PARENT_CATEGORY_ERROR:
        case GET_ALL_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
