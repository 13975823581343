// assets
import {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconSettings,
    IconKey,
    IconBox,
    IconHome,
    IconBorderAll,
    IconBook,
    IconLanguage,
    IconList,
    IconBoxMultiple,
    IconShoppingCart,
    IconShoppingCartPlus,
    IconCurrencyRupee,
    IconBriefcase,
    IconApps,
    IconBusinessplan,
    IconMap2,
    IconSchool,
    IconMapSearch,
    IconDiscount2,
    IconUsers,
    IconHeart,
    IconBrandFirebase,
    IconRefresh,
    IconReceiptRefund,
    IconBoxMultiple0,
    IconCalendarPlus,
    IconBrandSass,
    IconFileCertificate,
    IconDisabled,
    IconUserCircle,
    IconBoxPadding,
    IconNews,
    IconBrandTwitch
} from '@tabler/icons';
import Cookies from 'js-cookie';
// constant
const icons = {
    IconBrandChrome: IconBrandChrome,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap,
    IconSettings: IconSettings,
    IconBoxMultiple: IconBoxMultiple,
    IconKey: IconKey,
    IconHome: IconHome,
    IconBook: IconBook,
    IconLanguage: IconLanguage,
    IconList: IconList,
    IconBorderAll: IconBorderAll,
    IconBox: IconBox,
    IconShoppingCart: IconShoppingCart,
    IconShoppingCartPlus: IconShoppingCartPlus,
    IconCurrencyRupee: IconCurrencyRupee,
    IconBriefcase: IconBriefcase,
    IconApps: IconApps,
    IconBusinessplan: IconBusinessplan,
    IconMap2: IconMap2,
    IconSchool: IconSchool,
    IconMapSearch: IconMapSearch,
    IconDiscount2: IconDiscount2,
    IconUsers: IconUsers,
    IconHeart: IconHeart,
    IconBrandFirebase: IconBrandFirebase,
    IconRefresh: IconRefresh,
    IconReceiptRefund: IconReceiptRefund,
    IconBoxMultiple0: IconBoxMultiple0,
    IconCalendarPlus: IconCalendarPlus,
    IconBrandSass: IconBrandSass,
    IconFileCertificate,
    IconDisabled: IconDisabled,
    IconUserCircle: IconUserCircle,
    IconBoxPadding: IconBoxPadding,
    IconNews: IconNews,
    IconBrandTwitch: IconBrandTwitch
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//
const school_id = Cookies.get('schoolId');

export const dashboard = {
    id: 'dashboard',
    type: 'group',
    children: [

        {
            id: 'admin_dashboard',
            title: 'Dashboard',
            type: 'collapse',
            icon: icons['IconBook'],
            children: [
                {
                    id: 'user_dashboard',
                    title: 'Newly_Onboarded_User',
                    type: 'item',
                    url: '/admin/users/dashboard',
                    icon: icons['IconUsers'],
                    breadcrumbs: false
                },
                {
                    id: 'library_user',
                    title: 'Expired_Subscriptions',
                    type: 'item',
                    url: '/admin/library/dashboard',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                }
            ]
        },
        {
            
            id: 'books',
            title: 'Books',
            type: 'collapse',
            icon: icons['IconBook'],
            children: [
                {
                    id: 'all-books',
                    title: 'All Books',
                    type: 'item',
                    url: '/admin/books/all-books',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'books-languages',
                    title: 'Books Languages',
                    type: 'item',
                    url: '/admin/books/books-language-list',
                    icon: icons['IconLanguage'],
                    breadcrumbs: false
                },
                {
                    id: 'books-parent-categories',
                    title: 'Parent Categories',
                    type: 'item',
                    url: '/admin/books/parents-category',
                    icon: icons['IconApps'],
                    breadcrumbs: false
                },
                {
                    id: 'books-categories',
                    title: 'Sub Categories',
                    type: 'item',
                    url: '/admin/books/category',
                    icon: icons['IconBorderAll'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'collapse',
            icon: icons['IconBriefcase'],
            children: [
                {
                    id: 'place-orders',
                    title: 'Place Order',
                    type: 'item',
                    url: '/admin/orders/place-orders',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'bulk-place-orders',
                    title: 'Bulk Place Order',
                    type: 'item',
                    url: '/admin/orders/bulk-place-orders',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'all-orders',
                    title: 'Order Delivery',
                    type: 'item',
                    url: '/admin/orders/all-orders',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },

                {
                    id: 'order-books',
                    title: 'Book Return',
                    type: 'item',
                    url: '/admin/orders/order-books',
                    icon: icons['IconBrandFirebase'],
                    breadcrumbs: false
                },
                {
                    id: 'pending-books',
                    title: 'Pending Books',
                    type: 'item',
                    url: '/admin/orders/pending-books',
                    icon: icons['IconRefresh'],
                    breadcrumbs: false
                },
                {
                    id: 'view-bulk-batches',
                    title: 'Bulk Batches',
                    type: 'item',
                    url: '/admin/orders/bulk-batches',
                    icon: icons['IconBoxMultiple0'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'subscriptions',
            title: 'Subscription Plans',
            type: 'collapse',
            icon: icons['IconBusinessplan'],
            children: [
                
                {
                    id: 'library-subscription',
                    title: 'Library Subscriptions',
                    type: 'item',
                    url: '/admin/subscription-library',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'security-deposits',
                    title: 'Security Deposits',
                    type: 'item',
                    url: '/admin/security-deposits',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'plans',
                    title: 'Plans',
                    type: 'item',
                    url: '/admin/plans',
                    icon: icons['IconCalendarPlus'],
                    breadcrumbs: false
                },
                {
                    id: 'delivery-plan',
                    title: 'Delivery Zones',
                    type: 'item',
                    url: '/admin/delivery-zones',
                    icon: icons['IconMapSearch'],
                    breadcrumbs: false
                },
                {
                    id: 'book-trials',
                    title: 'Book Trials',
                    type: 'item',
                    url: '/admin/book-trials',
                    icon: icons['IconMapSearch'],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'carts',
            title: 'Carts',
            type: 'collapse',
            icon: icons['IconShoppingCart'],
            children: [
                {
                    id: 'books-carts',
                    title: 'Books Carts',
                    type: 'item',
                    url: '/admin/carts',
                    icon: icons['IconShoppingCartPlus'],
                    breadcrumbs: false
                },
                {
                    id: 'wishlist',
                    title: 'Wishlist',
                    type: 'item',
                    url: '/admin/wishlist',
                    icon: icons['IconHeart'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'transactions',
            title: 'Transactions',
            type: 'item',
            url: '/admin/transactions',
            icon: icons['IconCurrencyRupee'],
            breadcrumbs: false
        },
        {
            id: 'schools',
            title: 'Schools',
            type: 'collapse',
            icon: icons['IconSchool'],
            children: [
                {
                    id: 'school',
                    title: 'Schools',
                    type: 'item',
                    url: '/admin/schools',
                    icon: icons['IconNews'],
                    breadcrumbs: false
                },
                {
                    id: 'schoolEnquiry',
                    title: 'School Enquiry',
                    type: 'item',
                    url: '/admin/school-enquiry',
                    icon: icons['IconSchool'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'stock',
            title: 'Stock Management',
            type: 'collapse',
            icon: icons['IconBoxPadding'],
            children: [
                {
                    id: 'libraryStock',
                    title: 'Library Stock',
                    type: 'item',
                    url: '/admin/library-stock',
                    icon: icons['IconNews'],
                    breadcrumbs: false
                },
                {
                    id: 'schoolStock',
                    title: 'School Stock',
                    type: 'item',
                    url: '/admin/school-stock',
                    icon: icons['IconSchool'],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'discount',
            title: 'Discount Coupouns',
            type: 'item',
            url: '/admin/coupon-discount',
            icon: icons['IconDiscount2'],
            breadcrumbs: false
        },

        {
            id: 'voucher',
            title: 'Gift Vouchers',
            type: 'item',
            url: '/admin/gift-vouchers',
            icon: icons['IconDiscount2'],
            breadcrumbs: false
        },

        {
            id: 'referral',
            title: 'Referrals',
            type: 'item',
            url: '/admin/referrals',
            icon: icons['IconDiscount2'],
            breadcrumbs: false
        },
        {
            id: 'users-management',
            title: 'User Management',
            type: 'collapse',
            icon: icons['IconUsers'],
            children: [
                {
                    id: 'users',
                    title: 'Users',
                    type: 'item',
                    url: '/admin/users',
                    icon: icons['IconUsers'],
                    breadcrumbs: false
                },
                {
                    id: 'admin',
                    title: 'Admins',
                    type: 'item',
                    url: '/admin/users/all-profile/admin',
                    icon: icons['IconUsers'],
                    breadcrumbs: false
                },
                {
                    id: 'school-admins',
                    title: 'School Admins',
                    type: 'item',
                    url: '/admin/users/all-profile/school-admin',
                    icon: icons['IconBrandSass'],
                    breadcrumbs: false
                },
                {
                    id: 'teachers',
                    title: 'Teachers',
                    type: 'item',
                    url: '/admin/users/all-profile/teacher',
                    icon: icons['IconDisabled'],
                    breadcrumbs: false
                },
                {
                    id: 'students',
                    title: 'Students',
                    type: 'item',
                    url: '/admin/users/all-profile/student',
                    icon: icons['IconUserCircle'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'faqs',
            title: 'FAQs',
            type: 'item',
            url: '/admin/faqs',
            icon: icons['IconBrandTwitch'],
            breadcrumbs: false
        },
        {
            id: 'contact',
            title: 'CallBack Requests',
            type: 'item',
            url: '/admin/contact',
            icon: icons['IconBrandTwitch'],
            breadcrumbs: false
        }
    ]
};

export const schoolAdminDashboard = {
    id: 'dashboard',
    type: 'group',
    children: [
        {
            id: 'orders',
            title: 'Orders',
            type: 'collapse',
            icon: icons['IconBriefcase'],
            children: [
                {
                    id: 'all-orders',
                    title: 'All Orders',
                    type: 'item',
                    url: '/admin/orders/all-orders',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                // {
                //     id: 'order-books',
                //     title: 'Book Return',
                //     type: 'item',
                //     url: '/admin/orders/order-books',
                //     icon: icons['IconBrandFirebase'],
                //     breadcrumbs: false
                // }
                // ,{
                //     id: 'pending-books',
                //     title: 'Pending Books',
                //     type: 'item',
                //     url: '/admin/orders/pending-books',
                //     icon: icons['IconRefresh'],
                //     breadcrumbs: false
                // }
            ]
        },
        {
            
            id: 'books',
            title: 'Books',
            type: 'collapse',
            icon: icons['IconBook'],
            children: [
                {
                    id: 'all-books',
                    title: 'All Books',
                    type: 'item',
                    url: '/schooladmin/books/all-books',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                // {
                //     id: 'books-languages',
                //     title: 'Books Languages',
                //     type: 'item',
                //     url: '/admin/books/books-language-list',
                //     icon: icons['IconLanguage'],
                //     breadcrumbs: false
                // },
                // {
                //     id: 'books-parent-categories',
                //     title: 'Parent Categories',
                //     type: 'item',
                //     url: '/admin/books/parents-category',
                //     icon: icons['IconApps'],
                //     breadcrumbs: false
                // },
                // {
                //     id: 'books-categories',
                //     title: 'Sub Categories',
                //     type: 'item',
                //     url: '/admin/books/category',
                //     icon: icons['IconBorderAll'],
                //     breadcrumbs: false
                // }
            ]
        },
        {
            id: 'carts',
            title: 'Carts',
            type: 'collapse',
            icon: icons['IconShoppingCart'],
            children: [
                {
                    id: 'books-carts',
                    title: 'Books Carts',
                    type: 'item',
                    url: '/schooladmin/carts',
                    icon: icons['IconShoppingCartPlus'],
                    breadcrumbs: false
                },
                // {
                //     id: 'wishlist',
                //     title: 'Wishlist',
                //     type: 'item',
                //     url: '/admin/wishlist',
                //     icon: icons['IconHeart'],
                //     breadcrumbs: false
                // }
            ]
        },

        // {
        //     id: 'schools',
        //     title: 'Schools',
        //     type: 'item',
        //     url: `/schooladmin/school`,
        //     icon: icons['IconSchool'],
        //     breadcrumbs: false
        // },
        {
            id: 'stock',
            title: 'Stock Management',
            type: 'collapse',
            icon: icons['IconBoxPadding'],
            children: [
                {
                    id: 'schoolStock',
                    title: 'School Stock',
                    type: 'item',
                    url: `/admin/view-stock/${school_id}`,
                    icon: icons['IconSchool'],
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'users-management',
        //     title: 'User Management',
        //     type: 'collapse',
        //     icon: icons['IconUsers'],
        //     children: [
        //         {
        //             id: 'users',
        //             title: 'Users',
        //             type: 'item',
        //             url: '/admin/users',
        //             icon: icons['IconUsers'],
        //             breadcrumbs: false
        //         },

        //         {
        //             id: 'students',
        //             title: 'Students',
        //             type: 'item',
        //             url: '/admin/users/all-profile/student',
        //             icon: icons['IconUserCircle'],
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};


export const superAdmindashboard = {
    id: 'dashboard',
    type: 'group',
    children: [

        {
            id: 'admin_dashboard',
            title: 'Dashboard',
            type: 'collapse',
            icon: icons['IconBook'],
            children: [
                {
                    id: 'user_dashboard',
                    title: 'Newly_Onboarded_User',
                    type: 'item',
                    url: '/admin/users/dashboard',
                    icon: icons['IconUsers'],
                    breadcrumbs: false
                },
                {
                    id: 'library_user',
                    title: 'Expired_Subscriptions',
                    type: 'item',
                    url: '/admin/library/dashboard',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                }
            ]
        },
        {
            
            id: 'books',
            title: 'Books',
            type: 'collapse',
            icon: icons['IconBook'],
            children: [
                {
                    id: 'all-books',
                    title: 'All Books',
                    type: 'item',
                    url: '/admin/books/all-books',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'all-deleted-books',
                    title: 'All Deleted Books',
                    type: 'item',
                    url: '/admin/books/all-deleted-books',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'books-languages',
                    title: 'Books Languages',
                    type: 'item',
                    url: '/admin/books/books-language-list',
                    icon: icons['IconLanguage'],
                    breadcrumbs: false
                },
                {
                    id: 'books-parent-categories',
                    title: 'Parent Categories',
                    type: 'item',
                    url: '/admin/books/parents-category',
                    icon: icons['IconApps'],
                    breadcrumbs: false
                },
                {
                    id: 'books-categories',
                    title: 'Sub Categories',
                    type: 'item',
                    url: '/admin/books/category',
                    icon: icons['IconBorderAll'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'orders',
            title: 'Orders',
            type: 'collapse',
            icon: icons['IconBriefcase'],
            children: [
                {
                    id: 'place-orders',
                    title: 'Place Order',
                    type: 'item',
                    url: '/admin/orders/place-orders',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'bulk-place-orders',
                    title: 'Bulk Place Order',
                    type: 'item',
                    url: '/admin/orders/bulk-place-orders',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'all-orders',
                    title: 'Order Delivery',
                    type: 'item',
                    url: '/admin/orders/all-orders',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },

                {
                    id: 'order-books',
                    title: 'Book Return',
                    type: 'item',
                    url: '/admin/orders/order-books',
                    icon: icons['IconBrandFirebase'],
                    breadcrumbs: false
                },
                {
                    id: 'pending-books',
                    title: 'Pending Books',
                    type: 'item',
                    url: '/admin/orders/pending-books',
                    icon: icons['IconRefresh'],
                    breadcrumbs: false
                },
                {
                    id: 'view-bulk-batches',
                    title: 'Bulk Batches',
                    type: 'item',
                    url: '/admin/orders/bulk-batches',
                    icon: icons['IconBoxMultiple0'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'subscriptions',
            title: 'Subscription Plans',
            type: 'collapse',
            icon: icons['IconBusinessplan'],
            children: [
                
                {
                    id: 'library-subscription',
                    title: 'Library Subscriptions',
                    type: 'item',
                    url: '/admin/subscription-library',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'security-deposits',
                    title: 'Security Deposits',
                    type: 'item',
                    url: '/admin/security-deposits',
                    icon: icons['IconBoxMultiple'],
                    breadcrumbs: false
                },
                {
                    id: 'plans',
                    title: 'Plans',
                    type: 'item',
                    url: '/admin/plans',
                    icon: icons['IconCalendarPlus'],
                    breadcrumbs: false
                },
                {
                    id: 'Deleted plans',
                    title: 'Deleted Plans',
                    type: 'item',
                    url: '/admin/deleted-plans',
                    icon: icons['IconCalendarPlus'],
                    breadcrumbs: false
                },
                {
                    id: 'delivery-plan',
                    title: 'Delivery Zones',
                    type: 'item',
                    url: '/admin/delivery-zones',
                    icon: icons['IconMapSearch'],
                    breadcrumbs: false
                },
                {
                    id: 'book-trials',
                    title: 'Book Trials',
                    type: 'item',
                    url: '/admin/book-trials',
                    icon: icons['IconMapSearch'],
                    breadcrumbs: false
                },
                {
                    id: 'deleted-book-trials',
                    title: 'Deleted Book Trials',
                    type: 'item',
                    url: '/admin/deleted-book-trials',
                    icon: icons['IconMapSearch'],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'carts',
            title: 'Carts',
            type: 'collapse',
            icon: icons['IconShoppingCart'],
            children: [
                {
                    id: 'books-carts',
                    title: 'Books Carts',
                    type: 'item',
                    url: '/admin/carts',
                    icon: icons['IconShoppingCartPlus'],
                    breadcrumbs: false
                },
                {
                    id: 'wishlist',
                    title: 'Wishlist',
                    type: 'item',
                    url: '/admin/wishlist',
                    icon: icons['IconHeart'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'transactions',
            title: 'Transactions',
            type: 'item',
            url: '/admin/transactions',
            icon: icons['IconCurrencyRupee'],
            breadcrumbs: false
        },
        {
            id: 'schools',
            title: 'Schools',
            type: 'collapse',
            icon: icons['IconSchool'],
            children: [
                {
                    id: 'school',
                    title: 'Schools',
                    type: 'item',
                    url: '/admin/schools',
                    icon: icons['IconNews'],
                    breadcrumbs: false
                },
                {
                    id: 'deleted school',
                    title: 'Deleted Schools',
                    type: 'item',
                    url: '/admin/deletedschools',
                    icon: icons['IconNews'],
                    breadcrumbs: false
                },
                {
                    id: 'schoolEnquiry',
                    title: 'School Enquiry',
                    type: 'item',
                    url: '/admin/school-enquiry',
                    icon: icons['IconSchool'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'stock',
            title: 'Stock Management',
            type: 'collapse',
            icon: icons['IconBoxPadding'],
            children: [
                {
                    id: 'libraryStock',
                    title: 'Library Stock',
                    type: 'item',
                    url: '/admin/library-stock',
                    icon: icons['IconNews'],
                    breadcrumbs: false
                },
                {
                    id: 'schoolStock',
                    title: 'School Stock',
                    type: 'item',
                    url: '/admin/school-stock',
                    icon: icons['IconSchool'],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'discount',
            title: 'Discount Coupouns',
            type: 'item',
            url: '/admin/coupon-discount',
            icon: icons['IconDiscount2'],
            breadcrumbs: false
        },

        {
            id: 'voucher',
            title: 'Gift Vouchers',
            type: 'item',
            url: '/admin/gift-vouchers',
            icon: icons['IconDiscount2'],
            breadcrumbs: false
        },

        {
            id: 'referral',
            title: 'Referrals',
            type: 'item',
            url: '/admin/referrals',
            icon: icons['IconDiscount2'],
            breadcrumbs: false
        },
        {
            id: 'users-management',
            title: 'User Management',
            type: 'collapse',
            icon: icons['IconUsers'],
            children: [
                {
                    id: 'users',
                    title: 'Users',
                    type: 'item',
                    url: '/admin/users',
                    icon: icons['IconUsers'],
                    breadcrumbs: false
                },
                // {
                //     id: 'deleted users',
                //     title: 'Deleted Users',
                //     type: 'item',
                //     url: '/admin/deleted-users',
                //     icon: icons['IconUsers'],
                //     breadcrumbs: false
                // },
                {
                    id: 'admin',
                    title: 'Admins',
                    type: 'item',
                    url: '/admin/users/all-profile/admin',
                    icon: icons['IconUsers'],
                    breadcrumbs: false
                },
                {
                    id: 'school-admins',
                    title: 'School Admins',
                    type: 'item',
                    url: '/admin/users/all-profile/school-admin',
                    icon: icons['IconBrandSass'],
                    breadcrumbs: false
                },
                {
                    id: 'teachers',
                    title: 'Teachers',
                    type: 'item',
                    url: '/admin/users/all-profile/teacher',
                    icon: icons['IconDisabled'],
                    breadcrumbs: false
                },
                {
                    id: 'students',
                    title: 'Students',
                    type: 'item',
                    url: '/admin/users/all-profile/student',
                    icon: icons['IconUserCircle'],
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'faqs',
            title: 'FAQs',
            type: 'item',
            url: '/admin/faqs',
            icon: icons['IconBrandTwitch'],
            breadcrumbs: false
        },
        {
            id: 'contact',
            title: 'CallBack Requests',
            type: 'item',
            url: '/admin/contact',
            icon: icons['IconBrandTwitch'],
            breadcrumbs: false
        }
    ]
};