import { http } from '../../../utils/http';
import { GET_ALL_WISHLIST_START, GET_ALL_WISHLIST_SUCCESS, GET_ALL_WISHLIST_ERROR } from '../../constants';
import { setMessage } from '../MessageActions';

const getAllWishlistStart = () => {
    return { type: GET_ALL_WISHLIST_START };
};
const getAllWishlistSuccess = (payload) => {
    return { type: GET_ALL_WISHLIST_SUCCESS, payload };
};
const getAllWishlistError = (error) => {
    return { type: GET_ALL_WISHLIST_ERROR, error };
};

const addWishlist = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', `orders-service/wish-lists/profiles/${id}`, payload, true)
            .then((res) => {
                console.log("res====",res?.message)
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log("err===",err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log("error===",error);
    }
};

const moveToCart = (pId, bookId, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', `orders-service/carts/profiles/${pId}/move-to-cart/${bookId}`, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message));
                    successCB && successCB(res);
                }
            })
            .catch((err) => {
                dispatch(setMessage(err?.data?.message));
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

export const moveBookToCart = (pId, bookId) => {
    return new Promise((resolve, reject) => {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'POST', `orders-service/carts/profiles/${pId}/move-to-cart/${bookId}`, true)
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

const getWishlist =
    (id, searchData = '') =>
    (dispatch) => {
        dispatch(getAllWishlistStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_ORDER,
                'GET',
                `orders-service/wish-lists/profiles/${id}?pageNumber=1&pageSize=20&searchKey=${searchData}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getWishlistData: res
                    };
                    dispatch(getAllWishlistSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllWishlistError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllWishlistError('Something went wrong'));
        }
    };

const deleteBookFromWishlist = (wishlistId, bookId, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_ORDER, 'DELETE', `orders-service/wish-lists/${wishlistId}/books/${bookId}`, true)
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Book remove Successfully'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const getAllWishlist =
    (searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllWishlistStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_ORDER,
                'GET',
                `orders-service/wish-lists/profiles?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}&includeEmptyWishlists=true`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllWishlistData: res
                    };
                    dispatch(getAllWishlistSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllWishlistError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllWishlistError('Something went wrong'));
        }
    };

export { addWishlist, getAllWishlist, moveToCart, getWishlist, deleteBookFromWishlist };
