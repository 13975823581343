import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link, NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { AppBar, Toolbar, Button, IconButton, Menu, MenuItem, Box, Fade, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ProfileSection from '../../../../layout/MainLayout/Header/ProfileSection';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedUserProfile } from '../../../../store/actions/ProfileActions';
import './header-style.css';
import CallIcon from '@mui/icons-material/Call';
import PhoneIcon from '@mui/icons-material/Phone';

const useStyles = makeStyles((theme) => ({
    sectionDesktop: {
        // flexGrow: 1,
        display: 'none',
        paddingLeft: '200px',
        paddingRight: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            color: '#333',
            flexDirection: 'center'
        }
    },
    phoneDesktop: {
        // flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            color: '#333',
            flexDirection: 'center'
        }
    },
    mobileDesktop: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            color: 'darkcyan'
        }
        ,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            color: 'darkcyan',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '10px'
        }
    },
    appbar: {
        width: '100%',
        backgroundColor: '#FFE6A8',
        maxHeight: '68px'
        ,
        [theme.breakpoints.down('md')]: {
            alignItems: 'center',
            justifyContent: 'space-evenly',
        }
    }
    ,
    toolbar: {
        width: '100%',
        backgroundColor: '#FFE6A8',
        maxHeight: '68px',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center',
            justifyContent: 'space-evenly',
            fontSize: '20px',
        }
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: 'black',
        '&:hover': {
            color: '#ff8f44'
        }
    },
    phonetxt: {
        color: 'black'
    },
    list: {
        textDecoration: 'none',
        color: '#272343',
        fontSize: '1.1rem',
        marginRight: '2rem',
        '&:hover': {
            color: theme.palette.success.main
        }
    },
    logoGrid: {
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.between('960', '1080')]: { marginLeft: 40, minWidth: 100, height: 50 },
        [theme.breakpoints.down('sm')]: { marginRight: 10 },
        [theme.breakpoints.down('md')]: {
            marginRight: '20px'
        }
    },

    logo: {
        height: 50,
        [theme.breakpoints.between('960', '1080')]: { marginLeft: 40, minWidth: 100, height: 50 },
        [theme.breakpoints.down('sm')]: { width: 100, height: 30 }
    },
    activeMenu: {
        color: theme.palette.primary.main
    }
    ,
    buttonStyle: {
        backgroundColor: '#ff8f44',
        width: '130px',
        height: '45px',
        fontFamily: 'Josefin Sans',
        fontSize: '13px',
        [theme.breakpoints.down('sm')]: {
            width: '90px',
            height: '25px',
            fontSize: '8px',
        },
        [theme.breakpoints.down('md')]: {
            width: '120px',
            height: '35px',
            fontSize: '12px',
        }
    }
}));

export default function Header() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [mobileMenuAnchorE1, setMobileMenuAnchorE1] = useState(null);
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    const isMobileMenuOpen = Boolean(mobileMenuAnchorE1);
    const openMobileMenu = (event) => {
        setMobileMenuAnchorE1(event.currentTarget);
    };
    const token = Cookies.get('token');

    React.useEffect(() => {
        if (token && !loggedUserProfile?.role) {
            dispatch(getLoggedUserProfile());
        }
    }, [dispatch, token, loggedUserProfile?.role]);

    const closeMobileMenu = () => {
        setMobileMenuAnchorE1(null);
    };

    const mobileMenu = (
        <Menu
            anchorEl={mobileMenuAnchorE1}
            id="mobile-menu"
            keepMounted
            open={isMobileMenuOpen}
            onClose={closeMobileMenu}
            TransitionComponent={Fade}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            =
            <MenuItem onClick={closeMobileMenu}>
                <a href="/bookLibrary" className={classes.activeMenu}>
                    Book Library
                </a>
            </MenuItem>
            =
            <MenuItem onClick={closeMobileMenu}>
                <a href="/onlineClasses" className={classes.activeMenu}>
                    Enrichment Classes
                </a>
            </MenuItem>
            =
            <MenuItem onClick={closeMobileMenu}>
                <a href="/pricing-plans" className={classes.activeMenu}>
                    Pricing Plans
                </a>
            </MenuItem>
            =
            <MenuItem onClick={closeMobileMenu}>
                <a href="/school" className={classes.activeMenu}>
                    School Offering
                </a>
            </MenuItem>
            =
            <MenuItem onClick={closeMobileMenu}>
                <a href="/voucher" className={classes.activeMenu}>
                    Gift Voucher
                </a>
            </MenuItem>
        </Menu>
    );
    return (
        <>
            <AppBar className={classes.appbar} position="sticky">
                <Toolbar className={classes.toolbar}>
                    <Grid className={classes.logoGrid}>
                        <a href="/home">
                            <img
                                src={require('../../../../assets/images/users/logoWithName.png').default}
                                className={classes.logo}
                                alt="Login"
                            />
                        </a>
                    </Grid>
                    <Grid>
                        <nav className={classes.sectionDesktop}>
                            <a
                                href="/bookLibrary"
                                activeClassName={classes.activeMenu}
                                className={classes.link}
                                style={{ textDecoration: 'none' }}
                            >
                                Book Library
                            </a>

                            <a
                                href="/onlineClasses"
                                activeClassName={classes.activeMenu}
                                className={classes.link}
                                style={{ textDecoration: 'none' }}
                            >
                                Enrichment Classes
                            </a>

                            <a
                                href="/pricing-plans"
                                activeClassName={classes.activeMenu}
                                className={classes.link}
                                style={{ textDecoration: 'none' }}
                            >
                                Pricing Plans
                            </a>

                            <a
                                href="/school"
                                activeClassName={classes.activeMenu}
                                className={classes.link}
                                style={{ textDecoration: 'none' }}
                            >
                                School Offering
                            </a>

                            <a
                                href="/voucher"
                                activeClassName={classes.activeMenu}
                                className={classes.link}
                                style={{ textDecoration: 'none' }}
                            >
                                Gift Voucher
                            </a>
                        </nav>
                    </Grid>

                    <Grid sx={{ mx: 2 }} className={classes.phoneDesktop}>
                        <PhoneIcon />
                        <a href="tel:+919967650304" className={classes.phonetxt}>
                            (+91) 9967650304
                        </a>
                    </Grid>

                    {!loggedUserProfile?.role ? (
                        <Grid>

                            <Button
                                className={classes.buttonStyle}
                                size="small"
                                variant="contained"
                                color="primary"
                                component="a"
                                href="/login"
                            >
                                <span style={{ textDecoration: 'none', color: 'white' }}>Log in/ Register</span>
                            </Button>
                        </Grid>
                    ) : null}

                    <Grid sx={{ mx: 1 }} className={classes.mobileDesktop}>
                        <a href="tel:+919967650304" className={classes.phonetxt}>
                            (+91) 9967650304
                        </a>
                    </Grid>

                    {loggedUserProfile?.role ? <ProfileSection /> : null}

                    <IconButton className={classes.mobileDesktop} onClick={openMobileMenu}>
                        <MenuIcon style={{ fontSize: '40px', color: '#ff8f44' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {mobileMenu}
        </>
    );
}
