import React from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import menuItem from './../../../../menu-items';
import { useSelector } from 'react-redux';
import { onlineClassesTeacher } from '../../../../menu-items/onlineClassesTeacher';
import { onlineClassesSuperAdmin } from '../../../../menu-items/onlineClassesSuperAdmin';

//-----------------------|| SIDEBAR MENU LIST ||-----------------------///

const MenuList = () => {
    const [dashboard, onlineClasses, schoolAdminDashboard, superAdmindashboard] = menuItem.items;
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    const roles = {
        ADMIN: [dashboard, onlineClasses],
        SCHOOL_ADMIN: [schoolAdminDashboard],
        TEACHER: [onlineClassesTeacher],
        STUDENT: [],
        SUPER_ADMIN: [superAdmindashboard, onlineClassesSuperAdmin]
    };
    const sidebarList = roles[loggedUserProfile?.role] || [dashboard, onlineClasses];

    const navItems = sidebarList?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
