import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

// project imports
import config from '../../config';
import { useAuth } from '../../hooks/useAuth';

//-----------------------|| GUEST GUARD ||-----------------------//

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */
const GuestGuard = ({ children }) => {
    const auth = useAuth();
    const userRole = Cookies.get('userRole');

    if (auth?.user) {
        const roles = {
            ADMIN: config.defaultAdminPath,
            STUDENT: config.defaultStudentpath,
            SCHOOL_ADMIN: config.defaultSchoolAdminPath,
            TEACHER: config.defaultTeacherPath
        };
        return <Redirect to={roles[userRole]} />;
    }

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
