import React, { useEffect, useState } from 'react';
import Header from '../home/components/header';
import FooterNew from '../home/components/FooterNew';
import MainCard from '../../ui-component/cards/MainCard';
import HistoryIcon from '@material-ui/icons/History';
import { Button, Grid, Paper } from '@material-ui/core';
import useStyles from './styles';

import { useDispatch } from 'react-redux';
import { referAFriend } from '../../services/referServices';
import { setMessage } from '../../store/actions/MessageActions';
import TableRows from './tableRows';
import DeleteIcon from '@material-ui/icons/Delete';

function Refer(props) {
    const classes = useStyles();
    const [referData, setReferData] = useState([]);
    const [rowsData, setRowsData] = useState([
        {
            name: '',
            mobile: '',
            referredFor: '',
            message: '',
            email: ''
        }
    ]);

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = () => {
        ReferAFriend(rowsData);
    };
    // dynamic row
    const addTableRows = () => {
        const rowsInput = {
            name: '',
            mobile: '',
            referredFor: '',
            message: '',
            email: ''
        };
        setRowsData([...rowsData, rowsInput]);
    };
    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    };

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    };

    const ReferAFriend = async (rowsData) => {
        await referAFriend(rowsData)
            .then((res) => {
                setRowsData(res);
                dispatch(setMessage('Added Successfully!'));
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <>
            <Header />
            <MainCard style={{ padding: '0px', margin: '0px' }}>
                <div className={classes.titleContainer}>
                    <HistoryIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; Refer A Friend </span>
                </div>
                <Paper elevation={5} className={classes.root}>
                    <br />

                    <div>
                        <MainCard>
                            <Grid container>
                                <table style={{ borderCollapse: 'collapse' }}>
                                    <thead style={{ border: '0px solid black', borderTop: 'none', borderBottom: 'none' }}>
                                        <tr>
                                            <th style={{ verticalAlign: 'middle', width: '220px', textAlign: 'center' }}> Name </th>

                                            <th style={{ verticalAlign: 'middle', width: '200px', textAlign: 'center' }}> Reffered For </th>

                                            <th style={{ verticalAlign: 'middle', width: '200px', textAlign: 'center' }}> Email </th>

                                            <th style={{ verticalAlign: 'middle', width: '220px', textAlign: 'center' }}> Mobile</th>

                                            <th style={{ verticalAlign: 'middle', width: '220px', textAlign: 'center' }}>Short Message</th>
                                            <th style={{ verticalAlign: 'middle', width: '220px', textAlign: 'center' }}>
                                                <Button
                                                    onClick={addTableRows}
                                                    style={{ borderRadius: '16px', backgroundColor: '#3fb2cf', color: 'white' }}
                                                >
                                                    Add more Referral
                                                </Button>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody style={{ textAlign: 'center' }}>
                                        <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                                    </tbody>
                                </table>
                            </Grid>
                        </MainCard>
                        <br />
                        <Button type="submit" variant="contained" color="primary" onClick={onSubmit}>
                            Submit
                        </Button>
                    </div>
                </Paper>
            </MainCard>
            <FooterNew />
        </>
    );
}

export default Refer;
