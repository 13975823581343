import {
    GET_ALL_FEEDBACK_START,
    GET_ALL_FEEDBACK_SUCCESS,
    GET_ALL_FEEDBACK_ERROR,
    GET_ALL_COURSE_FEEDBACK_START,
    GET_ALL_COURSE_FEEDBACK_SUCCESS,
    GET_ALL_COURSE_FEEDBACK_ERROR,
    GET_FEEDBACK_START,
    GET_FEEDBACK_SUCCESS,
    GET_FEEDBACK_ERROR,
} from '../../constants';

const initialState = {
    loading: false,
    allFeedbackData: null,
    courseFeedbackData: null,
    feedbackData: null,
    message: ''
};

export default function FeedbackReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_FEEDBACK_START:
        case GET_ALL_COURSE_FEEDBACK_START:
        case GET_FEEDBACK_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_FEEDBACK_SUCCESS:
        case GET_ALL_COURSE_FEEDBACK_SUCCESS:
        case GET_FEEDBACK_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_FEEDBACK_ERROR:
        case GET_ALL_COURSE_FEEDBACK_ERROR:
        case GET_FEEDBACK_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
