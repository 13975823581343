// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Freedom-10eM.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./ShortBaby-Mg2w.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'Freedom-10eM';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n  }\n\n  @font-face {\n    font-family: 'ShortBaby-Mg2w';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n  }\n  ", "",{"version":3,"sources":["webpack://src/fonts/font.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,+DAAwD;EAC1D;;EAEA;IACE,6BAA6B;IAC7B,+DAA0D;EAC5D","sourcesContent":["@font-face {\n    font-family: 'Freedom-10eM';\n    src: url('../fonts/Freedom-10eM.ttf') format('truetype');\n  }\n\n  @font-face {\n    font-family: 'ShortBaby-Mg2w';\n    src: url('../fonts/ShortBaby-Mg2w.ttf') format('truetype');\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
