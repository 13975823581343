import { Container } from '@mui/material';
import React from 'react';
import FooterNew from '../components/FooterNew';
import Header from '../components/header';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#2ebee1',
        fontFamily: 'Josefin Sans',
        fontWeight: 'lighter'
    }
}));

const PrivacyPolicy = () => {
    const classes = useStyles();

    return (
        <>
            <Header />
            <Container>
                <h3 style={{ paddingTop: 80 }} className={classes.title}>
                    PRIVACY POLICY
                </h3>
                <p>
                    Bookeinstein.com recognizes that its customers, visitors, users, and others (known collectively or individually as
                    "Users") who use www.Bookeinstein.com (the "Website") value their privacy. This Privacy Policy applies to all of the
                    products, services, and Websites offered by Bookeinstein.com, and details important information regarding the use and
                    disclosure of User information collected on the Website. Your use of the Website and any personal information you
                    provide on the Website remains subject to the terms of this Privacy Policy and the Bookeinstein.com Terms of Use. We may
                    use third-party advertising companies to serve or track ads on our website and other websites. These companies may use
                    information (not including your name, address, email address or telephone number) about your visits to this and other
                    websites in order to measure advertising effectiveness and to provide advertisements about goods and services that may
                    be of interest to you.
                </p><br></br>
                <h3 className={classes.title}>COLLECTION OF INFORMATION</h3>
                <p>
                    We collect personal information (email address, name, phone number, etc.) from you when you set up an account with
                    Bookeinstein.com. While you can browse some sections of our site without being a registered member, certain activities
                    (such as placing an order) do require registration.
                </p><br></br>
                <h3 className={classes.title}>Use of Your Information</h3>
                <p>
                    We do use your contact information to send you offers based on your previous orders and your interests. We DO NOT trade
                    or sell Your Personal Information in any manner or so. We may use personal information about you to improve our
                    marketing and promotional efforts, to analyze site usage, improve the Site's content and product offerings, and
                    customize the Site's content, layout and services. These uses improve the Site to meet your needs so as to provide you
                    with a smooth, efficient, safe and customized experience while using the Site. We use other third parties such as a
                    shipping company to fulfill orders, a credit card processing company to bill you for goods sold on our website, and an
                    email service provider to send out emails on our behalf. When you make a purchase on our website or sign up for our
                    email list, we will share the necessary amount of personally identifiable information as necessary for the third party
                    to provide that service, including your name, address, credit card information and email address. These third parties
                    are prohibited from using your personally identifiable information for promotional purposes. We provide Non-Personal
                    Information to interested third-parties for purposes of understanding the usage patterns for certain content, services,
                    advertisements, promotions, and/or functionality on the Website.
                </p><br></br>
                <h3 className={classes.title}> COOKIES</h3>
                <p>
                    Bookeinstein.com may, at times, use cookies. Cookies are data files stored on your computer that identify you as a
                    unique user. Cookies enable us to track user trends, preferences and patterns to better understand our user base and to
                    improve the quality of our service. Most browsers permit users to refuse cookies or to be notified before a cookie is
                    accepted. You can visit our site without using cookies.
                </p><br></br>
                <h3 className={classes.title}> SECURITY</h3>
                <p>
                    We follow generally accepted industry standards to protect the personally identifiable information submitted to us, both
                    during transmission and once we receive it. No method of transmission over the Internet, or method of electronic
                    storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your personal
                    information, we cannot guarantee its absolute security. If you have any questions about security on our Website, you can
                    send us an email at support@Bookeinstein.com We limit access to Personal Information about you to employees who we
                    believe reasonably need to come into contact with Personal Information to provide products or services to you or in
                    order to do their jobs. The security of your personal information is important to us. When you enter sensitive
                    information (such as a credit card number) on our registration or order forms, we encrypt that information using secure
                    socket layer technology (SSL).
                </p><br></br>
                <h3 className={classes.title}>CONSENT</h3>
                <p>
                    By submitting data to us or using the Site, you consent to our use of your data in the manner set out in this Privacy
                    Policy.
                </p><br></br>
                <h3 className={classes.title}> YOUR ABILITY TO EDIT AND DELETE YOUR ACCOUNT INFORMATION</h3>
                <p>
                    If your personally identifiable information changes, or if you no longer desire to maintain an account on, you may
                    correct, update, amend, delete/remove or deactivate it by emailing our customer support at support@bookeinstein.com. We
                    will respond to your request to access within 30 days. If you have any comments or suggestions, we would be pleased to
                    receive it by emailing us at support@bookeinstein.com.
                    <br />
                    <br />
                    <b>Note:</b> Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes,
                    please review this policy periodically. BY USING THE WEBSITE YOU AGREE TO THE TERMS AND CONDITIONS OF THIS POLICY. IF
                    YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THIS POLICY PLEASE DO NOT PROCEED FURTHER TO USE THIS WEBSITE.
                </p><br></br>
                <br></br>
            </Container>
            <FooterNew />
        </>
    );
};

export default PrivacyPolicy;
