import { http } from '../utils/http';
import axios from 'axios';
import { setMessage } from '../store/actions/MessageActions';

export const getSecurityDeposits = (rowsPerPage = 10, searchData = '', page = 1) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS}/subscriptions-service/library-security-deposits?pageNumber=${page}&pageSize=${rowsPerPage}&searchKey=${searchData}`
            )
            .then((data) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const refund = (rid) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/library-security-deposits/${rid}/refund`, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message ? err?.data?.message : 'Refunded Successfully'));
            });
    } catch (error) {
        console.log(error);
    }
};

export const deduction = (id, payload, dispatch) => {
    return new Promise((resolve, reject) => {
        http(
            process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
            'POST',
            `subscriptions-service/library-security-deposits/${id}/deduction`,
            payload,
            true
        )
            .then((data) => {
                dispatch(setMessage(data?.message));
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
