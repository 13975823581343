// action - state management
import { BOOK_ID,IS_ADD_MODE } from './actions';

export const initialState = {
    book_id: '',
    isAddMode: true,

};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const bookReducer = (state = initialState, action) => {
    switch (action.type) {
        case BOOK_ID: {
            const { book_id } = action.payload;
            return {
                ...state,
                book_id: book_id
            };
        }
        case IS_ADD_MODE: {
            const { isAddMode } = action.payload;
            return {
                ...state,
                isAddMode: isAddMode
            };
        }

        default: {
            return { ...state };
        }
    }
};

export default bookReducer;
