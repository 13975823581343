import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Container, Grid, Card, Link, Button } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Header from '../components/header';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {
    Box,
    Checkbox,
    CircularProgress,
    Collapse,
    FormControl,
    FormControlLabel,
    InputAdornment,
    List,
    ListItem,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Typography
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { addToCart } from '../../../store/actions/CartActions';
import FooterNew from '../components/FooterNew';
import { getSearchLatestBooks, getSearchFiltersBooks } from '../../../store/actions/BookActions';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { IconSearch } from '@tabler/icons';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        width: 190,
        transition: 'box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease',
        '&:hover': {
            boxShadow: 'rgba(63,178,207,255) 0px 20px 30px -10px',
            transform: 'translate3d(0,-5px,0)'
        },
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            minWidth: 300
        }
    },

    cardImg: { width: '100%', height: '100%', display: 'block', maxHeight: '220px', borderRadius: 8, objectFit: 'fill' },

    btnAddToCart: {
        boxShadow: '0 0.5em 0.5em 0.1em #68c2d9'
    },

    statusStyle: { color: '#008d42' },
    nested: {
        background: 'transparent',
        padding: '0px 15px',
        marginBottom: 10,
        borderRadius: 8
    },

    categoryList: {
        background: theme.palette.primary.light,
        display: 'flex',
        borderRadius: 8,
        marginBottom: 5,
        marginLeft: 2,
        alignItems: 'center',
        '&:hover': {
            background: theme.palette.primary.main,
            color: '#fff'
        }
    },

    listItem: {
        background: theme.palette.primary.light,
        display: 'flex',
        padding: 2,
        borderRadius: 8,
        marginBottom: 5,
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
            background: theme.palette.primary.main,
            color: '#fff'
        }
    },

    pageContent: {
        position: 'relative',
        left: 'unset',
        width: ' calc(100% - 2.5rem - 280px)',
        marginLeft: '3rem',
        marginBottom: 30
    },

    expandMoreIcon: {
        marginRight: '10px',
        fontSize: '24px'
    },

    sidenavPaper: {
        color: '#2B3445',
        borderRadius: 4,
        boxShadow: '0px 1px 3px rgba(3, 0, 71, 0.09)',
        transition: 'all 250ms ease-in-out',
        position: 'relative',
        height: 'auto',
        zIndex: 10,
        overflow: 'unset'
    },

    sidenav: {
        position: 'relative',
        top: 0,
        bottom: 0,
        height: 'calc(100vh - 80px)',
        transition: 'all 350ms ease-in-out',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            marginBottom: 30
        }
    },

    selectDropdown: {
        width: '330%',
        borderRadius: '4px',
        backgroundColor: 'white',
        transition: 'border-color 0.2s ease-in-out',
        '&:focus': {
            outline: 'none',
            borderColor: theme.palette.primary.main
        }
    }
}));

export default function TopBooksList() {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const { searchFilterData } = useSelector((state) => state.books);
    const [open, setOpen] = useState({});
    const [isFilterId, setFilterId] = useState([]);
    const [isSearchValue, setSearchValue] = useState('');
    const [includeOutOfStock, setIncludeOutOfStock] = useState('true');
    const [selectedAge, setSelectedAge] = useState('');
    const location = useLocation();
    const [isSearchData, setSearchData] = useState(null);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    let profileId = Cookies.get('profileId');

    const handleDetails = (bookId) => {
        history.push(`/book-details/${bookId}`);
    };
    const handleClick = (event, id) => {
        setOpen({ ...open, [id]: !open[id] });
    };

    const handleMove = (bookId) => {
        let payload = {
            bookId: bookId
        };
        if (profileId) {
            dispatch(addToCart(payload, profileId));
        } else {
            history.push('/login');
        }
    };

    const [selectedCategory, setSelectedCategory] = useState();

    const handleChange = (id, isParentCategory, event, data) => {
        setLoading(true);
        let selectedIds = [...isFilterId];
        if (event.target.checked) {
            setSelectedCategory(id);
            data?.categories?.forEach((item) => {
                selectedIds.push(item.id);
                setFilterId(selectedIds);
            });
        } else {
            setSelectedCategory('');

            let toBeRemovedIds = [];

            data?.categories?.forEach((item) => {
                toBeRemovedIds.push(item.id);
            });

            const difference = selectedIds.filter(function (el) {
                return !toBeRemovedIds.includes(el);
            });

            setFilterId([...difference]);
            selectedIds = [...difference];
        }

        const payload = {
            search: isSearchValue,
            categoryIds: selectedIds,
            includeOutOfStock: includeOutOfStock,
            age: selectedAge
        };

        dispatch(
            getSearchLatestBooks(payload, (data) => {
                setSearchData(data);
                setLoading(false);
            })
        );
    };

    const handleCirculation = (event) => {
        setLoading(true);
        let selectedIds = [];

        searchFilterData?.categories
            ?.filter((f) => f.id === selectedCategory)[0]
            ?.categories?.forEach((item) => {
                selectedIds.push(item.id);
            });

        const updatedIncludeOutOfStock = !event?.target?.checked;

        setIncludeOutOfStock(updatedIncludeOutOfStock);

        const payload = {
            search: isSearchValue,
            categoryIds: selectedIds,
            includeOutOfStock: updatedIncludeOutOfStock,
            age: selectedAge
        };

        dispatch(
            getSearchLatestBooks(payload, (data) => {
                setSearchData(data);
                setLoading(false);
            })
        );
    };

    const handleSubCategoryChange = (id, e) => {
        let removeItem = [...isFilterId];

        if (e.target.checked) setFilterId([...isFilterId, id]);
        else {
            const filterData = removeItem.filter((i) => i === id);
            if (filterData?.length > 0) {
                var index = removeItem.indexOf(id);
                if (index !== -1) {
                    removeItem.splice(index, 1);
                    setFilterId(removeItem);
                }
            } else {
                setFilterId((prevState) => [...prevState, id]);
            }
        }
    };

    let searchTimeout;

    const handleSearchBooks = (event) => {
        clearTimeout(searchTimeout);

        const searchQuery = event.target.value;

        searchTimeout = setTimeout(() => {
            setSearchValue(searchQuery);
        }, 500);
    };

    const handleAgeChange = (event) => {
        setLoading(true);
        setSelectedAge(event.target.value);

        const payload = {
            search: isSearchValue,
            categoryIds: isFilterId?.length > 0 ? isFilterId : undefined,
            includeOutOfStock: includeOutOfStock,
            age: event.target.value
        };

        dispatch(
            getSearchLatestBooks(payload, (data) => {
                setSearchData(data);
                setLoading(false);
            })
        );
    };

    const [justAdded, setJustAdded] = useState(null);

    useEffect(() => {
        setLoading(true);
        const payload = {
            search: isSearchValue,
            categoryIds: isFilterId?.length > 0 ? isFilterId : undefined,
            includeOutOfStock: includeOutOfStock,
            age: selectedAge
        };
        dispatch(
            getSearchLatestBooks(payload, (data) => {
                setSearchData(data);
                setLoading(false);
            })
        );
    }, [isSearchValue, isFilterId]);

    useEffect(() => {
        if (location?.state?.id) setSelectedCategory(location.state.id);
        dispatch(getSearchFiltersBooks());
    }, []);

    useEffect(() => {
        setLoading(true);
        let selectedIds = [];

        searchFilterData?.categories
            ?.filter((f) => f.id === selectedCategory)[0]
            ?.categories?.forEach((item) => {
                selectedIds.push(item.id);
            });

        const payload = {
            search: isSearchValue,
            categoryIds: selectedIds,
            includeOutOfStock: includeOutOfStock,
            age: selectedAge
        };
        dispatch(
            getSearchLatestBooks(payload, (data) => {
                setSearchData(data);
                setLoading(false);
            })
        );
    }, [searchFilterData]);

    return (
        <div>
            <Header />
            <br />
            <br />
            <Container maxWidth="xl">
                <Grid container item xs={12} md={12} marginBottom={'2%'}>
                    {/* <Grid container spacing={2}> */}
                    <Grid item xs={12} md={1.65} className={classes.sidenav}>
                        <PerfectScrollbar>
                            <Paper
                                style={{
                                    backgroundColor: '#3fb2cf',
                                    color: '#ffffff',
                                    padding: 8,
                                    display: 'flex',
                                    borderRadius: 8,
                                    marginBottom: 5,
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'left' }}>
                                    <Typography variant="h4" color="inherit">
                                        CATEGORIES&nbsp;&nbsp;
                                    </Typography>
                                    <FilterListIcon />
                                </div>
                            </Paper>
                            <Paper className={classes.sidenavPaper}>
                                {searchFilterData?.categories?.map((data) => (
                                    <React.Fragment key={data?.id}>
                                        <ListItem button onClick={(event) => handleClick(event, data?.id)} className={classes.listItem}>
                                            <FormControlLabel
                                                color="inherit"
                                                control={
                                                    <Checkbox
                                                        style={{ marginLeft: ' 5px' }}
                                                        onChange={(e) => handleChange(data?.id, true, e, data)}
                                                        defaultChecked={data.id === location?.state?.id}
                                                    />
                                                }
                                                label={data?.title}
                                                primaryTypographyProps={{
                                                    style: {
                                                        color: 'inherit'
                                                    }
                                                }}
                                            />
                                            {open[data?.id] || false ? (
                                                <ExpandLess></ExpandLess>
                                            ) : (
                                                <ExpandMore className={classes.expandMoreIcon} />
                                            )}
                                        </ListItem>
                                        {data?.categories?.map((items) => (
                                            <Collapse key={items?.id} in={open[data?.id] || false} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    <ListItem button className={classes.nested}>
                                                        <FormControlLabel
                                                            color="inherit"
                                                            control={
                                                                <Checkbox
                                                                    onChange={(e) => handleSubCategoryChange(items?.id, e)}
                                                                    name="checkedA"
                                                                    defaultChecked={
                                                                        selectedCategory &&
                                                                        searchFilterData?.categories
                                                                            ?.filter((f) => f.id === selectedCategory)[0]
                                                                            ?.categories.filter((f) => f.id === items.id).length > 0
                                                                    }
                                                                />
                                                            }
                                                            label={items?.title}
                                                        />
                                                    </ListItem>
                                                </List>
                                            </Collapse>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </Paper>
                            <br />
                            <FormControlLabel
                                color="inherit"
                                control={<Checkbox style={{ marginLeft: '-5px' }} onChange={(e) => handleCirculation(e)} />}
                                label="Available"
                                className={classes.categoryList}
                                style={{ width: '200px' }}
                            />

                            <br />
                            <Grid item xs={2}>
                                <Grid
                                    item
                                    style={{
                                        height: '40px',
                                        width: '200px',
                                        background: theme.palette.primary.light,
                                        display: 'flex',
                                        borderRadius: 8,
                                        marginBottom: 5,
                                        marginLeft: 2,
                                        alignItems: 'center'
                                    }}
                                >
                                    &nbsp;&nbsp;Select Age
                                </Grid>

                                <FormControl style={{ width: '61px' }}>
                                    <Select className={classes.selectDropdown} onChange={handleAgeChange}>
                                        {Array.from({ length: 15 }, (_, i) => i + 1).map((age) => (
                                            <MenuItem key={age} value={age}>
                                                {age}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </PerfectScrollbar>
                    </Grid>
                    <Grid container item xs={12} md={9} justify="center" className={classes.pageContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <HeaderBreadcrumbs
                                    heading="Books"
                                    links={[
                                        { name: 'Home', href: '/home' },
                                        { name: 'Just Added', href: '' }
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={5} textAlign="right">
                                <OutlinedInput
                                    fullWidth
                                    type="text"
                                    placeholder="Search Books/ Author/ Isbn"
                                    onChange={(event) => handleSearchBooks(event)}
                                    style={{ position: 'relative', zIndex: 222, fontWeight: 'bolder' }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <IconSearch stroke={1.5} size="1rem" className={classes.startAdornment} />
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                            &nbsp;&nbsp;&nbsp;
                        </Grid>
                        {loading ? (
                            <Grid container justifyContent="center">
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <Grid container item justifyContent="left" spacing={3}>
                                {isSearchData?.map((item, index) => (
                                    <Grid item key={index}>
                                        <Card className={classes.cardRoot}>
                                            <Box
                                                style={{ width: '100%', height: '250px', padding: '25px 0px' }}
                                                onClick={() => handleDetails(item?.id)}
                                            >
                                                <img
                                                    src={item?.imageUrls?.[0] ? item?.imageUrls?.[0] : item?.googleUrl}
                                                    className={classes.cardImg}
                                                    alt="Book"
                                                />
                                                &nbsp;
                                            </Box>
                                            <Box
                                                style={{
                                                    textAlign: 'center',
                                                    height: '130px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <Link to="" onClick={() => handleDetails(item?.id)} style={{ textDecoration: 'none' }}>
                                                    <Typography variant="h4"> {item?.title} </Typography>
                                                </Link>
                                                <Typography
                                                    variant="h6"
                                                    className={classes.statusStyle}
                                                    style={{
                                                        color:
                                                            item?.availableQuantity !== 0
                                                                ? theme.palette.success.main
                                                                : theme.palette.error.main
                                                    }}
                                                >
                                                    {' '}
                                                    {item?.availableQuantity !== 0 ? 'AVAILABLE' : 'IN CIRCULATION'}
                                                </Typography>
                                                <Box style={{ padding: '10px 0' }}>
                                                    <Button variant="contained" onClick={() => handleMove(item?.id)}>
                                                        <ShoppingCartIcon />
                                                        &nbsp;&nbsp; Add to cart
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Container>
            <FooterNew />
        </div>
    );
}
