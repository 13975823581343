import {
    GET_DELIVERY_ZONE_START,
    GET_DELIVERY_ZONE_SUCCESS,
    GET_DELIVERY_ZONE_ERROR,
    GET_ALL_DELIVERY_ZONE_START,
    GET_ALL_DELIVERY_ZONE_SUCCESS,
    GET_ALL_DELIVERY_ZONE_ERROR,
    GET_ALL_PINCODE_START,
    GET_ALL_PINCODE_SUCCESS,
    GET_ALL_PINCODE_ZONE_ERROR,
} from '../../constants';

const initialState = {
    loading: false,
    allDeliveryZones: null,
    deliveryZone: null,
    allPincodes: null,
    message: ''
};

export default function DeliveryZoneReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_DELIVERY_ZONE_START:
        case GET_ALL_DELIVERY_ZONE_START:
        case GET_ALL_PINCODE_START:
            return {
                ...state,
                loading: true
            };
        case GET_DELIVERY_ZONE_SUCCESS:
        case GET_ALL_DELIVERY_ZONE_SUCCESS:
        case GET_ALL_PINCODE_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_DELIVERY_ZONE_ERROR:
        case GET_ALL_DELIVERY_ZONE_ERROR:
        case GET_ALL_PINCODE_ZONE_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
