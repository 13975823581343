import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVoucher } from '../../store/actions/VoucherActions';
import MainCard from '../../ui-component/cards/MainCard';
import { withStyles, useTheme } from '@material-ui/styles';
import {
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    OutlinedInput,
    InputAdornment
} from '@material-ui/core';

import { IconSearch } from '@tabler/icons';
import SubjectIcon from '@material-ui/icons/Subject';
import useStyles from '../ViewTableStyles';
import useUserRole from '../../hooks/useUserRole';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

export default function GiftVouchers() {
    const classes = useStyles();
    const theme = useTheme();
    useUserRole({ roleList: ['ADMIN', 'SUPER_ADMIN'] });

    const dispatch = useDispatch();
    const [value, setValue] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const { allVouchers } = useSelector((state) => state?.voucher);
    const newData = allVouchers?.content;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        dispatch(getAllVoucher(value ? value : ''));
    }, [value]);

    return (
        <>
            <MainCard>
                <div className={classes.titleContainer}>
                    <SubjectIcon className={classes.viewIcon} />
                    <span className={classes.pageTitle}>&nbsp; All Gift Vouchers </span>
                </div>
                <Paper elevation={5} className={classes.root}>
                    <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <OutlinedInput
                                label="hello"
                                className={classes.searchControl}
                                id="input-search-header"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                placeholder="Search"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <IconSearch stroke={1.5} size="1rem" className={classes.startAdornment} />
                                    </InputAdornment>
                                }
                            />
                        </div>
                    </Grid>
                    <TableContainer className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.tableHeaderCell}>
                                <StyledTableRow>
                                    <TableCell> Code </TableCell>
                                    <TableCell> Amount </TableCell>
                                    <TableCell> Transaction Id </TableCell>
                                    <TableCell> Purchased For Email </TableCell>
                                    <TableCell> Purchased By Email </TableCell>
                                    <TableCell> Used By Email </TableCell>
                                    <TableCell> Status </TableCell>
                                </StyledTableRow>
                            </TableHead>
                            {!newData ? (
                                <caption style={{ textAlign: 'center' }}> Sorry! No data available </caption>
                            ) : (
                                <TableBody>
                                    {newData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <StyledTableRow key={row?.id}>
                                            <TableCell>{row?.code}</TableCell>
                                            <TableCell> {row?.amount} </TableCell>
                                            <TableCell> {row?.orderId} </TableCell>
                                            <TableCell> {row?.purchasedForEmail} </TableCell>
                                            <TableCell> {row?.purchasedByEmail} </TableCell>
                                            <TableCell> {row?.usedBy} </TableCell>
                                            <TableCell>
                                                <Typography
                                                    className={classes.status}
                                                    style={{
                                                        backgroundColor:
                                                            (row?.status === 'ACTIVE' && theme.palette.success.main) ||
                                                            (row?.status === 'PENDING' && 'blue') ||
                                                            (row?.status === 'INACTIVE' && theme.palette.warning.dark)
                                                    }}
                                                >
                                                    {row?.status}
                                                </Typography>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={newData?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            '.MuiTablePagination-displayedRows': {
                                marginBottom: 0
                            },
                            '.MuiTablePagination-selectLabel': {
                                marginBottom: 0
                            }
                        }}
                    />
                </Paper>
            </MainCard>
        </>
    );
}
