import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../components/hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateFeedback, getAllAssignment_Submission } from '../../../../store/actions/Assignment_Submission_Actions';
import { getAllGrade } from '../../../../store/actions/GradeActions';
import useStyles from '../../../DialogStyles';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, Link, Divider } from '@material-ui/core';
import useUserRole from '../../../../hooks/useUserRole';

const validationSchema = yup.object({
    gradeSequence: yup.string('Choose Grade sequence').required('Grade is required'),
    feedback: yup.string('Enter Feedback').required('Feedback is required'),
    includeInReport: yup.string('Choose Status').required('Status is required')
});

const STATUS_ITEMS = [
    { id: true, status: 'YES' },
    { id: false, status: 'NO' }
];

export default function UpdateFeedback(props) {
    useUserRole({ roleList: ['ADMIN', 'TEACHER','SUPER_ADMIN'] });
    const { openUpdateFeedback, setOpenUpdateFeedback, submissionId } = props;
    const { id } = useParams();
    const classes = useStyles();
    const dispatch = useDispatch();

    const { allGrades } = useSelector((state) => state?.grade);
    const gradeList = allGrades;

    const initialValues = {
        gradeSequence: '',
        feedback: '',
        includeInReport: ''
    };
    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues
    });

    const { reset, handleSubmit } = methods;

    const handlePopupClose = () => {
        setOpenUpdateFeedback(false);
    };

    const onSubmit = async (data) => {
        const payload = {
            gradeSequence: data?.gradeSequence,
            feedback: data?.feedback,
            includeInReport: data?.includeInReport
        };

        dispatch(
            updateFeedback(payload, submissionId, () => {
                dispatch(getAllAssignment_Submission(id));
            })
        );
        handlePopupClose();
    };

    useEffect(() => {
        dispatch(getAllGrade());
    }, []);

    return (
        <>
            <Dialog open={openUpdateFeedback} classes={{ paper: classes.dialog }}>
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.dialogTitleChild}>
                        <Typography style={{ fontSize: '20px' }}> Update Feedback </Typography>
                        <Link to="#" onClick={handlePopupClose}>
                            <CloseIcon className={classes.closeIcon} />
                        </Link>
                    </div>
                </DialogTitle>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <label>
                                    Select Grade Sequence <span className={classes.mandatory}>*</span>
                                </label>
                                <RHFSelect name="gradeSequence" label="">
                                    <option value=""> Select Grade Sequence </option>
                                    {gradeList?.map((item, index) => (
                                        <option key={index} value={item?.sequence}>
                                            {item?.grade}
                                        </option>
                                    ))}
                                </RHFSelect>
                                <br /> <br />
                                <label>
                                    Feedback <span className={classes.mandatory}>*</span>
                                </label>
                                <RHFTextField name="feedback" placeholder="Feedback here" />
                                <br /> <br />
                                <label>
                                    IncludeInReport <span className={classes.mandatory}>*</span>
                                </label>
                                <RHFSelect name="includeInReport" label="">
                                    <option value=""> Please Select... </option>
                                    {STATUS_ITEMS?.map((item, index) => (
                                        <option key={index} value={item?.id}>
                                            {item?.status}
                                        </option>
                                    ))}
                                </RHFSelect>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <Divider style={{ marginTop: 15 }} />
                    <DialogActions className={classes.dialogAction}>
                        <Button variant="outlined" color="secondary" className={classes.buttonCancel} onClick={handlePopupClose}>
                            Close
                        </Button>
                        <Button variant="contained" type="submit" className={classes.buttonSubmit}>
                            Save
                        </Button>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </>
    );
}
