import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    mandatory: {
        color: theme.palette.error.main
    },
    buttonSubmit: {
        height: 40,
        width: 100,
        marginRight: 10
    },
    buttonCancel: {
        height: 40,
        width: 100
    },
    dialog: {
        position: 'absolute',
        // width: 550,
        [theme.breakpoints.down('xs')]: {
            width: '80vw'
        }
    },
    dialogAction: {
        justifyContent: 'right',
        marginRight: 10,
        marginBottom: 10,
        marginTop: 10
    },
    addUserDialog: {
        width: 800,
        height: 800,
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            width: 'auto',
            height: 'auto'
        }
    },
    closeIcon: {
        color: '#FFFFFF',
        marginBottom: '-5px',
        cursor: 'pointer'
    },
    dialogTitle: {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFFFFF',
        marginBottom: 20
    },
    dialogTitleChild: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    ScrollHeight: {
        height: '100%',
        maxHeight: 'calc(100vh - 240px)'
    },

   
}));
