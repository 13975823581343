import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import {
    GET_ALL_ASSIGNMENT_SUBMISSION_START,
    GET_ALL_ASSIGNMENT_SUBMISSION_SUCCESS,
    GET_ALL_ASSIGNMENT_SUBMISSION_ERROR,
    GET_ASSIGNMENT_SUBMISSION_START,
    GET_ASSIGNMENT_SUBMISSION_SUCCESS,
    GET_ASSIGNMENT_SUBMISSION_ERROR
} from '../../constants';

const getAllAssignment_SubmissionStart = () => {
    return { type: GET_ALL_ASSIGNMENT_SUBMISSION_START };
};
const getAllAssignment_SubmissionSuccess = (payload) => {
    return { type: GET_ALL_ASSIGNMENT_SUBMISSION_SUCCESS, payload };
};
const getAllAssignment_SubmissionError = (error) => {
    return { type: GET_ALL_ASSIGNMENT_SUBMISSION_ERROR, error };
};

const getAssignment_SubmissionStart = () => {
    return { type: GET_ASSIGNMENT_SUBMISSION_START };
};
const getAssignment_SubmissionSuccess = (payload) => {
    return { type: GET_ASSIGNMENT_SUBMISSION_SUCCESS, payload };
};
const getAssignment_SubmissionError = (error) => {
    return { type: GET_ASSIGNMENT_SUBMISSION_ERROR, error };
};

const addFeedbackDocument = (id, link, successCB) => (dispatch) => {
    try {
        http(
            process.env.REACT_APP_ROOT_URL_BATCHES,
            'POST',
            `batches-service/assignments/submissions/${id}/feedback/document-links/${link}`,
            true
        )
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message));
                    successCB && successCB(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const updateFeedback = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'PUT', `batches-service/assignments/submissions/${id}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message));
                    successCB && successCB(res);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const deleteFeedbackDoc = (id, link, successCB) => (dispatch) => {
    try {
        http(
            process.env.REACT_APP_ROOT_URL_BATCHES,
            'DELETE',
            `batches-service/assignments/submissions/${id}/feedback/document-links?link=${link}`,
            true
        )
            .then((res) => {
                dispatch(setMessage('Assignment_Submission deleted successfully'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const deleteSubmissionDoc = (id, link, successCB) => (dispatch) => {
    try {
        http(
            process.env.REACT_APP_ROOT_URL_BATCHES,
            'DELETE',
            `batches-service/assignments/submissions/${id}/document-links?link=${link}`,
            true
        )
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Submission document deleted successfully!!'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const getAssignment_Submission = (id) => (dispatch) => {
    dispatch(getAssignment_SubmissionStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'GET', `batches-service/assignments/submissions/${id}`, true)
            .then((res) => {
                const payload = {
                    getAssignment_Submission_Data: res
                };
                dispatch(getAssignment_SubmissionSuccess(payload));
            })
            .catch((err) => {
                dispatch(getAssignment_SubmissionError(err));
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const getAllAssignment_Submission =
    (id, searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllAssignment_SubmissionStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_BATCHES,
                'GET',
                `batches-service/assignments/${id}/submissions?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allAssignment_Submissions: res
                    };
                    dispatch(getAllAssignment_SubmissionSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllAssignment_SubmissionError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllAssignment_SubmissionError('Something went wrong'));
        }
    };

export {
    updateFeedback,
    getAllAssignment_Submission,
    addFeedbackDocument,
    deleteFeedbackDoc,
    getAssignment_Submission,
    deleteSubmissionDoc
};
