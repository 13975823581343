import {
    GET_ALL_BOOKS_START,
    GET_ALL_BOOKS_SUCCESS,
    GET_ALL_BOOKS_ERROR,
    ADD_BOOKS_ERROR,
    ADD_BOOKS_START,
    ADD_BOOKS_SUCCESS,
    GET_ID_BOOKS_ERROR,
    GET_ID_BOOKS_START,
    GET_ID_BOOKS_SUCCESS,
    GET_SEARCH_FILTERS_ERROR,
    GET_SEARCH_FILTERS_SUCCESS,
    GET_SEARCH_FILTERS_START,
    CLEAR_GET_ID_BOOKS_DATA,
    SEARCH_DATA_ERROR,
    SEARCH_DATA_START,
    SEARCH_DATA_SUCCESS,
    GET_SEARCH_FILTERS_TOP,
    GET_SEARCH_FILTERS_TOP_SUCCESS,
    GET_SEARCH_FILTERS_TOP_ERROR
} from '../../constants';
import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';

const getAllBooksStart = () => {
    return { type: GET_ALL_BOOKS_START };
};
const getAllBooksSuccess = (payload) => {
    return { type: GET_ALL_BOOKS_SUCCESS, payload };
};
const getAllBooksError = (error) => {
    return { type: GET_ALL_BOOKS_ERROR, error };
};
const addBooksStart = () => {
    return { type: ADD_BOOKS_START };
};
const addBooksSuccess = (payload) => {
    return { type: ADD_BOOKS_SUCCESS, payload };
};
const addBooksError = (error) => {
    return { type: ADD_BOOKS_ERROR, error };
};
const getIdBooksStart = () => {
    return { type: GET_ID_BOOKS_START };
};
const getIdBooksSuccess = (payload) => {
    return { type: GET_ID_BOOKS_SUCCESS, payload };
};
const getIdBooksError = (error) => {
    return { type: GET_ID_BOOKS_ERROR, error };
};
const getSearchFiltersBooksStart = () => {
    return { type: GET_SEARCH_FILTERS_START };
};
const getSearchFiltersBooksTop = () => {
    return { type: GET_SEARCH_FILTERS_TOP };
};
const getSearchFiltersBooksTopSuccess = () => {
    return { type: GET_SEARCH_FILTERS_TOP_SUCCESS };
};
const getSearchFiltersBooksTopFail = () => {
    return { type: GET_SEARCH_FILTERS_TOP_ERROR };
};
const getSearchFiltersBooksSuccess = (payload) => {
    return { type: GET_SEARCH_FILTERS_SUCCESS, payload };
};
const getSearchFiltersBooksError = (error) => {
    return { type: GET_SEARCH_FILTERS_ERROR, error };
};
const searchBooksStart = () => {
    return { type: SEARCH_DATA_START };
};
const searchBooksSuccess = (payload) => {
    return { type: SEARCH_DATA_SUCCESS, payload };
};
const searchBooksError = (error) => {
    return { type: SEARCH_DATA_ERROR, error };
};

const getBooks =
    (searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllBooksStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_BOOK,
                'GET',
                `books-service/books?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allBooks: res
                    };
                    dispatch(getAllBooksSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllBooksError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllBooksError('Something went wrong'));
        }
    };
const getDeletedBooks =
    (searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllBooksStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_BOOK,
                'GET',
                `books-service/books/deleted?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allBooks: res
                    };
                    dispatch(getAllBooksSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllBooksError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllBooksError('Something went wrong'));
        }
    };
const getByIdBooks = (bookId) => (dispatch) => {
    dispatch(getIdBooksStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'GET', `books-service/books/${bookId}`, true)
            .then((res) => {
                const payload = {
                    bookByIdData: res
                };
                dispatch(getIdBooksSuccess(payload));
            })
            .catch((err) => {
                dispatch(getIdBooksError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getIdBooksError('Something went wrong'));
    }
};

const getBooksDetails = (bookId) => (dispatch) => {
    dispatch(getIdBooksStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'GET', `books-service/books/${bookId}/detail`, false)
            .then((res) => {
                const payload = {
                    bookDetails: res
                };
                dispatch(getIdBooksSuccess(payload));
            })
            .catch((err) => {
                dispatch(getIdBooksError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getIdBooksError('Something went wrong'));
    }
};

const updateBooks = (payload, bookId, successCb) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'PUT', `books-service/books/${bookId}`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCb && successCb(res);
            })
            .catch((err) => {
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(setMessage(error?.message));
    }
};
const deleteBooks = (payload, bookId, successCb) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'PUT', `books-service/books/${bookId}/status`, payload, true)
            .then((res) => {
                dispatch(setMessage(payload?.status==='INACTIVE' ? 'Deleted Successfully!' : 'Restore Successfully!'));
                successCb && successCb(res);
            })
            .catch((err) => {
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(setMessage(error?.message));
    }
};
const addBooks = (payload, successCb) => (dispatch) => {
    dispatch(addBooksStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'POST', `books-service/books`, payload, true)
            .then((res) => {
                dispatch(addBooksSuccess(res));
                dispatch(setMessage(res?.message));
                successCb && successCb(res);
            })
            .catch((err) => {
                dispatch(addBooksError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(addBooksError(error?.message));
    }
};
const getSearchFiltersBooks = () => (dispatch) => {
    dispatch(getSearchFiltersBooksStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'GET', `books-service/books/search/filters`)
            .then((res) => {
                const payload = {
                    searchFilterData: res
                };
                dispatch(getSearchFiltersBooksSuccess(payload));
                dispatch(setMessage(res?.message));
            })
            .catch((err) => {
                dispatch(getSearchFiltersBooksError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getSearchFiltersBooksError(error?.message));
    }
};
const getSearchFiltersTopBooks = () => (dispatch) => {
    dispatch(getSearchFiltersBooksTop());
    try {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'GET', `books-service/books/top-picked`)
            .then((res) => {
                const payload = {
                    searchFilterDataTop: res
                };
                dispatch(getSearchFiltersBooksTopSuccess(payload));
                dispatch(setMessage(res?.message));
            })
            .catch((err) => {
                dispatch(getSearchFiltersBooksTopFail(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getSearchFiltersBooksTopFail(error?.message));
    }
};
const getSearchBooks = (payload, successCb) => (dispatch) => {
    dispatch(searchBooksStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'POST', `books-service/books/search`, payload)
            .then((res) => {
                const payload = {
                    searchBooksData: res
                };
                dispatch(searchBooksSuccess(payload));
                dispatch(setMessage(res?.message));
                successCb && successCb(res);
            })
            .catch((err) => {
                dispatch(searchBooksError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(searchBooksError(error?.message));
    }
};

const getSearchLatestBooks = (payload, successCb) => (dispatch) => {
    dispatch(searchBooksStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BOOK, 'POST', `books-service/books/latest/search`, payload)
            .then((res) => {
                const payload = {
                    searchBooksData: res
                };
                dispatch(searchBooksSuccess(payload));
                dispatch(setMessage(res?.message));
                successCb && successCb(res);
            })
            .catch((err) => {
                dispatch(searchBooksError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(searchBooksError(error?.message));
    }
};

const clearGetByIdBooksData = () => ({
    type: CLEAR_GET_ID_BOOKS_DATA
});
export { getBooks,deleteBooks,getDeletedBooks, addBooks, getByIdBooks, updateBooks, getSearchFiltersBooks, clearGetByIdBooksData,getSearchLatestBooks, getSearchBooks, getBooksDetails, getSearchFiltersTopBooks };
