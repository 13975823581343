import {
    GET_ALL_VOUCHER_START,
    GET_ALL_VOUCHER_SUCCESS,
    GET_ALL_VOUCHER_ERROR,
    GET_VOUCHER_START,
    GET_VOUCHER_SUCCESS,
    GET_VOUCHER_ERROR
} from '../../constants';

const initialState = {
    loading: false,
    allVouchers: null,
    getVoucherData: null,
    message: ''
};

export default function GetAllVoucherReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ALL_VOUCHER_START:
        case GET_VOUCHER_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_VOUCHER_SUCCESS:
        case GET_VOUCHER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ALL_VOUCHER_ERROR:
        case GET_VOUCHER_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
