import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography, Button, Divider, ButtonGroup } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { getCart, updateCart, deleteBookFromCart } from '../../../store/actions/CartActions';
import { getWishlist } from '../../../store/actions/WishlistActions';
import { moveWishlist, placeProfileOrder, getProfileOrder } from '../../../store/actions/OrderActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/Loader/Loader';
import { getDeliveryPlan } from '../../../store/actions/DeliveryPlanActions';
import { getProfileCart } from './../../../services/CartServices';

const useStyles = makeStyles((theme) => ({
    productBox: {
        marginLeft: 20
    },
    itemContainer: {
        marginTop: 20,
        borderColor: theme.palette.text.secondary,
        padding: 10,
        borderRadius: 8
    }
}));

export default function Cart({ active }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { loggedUserProfile } = useSelector((state) => state?.userProfile);
    const [isLoading, setLoading] = useState(false);
    const [isDecreasing, setDecreasing] = useState(false);
    const profile_id = loggedUserProfile?.profileId;
    const [deliveryPlan, setDeliveryPlan] = useState([]);
    const [cart, setCart] = useState([]);
    const [profileCart, setProfileCart] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleIncrease = (cartId, bookId) => {
        setLoading(true);
        const payload = {
            bookId: bookId,
            quantity: 1
        };
        dispatch(
            updateCart(payload, cartId, () => {
                dispatch(getCart(`${profile_id}`));
                setLoading(false);
            })
        );
    };

    const handleDecrease = (cartId, bookId, quantity) => {
        setDecreasing(true);
        setLoading(true);
        const payload = {
            bookId: bookId,
            quantity: -1
        };

        if (quantity > 1) {
            setTimeout(() => {
                dispatch(
                    updateCart(payload, cartId, () => {
                        dispatch(getCart(`${profile_id}`));
                        setLoading(false);
                        setDecreasing(false);
                    })
                );
            }, 500);
        }

        return;
    };

    const handleDelete = (cartId, bookId) => {
        setLoading(true);
        dispatch(
            deleteBookFromCart(cartId, bookId, () => {
                setTimeout(() => {
                    window.location.reload();
                    dispatch(getProfileCart(`${profile_id}`));
                    setLoading(false);
                }, 2000);
            })
        );
        setLoading(false);
    };
    const handleMoveToWishlist = (bookId) => {
        setLoading(true);
        dispatch(
            moveWishlist(bookId, () => {
                setTimeout(() => {
                    window.location.reload();
                    dispatch(getProfileCart(`${profile_id}`));
                    dispatch(getWishlist(`${profile_id}`));
                    setLoading(false);
                }, 2000);
            })
        );
        setLoading(false);
    };

    const handlePlaceOrder = async () => {
        // setLoading(true);
        setIsProcessing(true)

        try {
            const res = await dispatch(placeProfileOrder(profile_id,'','', () => {
                // setLoading(false);
                setTimeout(() => {
                    window.location.reload();
                    dispatch(getProfileCart(`${profile_id}`));
                    dispatch(getProfileOrder(`${profile_id}`));
                    setIsProcessing(false);
                }, 2000);


            }));
        } catch (error) {
            setIsProcessing(false);
        }

    };

    useEffect(() => {
        const getCartData = async () => {
            try {
                const response = await getProfileCart(`${profile_id}`);
                const data = await response?.data;
                setProfileCart(data);
            } catch (error) {
                console.log(error);
            }
        };

        if (profile_id) {
            getCartData();
        }

        const getDeliveryPlanData = async () => {
            try {
                const res = await getDeliveryPlan(`${profile_id}`);
                setDeliveryPlan(res?.data);
            } catch (error) {
                console.log(error);
            }
        };

        if (profile_id) {
            getDeliveryPlanData();
        }
    }, [profile_id]);


    return (
        <>
            {active === 'SecondCard' && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="h3">
                            {!profileCart?.content || profileCart?.content?.length === 0 ? (
                                <Typography variant="h3" style={{ marginBottom: 80 }}>
                                    Your cart is empty
                                    <br />
                                    <br />
                                    {deliveryPlan?.deliveryDate ? (
                                        <Typography style={{ fontSize: 16 }}>Next delivery date : {deliveryPlan?.deliveryDate}</Typography>
                                    ) : ('')}
                                </Typography>
                            ) : (
                                <Typography variant="h3" style={{ marginBottom: 80 }}>
                                    No. of books in cart: <Typography style={{ fontSize: 16 }}>{profileCart?.content?.length}</Typography>
                                    <br />
                                    Next delivery date : <Typography style={{ fontSize: 16 }}>{deliveryPlan?.deliveryDate}</Typography>
                                </Typography>
                            )}
                        </Typography>
                        <Divider style={{ marginTop: 10, marginBottom: 30 }} />
                        {!profileCart?.content || profileCart?.content?.length === 0 ? (
                            ''
                        ) : (
                            <>
                                {profileCart?.content?.map((item, index) => (
                                    <Grid container xs={12} className={classes.itemContainer} key={index}>
                                        <Grid item xs={10}>
                                            <Box display="flex">
                                                <img
                                                    src={item?.bookDetail?.imageUrls}
                                                    alt="product"
                                                    width="120px"
                                                    height="150px"
                                                    style={{ borderRadius: 8 }}
                                                />
                                                <Box className={classes.productBox}>
                                                    <Typography fontSize={15} fontWeight={'lighter'} variant="subtitle1">{item?.bookDetail?.title}</Typography>
                                                    <Typography fontSize={13} variant="subtitle2"> Delivery date: {item?.deliveryDate} </Typography>
                                                    <Grid style={{ display: 'flex' }}>
                                                        <ButtonGroup
                                                            color="primary"
                                                            size="small"
                                                            aria-label="outlined primary button group"
                                                            style={{ marginTop: 20, marginBottom: 20 }}
                                                        >
                                                            {/* <Button
                                                                onClick={() =>
                                                                    handleDecrease(item?.cartId, item?.bookDetail?.id, item?.quantity)
                                                                }
                                                                disabled={item?.quantity < 2 || isDecreasing}
                                                            >
                                                                <RemoveIcon />
                                                            </Button>
                                                            <Button disabled>
                                                                <span style={{ color: 'black' }}>
                                                                    {item?.quantity < 1 ? 1 : item?.quantity}
                                                                </span>
                                                            </Button>
                                                            <Button onClick={() => handleIncrease(item?.cartId, item?.bookDetail?.id)}>
                                                                <AddIcon />
                                                            </Button> */}
                                                        </ButtonGroup>
                                                        &nbsp;
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'flex-end',
                                                                marginLeft: "48px",

                                                            }}
                                                        >
                                                            {/* <Button
                                                                color="error"
                                                                fontSize="small"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    marginTop: 25,
                                                                    marginBottom: 20,
                                                                    marginLeft: 180
                                                                }}
                                                                onClick={() => handleDelete(item?.cartId, item?.bookDetail?.id)}
                                                            > */}
                                                            <Button style={{ backgroundColor: "red", color: "white", height: "27px", marginTop: "7px" }}
                                                                onClick={() => handleDelete(item?.cartId, item?.bookDetail?.id)}>Remove</Button>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography
                                                        variant="subtitle1"
                                                        color="primary"
                                                        onClick={() => {
                                                            handleMoveToWishlist(item?.bookDetail?.id);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        Move to wishlist
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                ))}
                                {/* <div style={{ marginTop: 25 }}>
                                    <TextField placeholder="Enter coupon code" />
                                </div> */}
                            </>
                        )}
                    </Grid>
                    {!profileCart?.content || profileCart?.content.length === 0 ? (
                        ''
                    ) : (
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            {/* <Typography variant="h3"> Order Summary </Typography>
                            <Divider style={{ marginTop: 10, marginBottom: 30 }} /> */}
                            {/* <Typography variant="body2">Shipping address</Typography> */}
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={!profileCart?.content || profileCart?.content.length === 0 || isProcessing}
                                style={{ marginTop: 30, marginBottom: 10 }}
                                onClick={() => handlePlaceOrder()}
                            >
                                {isProcessing ? "Processing..." : "Place Order"}

                            </Button>
                            <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                                <LockIcon fontSize="22px" /> Secure Checkout
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            )}
            {isLoading && <Loader />}
        </>
    );
}
