import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import {
    GET_SUBSCRIPTION_START,
    GET_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_ERROR,
    GET_ALL_SUBSCRIPTION_START,
    GET_ALL_SUBSCRIPTION_SUCCESS,
    GET_ALL_SUBSCRIPTION_ERROR,
    GET_ALL_BOOKTRIALS_START,
    GET_ALL_BOOKTRIALS_SUCCESS,
    GET_ALL_BOOKTRIALS_ERROR,
    GET_PLAN_START,
    GET_PLAN_SUCCESS,
    GET_PLAN_ERROR,
    GET_ALL_SECURITY_DEPOSITS_SUCCESS,
    GET_ALL_SECURITY_DEPOSITS_ERROR,
    GET_ALL_SECURITY_DEPOSITS_START,
    GET_ALL_PLAN_START,
    GET_ALL_PLAN_SUCCESS,
    GET_ALL_PLAN_ERROR,
    GET_ALL_USER_PLAN_START,
    GET_ALL_USER_PLAN_SUCCESS,
    GET_ALL_USER_PLAN_ERROR,
    GET_PLAN_OPTION_START,
    GET_PLAN_OPTION_SUCCESS,
    GET_PLAN_OPTION_ERROR,
    GET_ALL_PLAN_OPTION_START,
    GET_ALL_PLAN_OPTION_SUCCESS,
    GET_ALL_PLAN_OPTION_ERROR
} from '../../constants';

const getSubscriptionStart = () => {
    return { type: GET_SUBSCRIPTION_START };
};
const getSubscriptionSuccess = (payload) => {
    return { type: GET_SUBSCRIPTION_SUCCESS, payload };
};
const getSubscriptionError = (error) => {
    return { type: GET_SUBSCRIPTION_ERROR, error };
};

const getAllSubscriptionStart = () => {
    return { type: GET_ALL_SUBSCRIPTION_START };
};
const getAllSubscriptionSuccess = (payload) => {
    return { type: GET_ALL_SUBSCRIPTION_SUCCESS, payload };
};
const getAllSubscriptionError = (error) => {
    return { type: GET_ALL_SUBSCRIPTION_ERROR, error };
};
const getAllBookTrialsStart = () => {
    return { type: GET_ALL_BOOKTRIALS_START };
};
const getAllBookTrialsSuccess = (payload) => {
    return { type: GET_ALL_BOOKTRIALS_SUCCESS, payload };
};
const getAllBookTrialsError = (error) => {
    return { type: GET_ALL_BOOKTRIALS_ERROR, error };
};

const getPlanStart = () => {
    return { type: GET_PLAN_START };
};
const getPlanSuccess = (payload) => {
    return { type: GET_PLAN_SUCCESS, payload };
};
const getPlanError = (error) => {
    return { type: GET_PLAN_ERROR, error };
};
const getAllSecurityDepositsStart = () => {
    return { type: GET_ALL_SECURITY_DEPOSITS_START };
};
const getAllSecurityDepositsSuccess = (payload) => {
    return { type: GET_ALL_SECURITY_DEPOSITS_SUCCESS, payload };
};
const getAllSecurityDepositsError = (error) => {
    return { type: GET_ALL_SECURITY_DEPOSITS_ERROR, error };
};
const getAllPlansStart = () => {
    return { type: GET_ALL_PLAN_START };
};
const getAllPlansSuccess = (payload) => {
    return { type: GET_ALL_PLAN_SUCCESS, payload };
};
const getAllPlansError = (error) => {
    return { type: GET_ALL_PLAN_ERROR, error };
};
const getUserPlansStart = () => {
    return { type: GET_ALL_USER_PLAN_START };
};
const getUserPlansSuccess = (payload) => {
    return { type: GET_ALL_USER_PLAN_SUCCESS, payload };
};
const getUserPlansError = (error) => {
    return { type: GET_ALL_USER_PLAN_ERROR, error };
};

const getPlanOptionStart = () => {
    return { type: GET_PLAN_OPTION_START };
};
const getPlanOptionSuccess = (payload) => {
    return { type: GET_PLAN_OPTION_SUCCESS, payload };
};
const getPlanOptionError = (error) => {
    return { type: GET_PLAN_OPTION_ERROR, error };
};

const getAllPlansOptionStart = () => {
    return { type: GET_ALL_PLAN_OPTION_START };
};
const getAllPlansOptionSuccess = (payload) => {
    return { type: GET_ALL_PLAN_OPTION_SUCCESS, payload };
};
const getAllPlansOptionError = (error) => {
    return { type: GET_ALL_PLAN_OPTION_ERROR, error };
};

const addSubscription = (payload, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/subscriptions`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
            });
    } catch (error) {
        console.log(error);
    }
};

const getSubscription = (id) => (dispatch, getState) => {
    dispatch(getSubscriptionStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/plan-subscriptions/${id}`, true)
            .then((res) => {
                const payload = {
                    subscriptionData: res
                };
                dispatch(getSubscriptionSuccess(payload));
            })
            .catch((err) => {
                dispatch(getSubscriptionError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getSubscriptionError('Something went wrong'));
    }
};

const getAllSubscription =
    (searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllSubscriptionStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/plan-subscriptions?pageNumber=${page}&pageSize=${rowsPerPage}&type=HOME&userSearchKey=${searchData}&planSearchKey=${searchData}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allSubscription: res
                    };
                    dispatch(getAllSubscriptionSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllSubscriptionError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllSubscriptionError('Something went wrong'));
        }
    };

const getAllExpiredSubscription =
    (searchData = '', page = 1, rowsPerPage = 10, type = '', expiration = '') =>
    (dispatch) => {
        dispatch(getAllSubscriptionStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/plan-subscriptions/expired?pageNumber=${page}&pageSize=${rowsPerPage}&type=${type}&userSearchKey=${searchData}&planSearchKey=${searchData}&expirationFilter=${expiration}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allSubscription: res
                    };
                    dispatch(getAllSubscriptionSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllSubscriptionError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllSubscriptionError('Something went wrong'));
        }
    };

const getAllBookTrials =
    (searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllBookTrialsStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/book-trials?pageNumber=${page}&pageSize=${rowsPerPage}&searchKey=${searchData}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allBookTrials: res
                    };
                    dispatch(getAllBookTrialsSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllBookTrialsError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllBookTrialsError('Something went wrong'));
        }
    };
const getAllDeletedBookTrials =
    (searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllBookTrialsStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/book-trials/deleted?pageNumber=${page}&pageSize=${rowsPerPage}&searchKey=${searchData}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allBookTrials: res
                    };
                    dispatch(getAllBookTrialsSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllBookTrialsError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllBookTrialsError('Something went wrong'));
        }
    };

const deleteBookTrials = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'DELETE', `subscriptions-service/book-trials/${id}`, payload, true)
            .then((res) => {
                dispatch(setMessage(payload?.deleted ? 'Deleted Successfully!' : 'Restore Successfully!'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const getAllOnlineSubscription = () => (dispatch, getState) => {
    dispatch(getAllSubscriptionStart());
    try {
        http(
            process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
            'GET',
            `subscriptions-service/subscriptions?pageNumber=1&pageSize=20&type=ONLINE_CLASS`,
            true
        )
            .then((res) => {
                const payload = {
                    allSubscription: res
                };
                dispatch(getAllSubscriptionSuccess(payload));
            })
            .catch((err) => {
                dispatch(getAllSubscriptionError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getAllSubscriptionError('Something went wrong'));
    }
};

const getProfileSubscription = (id) => (dispatch) => {
    dispatch(getSubscriptionStart());
    try {
        http(
            process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
            'GET',
            `subscriptions-service/subscriptions/profiles/${id}?pageNumber=1&pageSize=20&type=LIBRARY_PLAN`,
            true
        )
            .then((res) => {
                const payload = {
                    subscriptionData: res
                };
                dispatch(getSubscriptionSuccess(payload));
            })
            .catch((err) => {
                dispatch(getSubscriptionError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getSubscriptionError('Something went wrong'));
    }
};

const getInvoice = (id) => (dispatch, getState) => {
    dispatch(getSubscriptionStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/plan-subscriptions/${id}/invoice`, true)
            .then((res) => {
                const payload = {
                    subscriptionData: res
                };
                dispatch(getSubscriptionSuccess(payload));
            })
            .catch((err) => {
                dispatch(getSubscriptionError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getSubscriptionError('Something went wrong'));
    }
};

const addPlan = (payload, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/plans`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const updatePlan = (payload, id, message, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'PUT', `subscriptions-service/plans/${id}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(message));
                    successCB && successCB(res);
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const getPlan = (id) => (dispatch) => {
    dispatch(getPlanStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/plans/${id}`, true)
            .then((res) => {
                const payload = {
                    getPlan: res
                };
                dispatch(getPlanSuccess(payload));
            })
            .catch((err) => {
                dispatch(getPlanError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getPlanError('Something went wrong'));
    }
};

const getAllPlan =
    (rowsPerPage = 25, searchData = '', page = '1') =>
    (dispatch) => {
        dispatch(getAllPlansStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/plans?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllPlans: res
                    };
                    dispatch(getAllPlansSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllPlansError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllPlansError('Something went wrong'));
        }
    };

const getAllDeletedPlan =
    (rowsPerPage = 25, searchData = '', page = '1') =>
    (dispatch) => {
        dispatch(getAllPlansStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/plans/deleted?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        getAllPlans: res
                    };
                    dispatch(getAllPlansSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllPlansError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllPlansError('Something went wrong'));
        }
    };

// For Dropdown use
const getAllPlanDropdown = () => (dispatch) => {
    dispatch(getAllPlansStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', 'subscriptions-service/plans', true)
            .then((res) => {
                const payload = {
                    getAllPlans: res
                };
                dispatch(getAllPlansSuccess(payload));
            })
            .catch((err) => {
                dispatch(getAllPlansError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getAllPlansError('Something went wrong'));
    }
};

const getAllPlanDropdownOption = (userId, profileId) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/plans/options/user/${userId}/${profileId}`, true)
            .then((res) => {
                const payload = {
                    getUserPlan: res
                };
                dispatch(getUserPlansSuccess(payload));
            })
            .catch((err) => {
                dispatch(getUserPlansError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getUserPlansError('Something went wrong'));
    }
};

const addPlanOption = (payload, id) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/plans/${id}/options`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const updatePlanOption = (payload, id) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'PUT', `subscriptions-service/plans/options/${id}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message));
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const getPlanOption = (id) => (dispatch) => {
    dispatch(getPlanOptionStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/plans/options/${id}`, true)
            .then((res) => {
                const payload = {
                    planOption: res
                };
                dispatch(getPlanOptionSuccess(payload));
            })
            .catch((err) => {
                dispatch(getPlanOptionError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getPlanOptionError('Something went wrong'));
    }
};

const getAllPlanOptions =
    (id, rowsPerPage = 10, searchData = '', page = '1') =>
    (dispatch) => {
        dispatch(getAllPlansOptionStart());
        try {
            http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/plans/${id}/options`, true)
                .then((res) => {
                    const payload = {
                        allPlanOptions: res
                    };
                    dispatch(getAllPlansOptionSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllPlansOptionError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllPlansOptionError('Something went wrong'));
        }
    };

const getAllPlanOptionsDropdown = (id) => (dispatch) => {
    dispatch(getAllPlansOptionStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/plans/${id}/options`, true)
            .then((res) => {
                const payload = {
                    allPlanOptions: res
                };
                dispatch(getAllPlansOptionSuccess(payload));
            })
            .catch((err) => {
                dispatch(getAllPlansOptionError(err));
                dispatch(setMessage(err?.message));
            });
    } catch (error) {
        dispatch(getAllPlansOptionError('Something went wrong'));
    }
};

const getAllSecurityDeposits =
    (rowsPerPage = 10, searchData = '', page = 1) =>
    (dispatch) => {
        dispatch(getAllSecurityDepositsStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/library-security-deposits?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    console.log('service res', res);
                    const payload = {
                        allSecurityDeposit: res
                    };
                    dispatch(getAllSecurityDepositsSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllSecurityDepositsError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllSecurityDepositsError('Something went wrong'));
        }
    };

export {
    getAllExpiredSubscription,
    getAllSecurityDeposits,
    addSubscription,
    getSubscription,
    getAllSubscription,
    getAllOnlineSubscription,
    getProfileSubscription,
    getInvoice,
    addPlan,
    updatePlan,
    getPlan,
    getAllPlan,
    getAllPlanDropdown,
    addPlanOption,
    updatePlanOption,
    getPlanOption,
    getAllPlanOptions,
    getAllPlanOptionsDropdown,
    getAllPlanDropdownOption,
    getAllBookTrials,
    deleteBookTrials,
    getAllDeletedPlan,
    getAllDeletedBookTrials
};
