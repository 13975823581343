import {
    GET_ORDER_START,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    GET_ALL_ORDER_START,
    GET_ALL_ORDER_SUCCESS,
    GET_ALL_PENDING_BOOKS_START,
    GET_ALL_PENDING_BOOKS_SUCCESS,
    GET_ALL_PENDING_BOOKS_ERROR,
    GET_ALL_ORDER_ERROR,
    GET_PROFILE_ORDER_START,
    GET_PROFILE_ORDER_SUCCESS,
    GET_PROFILE_ORDER_ERROR,
    GET_BOOK_ORDER_HISTORY_START,
    GET_BOOK_ORDER_HISTORY_SUCCESS,
    GET_BOOK_ORDER_HISTORY_ERROR,
} from '../../constants';

const initialState = {
    loading: false,
    allOrderData: null,
    profileOrderData: null,
    pendingBookData: null,
    orderData: null,
    bookOrderHistory: null,
    message: ''
};

export default function OrderReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case GET_ORDER_START:
        case GET_ALL_ORDER_START:
        case GET_PROFILE_ORDER_START:
        case GET_BOOK_ORDER_HISTORY_START:
            return {
                ...state,
                loading: true
            };
        case GET_ALL_PENDING_BOOKS_START:
            return {
                ...state,
                loading: true
            };
        case GET_ORDER_SUCCESS:
        case GET_ALL_ORDER_SUCCESS:
        case GET_ALL_PENDING_BOOKS_SUCCESS:
        case GET_PROFILE_ORDER_SUCCESS:
        case GET_BOOK_ORDER_HISTORY_SUCCESS:
            return {
                ...state,
                ...action.payload,
                loading: false
            };
        case GET_ORDER_ERROR:
        case GET_ALL_ORDER_ERROR:
        case GET_ALL_PENDING_BOOKS_ERROR:
        case GET_PROFILE_ORDER_ERROR:
        case GET_BOOK_ORDER_HISTORY_ERROR:
            return {
                ...state,
                loading: false,
                message: action.error.message
            };
        default:
            return state;
    }
}
