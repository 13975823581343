import { CircularProgress, Box, Typography } from "@material-ui/core";

const CircularProgressLoader = () => {
    return (
        <Box sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: "center",
            margin: 'auto',
        }}><CircularProgress style={{ color: "#10B4E3", width: 80, height: 80 }} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary" style={{ fontWeight: 'lighter' }}>Loading... </Typography>
            </Box>
        </Box >
    );
}

export default CircularProgressLoader;