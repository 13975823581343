import React from 'react';
import { Card, Box, Grid } from '@mui/material';
import testimonialBg from '../../../../assets/images/homepageOne/testimonialBg.png';
import testimonialImg from '../../../../assets/images/homepageOne/testimonialImage.png';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundImage: `url(${testimonialBg})`,
        backgroundSize: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: '500px'
        }
    }
}));

export default function TestimonialCard({ title, user, description, testImage }) {
    const classes = useStyles();
    return (
        <>
            <Card className={classes.root} elevation={0}>
                <Grid
                    container
                    spacing={2}
                    sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', padding: 5, height: '300px' }}
                >
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                fontFamily: 'Josefin Sans',
                                fontWeight: 'lighter',
                                fontSize: '18px',
                                marginBottom: '16px',
                                textAlign: 'start',
                                paddingLeft: 10
                            }}
                        >
                            <i> {`${title},`} </i> <span style={{ fontWeight: 'lighter' }}> {user} </span>
                        </Box>

                        <p
                            style={{
                                fontFamily: 'Josefin Sans',
                                fontWeight: 'lighter',
                                color: '#012A36',
                                fontSize: '20px',
                                textAlign: 'start',
                                paddingLeft: 50,
                                lineHeight: 1.4
                            }}
                        >
                            {description}
                        </p>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <Box sx={{ width: '75%', height: '10%', paddingLeft: 20 }}>
                            <img src={testImage} alt="childWithFather" sx={{ width: '100%' }} />
                        </Box>
                    </Grid> */}
                </Grid>
            </Card>
        </>
    );
}
