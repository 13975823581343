// MESSAGE
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

// LOGIN
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
// Forget Password

export const FORGET_PASSWORD_START = 'FORGET_PASSWORD_START';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';

// Reset Password

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

// CHANGE PASSWORD
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

//Register email
export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

//Register otp
export const REGISTER_USER_OTP_START = 'REGISTER_USER_OTP_START';
export const REGISTER_USER_OTP_SUCCESS = 'REGISTER_USER_OTP_SUCCESS';
export const REGISTER_USER_OTP_ERROR = 'REGISTER_USER_OTP_ERROR';

// ADD USER
export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

// GET ADD USER PROFILE
export const GET_ALL_USER_PROFILE_START = 'GET_ALL_USER_PROFILE_START';
export const GET_ALL_USER_PROFILE_SUCCESS = 'GET_ALL_USER_PROFILE_SUCCESS';
export const GET_ALL_USER_PROFILE_ERROR = 'GET_ALL_USER_PROFILE_ERROR';

// GET ALL PROFILE
export const GET_ALL_PROFILE_START = 'GET_ALL_PROFILE_START';
export const GET_ALL_PROFILE_SUCCESS = 'GET_ALL_PROFILE_SUCCESS';
export const GET_ALL_PROFILE_ERROR = 'GET_ALL_PROFILE_ERROR';

// UPDATE PROFILE
export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

// GET LOGGED USER PROFILE
export const LOGGED_USER_PROFILE_START = 'LOGGED_USER_PROFILE_START';
export const LOGGED_USER_PROFILE_SUCCESS = 'LOGGED_USER_PROFILE_SUCCESS';
export const LOGGED_USER_PROFILE_ERROR = 'LOGGED_USER_PROFILE_ERROR';

// GET ALL PARENT CATEGORY
export const GET_ALL_PARENT_CATEGORY_START = 'GET_ALL_PARENT_CATEGORY_START';
export const GET_ALL_PARENT_CATEGORY_SUCCESS = 'GET_ALL_PARENT_CATEGORY_SUCCESS';
export const GET_ALL_PARENT_CATEGORY_ERROR = 'GET_ALL_PARENT_CATEGORY_ERROR';

// GET ALL CATEGORY
export const GET_ALL_CATEGORY_START = 'GET_ALL_CATEGORY_START';
export const GET_ALL_CATEGORY_SUCCESS = 'GET_ALL_CATEGORY_SUCCESS';
export const GET_ALL_CATEGORY_ERROR = 'GET_ALL_CATEGORY_ERROR';

// GET SCHOOL
export const GET_SCHOOL_START = 'GET_SCHOOL_START';
export const GET_SCHOOL_SUCCESS = 'GET_SCHOOL_SUCCESS';
export const GET_SCHOOL_ERROR = 'GET_SCHOOL_ERROR';

// GET ALL SCHOOL
export const GET_ALL_SCHOOL_START = 'GET_ALL_SCHOOL_START';
export const GET_ALL_SCHOOL_SUCCESS = 'GET_ALL_SCHOOL_SUCCESS';
export const GET_ALL_SCHOOL_ERROR = 'GET_ALL_SCHOOL_ERROR';

export const GET_ALL_SCHOOL_ENQUIRY_START = 'GET_ALL_SCHOOL_ENQUIRY_START';
export const GET_ALL_SCHOOL_ENQUIRY_SUCCESS = 'GET_ALL_SCHOOL_ENQUIRY_SUCCESS';
export const GET_ALL_SCHOOL_ENQUIRY_ERROR = 'GET_ALL_SCHOOL_ENQUIRY_ERROR';

// GET SCHOOL LIST
export const GET_SCHOOL_LIST_START = 'GET_SCHOOL_LIST_START';
export const GET_SCHOOL_LIST_SUCCESS = 'GET_SCHOOL_LIST_SUCCESS';
export const GET_SCHOOL_LIST_ERROR = 'GET_SCHOOL_LIST_ERROR';

// GET SCHOOL LEAVE
export const GET_LEAVE_START = 'GET_ALL_LEAVE_START';
export const GET_LEAVE_SUCCESS = 'GET_ALL_LEAVE_SUCCESS';
export const GET_LEAVE_ERROR = 'GET_ALL_LEAVE_ERROR';

// GET ALL SCHOOL LEAVE
export const GET_ALL_LEAVE_START = 'GET_ALL_LEAVE_START';
export const GET_ALL_LEAVE_SUCCESS = 'GET_ALL_LEAVE_SUCCESS';
export const GET_ALL_LEAVE_ERROR = 'GET_ALL_LEAVE_ERROR';

// GET ALL DELIVERY PLAN
export const GET_ALL_DELIVERY_ZONE_START = 'GET_ALL_DELIVERY_ZONE_START';
export const GET_ALL_DELIVERY_ZONE_SUCCESS = 'GET_ALL_DELIVERY_ZONE_SUCCESS';
export const GET_ALL_DELIVERY_ZONE_ERROR = 'GET_ALL_DELIVERY_ZONE_ERROR';

// GET DELIVERY ZONE DATA (ADMIN)
export const GET_DELIVERY_ZONE_START = 'GET_DELIVERY_ZONE_START';
export const GET_DELIVERY_ZONE_SUCCESS = 'GET_DELIVERY_ZONE_SUCCESS';
export const GET_DELIVERY_ZONE_ERROR = 'GET_DELIVERY_ZONE_ERROR';

// GET ALL DELIVERY ZONE PINCODES (ADMIN)
export const GET_ALL_PINCODE_START = 'GET_ALL_PINCODE_START';
export const GET_ALL_PINCODE_SUCCESS = 'GET_ALL_PINCODE_SUCCESS';
export const GET_ALL_PINCODE_ZONE_ERROR = 'GET_ALL_PINCODE_ZONE_ERROR';

// GET ALL BOOK LANGUAGES
export const GET_ALL_LANGUAGE_START = 'GET_ALL_LANGUAGE_START';
export const GET_ALL_LANGUAGE_SUCCESS = 'GET_ALL_LANGUAGE_SUCCESS';
export const GET_ALL_LANGUAGE_ERROR = 'GET_ALL_LANGUAGE_ERROR';

// VIEW PROFILE
export const VIEW_PROFILE_START = 'VIEW_PROFILE_START';
export const VIEW_PROFILE_SUCCESS = 'VIEW_PROFILE_SUCCESS';
export const VIEW_PROFILE_ERROR = 'VIEW_PROFILE_ERROR';

// ADD ADDRESS
export const ADDRESS_START = 'ADDRESS_START';
export const ADDRESS_SUCCESS = 'ADDRESS_SUCCESS';
export const ADDRESS_ERROR = 'ADDRESS_ERROR';

// UPDATE ADDRESS
export const UPDATE_ADDRESS_START = 'UPDATE_ADDRESS_START';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_ERROR = 'ADDRESS_ERROR';

// GET ALL BOOKS
export const GET_ALL_BOOKS_START = 'GET_ALL_BOOKS_START';
export const GET_ALL_BOOKS_SUCCESS = 'GET_ALL_BOOKS_SUCCESS';
export const GET_ALL_BOOKS_ERROR = 'GET_ALL_BOOKS_ERROR';

// ADD BOOKS
export const ADD_BOOKS_START = 'ADD_BOOKS_START';
export const ADD_BOOKS_SUCCESS = 'ADD_BOOKS_SUCCESS';
export const ADD_BOOKS_ERROR = 'ADD_BOOKS_ERROR';

// GET BY ID BOOKS
export const GET_ID_BOOKS_START = 'GET_ID_BOOKS_START';
export const GET_ID_BOOKS_SUCCESS = 'GET_ID_BOOKS_SUCCESS';
export const GET_ID_BOOKS_ERROR = 'GET_ID_BOOKS_ERROR';

// CLEAR GET BY ID BOOKS
export const CLEAR_GET_ID_BOOKS_DATA = 'CLEAR_GET_ID_BOOKS_DATA';

// GET ALL TRANSACTION
export const GET_ALL_TRANSACTION_START = 'GET_ALL_TRANSACTION_START';
export const GET_ALL_TRANSACTION_SUCCESS = 'GET_ALL_TRANSACTION_SUCCESS';
export const GET_ALL_TRANSACTION_ERROR = 'GET_ALL_TRANSACTION_ERROR';

// GET CART DATA (ADMIN)
export const GET_CART_START = 'GET_CART_START';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_ERROR = 'GET_CART_ERROR';

// GET ALL CARTS (ADMIN)
export const GET_ALL_CART_START = 'GET_ALL_CART_START';
export const GET_ALL_CART_SUCCESS = 'GET_ALL_CART_SUCCESS';
export const GET_ALL_CART_ERROR = 'GET_ALL_CART_ERROR';

// GET ALL WISHLIST (ADMIN)
export const GET_ALL_WISHLIST_START = 'GET_ALL_WISHLIST_START';
export const GET_ALL_WISHLIST_SUCCESS = 'GET_ALL_WISHLIST_SUCCESS';
export const GET_ALL_WISHLIST_ERROR = 'GET_ALL_WISHLIST_ERROR';

// GET WISHLIST DATA (ADMIN)
export const GET_WISHLIST_START = 'GET_WISHLIST_START';
export const GET_WISHLIST_SUCCESS = 'GET_WISHLIST_SUCCESS';
export const GET_WISHLIST_ERROR = 'GET_WISHLIST_ERROR';

// GET BULK PROCESS (ADMIN)
export const GET_BULK_START = 'GET_BULK_START';
export const GET_BULK_SUCCESS = 'GET_BULK_SUCCESS';
export const GET_BULK_ERROR = 'GET_BULK_ERROR';

//GET ALL SECURITY DEPOSITS START
export const GET_ALL_SECURITY_DEPOSITS_START = 'GET_ALL_SECURITY_DEPOSITS_START';
export const GET_ALL_SECURITY_DEPOSITS_SUCCESS = 'GET_ALL_SECURITY_DEPOSITS_SUCCESS';
export const GET_ALL_SECURITY_DEPOSITS_ERROR = 'GET_ALL_SECURITY_DEPOSITS_ERROR';

// GET ALL BULK PROCESS (ADMIN)
export const GET_ALL_BULK_START = 'GET_ALL_BULK_START';
export const GET_ALL_BULK_SUCCESS = 'GET_ALL_BULK_SUCCESS';
export const GET_ALL_BULK_ERROR = 'GET_ALL_BULK_ERROR';

// GET SUBSCRIPTION DATA (ADMIN)
export const GET_SUBSCRIPTION_START = 'GET_SUBSCRIPTION_START';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_ERROR = 'GET_SUBSCRIPTION_ERROR';

// GET ALL SUBSCRIPTION DATA (ADMIN)
export const GET_ALL_SUBSCRIPTION_START = 'GET_ALL_SUBSCRIPTION_START';
export const GET_ALL_SUBSCRIPTION_SUCCESS = 'GET_ALL_SUBSCRIPTION_SUCCESS';
export const GET_ALL_SUBSCRIPTION_ERROR = 'GET_ALL_SUBSCRIPTION_ERROR';


export const GET_ALL_BOOKTRIALS_START = 'GET_ALL_BOOKTRIALS_START';
export const GET_ALL_BOOKTRIALS_SUCCESS = 'GET_ALL_BOOKTRIALS_SUCCESS';
export const GET_ALL_BOOKTRIALS_ERROR = 'GET_ALL_BOOKTRIALS_ERROR';

// GET PLAN DATA (ADMIN)
export const GET_PLAN_START = 'GET_PLAN_START';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_ERROR = 'GET_PLAN_ERROR';

// GET ALL PLAN DATA (ADMIN)
export const GET_ALL_PLAN_START = 'GET_ALL_PLAN_START';
export const GET_ALL_PLAN_SUCCESS = 'GET_ALL_PLAN_SUCCESS';
export const GET_ALL_PLAN_ERROR = 'GET_ALL_PLAN_ERROR';

// GET PLAN OPTION DATA (ADMIN)
export const GET_PLAN_OPTION_START = 'GET_PLAN_OPTION_START';
export const GET_PLAN_OPTION_SUCCESS = 'GET_PLAN_OPTION_SUCCESS';
export const GET_PLAN_OPTION_ERROR = 'GET_PLAN_OPTION_ERROR';

// GET ALL PLAN OPTION DATA (ADMIN)
export const GET_ALL_PLAN_OPTION_START = 'GET_ALL_PLAN_OPTION_START';
export const GET_ALL_PLAN_OPTION_SUCCESS = 'GET_ALL_PLAN_OPTION_SUCCESS';
export const GET_ALL_PLAN_OPTION_ERROR = 'GET_ALL_PLAN_OPTION_ERROR';

// GET BOOKS SEARCH FILTERS
export const GET_SEARCH_FILTERS_START = 'GET_SEARCH_FILTERS_START';
export const GET_SEARCH_FILTERS_SUCCESS = 'GET_SEARCH_FILTERS_SUCCESS';
export const GET_SEARCH_FILTERS_ERROR = 'GET_SEARCH_FILTERS_ERROR';

export const GET_SEARCH_FILTERS_TOP = 'GET_SEARCH_FILTERS_TOP';
export const GET_SEARCH_FILTERS_TOP_SUCCESS = 'GET_SEARCH_FILTERS_TOP_SUCCESS';
export const GET_SEARCH_FILTERS_TOP_ERROR = 'GET_SEARCH_FILTERS_TOP_ERROR';

// GET ORDER DATA (ADMIN)
export const GET_ORDER_START = 'GET_ORDER_START';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR';

// GET ALL ORDER DATA (ADMIN)
export const GET_ALL_ORDER_START = 'GET_ALL_ORDER_START';
export const GET_ALL_ORDER_SUCCESS = 'GET_ALL_ORDER_SUCCESS';
export const GET_ALL_ORDER_ERROR = 'GET_ALL_ORDER_ERROR';

export const GET_ALL_PENDING_BOOKS_START = 'GET_ALL_PENDING_BOOKS_START';
export const GET_ALL_PENDING_BOOKS_SUCCESS = 'GET_ALL_PENDING_BOOKS_SUCCESS';
export const GET_ALL_PENDING_BOOKS_ERROR = 'GET_ALL_PENDING_BOOKS_ERROR';

export const GET_ALL_USER_PLAN_START = 'GET_ALL_USER_PLAN_START';
export const GET_ALL_USER_PLAN_SUCCESS = 'GET_ALL_USER_PLAN_SUCCESS';
export const GET_ALL_USER_PLAN_ERROR = 'GET_ALL_USER_PLAN_ERROR';

// GET ALL PROFILE ORDER DATA (ADMIN)
export const GET_PROFILE_ORDER_START = 'GET_PROFILE_ORDER_START';
export const GET_PROFILE_ORDER_SUCCESS = 'GET_PROFILE_ORDER_SUCCESS';
export const GET_PROFILE_ORDER_ERROR = 'GET_PROFILE_ORDER_ERROR';

// GET ALL PROFILE ORDER DATA (ADMIN)
export const GET_BOOK_ORDER_HISTORY_START = 'GET_BOOK_ORDER_HISTORY_START';
export const GET_BOOK_ORDER_HISTORY_SUCCESS = 'GET_BOOK_ORDER_HISTORY_SUCCESS';
export const GET_BOOK_ORDER_HISTORY_ERROR = 'GET_BOOK_ORDER_HISTORY_ERROR';

//BOOKS SEARCH DATA
export const SEARCH_DATA_START = 'SEARCH_DATA_START';
export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS';
export const SEARCH_DATA_ERROR = 'SEARCH_DATA_ERROR';

//VIEW STOCK
export const VIEW_STOCK_START = 'VIEW_STOCK_START';
export const VIEW_STOCK_SUCCESS = 'VIEW_STOCK_SUCCESS';
export const VIEW_STOCK_ERROR = 'VIEW_STOCK_ERROR';

//UPDATE STOCK
export const UPDATE_STOCK_START = 'UPDATE_STOCK_START';
export const UPDATE_STOCK_SUCCESS = 'UPDATE_STOCK_SUCCESS';
export const UPDATE_STOCK_ERROR = 'UPDATE_STOCK_ERROR';

//ADD STOCK
export const ADD_STOCK_START = 'ADD_STOCK_START';
export const ADD_STOCK_SUCCESS = 'ADD_STOCK_SUCCESS';
export const ADD_STOCK_ERROR = 'ADD_STOCK_ERROR';

//GET LIBRARY STOCK
export const GET_LIBRARY_STOCK_START = 'GET_LIBRARY_STOCK_START';
export const GET_LIBRARY_STOCK_SUCCESS = 'GET_LIBRARY_STOCK_SUCCESS';
export const GET_LIBRARY_STOCK_ERROR = 'GET_LIBRARY_STOCK_ERROR';

//UPDATE LIBRARY STOCK
export const UPDATE_LIBRARY_STOCK_START = 'UPDATE_LIBRARY_STOCK_START';
export const UPDATE_LIBRARY_STOCK_SUCCESS = 'UPDATE_LIBRARY_STOCK_SUCCESS';
export const UPDATE_LIBRARY_STOCK_ERROR = 'UPDATE_LIBRARY_STOCK_ERROR';

//VIEW ALL ADDRESS
export const VIEW_ALL_ADDRESS_START = 'VIEW_ALL_ADDRESS_START';
export const VIEW_ALL_ADDRESS_SUCCESS = 'VIEW_ALL_ADDRESS_SUCCESS';
export const VIEW_ALL_ADDRESS_ERROR = 'VIEW_ALL_ADDRESS_ERROR';

//GET MY ADDRESS
export const GET_MY_ADDRESS_START = 'GET_MY_ADDRESS_START';
export const GET_MY_ADDRESS_SUCCESS = 'GET_MY_ADDRESS_SUCCESS';
export const GET_MY_ADDRESS_ERROR = 'GET_MY_ADDRESS_ERROR';

// GET COURSE
export const GET_COURSE_START = 'GET_COURSE_START';
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS';
export const GET_COURSE_ERROR = 'GET_COURSE_ERROR';

// GET ALL COURSES
export const GET_ALL_COURSE_START = 'GET_ALL_COURSE_START';
export const GET_ALL_COURSE_SUCCESS = 'GET_ALL_COURSE_SUCCESS';
export const GET_ALL_COURSE_ERROR = 'GET_ALL_COURSE_ERROR';

// GET MODULE
export const GET_MODULE_START = 'GET_MODULE_START';
export const GET_MODULE_SUCCESS = 'GET_MODULE_SUCCESS';
export const GET_MODULE_ERROR = 'GET_MODULE_ERROR';

// GET ALL MODULES
export const GET_ALL_MODULE_START = 'GET_ALL_MODULE_START';
export const GET_ALL_MODULE_SUCCESS = 'GET_ALL_MODULE_SUCCESS';
export const GET_ALL_MODULE_ERROR = 'GET_ALL_MODULE_ERROR';

// GET ALL SESSIONS
export const GET_ALL_SESSION_START = 'GET_ALL_SESSION_START';
export const GET_ALL_SESSION_SUCCESS = 'GET_ALL_SESSION_SUCCESS';
export const GET_ALL_SESSION_ERROR = 'GET_ALL_SESSION_ERROR';

// GET BATCH
export const GET_BATCH_START = 'GET_BATCH_START';
export const GET_BATCH_SUCCESS = 'GET_MBATCH_SUCCESS';
export const GET_BATCH_ERROR = 'GET_BATCH_ERROR';

// GET ALL BATCHES
export const GET_ALL_BATCH_START = 'GET_ALL_BATCH_START';
export const GET_ALL_BATCH_SUCCESS = 'GET_ALL_BATCH_SUCCESS';
export const GET_ALL_BATCH_ERROR = 'GET_ALL_BATCH_ERROR';

// GET ASSIGNMENT
export const GET_ASSIGNMENT_START = 'GET_ASSIGNMENT_START';
export const GET_ASSIGNMENT_SUCCESS = 'GET_ASSIGNMENT_SUCCESS';
export const GET_ASSIGNMENT_ERROR = 'GET_ASSIGNMENT_ERROR';

// GET ALL ASSIGNMENTS
export const GET_ALL_ASSIGNMENT_START = 'GET_ALL_ASSIGNMENT_START';
export const GET_ALL_ASSIGNMENT_SUCCESS = 'GET_ALL_ASSIGNMENT_SUCCESS';
export const GET_ALL_ASSIGNMENT_ERROR = 'GET_ALL_ASSIGNMENT_ERROR';

// GET ASSIGNMENT
export const GET_DOCUMENT_START = 'GET_DOCUMENT_START';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_ERROR = 'GET_DOCUMENT_ERROR';

// GET ALL ASSIGNMENTS
export const GET_ALL_DOCUMENT_START = 'GET_ALL_DOCUMENT_START';
export const GET_ALL_DOCUMENT_SUCCESS = 'GET_ALL_DOCUMENT_SUCCESS';
export const GET_ALL_DOCUMENT_ERROR = 'GET_ALL_DOCUMENT_ERROR';

// GET ALL TEACHER OF BATCH
export const GET_ALL_TEACHERS_START = 'GET_ALL_TEACHERS_START';
export const GET_ALL_TEACHERS_SUCCESS = 'GET_ALL_TEACHERS_SUCCESS';
export const GET_ALL_TEACHERS_ERROR = 'GET_ALL_TEACHERS_ERROR';

// GET ALL STUDENT OF BATCH
export const GET_ALL_STUDENTS_START = 'GET_ALL_STUDENTS_START';
export const GET_ALL_STUDENTS_SUCCESS = 'GET_ALL_STUDENTS_SUCCESS';
export const GET_ALL_STUDENTS_ERROR = 'GET_ALL_STUDENTS_ERROR';

// GET ALL Libarary Plans with options
export const GET_ALL_PLANS_WITH_OPTIONS_START = 'GET_ALL_PLANS_WITH_OPTIONS_START';
export const GET_ALL_PLANS_WITH_OPTIONS_SUCCESS = 'GET_ALL_PLANS_WITH_OPTIONS_SUCCESS';
export const GET_ALL_PLANS_WITH_OPTIONS_ERROR = 'GET_ALL_PLANS_WITH_OPTIONS_ERROR';

// GET USER PROFILE
export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';

// GET SEARCHED PROFILE
export const GET_SEARCHED_PROFILE_START = 'GET_SEARCHED_PROFILE_START';
export const GET_SEARCHED_PROFILE_SUCCESS = 'GET_SEARCHED_PROFILE_SUCCESS';
export const GET_SEARCHED_PROFILE_ERROR = 'GET_SEARCHED_PROFILE_ERROR';

// GET FAQs
export const GET_FAQ_START = 'GET_FAQ_START';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_ERROR = 'GET_FAQ_ERROR';

// GET ALL FAQs
export const GET_ALL_FAQ_START = 'GET_ALL_FAQ_START';
export const GET_ALL_FAQ_SUCCESS = 'GET_ALL_FAQ_SUCCESS';
export const GET_ALL_FAQ_ERROR = 'GET_ALL_FAQ_ERROR';

// GET QUES/ANS
export const GET_QA_START = 'GET_QA_START';
export const GET_QA_SUCCESS = 'GET_QA_SUCCESS';
export const GET_QA_ERROR = 'GET_QA_ERROR';

// GET ALL QUES/ANS
export const GET_ALL_QA_START = 'GET_ALL_QA_START';
export const GET_ALL_QA_SUCCESS = 'GET_ALL_QA_SUCCESS';
export const GET_ALL_QA_ERROR = 'GET_ALL_QA_ERROR';

// GET ALL FEEDBACK
export const GET_ALL_FEEDBACK_START = 'GET_ALL_FEEDBACK_START';
export const GET_ALL_FEEDBACK_SUCCESS = 'GET_ALL_FEEDBACK_SUCCESS';
export const GET_ALL_FEEDBACK_ERROR = 'GET_ALL_FEEDBACK_ERROR';

// GET COURSE ALL FEEDBACK
export const GET_ALL_COURSE_FEEDBACK_START = 'GET_ALL_COURSE_FEEDBACK_START';
export const GET_ALL_COURSE_FEEDBACK_SUCCESS = 'GET_ALL_COURSE_FEEDBACK_SUCCESS';
export const GET_ALL_COURSE_FEEDBACK_ERROR = 'GET_ALL_COURSE_FEEDBACK_ERROR';

// GET FEEDBACK
export const GET_FEEDBACK_START = 'GET_FEEDBACK_START';
export const GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS';
export const GET_FEEDBACK_ERROR = 'GET_FEEDBACK_ERROR';

// GET GRADE
export const GET_GRADE_START = 'GET_GRADE_START';
export const GET_GRADE_SUCCESS = 'GET_GRADE_SUCCESS';
export const GET_GRADE_ERROR = 'GET_GRADE_ERROR';

// GET ALL GRADE
export const GET_ALL_GRADE_START = 'GET_ALL_GRADE_START';
export const GET_ALL_GRADE_SUCCESS = 'GET_ALL_GRADE_SUCCESS';
export const GET_ALL_GRADE_ERROR = 'GET_ALL_GRADE_ERROR';

// GET REPORT
export const GET_REPORT_START = 'GET_REPORT_START';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';

// GET ALL REPORT
export const GET_ALL_REPORT_START = 'GET_ALL_REPORT_START';
export const GET_ALL_REPORT_SUCCESS = 'GET_ALL_REPORT_SUCCESS';
export const GET_ALL_REPORT_ERROR = 'GET_ALL_REPORT_ERROR';

// GET MEETING
export const GET_MEETING_START = 'GET_MEETING_START';
export const GET_MEETING_SUCCESS = 'GET_MEETING_SUCCESS';
export const GET_MEETING_ERROR = 'GET_MEETING_ERROR';

// GET ALL MEETING
export const GET_ALL_MEETING_START = 'GET_ALL_MEETING_START';
export const GET_ALL_MEETING_SUCCESS = 'GET_ALL_MEETING_SUCCESS';
export const GET_ALL_MEETING_ERROR = 'GET_ALL_MEETING_ERROR';

// GET ASSIGNMENT SUBMISSION (ADMIN)
export const GET_ASSIGNMENT_SUBMISSION_START = 'GET_ASSIGNMENT_SUBMISSION_START';
export const GET_ASSIGNMENT_SUBMISSION_SUCCESS = 'GET_ASSIGNMENT_SUBMISSION_SUCCESS';
export const GET_ASSIGNMENT_SUBMISSION_ERROR = 'GET_ASSIGNMENT_SUBMISSION_ERROR';

// GET ALL ASSIGNMENT SUBMISSION (ADMIN)
export const GET_ALL_ASSIGNMENT_SUBMISSION_START = 'GET_ALL_ASSIGNMENT_SUBMISSION_START';
export const GET_ALL_ASSIGNMENT_SUBMISSION_SUCCESS = 'GET_ALL_ASSIGNMENT_SUBMISSION_SUCCESS';
export const GET_ALL_ASSIGNMENT_SUBMISSION_ERROR = 'GET_ALL_ASSIGNMENT_SUBMISSION_ERROR';

// GET DISCOUNT
export const GET_DISCOUNT_START = 'GET_DISCOUNT_START';
export const GET_DISCOUNT_SUCCESS = 'GET_DISCOUNT_SUCCESS';
export const GET_DISCOUNT_ERROR = 'GET_DISCOUNT_ERROR';

// GET ALL DISCOUNT
export const GET_ALL_DISCOUNT_START = 'GET_ALL_DISCOUNT_START';
export const GET_ALL_DISCOUNT_SUCCESS = 'GET_ALL_DISCOUNT_SUCCESS';
export const GET_ALL_DISCOUNT_ERROR = 'GET_ALL_DISCOUNT_ERROR';

// GET GIFT-VOUCHER
export const GET_VOUCHER_START = 'GET_VOUCHER_START';
export const GET_VOUCHER_SUCCESS = 'GET_VOUCHER_SUCCESS';
export const GET_VOUCHER_ERROR = 'GET_VOUCHER_ERROR';

// GET ALL GIFT-VOUCHER
export const GET_ALL_VOUCHER_START = 'GET_ALL_VOUCHER_START';
export const GET_ALL_VOUCHER_SUCCESS = 'GET_ALL_VOUCHER_SUCCESS';
export const GET_ALL_VOUCHER_ERROR = 'GET_ALL_VOUCHER_ERROR';

// GET ALL REFERRALS
export const GET_ALL_REFERRAL_START = 'GET_ALL_REFERRAL_START';
export const GET_ALL_REFERRAL_SUCCESS = 'GET_ALL_REFERRAL_SUCCESS';
export const GET_ALL_REFERRAL_ERROR = 'GET_ALL_REFERRAL_ERROR';

// GET ALL REFERRALS STUDENTS
export const GET_ALL_STUDENT_REFERRAL_START = 'GET_ALL_STUDENT_REFERRAL_START';
export const GET_ALL_STUDENT_REFERRAL_SUCCESS = 'GET_ALL_STUDENT_REFERRAL_SUCCESS';
export const GET_ALL_STUDENT_REFERRAL_ERROR = 'GET_ALL_STUDENT_REFERRAL_ERROR';

// GET ALL BATCHES(MODULES) OF PROFILE
export const GET_ALL_STUDENT_MODULE_START = 'GET_ALL_STUDENT_MODULE_START';
export const GET_ALL_STUDENT_MODULE_SUCCESS = 'GET_ALL_STUDENT_MODULE_SUCCESS';
export const GET_ALL_STUDENT_MODULE_ERROR = 'GET_ALL_STUDENT_MODULE_ERROR';

// GET ALL EVENTS

export const GET_EVENT_START = 'GET_ALL_REFERRAL_START';
export const GET_EVENT_SUCCESS = 'GET_ALL_REFERRAL_SUCCESS';
export const GET_EVENT_ERROR = 'GET_ALL_REFERRAL_ERROR';

// PENDING HOMEWORK
export const GET_PENDING_HOMEWORK_START = 'GET_PENDING_HOMEWORK_START';
export const GET_PENDING_HOMEWORK_SUCCESS = 'GET_PENDING_HOMEWORK_SUCCESS';
export const GET_PENDING_HOMEWORK_ERROR = 'GET_PENDING_HOMEWORK_ERROR';

// FOCUS SKILLS
export const GET_FOCUS_SKILL_START = 'GET_FOCUS_SKILL_START';
export const GET_FOCUS_SKILL_SUCCESS = 'GET_FOCUS_SKILL_SUCCESS';
export const GET_FOCUS_SKILL_ERROR = 'GET_FOCUS_SKILL_ERROR';

// GET ALL DELIVERY PLAN
export const GET_ALL_DELIVERY_PLAN_START = 'GET_ALL_DELIVERY_PLAN_START';
export const GET_ALL_DELIVERY_PLAN_SUCCESS = 'GET_ALL_DELIVERY_PLAN_SUCCESS';
export const GET_ALL_DELIVERY_PLAN_ERROR = 'GET_ALL_DELIVERY_PLAN_ERROR';

//SUBSCRIPTION PLAN
export const GET_ALL_CALCULATE_START = 'GET_ALL_CALCULATE_START';
export const GET_ALL_CALCULATE_SUCCESS = 'GET_ALL_CALCULATE_SUCCESS';
export const GET_ALL_CALCULATE_ERROR = 'GET_ALL_CALCULATE_ERROR';

//FOCUS SKILL
export const GET_ALL_FOCUS_SKILL_START = 'GET_ALL_FOCUS_SKILL_START';
export const GET_ALL_FOCUS_SKILL_SUCCESS = 'GET_ALL_FOCUS_SKILL_SUCCESS';
export const GET_ALL_FOCUS_SKILL_ERROR = 'GET_ALL_FOCUS_SKILL_ERROR';

//FOCUS SKILL
export const GET_ALL_MODULE_DETAIL_START = 'GET_ALL_MODULE_DETAIL_START';
export const GET_ALL_MODULE_DETAIL_SUCCESS = 'GET_ALL_MODULE_DETAIL_SUCCESS';
export const GET_ALL_MODULE_DETAIL_ERROR = 'GET_ALL_MODULE_DETAIL_ERROR';


export const GET_ALL_CONTACTUS_START = 'GET_ALL_CONTACTUS_START';
export const GET_ALL_CONTACTUS_SUCCESS = 'GET_ALL_CONTACTUS_SUCCESS';
export const GET_ALL_CONTACTUS_ERROR = 'GET_ALL_CONTACTUS_ERROR';