import { http } from '../../../utils/http';
import { setMessage } from '../MessageActions';
import {
    GET_ALL_COURSE_START,
    GET_ALL_COURSE_SUCCESS,
    GET_ALL_COURSE_ERROR,
    GET_COURSE_START,
    GET_COURSE_SUCCESS,
    GET_COURSE_ERROR,
    GET_ALL_MODULE_START,
    GET_ALL_MODULE_SUCCESS,
    GET_ALL_MODULE_ERROR,
    GET_MODULE_START,
    GET_MODULE_SUCCESS,
    GET_MODULE_ERROR,
    GET_ALL_SESSION_START,
    GET_ALL_SESSION_SUCCESS,
    GET_ALL_SESSION_ERROR,
    GET_ALL_BATCH_START,
    GET_ALL_BATCH_SUCCESS,
    GET_ALL_BATCH_ERROR,
    GET_BATCH_START,
    GET_BATCH_SUCCESS,
    GET_BATCH_ERROR,
    GET_ALL_ASSIGNMENT_START,
    GET_ALL_ASSIGNMENT_SUCCESS,
    GET_ALL_ASSIGNMENT_ERROR,
    GET_ASSIGNMENT_START,
    GET_ASSIGNMENT_SUCCESS,
    GET_ASSIGNMENT_ERROR,
    GET_ALL_DOCUMENT_START,
    GET_ALL_DOCUMENT_SUCCESS,
    GET_ALL_DOCUMENT_ERROR,
    GET_ALL_TEACHERS_START,
    GET_ALL_TEACHERS_SUCCESS,
    GET_ALL_TEACHERS_ERROR,
    GET_ALL_STUDENTS_START,
    GET_ALL_STUDENTS_SUCCESS,
    GET_ALL_STUDENTS_ERROR
} from '../../constants';
import Cookies from 'js-cookie';

const getCourseStart = () => {
    return { type: GET_COURSE_START };
};
const getCourseSuccess = (payload) => {
    return { type: GET_COURSE_SUCCESS, payload };
};
const getCourseError = (error) => {
    return { type: GET_COURSE_ERROR, error };
};

const getAllCourseStart = () => {
    return { type: GET_ALL_COURSE_START };
};
const getAllCourseSuccess = (payload) => {
    return { type: GET_ALL_COURSE_SUCCESS, payload };
};
const getAllCourseError = (error) => {
    return { type: GET_ALL_COURSE_ERROR, error };
};

const getModuleStart = () => {
    return { type: GET_MODULE_START };
};
const getModuleSuccess = (payload) => {
    return { type: GET_MODULE_SUCCESS, payload };
};
const getModuleError = (error) => {
    return { type: GET_MODULE_ERROR, error };
};

const getAllModuleStart = () => {
    return { type: GET_ALL_MODULE_START };
};
const getAllModuleSuccess = (payload) => {
    return { type: GET_ALL_MODULE_SUCCESS, payload };
};
const getAllModuleError = (error) => {
    return { type: GET_ALL_MODULE_ERROR, error };
};

const getAllSessionStart = () => {
    return { type: GET_ALL_SESSION_START };
};
const getAllSessionSuccess = (payload) => {
    return { type: GET_ALL_SESSION_SUCCESS, payload };
};
const getAllSessionError = (error) => {
    return { type: GET_ALL_SESSION_ERROR, error };
};

const getBatchStart = () => {
    return { type: GET_BATCH_START };
};
const getBatchSuccess = (payload) => {
    return { type: GET_BATCH_SUCCESS, payload };
};
const getBatchError = (error) => {
    return { type: GET_BATCH_ERROR, error };
};

const getAllBatchStart = () => {
    return { type: GET_ALL_BATCH_START };
};
const getAllBatchSuccess = (payload) => {
    return { type: GET_ALL_BATCH_SUCCESS, payload };
};
const getAllBatchError = (error) => {
    return { type: GET_ALL_BATCH_ERROR, error };
};

const getAssignmentStart = () => {
    return { type: GET_ASSIGNMENT_START };
};
const getAssignmentSuccess = (payload) => {
    return { type: GET_ASSIGNMENT_SUCCESS, payload };
};
const getAssignmentError = (error) => {
    return { type: GET_ASSIGNMENT_ERROR, error };
};

const getAllAssignmentStart = () => {
    return { type: GET_ALL_ASSIGNMENT_START };
};
const getAllAssignmentSuccess = (payload) => {
    return { type: GET_ALL_ASSIGNMENT_SUCCESS, payload };
};
const getAllAssignmentError = (error) => {
    return { type: GET_ALL_ASSIGNMENT_ERROR, error };
};

const getAllDocumentStart = () => {
    return { type: GET_ALL_DOCUMENT_START };
};
const getAllDocumentSuccess = (payload) => {
    return { type: GET_ALL_DOCUMENT_SUCCESS, payload };
};
const getAllDocumentError = (error) => {
    return { type: GET_ALL_DOCUMENT_ERROR, error };
};

const getAllTeachersStart = () => {
    return { type: GET_ALL_TEACHERS_START };
};
const getAllTeachersSuccess = (payload) => {
    return { type: GET_ALL_TEACHERS_SUCCESS, payload };
};
const getAllTeachersError = (error) => {
    return { type: GET_ALL_TEACHERS_ERROR, error };
};

const getAllStudentStart = () => {
    return { type: GET_ALL_STUDENTS_START };
};
const getAllStudentSuccess = (payload) => {
    return { type: GET_ALL_STUDENTS_SUCCESS, payload };
};
const getAllStudentError = (error) => {
    return { type: GET_ALL_STUDENTS_ERROR, error };
};

const addCourse = (payload, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', 'subscriptions-service/courses', payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Course Added Successfully'));
                successCB && successCB(res);
            })
            .catch((error) => {
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const addModule = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/modules/course/${id}`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Module Added Successfully'));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const addSession = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/sessions/module/${id}`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};
const moduleDetails = (mid, payload, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/modules/${mid}/detail`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.data?.message ? res?.data?.message : 'Added Successfully!!'));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const updateSession = (payload, mid, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'PUT', `subscriptions-service/sessions/${mid}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message ? res?.message : 'Module updated Successfully'));
                    successCB && successCB(res);
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const addBatch = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'POST', `batches-service/batches/modules/${id}`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const addAssignment = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'POST', `batches-service/assignments/meetings/${id}`, payload, true)
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Added Successfully!'));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);
                // if (error.code === 409) {
                dispatch(setMessage(error?.data?.message));
                // }
            });
    } catch (error) {
        console.log(error);
    }
};

const add_Teacher_In_Batch = (teacherId, batchId, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'POST', `batches-service/batches/${batchId}/teachers/${teacherId}`, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);

                dispatch(setMessage(error?.data?.message ? error?.data?.message : 'Conflict!'));
            });
    } catch (error) {
        console.log(error);
    }
};

const addDocument = (id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'POST', `batches-service/assignments/${id}/document`, true)
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Added Successfully'));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
        // if (error.code === 409) {
        //     dispatch(setMessage(error?.data?.message));
        // }
    }
};

const add_Student_In_Batch = (studentId, batchId, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'POST', `batches-service/batches/${batchId}/students/${studentId}`, true)
            .then((res) => {
                dispatch(setMessage(res?.message));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const delete_Teacher_In_Batch = (teacherId, batchId, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'DELETE', `batches-service/batches/${batchId}/teachers/${teacherId}`, true)
            .then((res) => {
                dispatch(setMessage('Deleted Successfully'));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const delete_Student_In_Batch = (studentId, batchId, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'DELETE', `batches-service/batches/${batchId}/students/${studentId}`, true)
            .then((res) => {
                dispatch(setMessage('Deleted Successfully'));
                successCB && successCB(res);
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const get_Batch_All_Teachers =
    (batchId, searchData = '', successCB) =>
    (dispatch) => {
        dispatch(getAllTeachersStart());
        try {
            http(process.env.REACT_APP_ROOT_URL_BATCHES, 'GET', `batches-service/batches/${batchId}/teachers?searchKey=${searchData}`, true)
                .then((res) => {
                    const payload = {
                        allTeachersData: res
                    };
                    dispatch(getAllTeachersSuccess(payload));
                    successCB && successCB(res);
                })
                .catch((error) => {
                    dispatch(getAllTeachersError(error));
                    dispatch(setMessage(error?.data?.message));
                });
        } catch (error) {
            dispatch(getAllTeachersError('Something went wrong'));
        }
    };

const get_Batch_All_Student =
    (batchId, searchData = '', successCB) =>
    (dispatch) => {
        dispatch(getAllStudentStart());
        try {
            http(process.env.REACT_APP_ROOT_URL_BATCHES, 'GET', `batches-service/batches/${batchId}/students?searchKey=${searchData}`, true)
                .then((res) => {
                    const payload = {
                        allStudentsData: res
                    };
                    dispatch(getAllStudentSuccess(payload));
                    successCB && successCB(res);
                })
                .catch((error) => {
                    dispatch(getAllStudentError(error));
                    dispatch(setMessage(error?.data?.message));
                });
        } catch (error) {
            dispatch(getAllStudentError('Something went wrong'));
        }
    };

const updateCourse = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'PUT', `subscriptions-service/courses/${id}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message ? res?.message : 'Course updated Successfully'));
                    successCB && successCB(res);
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const updateModule = (payload, mid, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'PUT', `subscriptions-service/modules/${mid}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message ? res?.message : 'Module updated Successfully'));
                    successCB && successCB(res);
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const updateBatch = (payload, mid, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'PUT', `batches-service/batches/${mid}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message));
                    successCB && successCB(res);
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const updateAssignment = (payload, mid, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'PUT', `batches-service/assignments/${mid}`, payload, true)
            .then((res) => {
                if (res) {
                    dispatch(setMessage(res?.message ? res?.message : 'Updated Successfully'));
                    successCB && successCB(res);
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const getCourse = (id, successCB) => (dispatch) => {
    dispatch(getCourseStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/courses/${id}`, true)
            .then((res) => {
                const payload = {
                    getCourseData: res
                };
                dispatch(getCourseSuccess(payload));
                successCB && successCB(res);
            })
            .catch((error) => {
                dispatch(getCourseError(error));
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        dispatch(getCourseError('Something went wrong'));
    }
};

const getModule = (mid, successCB) => (dispatch) => {
    dispatch(getModuleStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'GET', `subscriptions-service/modules/${mid}`, true)
            .then((res) => {
                const payload = {
                    getModuleData: res
                };
                dispatch(getModuleSuccess(payload));
                successCB && successCB(res);
            })
            .catch((error) => {
                dispatch(getModuleError(error));
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        dispatch(getModuleError('Something went wrong'));
    }
};

const getModuleDetails = (mid, successCB) => (dispatch) => {
    dispatch(getModuleStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'POST', `subscriptions-service/modules/${mid}/detail`, false)
            .then((res) => {
                const payload = {
                    getModuleData: res
                };
                dispatch(getModuleSuccess(payload));
                successCB && successCB(res);
            })
            .catch((error) => {
                dispatch(getModuleError(error));
                dispatch(setMessage(error?.data?.message));
            });
    } catch (error) {
        dispatch(getModuleError('Something went wrong'));
    }
};

const getBatch = (mid, successCB) => (dispatch) => {
    dispatch(getBatchStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'GET', `batches-service/batches/${mid}`, true)
            .then((res) => {
                const payload = {
                    getBatchData: res
                };
                dispatch(getBatchSuccess(payload));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(getBatchError(err));
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        dispatch(getBatchError('Something went wrong'));
    }
};

const getAssignment =
    (mid, searchData = '', successCB) =>
    (dispatch) => {
        dispatch(getAssignmentStart());
        try {
            http(process.env.REACT_APP_ROOT_URL_BATCHES, 'GET', `batches-service/assignments/${mid}?searchKey=${searchData}`, true)
                .then((res) => {
                    const payload = {
                        getAssignmentData: res
                    };
                    dispatch(getAssignmentSuccess(payload));
                    successCB && successCB(res);
                })
                .catch((err) => {
                    dispatch(getAssignmentError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAssignmentError('Something went wrong'));
        }
    };

const getOnlineCourses =
    (searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllCourseStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/courses?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allCourses: res
                    };
                    dispatch(getAllCourseSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllCourseError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllCourseError('Something went wrong'));
        }
    };
const getAllDeletedCourses =
    (searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllCourseStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/courses/deleted?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allDeletedCourses: res
                    };
                    dispatch(getAllCourseSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllCourseError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllCourseError('Something went wrong'));
        }
    };
const token = Cookies.get('token');

const getAllCourse =
    (id, age = '', rowsPerPage = 10, searchData = '', page = 1) =>
    (dispatch) => {
        dispatch(getAllModuleStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/modules/course/${id}?age=${age}&searchKey=${searchData}&page=${page}&limit=${rowsPerPage}`,
                {},
                token ? true : false,
                {},
                {}
            )
                .then((res) => {
                    const payload = {
                        allModules: res
                    };
                    dispatch(getAllModuleSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllModuleError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllModuleError('Something went wrong'));
        }
    };

const getAllModule =
    (id, rowsPerPage = 10, searchData = '', page = 1) =>
    (dispatch) => {
        dispatch(getAllSessionStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
                'GET',
                `subscriptions-service/sessions/module/${id}?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                {},
                token ? true : false,
                {},
                {}
            )
                .then((res) => {
                    const payload = {
                        allSessions: res
                    };
                    dispatch(getAllSessionSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllSessionError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllModuleError('Something went wrong'));
        }
    };

const getAllSession = (id) => (dispatch) => {
    dispatch(getAllSessionStart());
    try {
        http(
            process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
            'GET',
            `subscriptions-service/sessions/module/${id}?pageNumber=1&pageSize=20`,
            false
        )
            .then((res) => {
                const payload = {
                    allSessions: res
                };
                dispatch(getAllSessionError(payload));
            })
            .catch((err) => {
                dispatch(getAllSessionError(err));

                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        dispatch(getAllSessionError('Something went wrong'));
    }
};

const getAllBatch =
    (id, page = 1, rowsPerPage = 10, batchStartDate = '', batchEndDate) =>
    (dispatch) => {
        dispatch(getAllBatchStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_BATCHES,
                'GET',
                `batches-service/batches/modules/${id}?pageNumber=${page}&pageSize=${rowsPerPage}&startDate=${batchStartDate}&endDate=${batchEndDate}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allBatches: res
                    };
                    dispatch(getAllBatchSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllBatchError(err));
                    dispatch(setMessage(err?.message));
                });
        } catch (error) {
            dispatch(getAllBatchError('Something went wrong'));
        }
    };

const getAllAssignment =
    (id, searchData = '', page = 1, rowsPerPage = 10) =>
    (dispatch) => {
        dispatch(getAllAssignmentStart());
        try {
            http(
                process.env.REACT_APP_ROOT_URL_BATCHES,
                'GET',
                `batches-service/assignments/meetings/${id}?searchKey=${searchData}&pageNumber=${page}&pageSize=${rowsPerPage}`,
                true
            )
                .then((res) => {
                    const payload = {
                        allAssignments: res
                    };
                    dispatch(getAllAssignmentSuccess(payload));
                })
                .catch((err) => {
                    dispatch(getAllAssignmentError(err));
                    dispatch(setMessage(err?.data?.message));
                });
        } catch (error) {
            dispatch(getAllAssignmentError('Something went wrong'));
        }
    };

const getAllDocument = (id, successCB) => (dispatch) => {
    dispatch(getAllDocumentStart());
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'GET', `batches-service/assignments/${id}/document-links`, true)
            .then((res) => {
                const payload = {
                    allDocuments: res
                };
                dispatch(getAllDocumentSuccess(payload));
                successCB && successCB(res);
            })
            .catch((err) => {
                dispatch(getAllDocumentError(err));
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        dispatch(getAllDocumentError('Something went wrong'));
    }
};

const deleteModule = (mid, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'DELETE', `subscriptions-service/courses/modules/${mid}`, true)
            .then((res) => {
                dispatch(setMessage(res?.data?.message ? res?.data?.message : 'Deleted Module Successfully'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
        dispatch(setMessage('Deleted Module Successfully'));
    }
};

const deleteSession = (mid, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'DELETE', `subscriptions-service/sessions/${mid}`, true)
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Session Deleted Successfully'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
        dispatch(setMessage('Deleted Module Successfully'));
    }
};

const deleteDocument = (aid, link, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_BATCHES, 'DELETE', `batches-service/assignments/${aid}/document-links?link=${link}`, true)
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Deleted Successfully'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};
const shiftModuleUp = (mid, successCB) => (dispatch) => {
    try {
        http(
            process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS,
            'PUT',

            `subscriptions-service/sessions/shift/${mid}?shiftDown=false`,
            true
        )
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Shifted Successfully'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

const shiftModuleDown = (mid, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'PUT', `subscriptions-service/sessions/shift/${mid}?shiftDown=true`, true)
            .then((res) => {
                dispatch(setMessage(res?.message ? res?.message : 'Shifted Successfully'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};
const deleteCourse = (payload, id, successCB) => (dispatch) => {
    try {
        http(process.env.REACT_APP_ROOT_URL_SUBSCRIPTIONS, 'PUT', `subscriptions-service/courses/${id}`, payload, true)
            .then((res) => {
                dispatch(setMessage(payload.status === 'INACTIVE' ? 'Deleted Successfully!' : 'Restore Successfully'));
                successCB && successCB(res);
            })
            .catch((err) => {
                console.log(err);
                dispatch(setMessage(err?.data?.message));
            });
    } catch (error) {
        console.log(error);
    }
};

export {
    getOnlineCourses,
    deleteCourse,
    getAllCourse,
    addCourse,
    updateCourse,
    getCourse,
    getAllModule,
    getAllSession,
    addModule,
    updateModule,
    getModule,
    getModuleDetails,
    shiftModuleUp,
    shiftModuleDown,
    deleteModule,
    getAllBatch,
    addBatch,
    updateBatch,
    getBatch,
    deleteDocument,
    getAllAssignment,
    getAllDocument,
    getAssignment,
    updateAssignment,
    addDocument,
    addAssignment,
    add_Teacher_In_Batch,
    get_Batch_All_Teachers,
    get_Batch_All_Student,
    delete_Teacher_In_Batch,
    delete_Student_In_Batch,
    add_Student_In_Batch,
    addSession,
    updateSession,
    deleteSession,
    moduleDetails,
    getAllDeletedCourses
};
