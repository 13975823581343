import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import useStyles from '../../../../DialogStyles';
import { setMessage } from '../../../../../store/actions/MessageActions';
import { deduction, getSecurityDeposits } from '../../../../../services/SecurityDeposits';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, Link, Divider } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

const validationSchema = yup.object({
    deduction: yup.string().required('Amount is required'),
    deductionComment: yup.string().required('Comment is required')
});

export default function AddDeductions(props) {
    const { isOpen, setOpen, rowData } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const initialValues = {
        deduction: '',
        deductionComment: ''
    };
    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: initialValues
    });
    const { reset, handleSubmit } = methods;

    const handlePopupClose = () => {
        setOpen(false);
        reset();
    };
    const onSubmit = async (data) => {
        const payload = {
            deduction: data?.deduction,
            deductionComment: data?.deductionComment
        };

        await deduction(rowData?.id, payload, dispatch)
            .then((res) => {
                dispatch(setMessage(res?.message));

                getSecurityDeposits()
                    .then((resp) => {
                        handlePopupClose();
                    })
                    .catch((err) => {});
                handlePopupClose();
                props.getAllDeposits();
            })
            .catch((error) => {
                console.log(error);
                dispatch(setMessage(error?.data?.message));
            });
    };

    return (
        <div>
            <Dialog open={isOpen} classes={{ paper: classes.dialog }}>
                <DialogTitle className={classes.dialogTitle}>
                    <div className={classes.dialogTitleChild}>
                        <Typography style={{ fontSize: '20px' }}> Add Deductions </Typography>
                        <Link to="#" onClick={handlePopupClose}>
                            <CloseIcon className={classes.closeIcon} />
                        </Link>
                    </div>
                </DialogTitle>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <PerfectScrollbar className={classes.ScrollHeight}>
                        <DialogContent>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12}>
                                    <label>
                                        Amount <span className={classes.mandatory}>*</span>
                                    </label>
                                    <RHFTextField name="deduction" autoFocus />
                                </Grid>
                                <Grid item xs={12}>
                                    <label>
                                        Comment <span className={classes.mandatory}>*</span>
                                    </label>
                                    <RHFTextField name="deductionComment" />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </PerfectScrollbar>
                    <Divider style={{ marginTop: 15 }} />
                    <DialogActions className={classes.dialogAction}>
                        <Button variant="outlined" color="secondary" className={classes.buttonCancel} onClick={handlePopupClose}>
                            Close
                        </Button>
                        <Button variant="contained" type="submit" onClick={onSubmit} className={classes.buttonSubmit}>
                            Deduct
                        </Button>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </div>
    );
}
